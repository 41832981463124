import React, { RefObject, useEffect, useState } from "react";

import {
    Button, CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {AddRounded, CloseRounded} from "@mui/icons-material";

import { FieldArray, Formik, FormikProps, getIn } from "formik";
import { nameof } from "ts-simple-nameof";

import {
    UserInvitationDto,
    userInvitationDtoInitialState,
    UserInvitationsForm,
    userInvitationsValidationSchema
} from "./models";

import { LookupDto } from "common/services/LookupService/models";
import { lookupService } from "common/services/LookupService/lookupService";
import { renderLookupsAsMenuItem } from "common/helpers/Object/object";

import "./styles.scss"

export interface InviteUsersFormProps {
    infoText: string;
    initialState: UserInvitationsForm;
    onSubmit: (invites: UserInvitationDto[]) => Promise<void>;
    entryRequired: boolean;
    formRef: RefObject<any>;
}

const InviteUsersForm: React.FC<InviteUsersFormProps> = ({infoText, initialState, entryRequired, onSubmit, formRef}) => {
    
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [userRoles, setUserRoles] = useState<LookupDto<string>[]>([])
    
    useEffect(() => {
        if (userRoles.length === 0) {
            lookupService.getUserRoles().then(response => {
                setUserRoles(response);
            })
        }
    }, [userRoles.length]);
    
    return (
        <Formik
            innerRef={formRef}
            onSubmit={(values) => {
                setSubmitting(true)
                onSubmit(values.invites).finally(() => setSubmitting(false));
            }}
            validationSchema={userInvitationsValidationSchema}
            initialValues={initialState}
            enableReinitialize
        >
            {(props: FormikProps<UserInvitationsForm>) => {

                const {errors, touched, handleChange, setFieldValue, values} = props;
                
                const onAddAnotherClicked = () => {
                    setFieldValue(nameof<UserInvitationsForm>(f => f.invites), [...values.invites, userInvitationDtoInitialState])
                }

                const onDeleteClicked = (index: number) => {
                    setFieldValue(nameof<UserInvitationsForm>(f => f.invites),
                        values.invites.length === 1 && entryRequired
                            ? initialState.invites
                            : values.invites.filter((f, i) => i !== index)
                    );
                }

                return (
                    <Grid container spacing={3}>
                        <Grid item container spacing={1}>
                            <Grid container item alignItems={"center"} spacing={1}>
                                <Grid item>
                                    <Typography variant={"h6"}>Invite New Members</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color={"textSecondary"}>
                                        {infoText}
                                        <br/>
                                        <br/>
                                    There are several Role options for Users:
                                        <br/>
                                        <br/>
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <b>Admin</b>
                                        </Grid>
                                        <Grid item xs={10}>
                                            You have full access and control of the application
                                        </Grid>
                                        <Grid item xs={2}>
                                            <b>Officer</b>
                                        </Grid>
                                        <Grid item xs={10}>
                                            You are an Officer of the company, and have been allocated higher privileges
                                        </Grid>
                                        <Grid item xs={2}>
                                            <b>User</b>
                                        </Grid>
                                        <Grid item xs={10}>
                                            You are an unprivileged User with general standard access to the app   
                                        </Grid>
                                        <Grid item xs={2}>
                                            <b>Assessor</b>
                                        </Grid>
                                        <Grid item xs={10}>
                                            You are providing a peer guided assessment and will change the status's based on your investigation
                                        </Grid>
                                       
                                        <Grid item xs={2}>
                                            <b>Contributor</b>
                                        </Grid>
                                        <Grid item xs={10}>
                                            You are able to update notes and add attachments, but not able to change the status's of questions
                                        </Grid>
                                    </Grid> 
                                </Grid>
                            </Grid>
                            <Grid container item spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant={"subtitle1"}>Email</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant={"subtitle1"}>User Role</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant={"subtitle1"}>Invitation Notes</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={2} alignItems={"center"}>
                                <FieldArray
                                    name={nameof<UserInvitationsForm>(f => f.invites)}
                                    render={({name}) => (
                                        values.invites.map((invite, index) => {

                                            const emailTouched = getIn(touched, `${name}[${index}].${nameof<UserInvitationDto>(u => u.email)}`)
                                            const emailError = getIn(errors, `${name}[${index}].${nameof<UserInvitationDto>(u => u.email)}`)
                                            const roleTouched = getIn(touched, `${name}[${index}].${nameof<UserInvitationDto>(u => u.role)}`)
                                            const roleError = getIn(errors, `${name}[${index}].${nameof<UserInvitationDto>(u => u.role)}`)

                                            return (
                                                <React.Fragment key={index}>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            name={`${nameof<UserInvitationsForm>(f => f.invites)}[${index}]${nameof<UserInvitationDto>(u => u.email)}`}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            fullWidth
                                                            onChange={handleChange}
                                                            value={values.invites[index].email}
                                                            error={emailTouched && Boolean(emailError)}
                                                            helperText={emailTouched && emailError}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <FormControl size={"small"} fullWidth>
                                                            <Select
                                                                name={`${nameof<UserInvitationsForm>(f => f.invites)}[${index}]${nameof<UserInvitationDto>(u => u.role)}`}
                                                                variant={"outlined"}
                                                                fullWidth
                                                                onChange={handleChange}
                                                                value={values.invites[index].role}
                                                                error={roleTouched && Boolean(roleError)}
                                                            >
                                                                {renderLookupsAsMenuItem(userRoles)}
                                                            </Select>
                                                            {roleTouched && Boolean(roleError) &&
                                                            <FormHelperText error={true}>{roleError}</FormHelperText>
                                                            }

                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            name={`${nameof<UserInvitationsForm>(f => f.invites)}[${index}]${nameof<UserInvitationDto>(u => u.note)}`}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            fullWidth
                                                            onChange={handleChange}
                                                            value={values.invites[index].note}
                                                        />
                                                    </Grid>
                                                    <Grid container item xs={1} justifyContent={"flex-end"}>
                                                        <Grid item>
                                                            <IconButton
                                                                size={"small"}
                                                                onClick={() => onDeleteClicked(index)}
                                                            >{submitting ? <CircularProgress size={20} color={"secondary"}/> : <CloseRounded/>}</IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            );
                                        })
                                    )}
                                />
                            </Grid>
                            <Grid container item justifyContent={"space-between"}>
                                <Grid item xs={3}>
                                    <Button
                                        color={"primary"}
                                        size={"small"}
                                        startIcon={<AddRounded/>}
                                        onClick={onAddAnotherClicked}
                                    >Add Another</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export { InviteUsersForm }