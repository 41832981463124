import React, { useEffect, useState } from 'react';

import { Container } from '@mui/material';

import { getComplianceOverTimeByObligation } from "../../store/actions";
import { ComplianceOverTimeDto } from "common/services/ReportingService/models";
import { BumpChart } from "./bumpChart/BumpChart";

import "./styles.scss"
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "../../../user/store/selectors";

const CompanyComplianceOverTimeByObligation = () => {
    const functionDetails = useSelector(selectedFunctionSelector);
    const [companyCompliance, setCompanyCompliance] = useState<ComplianceOverTimeDto[]>([]);

    useEffect(() => {
        getComplianceOverTimeByObligation().then((response) => {
            setCompanyCompliance(response);
        })
    }, [setCompanyCompliance, functionDetails])

    return (
        companyCompliance &&
            <Container className={"containerWrapper"} maxWidth={"xl"}>
                <BumpChart data={companyCompliance}/>
            </Container>
       )
}

export { CompanyComplianceOverTimeByObligation }