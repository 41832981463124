import React from 'react';

import { TableFooter, TableRow, TablePagination } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

import { Pagination } from "./models";

export interface TablePaginatorProps {
    pagination: Pagination;
    totalRows: number;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}

const TablePaginator: React.FC<TablePaginatorProps> = ({pagination, totalRows, onPageChange, onRowsPerPageChange}) => {

    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    count={totalRows}
                    rowsPerPage={pagination.rowsPerPage}
                    page={pagination.pageNumber}
                    SelectProps={{
                        inputProps: {'aria-label': 'rows per page'},
                        native: true,
                    }}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    ActionsComponent={TablePaginationActions}
                />
            </TableRow>
        </TableFooter>
    )
}
export { TablePaginator }