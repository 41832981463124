import React from 'react';
import { Box, Container } from "@mui/material";

import * as colours from  "../../../../styles/colours.scss";


export interface LoadingProps {
    loadingIcon: React.ComponentType<any>;
}

const LoadingPage: React.FC<LoadingProps> = ({loadingIcon: Component}) => {
    return (
        <Container className={"loading"} maxWidth={false}>
            <Box color={colours.backdrop} height={"100vh"} display={"flex"} alignContent={"center"} justifyContent={"center"}>
                <Component/>
            </Box>
        </Container>
    )
}
export { LoadingPage }