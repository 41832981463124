import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
    Box,
    Card,
    Grid,
    IconButton,
    List,
    ListItem,
    Typography
} from "@mui/material";
import { ChevronRightRounded } from "@mui/icons-material";

import { getObligationsFromAssignedAssets } from "assetManagement/actions";
import { cardProps } from "common/theming/models";
import { ObligationAssignedAssetsDto } from "common/dtos/Assets/ObligationAssignedAssetsDto";
import { AmountDisplay } from "common/components/content/AmountDisplay/AmountDisplay";
import { AssetDetailsDto } from "common/dtos/Assets/AssetDetailsDto";

import { history } from "../../../history";

import "./styles.scss"
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "../../../user/store/selectors";

export interface AssetObligationsOverviewProps {
    dataUpdateSeed: number;
}

const AssetObligationsOverview: React.FC<AssetObligationsOverviewProps> = ({dataUpdateSeed}) => {

    const [obligations, setObligations] = useState<ObligationAssignedAssetsDto[]>([]);

    const selectedFunction = useSelector(selectedFunctionSelector);
    
    useEffect(() => {
        getObligationsFromAssignedAssets().then(response => {
            setObligations(response)
        })
    }, [dataUpdateSeed, selectedFunction]);

    const renderTooltip = (assets: AssetDetailsDto[]): ReactElement => {
        return (
            <Grid container className={"tooltip-list"}>
                <Grid item>
                    <Typography variant={"body1"}><b>Linked Assets</b></Typography>
                </Grid>
                <Grid container item className={"list"}>
                    {assets.map((asset, index) => {
                        return (
                            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                <Grid item xs={11}><Typography variant={"caption"}>{asset.alias}</Typography></Grid>
                                <Grid item xs={1}><IconButton size={"small"}
                                                              onClick={() => history.push(`/asset/${asset.id}`)}><ChevronRightRounded
                                    color={"primary"}/></IconButton></Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        );
    }

    return (
        <Card {...cardProps}>
            <Typography variant={"h6"}>My Obligations</Typography>
            {obligations.length > 0 &&
            <List className={"obligationList"} dense>
                {obligations.map((obligation, index) => {

                    const divider: boolean = index < obligations.length - 1;

                    return (
                        <ListItem disableGutters divider={divider}>
                            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                <Grid item xs={10}>
                                    <Typography variant={"body2"}
                                                color={"textSecondary"}>{obligation.obligationCategoryDetails.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <AmountDisplay
                                        amount={`${obligation.linkedAssets.length}`}
                                        tooltip={renderTooltip(obligation.linkedAssets)}
                                        placement={"right"}
                                        color={"info"}
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                    );
                })}
            </List>
            }
            {obligations.length === 0 &&
            <Box width={"100%"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}
                 flexDirection={"column"}>
                <Typography align={"center"} color={"textSecondary"}>No Obligations Linked</Typography>
                <Link to={"/categories/obligations"}><Typography align={"center"} variant={"body2"} className={"hyperlink"}>Click
                    here to manage obligations</Typography></Link>
            </Box>
            }
        </Card>
    );
}
export { AssetObligationsOverview }