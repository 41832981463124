import React from 'react';
import { useSelector } from "react-redux";

import {selectedCompanySelector, selectedFunctionSelector, userSelector} from "user/store/selectors";
import { SidePanel } from 'common/components/panels/SidePanel/SidePanel';
import { MenuUserProps } from 'common/components/panels/MenuUser/MenuUser';

import { ContentOptions, FooterOptions } from "../../ContentPanels";

import AsirtaLogo from "assets/logos/ASIRTA.png";
import AsirtaLogoShorthand from "assets/logos/ASIRTA-Shorthand.png";

import { CompanyDetailsDto } from "common/dtos/Companies/models";
import { MenuOptionProps } from "common/components/panels/MenuOption/MenuOption";
import { getNameFromUser } from "common/helpers/Naming/naming";
import { UserCompanyPermission } from "auth/store/Model";
import { companyUserPermissions } from "auth/store/selectors";

const NavigationPanel: React.FC = () => {
    const user = useSelector(userSelector);
    const userPermissions: UserCompanyPermission[] = useSelector(companyUserPermissions)
    const company = useSelector(selectedCompanySelector)
    const companyBillingTier = useSelector(selectedCompanySelector)

    const selectedFunction = useSelector(selectedFunctionSelector);

    const requiredBillingTierExists = (ContentOptions: MenuOptionProps[], companyBillingTier: CompanyDetailsDto | undefined): MenuOptionProps[] => {
        if(!companyBillingTier)
        {
            return ContentOptions.filter(co => co.restrictedBillingTiers === undefined)
        }

        const filteredOptions = ContentOptions.filter(co => co.restrictedBillingTiers === undefined ||
            co.restrictedBillingTiers.some(rb => rb === companyBillingTier.settings.billingTiersId));
        
        filteredOptions.forEach(co => {
            if(co.options !== undefined){
                co.options = co.options.filter(o => o.restrictedBillingTiers === undefined ||
                    o.restrictedBillingTiers.some(rb => rb === companyBillingTier.settings.billingTiersId))
            }
        });
        
        return filteredOptions;
    }

    const requiredPermissionExists = (ContentOptions: MenuOptionProps[], permissions: UserCompanyPermission[] | undefined): MenuOptionProps[] => {
        if(!permissions || permissions.length === 0)
        {
            return ContentOptions.filter(co => co.permission === undefined)
        }

        
        return ContentOptions.filter(co => {
            if(!co.permission){
                return true
            }

            return permissions.some(p => p.permissionId === co.permission);
        });
    }

    const noFunctionRestriction = (ContentOptions: MenuOptionProps[]): MenuOptionProps[] => {
        if(!selectedFunction) {
            return ContentOptions.filter(co => !co.hideOnNoFunction)
        }
        return ContentOptions;
    }

    let menuOptions = requiredBillingTierExists(ContentOptions, companyBillingTier)
    menuOptions = noFunctionRestriction(menuOptions);
    menuOptions = requiredPermissionExists(menuOptions, userPermissions);
    
    const menuUserProps: MenuUserProps = {
        fullName: getNameFromUser(user),
        companyName: company?.name || "",
        showText: true,
        iconUrl: null
    }
    
    const isNavbarHiddenOnActivePage = (): boolean => {
        const isSelected = ContentOptions.filter(e => e.selected);
        
        if(isSelected.length > 0){
            return isSelected[0].hidePanelOnSelected === undefined ? false : isSelected[0].hidePanelOnSelected;
        }
        
        return false;
    }
    
    const DetermineSelectedPage = (): void => {
        
    }

    DetermineSelectedPage();
    
    return (
        <SidePanel
            shorthandLogoPath={AsirtaLogoShorthand}
            logoPath={AsirtaLogo}
            menuUserProps={menuUserProps}
            menuOptions={menuOptions}
            footerOptions={FooterOptions}
            collapsible={true}
            hidden={isNavbarHiddenOnActivePage()}
        />
    )
}
export {NavigationPanel}