import { DelegationType } from "../Common/delegation";
import { FunctionDetailsDto, functionDetailsInitialState } from "common/dtos/Businesses/FunctionDetailsDto";
import { LookupDto, lookupDtoInitialState } from "common/services/LookupService/models";
import { ActivityCategoryDetailsDto, activityCategoryInitialState } from "common/dtos/Questions/QuestionDetails/models";

export interface UserRaciToActivityCategoryRequest {
    raciAssignments: RaciAssignment[];
    raciUnassignments: RaciAssignment[];
}

export interface RaciAssignment {
    userId: number;
    email: string;
    delegationType: DelegationType;
    activityCategoryId: number;
}

export interface TrackedActivityCategoryDto {
    id: number;
    functionDetailsId: number;
    functionDetails: FunctionDetailsDto;
    activityCategoryDetailsId: number;
    activityCategoryDetails: ActivityCategoryDetailsDto;
    trackTypeId: number;
    trackType: LookupDto<string>;
}

export const trackedActivityCategoryInitialState: TrackedActivityCategoryDto = {
    id: 0,
    activityCategoryDetails: activityCategoryInitialState,
    activityCategoryDetailsId: 0,
    functionDetails: functionDetailsInitialState,
    functionDetailsId: 0,
    trackType: lookupDtoInitialState,
    trackTypeId: 0
}