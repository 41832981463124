import React from 'react';
import { Card, Grid } from "@mui/material";
import { CollapsibleSection } from "common/components/sections/CollapsibleSection/CollapsibleSection";
import { UserDetailsEditing } from './components/UserDetailsEditing/UserDetailsEditing';

import "./styles.scss";

const UserSettings: React.FC = () => {
    return (  
        <Grid className={"topBottomGutters"} justifyContent={"center"} alignContent={"flex-start"} container>
            <Grid item container xs={12} sm={10} md={6} spacing={2}>
                <Grid item xs={12}>
                    <Card variant={"outlined"}>
                        <CollapsibleSection
                            title={"User Details"}
                            subtitle={"View/ Edit information relating to you"}
                        >
                            <UserDetailsEditing/>
                        </CollapsibleSection>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )
}
export { UserSettings }