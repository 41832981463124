import React from 'react';

import { Container } from "@mui/material";

import { ComponentSwitcher } from "common/components/tables/ComponentSwitcher/ComponentSwitcher";

import { AssetTables } from "./AssetTables";

const AssetManagement: React.FC = () => {
    return (
        <Container maxWidth={'xl'}>
            <ComponentSwitcher tables={AssetTables}/>
        </Container>
    )
}
export { AssetManagement }