import React, { ReactElement, useEffect, useState } from 'react';

import {
    Grid as GridTable,
    GridColumn,
    GridColumnMenuFilter, GridDataStateChangeEvent,
    GridPageChangeEvent, GridSortChangeEvent
} from '@progress/kendo-react-grid';
import { orderBy, process, State } from '@progress/kendo-data-query';

import { nameof } from "ts-simple-nameof";
import { Avatar, Grid, Tooltip } from "@mui/material";
import { SortDescriptor } from "@progress/kendo-data-query";

import { deleteAsset, duplicateAsset, getFunctionAssets } from "../../store/actions";
import { AssetDetailsDto } from "common/dtos/Assets/AssetDetailsDto";
import { LayerLoader } from "common/components/loader/layers/LayerLoader";

import { getUserInitials, isEmptyOrSpaces } from "common/helpers/Regex/regex";
import { UserDetailsDto } from "common/dtos/Users/models";
import { RatingGauge } from "common/components/content/RatingGauge/RatingGauge";
import { FunctionAssetActions } from "../FunctionAssetActions/FunctionAssetActions";

import "../styles.scss";
import { history } from "../../../history";
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "../../../user/store/selectors";

const FunctionAssetsTable: React.FC = () => {
    const functionDetails = useSelector(selectedFunctionSelector);
    
    const [data, setData] = useState<AssetDetailsDto[]>([]);
    const [processedData, setProcessedData] = useState<any[]>([]);
    const [dataState, setDatastate] = useState<State>({skip: 0, take: 20});
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)

        getFunctionAssets().then(response => {
            setData(response.data)
            setProcessedData(response.data)
            setLoading(false)
        });
    }, [functionDetails]);

    const onPageChange = (event: GridPageChangeEvent) => {
        setDatastate({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take
        })
    }

    const onDataStateChange = (event: GridDataStateChangeEvent) => {
        setDatastate(event.dataState);
        setProcessedData((event.dataState.filter !== null)
            ? process([...data], event.dataState).data
            : data);
    }

    const onSortChange = (event: GridSortChangeEvent) => {
        setSort(event.sort);
    }

    const onViewClicked = (assetId: number) => {
        history.push(`/asset/${assetId}`);
    }

    const onDuplicateClicked = (assetId: number) => {
        duplicateAsset(assetId).then(response => {
            setData([...data, response])
            setProcessedData([...processedData, response]);
        })
    }

    const onDeleteClicked = (assetId: number) => {
        deleteAsset(assetId).then(_ => {
            setData(data.filter(d => d.id !== assetId));
            setProcessedData(processedData.filter(d => d.id !== assetId));
        })
    }

    const skip: number = dataState.skip!;
    const take: number = dataState.take!;

    const renderOwner = (owner: UserDetailsDto): ReactElement => {

        const useUsername = isEmptyOrSpaces(owner.firstName);

        const initials = getUserInitials(owner);

        const fullName = useUsername
            ? owner.identityProviderUserName
            : `${owner.firstName} ${owner.surname}`;

        return (
            <td>
                <Tooltip title={fullName}>
                    <Avatar className={"ownerAvatars"}>{initials}</Avatar>
                </Tooltip>
            </td>
        )
    }

    return (
        <Grid container spacing={2}>
            {loading && <LayerLoader/>}
            <Grid item>
                <GridTable
                    {...dataState}
                    data={orderBy(processedData, sort).slice(skip, take + skip)}
                    onPageChange={onPageChange}
                    onDataStateChange={onDataStateChange}
                    onSortChange={onSortChange}
                    total={processedData.length}
                    pageable={true}
                    sortable={true}
                    sort={sort}
                    onRowClick={(props) => onViewClicked(props.dataItem.id)}
                    className={'assetTableRow'}
                >
                    <GridColumn field={nameof<AssetDetailsDto>(a => a.alias)}
                                title={"Alias"}
                                filter={"text"}
                                columnMenu={GridColumnMenuFilter}
                    />
                    <GridColumn field={nameof<AssetDetailsDto>(a => a.confidentiality)}
                                title={"Confidentiality"}
                                cell={props => <td><RatingGauge rating={(props.dataItem.confidentiality)}/></td>}
                                width={"150px"}
                    />
                    <GridColumn field={nameof<AssetDetailsDto>(a => a.integrity)}
                                title={"Integrity"}
                                cell={props => <td><RatingGauge rating={(props.dataItem.integrity)}/></td>}
                                width={"150px"}
                    />
                    <GridColumn field={nameof<AssetDetailsDto>(a => a.availability)}
                                title={"Availability"}
                                cell={props => <td><RatingGauge rating={(props.dataItem.availability)}/></td>}
                                width={"150px"}
                    />
                    <GridColumn field={nameof<AssetDetailsDto>(a => a.accountableOwner)}
                                title={"Accountable"}
                                cell={props => renderOwner(props.dataItem.accountableOwner)}
                                width={"150px"}
                    />
                    <GridColumn field={nameof<AssetDetailsDto>(a => a.responsibleOwner)}
                                title={"Responsible"}
                                cell={props => renderOwner(props.dataItem.responsibleOwner)}
                                width={"150px"}

                    />
                    <GridColumn
                        title={"Actions"}
                        cell={props =>
                            <FunctionAssetActions
                                asset={props.dataItem}
                                onViewClicked={onViewClicked}
                                onDuplicateClicked={onDuplicateClicked}
                                onDeleteClicked={onDeleteClicked}
                            />}
                        width={"200px"}
                    />
                </GridTable>
            </Grid>
        </Grid>
    )
}
export { FunctionAssetsTable }