import React, { useCallback, useEffect, useState } from 'react';

import { BarChart } from "./components/charts/barChart/BarChart";
import { TitleCard } from "common/components/panels/TitleCard/TitleCard";
import { reportingService } from "common/services/ReportingService/reportingService";
import { TrackingYourComplianceScoreCompletion } from "common/views/TrackingYourComplianceScore";
import { DashboardFunctionProps } from "../dashboard/Dashboard";

const TrackingYourComplianceScore: React.FC<DashboardFunctionProps> = ({ selectedFunction }) => {
    const [data, setData] = useState<any[]>([]);

    const prepareObligations = useCallback((response: TrackingYourComplianceScoreCompletion[]) => {
        setData(response
            .map((obligation, index) => {
                return {
                    "obligation": obligation.obligation,
                    "obligationShort": obligation.obligationShort,
                    "Compliant": obligation.compliant,
                    "Non-compliant": obligation.notCompliant,
                    "Outstanding": obligation.outstanding,
                    "index": index
                }
            })
            .slice(0, 15)
            .sort((a, b) => a.index > b.index ? -1 : 0)
        )
    }, [])

    useEffect(() => {
        reportingService.getTrackingYourComplianceScore().then(response => {
            prepareObligations(response);
        })
    }, [prepareObligations, selectedFunction])

    return (
        <TitleCard
            title={'High level obligation risk overview'}
            hoverOver={'The average maturity rating of responses across the key obligation risk groupings.'}
            height={250}
            linkIcon={true}
            link={'reporting/highLevelObligationRiskOverview'}
        >
            <BarChart dataSet={data}/>
        </TitleCard>
    )
}
export { TrackingYourComplianceScore }