import React, { ReactElement, useCallback } from 'react';

import { Grid, MenuItem, Select, Typography } from "@mui/material";

import {
    AssessmentAnswerLookup,
    assessmentAnswers,
    initialAssessmentAnswerLookup
} from "common/dtos/Questions/Assessment/models";
import { nicifyString } from "common/helpers/Regex/regex";
import { AnswerValue } from "common/dtos/Questions/models";

import { AssessmentSubquestionAnswer } from "questions/Assessment/models";

export interface AssessmentSubquestionProps {
    subquestion: AssessmentSubquestionAnswer;
    onAnswer: (answer: AssessmentSubquestionAnswer) => void;
}

const AssessmentSubquestion: React.FC<AssessmentSubquestionProps> = ({subquestion, onAnswer}) => {
    
    const renderAnswers = useCallback((): ReactElement[] => {
        return assessmentAnswers.filter(l => l.value !== AnswerValue.NotAnswered).map((answer: AssessmentAnswerLookup) => {
            return <MenuItem value={answer.id} key={answer.id}>{nicifyString(answer.description)}</MenuItem>
        })
    },[]);
    
    const onChange = useCallback((e: any) => {
        
        onAnswer({
            details: subquestion.details,
            answer: assessmentAnswers.find(a => a.id === e.target.value) ?? initialAssessmentAnswerLookup
        });
        
    }, [onAnswer, subquestion.details])
    
    return (
        <Grid container item justifyContent={"center"} xs={8} spacing={2}>
            <Grid item>
                <Typography variant={"h6"} align={"left"}>{subquestion.details.question.value}</Typography>
            </Grid>
            <Grid item container justifyContent={"center"}>
                <Select fullWidth={true} value={subquestion.answer.id} onChange={onChange}>
                    {renderAnswers()}
                </Select>
            </Grid>
        </Grid>
    );
}
export { AssessmentSubquestion }