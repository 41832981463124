import React from 'react';

import { Container } from "@mui/material";

import { HighLevelObligationRiskDetail } from "./components/obligationRiskDetail/HighLevelObligationRiskDetail";
import { ReportingHeader } from "./components/reportingHeader/ReportingHeader";

const HighLevelObligationRiskOverview: React.FC = () => {

    return (
        <Container className={"topBottomGutters"} maxWidth={"xl"}>
            <ReportingHeader name={'High level obligation risk breakdown'} pdfReportTitle={'High level obligation risk breakdown'} pdfLandscape={true}/>
            <HighLevelObligationRiskDetail/>
        </Container>
    )
}
export { HighLevelObligationRiskOverview }