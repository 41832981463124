import { createSelector } from "reselect";

import { ApplicationState } from "../../store";
import { QuestionFiltersState } from "./model";
import { QuestionFilters } from "../Questions/components/QuestionFilters/models";

const selectorState = (state: ApplicationState): QuestionFiltersState =>
    state.questionFilters;

export const questionFiltersSelector = createSelector(
    selectorState,
    (questionFiltersState: QuestionFiltersState): QuestionFilters => {
        return questionFiltersState.questionFilters;
    }
)