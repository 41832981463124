import React, { RefObject } from 'react';
import { OfficerDto, officerDtoInitialState } from "wizards/company/components/CreationForm/models";
import { FieldArray, Formik, FormikProps, getIn } from "formik";
import { nameof } from "ts-simple-nameof";
import {
    Button,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import { AddRounded, CloseRounded } from "@mui/icons-material";
import {
    OfficerFormState,
    officerFormValidationSchema
} from "common/components/input/forms/OfficerSelectionForm/models";
import { DatePicker } from "@mui/lab";

import "./styles.scss";

export interface OfficerSelectionFormProps {
    formRef: RefObject<any>;
    initialState: OfficerFormState;
    onSubmit: (officers: OfficerDto[]) => void;
    onChange?: () => void;
}

const OfficerSelectionForm: React.FC<OfficerSelectionFormProps> = ({
                                                                       formRef,
                                                                       initialState,
                                                                       onSubmit,
                                                                       onChange
                                                                   }) => {
    
    return (
        <Formik
            innerRef={formRef}
            onSubmit={(values) => {
                onSubmit(values.officers);
            }}
            validationSchema={officerFormValidationSchema}
            initialValues={initialState}
            enableReinitialize
        >
            {(props: FormikProps<OfficerFormState>) => {

                const {errors, touched, handleChange, setFieldValue, values} = props;

                const onFieldChange = (e: any) => {
                    onChange && onChange();
                    handleChange(e);
                }
                
                const onAddAnotherClicked = () => {
                    onChange && onChange();
                    setFieldValue(nameof<OfficerFormState>(f => f.officers), [...values.officers, officerDtoInitialState])
                }

                const onDeleteClicked = (index: number) => {
                    onChange && onChange();
                    setFieldValue(nameof<OfficerFormState>(f => f.officers),
                        values.officers.filter((f, i) => i !== index)
                    );
                }

                const onDateChange = (index: number) => (date: any) => {
                    onChange && onChange();
                    setFieldValue(
                        `${nameof<OfficerFormState>(f => f.officers)}[${index}]${nameof<OfficerDto>(u => u.appointmentDate)}`,
                        date?.toDate()
                    );
                }

                return (
                    <Grid container>
                        <Grid container spacing={1}>
                            <Grid container item spacing={1}>
                                <Grid item xs={3}>
                                    <Typography variant={"subtitle1"}>Name</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={"subtitle1"}>Email</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant={"subtitle1"}>Occupation</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant={"subtitle1"}>Appointment Date</Typography>
                                </Grid>
                            </Grid>
                            <Grid container className={"officerList"} item spacing={1}>
                                <FieldArray
                                    name={nameof<OfficerFormState>(f => f.officers)}
                                    render={({name}) => (
                                        values.officers.map((officer, index) => {

                                            const nameTouched = getIn(touched, `${name}[${index}].${nameof<OfficerDto>(u => u.name)}`)
                                            const nameError = getIn(errors, `${name}[${index}].${nameof<OfficerDto>(u => u.name)}`)
                                            const emailTouched = getIn(touched, `${name}[${index}].${nameof<OfficerDto>(u => u.email)}`)
                                            const emailError = getIn(errors, `${name}[${index}].${nameof<OfficerDto>(u => u.email)}`)
                                            const occupationTouched = getIn(touched, `${name}[${index}].${nameof<OfficerDto>(u => u.occupation)}`)
                                            const occupationError = getIn(errors, `${name}[${index}].${nameof<OfficerDto>(u => u.occupation)}`)
                                            const appointmentTouched = getIn(touched, `${name}[${index}].${nameof<OfficerDto>(u => u.appointmentDate)}`)
                                            const appointmentError = getIn(errors, `${name}[${index}].${nameof<OfficerDto>(u => u.appointmentDate)}`)

                                            return (
                                                <Grid key={index} container item alignItems={"center"} spacing={1}>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            name={`${nameof<OfficerFormState>(f => f.officers)}[${index}]${nameof<OfficerDto>(u => u.name)}`}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            fullWidth
                                                            onChange={onFieldChange}
                                                            value={values.officers[index].name}
                                                            error={nameTouched && Boolean(nameError)}
                                                            helperText={nameTouched && nameError}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            name={`${nameof<OfficerFormState>(f => f.officers)}[${index}]${nameof<OfficerDto>(u => u.email)}`}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            fullWidth
                                                            onChange={onFieldChange}
                                                            value={values.officers[index].email}
                                                            error={emailTouched && Boolean(emailError)}
                                                            helperText={emailTouched && emailError}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <TextField
                                                            name={`${nameof<OfficerFormState>(f => f.officers)}[${index}]${nameof<OfficerDto>(u => u.occupation)}`}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            fullWidth
                                                            onChange={onFieldChange}
                                                            value={values.officers[index].occupation}
                                                            error={occupationTouched && Boolean(occupationError)}
                                                            helperText={occupationTouched && occupationError}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <DatePicker
                                                            onChange={onDateChange(index)}
                                                            value={values.officers[index].appointmentDate}
                                                            renderInput={(props) =>
                                                                <TextField
                                                                    {...props}
                                                                    error={appointmentTouched && Boolean(appointmentError)}
                                                                    helperText={appointmentTouched && appointmentError}
                                                                    fullWidth
                                                                />}
                                                            inputFormat={"DD/MM/yy"}
                                                        />
                                                    </Grid>
                                                    <Grid container item xs={1} justifyContent={"flex-end"}>
                                                        <Grid item>
                                                            <IconButton
                                                                size={"small"}
                                                                onClick={() => onDeleteClicked(index)}
                                                            >
                                                                <CloseRounded/>
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })
                                    )}
                                />
                            </Grid>
                            <Grid container item justifyContent={"space-between"}>
                                <Grid item xs={3}>
                                    <Button
                                        color={"primary"}
                                        size={"small"}
                                        startIcon={<AddRounded/>}
                                        onClick={onAddAnotherClicked}
                                    >Add Another</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export { OfficerSelectionForm }