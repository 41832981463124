import React, { ReactElement, useCallback, useState } from 'react';
import { Typography } from "@mui/material";

import "./styles.scss";

export interface AvatarButtonProps {
    onClick: () => void;
    image?: string;
    imageAlt?: string;
    icon?: ReactElement;
    text?: string;
}

const AvatarButton: React.FC<AvatarButtonProps> = ({onClick, image, imageAlt, icon, text}) => {
    
    const [onHover, setOnHover] = useState<number>(0);
    
    const onMouseEnter = useCallback(() => {
        setOnHover(prevState => prevState + 1);
    }, []);

    const onMouseLeave = useCallback(() => {
        setOnHover(prevState => prevState - 1);
    }, []);
    
    return (
        <div className={"avatarButton"} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className={"avatarContainer"}>
                { onHover > 1
                    ? icon
                    : <Typography color={"inherit"} variant={"h4"} className={"noselect"}>{text}</Typography>
                }
                { icon && <img src={image} alt={imageAlt}/> }
            </div>
        </div>
    )
}
export { AvatarButton }