import React from 'react';
import {
    BumpInputSerie, 
    BumpProps,
    BumpInputDatum,
    ResponsiveBump
} from '@nivo/bump'

import "./styles.scss"

export interface BarChartProps {
    dataSet: BumpInputSerie[]
}

export interface ToolTipProps {
    obligation: string | number,
    data: BumpInputDatum[]
}

const ToolTipDiv: React.FC<ToolTipProps>  = ({obligation, data}) => {
    return(
        <div className={'bumpChartToolTip'}>
            <strong className={'bumpChartToolTipHeading'}>{obligation}</strong>
            <br/>
            <div>
                {data.map((a) => {
                    return(
                       <div><span> {a.x}:   {-1 * Number(a.y)}%</span>  <br/></div>
                    )
                })}
            </div>
        </div>
    )
}

const BumpChart: React.FC<BumpProps> = ({data}) => {
   
    const commonProps = {
        lineWidth:2,
        activeLineWidth:6,
        inactiveLineWidth:2,
        inactiveOpacity:0.15
    }
    
    // @ts-ignore
    return (
        <div className={'ResponsiveBarChart'}>
            <ResponsiveBump
                {...commonProps}
                data={data}
                margin={{ top: 10, right: 250, bottom: 25, left: 0 }}
                colors={{ scheme: 'set2' }}
                pointSize={8}
                activePointSize={16}
                inactivePointSize={0}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={3}
                activePointBorderWidth={3}
                enableGridX={true}
                enableGridY={true}
                axisTop={null} 
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 15
                }}
                axisLeft={null}
                tooltip={({ serie }) => (
                    <ToolTipDiv
                        obligation={serie.id}
                        data={serie.data}
                    />
                )}
            />
        </div>
    )
}

export { BumpChart }