export enum FunctionType {
    Company = 1,
    Programme = 2,
    Project = 3,
    BusinessProcess = 4,
    Service = 5
}

export enum ManagementType {
    External = 1,
    Internal = 2,
    CoManaged = 3
}

export enum StatusType {
    Live = 1,
    Pending = 2,
    Dormant= 3,
    Closed = 4,
}