import React from 'react';

import { FiltersForm } from "questions/Questions/components/QuestionFilters/filtersForm";
import { QuestionFilters } from "questions/Questions/components/QuestionFilters/models";

import "./styles.scss";

interface QuestionFiltersPanelProps {
    onSubmit: (questionFilters: QuestionFilters) => void
    filters: QuestionFilters,
    clearFilter: () => void
}

const QuestionFiltersPanel: React.FC<QuestionFiltersPanelProps> = ({onSubmit, filters, clearFilter}) => {
    return (
        <FiltersForm onSubmit={onSubmit} filters={filters} clearFilter={clearFilter}/>
    )
}
export { QuestionFiltersPanel }