import * as Yup from "yup";
import {isEmailUnique} from "../../user/store/actions";
import {SchemaOf} from "yup";
import { formikHelper } from "common/helpers/Formik/formikHelper";

export interface UserInvitationDto {
    email: string;
    role: number | "";
    note: string;
}

export interface UserInvitationsForm {
    invites: UserInvitationDto[];
}

const userInvitationDtoInitialState: UserInvitationDto = {
    email: "",
    role: "",
    note: ""
}
export { userInvitationDtoInitialState }

const userInvitationsFormInitialState: UserInvitationsForm = {
    invites: []
}
export { userInvitationsFormInitialState }

formikHelper.addUnique();

const userInvitationsValidationSchema: SchemaOf<UserInvitationsForm>  = Yup.object().shape({
    invites: Yup.array()
        .of(Yup.object().shape({
            email: Yup.string()
                .email("Enter a valid email address")
                .required("Required")
                .test("isNameValid", "Alias already taken",
                    function test(value){
                        return checkEmailUniqueness(value);
                    }),
            role: Yup.number()
                .required("Required")
                .min(1, "Required")
                .typeError("Required"),
            note: Yup.string()
        })).unique("One or more emails has been specified multiple times", i => i.email)

})
export { userInvitationsValidationSchema }

function checkEmailUniqueness(value: string | undefined) {

    if(value === undefined)
        return false;

    return isEmailUnique(value).then(response => {
        return response
    })
}
