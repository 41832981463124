import { nameof } from "ts-simple-nameof";

import { AxiosApi } from "../../axios/axiosApi";
import {
    QuestionDetailsDto,
    questionDetailsDtoInitialState
} from "../../dtos/Questions/QuestionDetails/models";
import { PaginationResponseDto } from "../../dtos/Common/pagination";
import { AnswerDto } from "../../dtos/Questions/models";
import {
    QuestionAnswerRequest,
    QuestionAnswerDto, questionAnswerInitialState,
    SubquestionAnswersRequest, AnswerAdditionalDetailsDto, AnswerAdditionalDetailsRequest
} from "../../dtos/Questions/QuestionAnswers/models";
import qs from "qs";
import { QuestionDelegationRequest } from "../../dtos/Questions/Requests/models";
import { DelegatedQuestionDto } from "../../dtos/Users/models";
import { HistoryItem, HistoryRequest } from "../../dtos/History/models";
import SnackbarUtils from "../../helpers/SnackBar/snackbar";
import { QuestionFilters } from "questions/Questions/components/QuestionFilters/models";
import {
    ObligationCategoryResponse
} from "common/dtos/Obligations/models";
import { QuestionStatisticsDto, questionStatisticsInitialState } from "common/dtos/Questions/QuestionStatistics/models";
import {
    TrackedQuestionGroupDto,
    trackedQuestionGroupInitialState, TrackQuestionGroupsDto
} from "common/dtos/Questions/TrackedQuestionGroupDto/models";

class QuestionsService {
    private controllerName = "api/Questions";

    public async getQuestions(top: number, pageNumber: number, questionFilters: QuestionFilters): Promise<PaginationResponseDto<QuestionDetailsDto[]>> {
        let filterQuery=`&${nameof<QuestionFilters>(q => q.questionState)}=${questionFilters.questionState}`
        filterQuery +=`&${nameof<QuestionFilters>(q => q.search)}=${questionFilters.search}`
        
        if(questionFilters.answerValue) {
            filterQuery += `&${nameof<QuestionFilters>(q => q.answerValue)}=${questionFilters.answerValue}`
        }

        if(questionFilters.delegationTypes.length > 0) {
            questionFilters.delegationTypes.forEach((delegationTypeId) => {
                filterQuery += `&${nameof<QuestionFilters>(q => q.delegationTypes)}=${delegationTypeId}`
            })
        }

        if(questionFilters.sectors.length > 0) {
            questionFilters.sectors.forEach((sectorId) => {
                filterQuery += `&${nameof<QuestionFilters>(q => q.sectors)}=${sectorId.id}`
            })
        }

        if(questionFilters.obligationCategories.length > 0) {
            questionFilters.obligationCategories.forEach((obligationCategory) => {
                filterQuery += `&${nameof<QuestionFilters>(q => q.obligationCategories)}=${obligationCategory.id}`
            })
        }

        if(questionFilters.activityCategories?.length > 0) {
            questionFilters.activityCategories.forEach((activityCategory) => {
                filterQuery += `&${nameof<QuestionFilters>(q => q.activityCategories)}=${activityCategory.id}`
            })
        }
        
        if(questionFilters.trackedQuestionGroups){
            filterQuery += `&${nameof<QuestionFilters>(q => q.trackedQuestionGroups)}=${questionFilters.trackedQuestionGroups}`
        }

        if(questionFilters.trackedObligations){
            filterQuery += `&${nameof<QuestionFilters>(q => q.trackedObligations)}=${questionFilters.trackedObligations}`
        }

        if(questionFilters.trackedActivities){
            filterQuery += `&${nameof<QuestionFilters>(q => q.trackedActivities)}=${questionFilters.trackedActivities}`
        }
        
        const url: string = `${this.controllerName}/FunctionQuestions?$count=true&$top=${top}&$skip=${top * pageNumber}${filterQuery}`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return { data: [], total: 0 };
        });
    }
    
    public async getFunctionObligationCategories(): Promise<ObligationCategoryResponse> {

        const url: string = `${this.controllerName}/FunctionObligationCategories`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getQuestionById(questionId: number, questionFilters: QuestionFilters): Promise<QuestionDetailsDto> {

        let filterQuery=`?${nameof<QuestionFilters>(q => q.questionState)}=${questionFilters.questionState}`
        filterQuery +=`&${nameof<QuestionFilters>(q => q.search)}=${questionFilters.search}`

        if(questionFilters.answerValue) {
            filterQuery += `&${nameof<QuestionFilters>(q => q.answerValue)}=${questionFilters.answerValue}`
        }

        if(questionFilters.delegationTypes.length > 0) {
            questionFilters.delegationTypes.forEach((delegationTypeId) => {
                filterQuery += `&${nameof<QuestionFilters>(q => q.delegationTypes)}=${delegationTypeId}`
            })
        }

        if(questionFilters.sectors.length > 0) {
            questionFilters.sectors.forEach((sectorId) => {
                filterQuery += `&${nameof<QuestionFilters>(q => q.sectors)}=${sectorId}`
            })
        }

        if(questionFilters.obligationCategories.length > 0) {
            questionFilters.obligationCategories.forEach((obligationCategory) => {
                filterQuery += `&${nameof<QuestionFilters>(q => q.obligationCategories)}=${obligationCategory.id}`
            })
        }

        if(questionFilters.activityCategories?.length > 0) {
            questionFilters.activityCategories.forEach((activityCategory) => {
                filterQuery += `&${nameof<QuestionFilters>(q => q.activityCategories)}=${activityCategory.id}`
            })
        }

        if(questionFilters.trackedQuestionGroups){
            filterQuery += `&${nameof<QuestionFilters>(q => q.trackedQuestionGroups)}=${questionFilters.trackedQuestionGroups}`
        }

        if(questionFilters.trackedObligations){
            filterQuery += `&${nameof<QuestionFilters>(q => q.trackedObligations)}=${questionFilters.trackedObligations}`
        }

        if(questionFilters.trackedActivities){
            filterQuery += `&${nameof<QuestionFilters>(q => q.trackedActivities)}=${questionFilters.trackedActivities}`
        }

        const url: string = `${this.controllerName}/FunctionQuestion/${questionId}${filterQuery}`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return questionDetailsDtoInitialState;
        });
    }

    public async getAnswerValues(): Promise<AnswerDto[]> {

        const url: string = `${this.controllerName}/AnswerValues`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async getFunctionQuestionAnswer(answerRequest: SubquestionAnswersRequest): Promise<QuestionAnswerDto> {
        
        const url: string = `${this.controllerName}/GetFunctionQuestionAnswer`;
        
        return await AxiosApi.get(url, {params: {...answerRequest}, paramsSerializer: params => qs.stringify(params)}).then(response => {
            return response.data;
        }).catch(e => {
            return questionAnswerInitialState;
        });   
    }
    
    public async getAnswerAdditionalDetails(questionId: number): Promise<AnswerAdditionalDetailsDto> {
        const url: string = `${this.controllerName}/${questionId}/AnswerAdditionalDetails`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
        });
    }

    public async getQuestionStatistics(): Promise<QuestionStatisticsDto> {
        const url: string = `${this.controllerName}/GetQuestionStatistics`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return questionStatisticsInitialState;
        });
    }
    
    public async getQuestionDelegatedUsers(questionId: number): Promise<DelegatedQuestionDto[]> {
        
        const url: string = `${this.controllerName}/GetQuestionDelegatedUsers/${questionId}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return;
        });        
    }

    public async answerFunctionQuestions(questionId: number, request: QuestionAnswerRequest): Promise<void> {
        const url: string = `${this.controllerName}/AnswerFunctionQuestionsIncludingDetails`
        return await AxiosApi.post(url, JSON.stringify(request)).then(response => {
            SnackbarUtils.success('Submitted answer...');
            return;
        }).catch(e => {
            return;
        });
    }

    public async updateAnswerAdditionalDetails(questionId: number, request: AnswerAdditionalDetailsRequest): Promise<void> {
        const url: string = `${this.controllerName}/AnswerQuestionAdditionalDetails/${questionId}`
        return await AxiosApi.post(url, JSON.stringify(request)).then(response => {
            SnackbarUtils.success('Additional Details Saved');
            return;
        }).catch(e => {
            return;
        });
    }
    
    public async delegateUsersToQuestion(questionId: number, request: QuestionDelegationRequest): Promise<DelegatedQuestionDto[]> {
        
        const url: string = `${this.controllerName}/DelegateUsersToQuestion/${questionId}`

        return await AxiosApi.post(url, JSON.stringify(request)).then(response => {
            SnackbarUtils.success('Delegated question...');
            return response.data;
        }).catch(e => {
            return;
        });
    }

    public async undelegateUsersFromQuestion(questionId: number, userIds: number[]): Promise<void> {

        const url: string = `${this.controllerName}/UndelegateUsersFromQuestion/${questionId}`

        return await AxiosApi.post(url, JSON.stringify(userIds)).then(response => {
            SnackbarUtils.success('Removed delegation from question...');
            return;
        }).catch(e => {
            return;
        });
    }
    
    public async getFunctionQuestionHistory(questionId: number, historyRequest: HistoryRequest) : Promise<HistoryItem[]> {

        const url: string = `${this.controllerName}/question/${questionId}/history`

        return await AxiosApi.get(url, {params: {...historyRequest}, paramsSerializer: params => qs.stringify(params)}).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async getTrackedQuestionGroups(): Promise<TrackedQuestionGroupDto[]> {
        
        const url: string = `${this.controllerName}/GetTrackedQuestionGroups`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }
    
    public async trackQuestionGroup(questionGroupId: number, track: boolean): Promise<TrackedQuestionGroupDto> {
        
        const url: string = `${this.controllerName}/TrackQuestionGroup/${questionGroupId}/${track}`;
        
        return await AxiosApi.post(url).then(response => {
            return response.data;
        }).catch(e => {
            return trackedQuestionGroupInitialState;
        })
    }

    public async trackQuestionGroups(trackingData: TrackQuestionGroupsDto): Promise<TrackedQuestionGroupDto[]> {

        const url: string = `${this.controllerName}/TrackQuestionGroups/`;

        return await AxiosApi.post(url, JSON.stringify(trackingData)).then(response => {
            return response.data;
        }).catch(e => {
            return trackedQuestionGroupInitialState;
        })
    }
    
}

const questionsService = new QuestionsService();
export { questionsService }