import React, { ReactElement } from "react";

import { MenuOption, MenuOptionProps } from "../MenuOption/MenuOption";

import "./styles.scss";

export interface SubMenuProps {
  title: string;
  menuOptions: MenuOptionProps[];
  parentSelected: boolean;
  visible: boolean;
  shadow?: boolean;
}

const SubMenu: React.FC<SubMenuProps> = ({ title, menuOptions, parentSelected, visible, shadow }) => {

  const [selected, setSelected] = React.useState<string>();

  const onHandleClick = (e: React.MouseEvent, o: MenuOptionProps) => {
    setSelected(o.title)
    o.onClick(e);
  }

  const renderMenuOptions = (
    menuOptionProps: MenuOptionProps[]
  ): ReactElement[] => {
    return menuOptionProps.map((option, index) => {
      return <MenuOption {...option} onClick={(e) => onHandleClick(e, option)} selected={(parentSelected && selected === option.title) ? true: false } key={index} />;
    });
  };

  const classList = shadow ? "subMenuContent shadow" : "subMenuContent" 

  return (
      <div className={"subMenuContainer"}>
        {visible && (
      <div className={classList}>
      {title !== "" && <h4>{title}</h4>}
      {renderMenuOptions(menuOptions)}
      </div>
        )}
    </div>
  );
};

export { SubMenu };
