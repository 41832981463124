import React from 'react';

import "./styles.scss";

export interface VerticalLineProps {
    className?: string;
}

const VerticalLine: React.FC<VerticalLineProps> = ({className}) => {
    return (
        <div className={`verticalLine ${className}`}/>
    )
}
export { VerticalLine }