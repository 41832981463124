import React, { ReactElement, useState } from 'react'

import { Grid, Step, StepLabel, Stepper } from "@mui/material";

import { ComponentStep } from "./ComponentStep";

import './styles.scss'

export interface ComponentStepperProps {
    steps: ComponentStep[];
    topImage?: any;
    bottomImage?: any;
    object?: any;
    onComplete: (object: any) => void;
    onFinish: (object: any) => void;
    onNext: (object: any) => void;
}

const ComponentStepper: React.FC<ComponentStepperProps> = ({
                                                               steps,
                                                               topImage,
                                                               bottomImage,
                                                               object,
                                                               onComplete,
                                                               onNext,
                                                               onFinish
                                                           }) => {

    const [activeStep, setActiveStep] = useState<number>(0);

    const renderActiveStep = (): ReactElement => {

        return React.cloneElement(steps[activeStep].component, {
            onBackClicked: () => {
                previousStep();
            },
            onContinueClicked: (object: any) => {
                nextStepOrFinish(object);
            },
            object: object
        })
    }

    const nextStepOrFinish = (object: any) => {

        const nextActiveStep = activeStep + 1;
        onNext(object);

        if (nextActiveStep === steps.length - 1)
            onComplete(object);

        if (nextActiveStep === steps.length) {
            onFinish(object)
            return;
        }

        setActiveStep(nextActiveStep);
    }

    const previousStep = () => {

        const previousActiveStep = activeStep - 1;

        if (previousActiveStep >= 0)
            setActiveStep(previousActiveStep)

    }

    const renderActiveSteps = (): ReactElement[] => {
        return steps.filter(s => s.isVisible).map((step) => (
            <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
            </Step>
        ))
    }

    return (
        <Grid className={"stepper_container"} container>
            <Grid className={"stepper_panel"} container item justifyContent={"center"} xs={3}>
                <Grid item xs={12}>
                    {topImage && <img className={"topImage"} alt={"Top Artwork"} src={topImage}/>}
                    <Stepper className={"stepper"} orientation={"vertical"} color={"secondary"} activeStep={activeStep}>
                        {renderActiveSteps()}
                    </Stepper>
                </Grid>
                {bottomImage && <img className={"bottomImage"} alt={"Bottom Artwork"} src={bottomImage}/>}
            </Grid>
            <Grid className={"stepper_content"} item container justifyContent={"center"} alignContent={"center"} xs={9}>
                    {renderActiveStep()}
            </Grid>
        </Grid>
    );
}
export { ComponentStepper }