import { ActionTypes, ADD_COMPANY_FUNCTION, SET_COMPANY_FUNCTIONS } from "./actionTypes";
import { functionService } from "common/services/FunctionService/functionService";
import { FunctionDetailsDto } from "common/dtos/Businesses/FunctionDetailsDto";

import { ApplicationState } from "../../store";

export function SetCompanyFunctionsAction(companyFunctions: FunctionDetailsDto[]): ActionTypes {
    return {
        type: SET_COMPANY_FUNCTIONS,
        payload: companyFunctions
    }
}

export function AddCompanyFunctionAction(companyFunction: FunctionDetailsDto): ActionTypes {
    return {
        type: ADD_COMPANY_FUNCTION,
        payload: companyFunction
    }
}

export function getCompanyFunctions(force: boolean = false) {
    return async (dispatch: any, getState: () => ApplicationState) => {
        if (force || getState().businessFunctions.companyFunctions.length === 0) {
            await functionService.getCompanyFunctions().then(async data => {
                await dispatch(SetCompanyFunctionsAction(data))
            })
        }
    }
}

export function addCompanyFunction(companyFunction: FunctionDetailsDto) {
    return async (dispatch: any) => {
        await dispatch(AddCompanyFunctionAction(companyFunction))
    }
}

export function updateFunctionDetails(functionDetails: FunctionDetailsDto): Promise<void> {
    return functionService.updateFunctionDetails(functionDetails);
}