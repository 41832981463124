import { AssetStatisticsDto } from "common/dtos/Assets/AssetStatisticsDto";
import { assetService } from "common/services/AssetService/assetService";
import { AssetDetailsDto } from "common/dtos/Assets/AssetDetailsDto";
import { ObligationAssignedAssetsDto } from "common/dtos/Assets/ObligationAssignedAssetsDto";

export function getAssetStatistics(): Promise<AssetStatisticsDto> {
    return assetService.getAssetStatistics();
}

export function getAssetsByUser(): Promise<AssetDetailsDto[]> {
    return assetService.getAssetsByUser();
}

export function getObligationsFromAssignedAssets(): Promise<ObligationAssignedAssetsDto[]> {
    return assetService.getObligationsFromAssignedAssets();
}