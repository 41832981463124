import React from 'react';

import "./styles.scss";

const LayerLoader: React.FC = () => {

    return (
        <div className={"loader"}>
            <div className={"layers"}>
                <div className={"layer"}/>
                <div className={"layer"}/>
                <div className={"layer"}/>
            </div>
        </div>
        
    );
};
export { LayerLoader }