import React, { useCallback, useEffect, useState } from 'react';

import { DeleteOutline, EditOutlined } from "@mui/icons-material";

import moment from "moment";

import { UserDetailsDto } from "common/dtos/Users/models";
import { FormControl, Grid, IconButton, Select, Tooltip, Typography } from "@mui/material";
import { CompanyUserDto } from "common/dtos/Companies/models";
import { AmountDisplay } from "common/components/content/AmountDisplay/AmountDisplay";
import { renderLookupsAsMenuItem } from "common/helpers/Object/object";
import { LookupDto } from "common/services/LookupService/models";
import { lookupService } from "common/services/LookupService/lookupService";
import { companyService } from "common/services/CompanyService/companyService";
import { UserRoles } from "common/dtos/Common/userRoles";
import { useValidPermission, ValidateUserPermission } from "auth/components/ValidateUserPermission";
import { Permission } from "auth/store/Model";

export interface UsersTableProps {
    companyUsers: CompanyUserDto[];
    setCompanyUsers: (companyUsers: CompanyUserDto[]) => void;
    onModifyClicked: (user: CompanyUserDto) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({companyUsers, setCompanyUsers, onModifyClicked}) => {
    
    const [roles, setRoles] = useState<LookupDto<string>[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    
    useEffect(() => {
        lookupService.getUserRoles().then(response => {
            setRoles(response);
        });
    }, [])
    
    const onRoleChange = useCallback( (user: UserDetailsDto, index: number) => (event: any) => {
        const roleId: number = event.target.value;

        setSaving(true);
        
        companyService.setCompanyUserRole(user.id, roleId).then(() => {
            let users: CompanyUserDto[] = [ ...companyUsers ]
            users[index] = { 
                ...users[index],
                roleId: roleId
            };
            setCompanyUsers(users)
        }).finally(() => {
            setSaving(false);
        });
    }, [setCompanyUsers, companyUsers])
    
    const onEditClicked = useCallback((user: CompanyUserDto) => (event: any) => {
        onModifyClicked(user);
    }, [onModifyClicked])

    const getDisabledRoles = (companyUser: CompanyUserDto): LookupDto<string>[] => {
        if (companyUser.roleId === UserRoles.Admin) {
            return companyUsers
                .filter(i => i.user.id !== companyUser.user.id && i.user.claimed)
                .some(i => i.roleId === UserRoles.Admin) ? [] : roles.filter(r => r.id !== UserRoles.Admin);
        }

        return [];
    }

    const userEdit = !useValidPermission(Permission.UserEdit);
    
    return (
        <Grid container spacing={3}>
            <Grid item container spacing={1}>
                <Grid container item spacing={1} justifyContent={'space-between'} alignItems={'stretch'}>
                    <Grid container item>
                        <Grid item xs={3}>
                            <Typography variant={"subtitle1"}>Email</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant={"subtitle1"}>First Name</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant={"subtitle1"}>Last Name</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant={"subtitle1"}>Status</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant={"subtitle1"}>Join Date</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant={"subtitle1"}>Role</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1}>
                        {companyUsers && companyUsers.map((companyUser, index) => {
                            return (
                                <Grid container item alignItems={"center"}>
                                    <Grid item xs={3}><Typography variant={"body1"} color={"textSecondary"}>{companyUser.user.email}</Typography></Grid>
                                    <Grid item xs={2}><Typography variant={"body1"} color={"textSecondary"}>{companyUser.user.firstName}</Typography></Grid>
                                    <Grid item xs={2}><Typography variant={"body1"} color={"textSecondary"}>{companyUser.user.surname}</Typography></Grid>
                                    <Grid item xs={1}>
                                        <AmountDisplay
                                            amount={companyUser.user.claimed ? "Claimed" : "Pending"}
                                            color={companyUser.user.claimed ? "success" : "warning"}
                                            tooltip={false}
                                        />
                                    </Grid>
                                    <Grid item xs={1}><Typography variant={"body1"} color={"textSecondary"}>{moment(companyUser.joinDate).format("DD/MM/YYYY")}</Typography></Grid>
                                    <Grid item xs={2}>
                                        <FormControl size={"small"} fullWidth>
                                            <Select readOnly={userEdit} value={companyUser.roleId} disabled={saving} onChange={onRoleChange(companyUser.user, index)} variant={"outlined"} fullWidth>
                                                { renderLookupsAsMenuItem(roles, false, getDisabledRoles(companyUser)) }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1} container justifyContent={"flex-end"}>
                                        <ValidateUserPermission permissions={[Permission.UserEdit]}>
                                            <Grid item>
                                                <Tooltip title={"Modify Permissions"}>
                                                    <IconButton onClick={onEditClicked(companyUser)} size={"small"}><EditOutlined/></IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </ValidateUserPermission>
                                        <ValidateUserPermission permissions={[Permission.UserDelete]}>
                                            <Grid item>
                                                <Tooltip title={"Remove User from Company"}>
                                                    <IconButton disabled size={"small"}><DeleteOutline/></IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </ValidateUserPermission>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
    
}
export { UsersTable }