import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { history as appHistory } from "../../history";

import { Button, Container, Divider, Grid, Mark, SelectChangeEvent, Typography } from "@mui/material";

import { DeleteRounded, LinkRounded } from "@mui/icons-material";

import { Formik } from 'formik';

import {
    QuestionDetailsDto,
    questionDetailsDtoInitialState
} from "common/dtos/Questions/QuestionDetails/models";
import { isEmptyOrSpaces, nicifyString } from "common/helpers/Regex/regex";
import { AttachmentDropper } from "common/components/input/attachments/AttachmentDropper/AttachmentDropper";
import { CollapsibleSubsection } from "common/components/sections/CollapsibleSubsection/CollapsibleSubsection";
import { AnswerDto, AnswerValue } from "common/dtos/Questions/models";
import { SaveChangesBar } from "common/components/panels/SaveChangesBar/SaveChangesBar";
import {
    AnswerAdditionalDetailsDto,
    answerAdditionalDetailsInitialState, AnswerAdditionalDetailsRequest,
    answerAdditionalDetailsRequestInitialState,
    QuestionAnswerForm,
    questionAnswerFormInitialState,
    questionAnswerFormValidationSchema,
    QuestionAnswerRequest,
    SubquestionAnswersDto
} from "common/dtos/Questions/QuestionAnswers/models";
import { HistoryItem, HistoryType } from "common/dtos/History/models";
import { FileRoutesResponseDto } from "common/dtos/Questions/FileRoutesResponseDto/models";
import { SectorChips } from "common/components/content/SectorChips/SectorChips";
import { historyHelper } from "common/components/content/History/historyHelper";
import {
    deleteEvidence,
    getAnswerValues,
    getFileRoutes,
    getFunctionQuestionAnswer,
    getQuestionById,
    saveFunctionQuestionAnswers, updateAnswerAdditionalDetails,
    uploadEvidence
} from "./actions";

import { Help } from "./components/Help/Help";
import { Subquestion } from "./components/Subquestion/Subquestion";
import { QuestionProgress } from "./components/QuestionProgress/QuestionProgress";
import { selectedFunctionSelector, userSelector } from "user/store/selectors";
import { QuestionSaveChangesModal } from "./components/QuestionSaveChangesModal/QuestionSaveChangesModal";
import { QuestionHistory } from "./components/QuestionHistory/QuestionHistory";
import { QuestionSidepanel } from "./components/QuestionSidepanel/QuestionSidepanel";
import { sidepanelStyles } from "./components/QuestionSidepanel/MuiStyles";
import { ReferenceItem } from "./components/ReferenceItem/ReferenceItem";
import { useValidPermission, ValidateUserPermission } from "auth/components/ValidateUserPermission";
import { Permission } from "auth/store/Model";

import "./styles.scss";

const Question: React.FC = () => {

    const [expandSave, setExpandSave] = useState<boolean>(false);
    const [evidenceRoutes, setEvidenceRoutes] = useState<FileRoutesResponseDto[]>([])
    const [answerModal, setAnswerModal] = useState<boolean>(false);
    const [trackedAnswers, setTrackedAnswers] = useState<number[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [sidepanelOpen, setSidepanelOpen] = useState<boolean>(true);
    const [assigneeDropdownOpen, setAssigneeDropdownOpen] = useState<boolean>(false);
    const [question, setQuestion] = useState<QuestionDetailsDto>(questionDetailsDtoInitialState);
    const [answerAdditionalDetails, setAnswerAdditionalDetails] = useState<AnswerAdditionalDetailsDto>(answerAdditionalDetailsInitialState);
    const [answerValues, setAnswerValues] = useState<AnswerDto[]>([]);
    const [formState, setFormState] = useState<QuestionAnswerForm>(questionAnswerFormInitialState);
    const [history, setHistory] = React.useState<HistoryItem[]>([]);

    const selectedFunction = useSelector(selectedFunctionSelector);
    
    const user = useSelector(userSelector)
    const params: any = useParams();

    useEffect(() => {
        getAnswerValues().then((response) => {
            setAnswerValues(response);
        })
    }, [setAnswerValues]);

    // todo: https://gitlab.com/bi-data-solutions/ASIRTA/-/issues/125
    // way to many re renderings happening, this use effect has way to much responsabilty
    // update (DB): I've separated the useEffects however this still needs to be addressed further in #125
    
    useEffect(() => {
        getQuestionById(params.questionId).then(async (question) => {
            setQuestion(question);
            setTrackedAnswers([])
                       
            const answers = await getFunctionQuestionAnswer(question);

            setAnswerAdditionalDetails(answers.answerAdditionalDetails ?? answerAdditionalDetailsInitialState)
            
            const subquestionAnswers: SubquestionAnswersDto[] = question.linkedQuestions.map((linkedQuestion): SubquestionAnswersDto => {
                
                const subanswer = answers.subquestionAnswers.find(a => a.questionId === linkedQuestion.question.id)
                
                return {
                    questionId: linkedQuestion.question.id,
                    answer: (subanswer) ? subanswer.answer : AnswerValue.NotAnswered,
                    justification: ""
                }
            })
            
            setFormState((prevState) => {
                return({
                    ...prevState,
                    answers: subquestionAnswers
                })
            });
        })
    }, [params.questionId])
    
    useEffect(() => {
        
        if(selectedFunction === undefined)
            return;
        
        getFileRoutes(selectedFunction.id, params.questionId).then(response => {
            setEvidenceRoutes(response);
        })
    }, [params.questionId, selectedFunction])

    const needHelpClicked = useCallback(() => {
        setSidepanelOpen(true)
        setAssigneeDropdownOpen(true)
    }, [])

    const saveChanges = (values: QuestionAnswerForm, resetForm: () => void) => {

        setSaving(true);

        const output: QuestionAnswerRequest = {
            ...values,
            answers: {
                questionDetailsId: question.id,
                questionGroupId: question.questionGroupToQuestion.questionGroup.id,
                subquestionAnswers: values.answers.filter(a => a.answer > AnswerValue.NotAnswered),
                answerAdditionalDetails: answerAdditionalDetails
            }
        }

        saveFunctionQuestionAnswers(question.id, output).then(_ => {
            
            output.answers.subquestionAnswers.forEach((answer) => answer.justification = "")
            
            setFormState({
                answers: values.answers,
                additionalDetailsRequest: answerAdditionalDetailsRequestInitialState
            })

            setHistory([
                ...history,
                ...historyHelper.subquestionAnswersToHistoryItems(output.answers.subquestionAnswers, user)
            ]);

            setTrackedAnswers([]);
            resetForm();
        }).finally(() => {
            setSaving(false);
            setAnswerModal(false);
        });
    }

    const handleSubquestionChange = (index: number, handleChange: (e: SelectChangeEvent<any>) => void) => (e: SelectChangeEvent<any>) => {

        const answer = e.target.value;
        let tracked = trackedAnswers;

        if (trackedAnswers.includes(index) && answer === formState.answers[index].answer)
            tracked = trackedAnswers.filter(a => a !== index)
        else if (!trackedAnswers.includes(index))
            tracked = [...trackedAnswers, index]

        setTrackedAnswers(tracked);

        handleChange(e);
    }

    const handleAdditionalDetailsChanged = useCallback((value: any, field: string) => {

        const request: AnswerAdditionalDetailsRequest = {
            ...answerAdditionalDetails,
            [field]: value,
        }

        updateAnswerAdditionalDetails(question.id, request).then(_ => {
            setAnswerAdditionalDetails({
                ...answerAdditionalDetails,
                [field]: value
            })
        });

    }, [answerAdditionalDetails, question.id, setAnswerAdditionalDetails])
    
    const validateSaveChangesDisplay = () => {
        setExpandSave(trackedAnswers.length > 0);
    }

    const getAnswerValueMarks = (): Mark[] => {
        const valueMarks: Mark[] = [{label: "", value: 0}];

        const values: Mark[] = answerValues.map((a) => {
            return {
                label: nicifyString(AnswerValue[a.answerValue].toString()),
                value: a.weight
            };
        });

        valueMarks.push(...values)

        return valueMarks;
    }

    const calculateProgress = (): number => {
        let overall: number = 0;

        formState.answers.map(a =>
            overall += answerValues.find(av => av.answerValue === a.answer)?.weight || 0
        );

        return overall / formState.answers.length;
    }

    const onFilesDropped = async (files: FileList): Promise<void> => {
        const functionId = selectedFunction?.id;
        if (functionId) {
            for (let i = 0; i < files.length; i++) {

                // TODO: Add Snackbar Notification highlighting rejected File Upload
                if (evidenceRoutes.some(e => e.fileName === files[i].name)) {
                    continue;
                }

                await uploadEvidence(functionId, question.id, files[i]).then(_ => {
                    getFileRoutes(selectedFunction!.id, question.id).then(response => {
                        setEvidenceRoutes(response);

                        setHistory([
                            ...history,
                            ...historyHelper.fileRoutesToHistoryItems(response, user, HistoryType.QuestionEvidence)
                        ]);
                    })
                })
            }
        }
    }

    const onEvidenceDeleted = (fileRoute: FileRoutesResponseDto): Promise<void> => {
        return deleteEvidence(selectedFunction!.id, question.id, fileRoute.fileName).then(_ => {
            setEvidenceRoutes(evidenceRoutes.filter(r => r.fileRoute !== fileRoute.fileRoute))
        });
    }

    const renderQuestions = (values: SubquestionAnswersDto[], handleChange: (e: SelectChangeEvent<any>) => void, readonly: boolean): ReactElement[] => {

        return question.linkedQuestions.map((q, i) => {

             
            const index = values.findIndex(v => v.questionId === q.question.id);

            if (index === -1)
                return (<Grid key={`question-${i}`} item/>);

            return (
                <React.Fragment key={i}>
                    { i > 0 &&
                    <Grid item container key={i}><Divider light={false}/></Grid>
                    }
                    <Grid item container>
                        <Subquestion
                            name={`answers[${index}].answer`}
                            keyName={`question-${i}`}
                            subquestion={q}
                            answer={values[index].answer}
                            answerValues={answerValues}
                            handleChange={handleSubquestionChange(index, handleChange)}
                            key={i}
                            readOnly={readonly}
                        />
                    </Grid>
                </React.Fragment>
            )
        })
    }
    
    const onNavigationClicked = useCallback((questionId: number | null) => (e: any) => {
        appHistory.push(`/questions/${questionId}`)
    },[])

    const classes = sidepanelStyles();
    
    const userCanEdit = !useValidPermission(Permission.QuestionsEdit);
    
    const guidanceHeader = question.excelKey !== 'Import' && 'ASIRTA™ guidance';
    return (
        <Container className={classes.root} maxWidth={false} disableGutters>
            <div className={classes.content}>
                <Formik
                    initialValues={formState}
                    validationSchema={questionAnswerFormValidationSchema}
                    enableReinitialize
                    onSubmit={(values, {resetForm}) => {
                        saveChanges(values, resetForm)
                    }}
                >
                    {({
                          values,
                          handleChange,
                          handleSubmit,
                          touched,
                          errors
                      }) => {

                        validateSaveChangesDisplay();
                        
                        return (
                            <Grid className={"question"} item container xs={12} sm={11} md={11} lg={11} xl={8}
                                  spacing={2}>
                                <Grid container item justifyContent={"space-between"}>
                                    <Grid item>
                                        <Button 
                                            color={"primary"}
                                            onClick={() => appHistory.push("/questions")}
                                            size={"small"}
                                            >Back to Questions</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color={"primary"}
                                                disabled={question?.questionNavigationDto?.previousQuestionId === null}
                                                size={"small"}
                                                onClick={onNavigationClicked(question?.questionNavigationDto?.previousQuestionId)}>Previous</Button>
                                        <Button color={"primary"}
                                                disabled={question?.questionNavigationDto?.nextQuestionId === null}
                                                size={"small"}
                                                onClick={onNavigationClicked(question?.questionNavigationDto?.nextQuestionId)}>Next</Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider light={false}/>
                                </Grid>
                                <Grid container item spacing={2} justifyContent={"space-between"}>
                                    <Grid item xs={10}>
                                        <Typography variant={"h5"}><b>{question.reportCategory}</b></Typography>
                                    </Grid>
                                    <Grid container item>
                                        <QuestionProgress marks={getAnswerValueMarks()}
                                                          progress={answerValues && calculateProgress()}/>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1} justifyContent={"space-between"}>
                                        <Grid item xs={8}>
                                            <SectorChips sectors={question.sectors}/>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={needHelpClicked}
                                                color={"primary"}
                                                variant={"outlined"}
                                                size={"small"}
                                            >Delegate question?</Button>
                                        </Grid>
                                        <Grid item>
                                            <Help />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <CollapsibleSubsection
                                        title={"Question(s)"}
                                        collapsible
                                    >
                                        <Grid item xs={12}>
                                            {question.questionHeader && <Typography className={"questionHeader"}
                                                                                    gutterBottom>{question.questionHeader}</Typography>}
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            {renderQuestions(values.answers, handleChange, userCanEdit)}
                                        </Grid>
                                    </CollapsibleSubsection>
                                </Grid>
                                <Grid container item xs={12}  >
                                    <Grid item xs={12}>
                                        <CollapsibleSubsection title={"Guidance"}>
                                            <Grid item xs={12}>
                                                <Typography variant={"body2"}><b>{guidanceHeader}</b></Typography>
                                                <pre className={'questionGuidance'}>{isEmptyOrSpaces(question.guidance) ? <i>No known supplied guidance</i> : question.guidance }</pre>
                                            </Grid>
                                            <br/>
                                            {question.frameworkGuidance &&
                                            <Grid item>
                                                <Typography
                                                    variant={"body2"}><b>{question.frameworkGuidance.value}</b></Typography>
                                                <Typography
                                                    variant={"body2"}>{question.frameworkGuidance.description}</Typography>
                                            </Grid>
                                            }
                                        </CollapsibleSubsection>
                                    </Grid>

                                </Grid>
                                <Grid item xs={6}>
                                    <CollapsibleSubsection
                                        title={"Evidence & Documentation"}
                                        collapsible={evidenceRoutes.length > 0}
                                    >
                                        {evidenceRoutes.length > 0 && evidenceRoutes.map((e, i) => {
                                            return <ValidateUserPermission permissions={[Permission.QuestionsEvidenceUpload]}>
                                                <ReferenceItem 
                                                    key={i}
                                                    label={e.fileName}
                                                    href={e.fileRoute}
                                                    icon={<DeleteRounded/>}
                                                    iconTooltip={"Delete"}
                                                    onIconClicked={() => onEvidenceDeleted(e)}
                                                />
                                            </ValidateUserPermission>
                                        })
                                        }

                                        <ValidateUserPermission permissions={[Permission.QuestionsEvidenceUpload]}>
                                            <Grid item container>
                                                <AttachmentDropper
                                                    onFilesDropped={onFilesDropped}
                                                    uploadDescription={"documents relating to this question"}
                                                />
                                            </Grid>
                                        </ValidateUserPermission>
                                    </CollapsibleSubsection>
                                </Grid>
                                <Grid item xs={6}>
                                    <CollapsibleSubsection
                                        title={"Additional guidance"}
                                        collapsible={evidenceRoutes.length > 0}
                                    >
                                        <ReferenceItem
                                            label={question.compliance}
                                            href={question.obligationLink}
                                            icon={<LinkRounded/>}
                                        />
                                    </CollapsibleSubsection>
                                </Grid>
                                <QuestionHistory
                                    question={question}
                                    history={history}
                                    setHistory={setHistory}
                                />
                                <SaveChangesBar
                                    expand={expandSave}
                                    onClick={() => setAnswerModal(true)}
                                    saving={saving}
                                    message={`You've made ${trackedAnswers.length} change(s)`}
                                    buttonText={"Finalise Changes"}
                                />

                                <QuestionSaveChangesModal
                                    open={answerModal}
                                    setOpen={setAnswerModal}
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    question={question}
                                    trackedAnswers={trackedAnswers}
                                    initialAnswers={formState.answers}
                                    answers={values.answers}
                                    errors={errors}
                                    touched={touched}
                                />
                            </Grid>
                        );
                    }}
                </Formik>
            </div>
            <QuestionSidepanel
                open={sidepanelOpen}
                setOpen={setSidepanelOpen}
                assigneeDropdownOpen={assigneeDropdownOpen}
                setAssigneeDropdownOpen={setAssigneeDropdownOpen}
                questionId={params.questionId}
                answerAdditionalDetails={answerAdditionalDetails}
                onAdditionalDetailsUpdated={handleAdditionalDetailsChanged}
                user={user}
                history={history}
                setHistory={setHistory}
            />
        </Container>
    );
}
export {Question}
