import { assessmentQuestionsService } from "common/services/AssessmentQuestionsService/assessmentQuestionsService";
import { AssessmentQuestionDto } from "common/dtos/Questions/Assessment/models";
import { AssessmentQuestionsAnswerRequest } from "questions/Assessment/models";

export enum AssessmentState {
    WELCOME, ANSWER, COME_BACK_LATER,  COMPLETE, NO_ACTION
}

export function getAssessmentQuestions(): Promise<AssessmentQuestionDto[]> {
    return assessmentQuestionsService.getAssessmentQuestions();
}

export function answerAssessmentQuestions(assessmentQuestionsAnswers: AssessmentQuestionsAnswerRequest): Promise<void> {
    return assessmentQuestionsService.answerAssessmentQuestions(assessmentQuestionsAnswers);
}