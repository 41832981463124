import React, { useState } from 'react';

import { Button, Grid } from "@mui/material";

import { TypeSelector } from "common/components/input/selectors/TypeSelector/TypeSelector";
import { WizardStepProps } from "../../../models";
import { AssignCompanyTypesText } from "common/helpers/HelperText/HelperTexts";

const CompanySelectionTypes: React.FC<WizardStepProps> = ({onBackClicked, onContinueClicked, object}) => {
    
    const [selectedTypes, setSelectedTypes] = useState<any[]>(object.types);
    const [selectedErrorText, setSelectedErrorText] = useState<string>("");
        
    const onClick = () => {
        
        if(selectedTypes.length === 0){
            setSelectedErrorText("Required");
            return;
        }

        setSelectedErrorText("");
        
        onContinueClicked({
            ...object,
            types: selectedTypes
        })
    }
    
    return (
        <Grid xs={10} item container spacing={2}>
            <Grid item xs={12}>
                { AssignCompanyTypesText }
            </Grid>
            
            <Grid item xs={12}>
                <TypeSelector
                    label={`Which of the following types describes your organisation ${object.company.name} in general?`}
                    selectedTypes={selectedTypes}
                    onChange={setSelectedTypes}
                    errorText={selectedErrorText}
                />
            </Grid>

            <Grid item xs={12}/>

            <Grid container item xs={12}>
                <Grid container item xs={2} justifyContent={"flex-start"}>
                    <Grid item>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            onClick={onBackClicked}
                        >Previous</Button>
                    </Grid>
                </Grid>
                <Grid container xs={10} item justifyContent={"flex-end"}>
                    <Grid item>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            onClick={onClick}
                        >Continue</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export { CompanySelectionTypes }