import React, { useCallback, useEffect, useState } from 'react';

import { CircularProgress, Grid, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";

import { ObligationCategoryDetailsDto, ObligationCategoryDto } from "common/dtos/Obligations/models";
import { AssetObligationComplianceScoreDto } from "common/dtos/Assets/AssetObligationComplianceScoreDto";
import { AssetLinkedObligationsDto } from "common/dtos/Assets/AssetLinkedObligationsDto";
import { linkObligationsToAssets, unlinkObligationById } from "assetManagement/store/actions";
import { CollapsibleSubsection } from "common/components/sections/CollapsibleSubsection/CollapsibleSubsection";
import { AddRounded, CloseRounded } from "@mui/icons-material";
import { SearchableMenu } from "common/components/panels/SearchableMenu/SearchableMenu";
import { AmountDisplay } from "common/components/content/AmountDisplay/AmountDisplay";
import { assetService } from "common/services/AssetService/assetService";

import "./styles.scss";

export interface AssetLinkedObligationsProps {
    asset: AssetLinkedObligationsDto;
    obligationCategories: ObligationCategoryDto[];
    setAsset: (asset: AssetLinkedObligationsDto) => void;
    loading?: boolean;
}

const AssetLinkedObligations: React.FC<AssetLinkedObligationsProps> = ({asset, obligationCategories, setAsset, loading}) => {

    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [savingChanges, setSavingChanges] = useState<boolean>(false);
    const [obligationComplianceScores, setObligationComplianceScores] = useState<AssetObligationComplianceScoreDto[]>([]);
    const [selectedMenuItems, setSelectedMenuItems] = useState<ObligationCategoryDetailsDto[]>([]);
    const [loadingScores, setLoadingScores] = useState<boolean>(false);

    useEffect(() => {
        if(!loading){
            setLoadingScores(true);
            assetService.getObligationComplianceScoresByAsset(asset.assetDetails.id).then(response => {
                setObligationComplianceScores(response);
            }).finally(() => setLoadingScores(false));
        }
    }, [asset.assetDetails, loading])
    
    useEffect(() => {
        setSelectedMenuItems(asset.linkedObligations)
    }, [asset.linkedObligations])

    const handleMenuOpen = () => {
        setOpenMenu(true);
    }

    const handleMenuClose = () => {
        setOpenMenu(false);
        saveChanges();
    }

    const saveChanges = () => {
        setSavingChanges(true);
        linkObligationsToAssets(asset.assetDetails.id, selectedMenuItems.map((item) => item.id)).then(response => {
            setSavingChanges(false);
            setAsset({
                ...asset,
                linkedObligations: response.map(o => o.obligationCategoryDetails)
            });
            setSelectedMenuItems(response.map(o => o.obligationCategoryDetails));
        })
    }

    const unlinkObligation = (assetId: number, obligationId: number) => {
        setDeleting(true)
        unlinkObligationById(assetId, obligationId).then(_ => {
            setSelectedMenuItems(selectedMenuItems.filter(m => m.id !== obligationId));
            setAsset({
                ...asset,
                linkedObligations: asset.linkedObligations.filter(o => o.id !== obligationId)
            });
        }).finally(() => setDeleting(false))
    }

    const getDisabledObligations = useCallback(() => {
        return obligationCategories
            .filter(o => o.additionalDetails !== undefined)
            .filter(o => o.additionalDetails.autoApplied)
            .map(o => o.details);
    }, [obligationCategories])

    const getObligationDetails = useCallback(() => {
        return obligationCategories.map(o => o.details);
    }, [obligationCategories])
    
    const getComplianceScoreColour = useCallback((score: number): string => {
        const colours = ["error", "warning", "success"];
        
        // Takes the 0 - 100 range and converts it to 0-4 for index
        const index = Math.round((score / 10) / 4);
        
        return colours[index];        
    }, [])
    
    const renderTooltip = useCallback(() => {
        return (
            <Typography variant={"caption"}>Compliance Score</Typography>
        )
    }, [])

    const anchorRef = React.useRef<HTMLButtonElement>(null);
    
    return (
        <Grid container item justifyContent={"flex-end"}>
            <CollapsibleSubsection
                title={"Linked Obligations"}
                collapsible={asset.linkedObligations.length > 0}
                subsectionItems={[
                    <Tooltip title={"Link an Obligation"} placement={"top"}>
                        <IconButton
                            ref={anchorRef}
                            onClick={handleMenuOpen}
                            size={"small"}
                        >{savingChanges
                            ? <CircularProgress color={"primary"} size={24}/>
                            : <AddRounded/>}
                        </IconButton>
                    </Tooltip>
                ]}>
                {
                    obligationCategories
                        .filter(o => o.additionalDetails)
                        .filter(o => selectedMenuItems.some(lo => lo.id === o.details.id))
                        .map((o, i) => {
                            
                            const compliance = obligationComplianceScores.find(c => c.obligationCategoryDetailsId === o.details.id);
                            const score = compliance?.compliance || 0;
                            
                            return (
                                <Grid container justifyContent={"space-between"} alignItems={"center"} key={i}>
                                    <Grid item>
                                        <Typography>{o.details.name}</Typography>
                                    </Grid>
                                    <Grid item className={"obligationScore"}>
                                        { loadingScores ? <Skeleton width={40}/>:
                                            <AmountDisplay
                                                amount={`${score}%`}
                                                tooltip={renderTooltip()}
                                                placement={"left"}
                                                color={getComplianceScoreColour(score)}
                                                typography={"body2"}
                                            />
                                        }
                                        
                                        <Tooltip title={"Unlink Obligation"}>
                                            <IconButton disabled={o.additionalDetails.autoApplied || deleting}
                                                        onClick={() => unlinkObligation(asset.assetDetails.id, o.details.id)}
                                                        size={"small"}><CloseRounded/></IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            )
                        })
                }
            </CollapsibleSubsection>
            <SearchableMenu
                readOnly={false}
                items={getObligationDetails()}
                selectedItems={selectedMenuItems}
                setSelectedItems={setSelectedMenuItems}
                disabledItems={getDisabledObligations()}
                open={openMenu}
                anchorElement={anchorRef}
                searchable
                titleSelector={(item: ObligationCategoryDetailsDto) => item.name}
                searchSelector={(item: ObligationCategoryDetailsDto) => item.name}
                idSelector={(item: ObligationCategoryDetailsDto) => item.id}
                handleClose={handleMenuClose}
            />
        </Grid>
    )
}
export { AssetLinkedObligations }