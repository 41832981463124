import React, { useCallback, useEffect, useState } from "react";

import { Button, Card, Container, Grid, Typography } from "@mui/material";

import { UsersTable } from "./UsersTable/UsersTable";

import { CompanyUserDto, companyUserInitialState } from "common/dtos/Companies/models";
import { getUsersAndGroups } from "company/actions";
import { InviteUsersModal } from "users/InviteUsersModal/InviteUsersModal";

import "./styles.scss";
import { ModifyPermissionsModal } from "users/ModifyPermissionsModal/ModifyPermissionsModal";
// import { Permission } from "auth/store/Model";
// import { ValidateUserPermission } from "auth/components/ValidateUserPermission";

const UsersOverview: React.FC = () => {
    
    const [users, setUsers] = useState<CompanyUserDto[]>([]);
    const [selectedUser, setSelectedUser] = useState<CompanyUserDto>(companyUserInitialState);
    const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
    const [openPermissionsModal, setOpenPermissionsModal] = useState<boolean>(false);
    
    useEffect(() => {
        getUsersAndGroups().then(response => {
            setUsers(response)
        })
    }, [])
    
    const onModifyClicked = useCallback((companyUser: CompanyUserDto) => {
        setSelectedUser(companyUser);
        setOpenPermissionsModal(true);
    }, [])
    
    const openInviteMoodal = useCallback(() => setOpenInviteModal(true), [])
    
    return (
        <Container className={"topBottomGutters"} maxWidth={'lg'}>
            <Card variant={"outlined"}>
                <Grid container spacing={2}>
                    <Grid container item alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item>
                            <Grid item>
                                <Typography variant={"h6"}>Users</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    color={"secondary"}>An active list of all Users in your estate</Typography>
                            </Grid>
                        </Grid>
                       
                            <Grid item>
                                <Button disabled={false} size={"small"} color={"primary"} variant={"outlined"} onClick={openInviteMoodal}>Invite Users</Button>
                            </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <UsersTable
                            companyUsers={users}
                            setCompanyUsers={setUsers}
                            onModifyClicked={onModifyClicked}
                        />
                    </Grid>
                </Grid>
            </Card>
            <InviteUsersModal
                open={openInviteModal}
                setOpen={setOpenInviteModal}
                setCompanyUsers={setUsers}
            />
            <ModifyPermissionsModal
                open={openPermissionsModal}
                setOpen={setOpenPermissionsModal}
                companyUser={selectedUser}
            />
        </Container>
    );
}
export { UsersOverview }