import {
    RaciAssignment,
    UserRaciToActivityCategoryRequest
} from "common/dtos/ActivityCategories/models";
import { DelegatedActivityCategoryDto, UserDetailsDto } from "common/dtos/Users/models";
import { getArrayDifferencesByComparator } from "common/helpers/Array/array";
import { DelegationType } from "common/dtos/Common/delegation";
import { ActivityCategoryDetailsDto } from "common/dtos/Questions/QuestionDetails/models";
import { RaciAssignmentForm } from "./RaciDelegation/models";

class RaciHelper {

    public prepareAssignmentRequest(activityCategories: ActivityCategoryDetailsDto[], changes: DelegatedActivityCategoryDto[], initial: DelegatedActivityCategoryDto[]): UserRaciToActivityCategoryRequest {

        const assignments: DelegatedActivityCategoryDto[] = [];
        const unassignments: DelegatedActivityCategoryDto[] = [];
        
        activityCategories.forEach((activityCategory) => {
            const acChanges: DelegatedActivityCategoryDto[] = changes.filter(c => c.activityCategoryDetailsId === activityCategory.id);
            const acInitial: DelegatedActivityCategoryDto[] = initial.filter(c => c.activityCategoryDetailsId === activityCategory.id);
            
            const deductions: DelegatedActivityCategoryDto[] = 
                getArrayDifferencesByComparator(acInitial, acChanges, (source: DelegatedActivityCategoryDto, target: DelegatedActivityCategoryDto) => 
                    source.userDetailsId === target.userDetailsId && source.delegationId === target.delegationId);
            const additions: DelegatedActivityCategoryDto[] =
                getArrayDifferencesByComparator(acChanges, acInitial, (source: DelegatedActivityCategoryDto, target: DelegatedActivityCategoryDto) =>
                    source.userDetailsId === target.userDetailsId && source.delegationId === target.delegationId);
            
            unassignments.push(...deductions)
            assignments.push(...additions)
        })
        
        return {
            raciAssignments: assignments.map((a: DelegatedActivityCategoryDto) => ({ userId: a.userDetailsId, email: a.user.email, activityCategoryId: a.activityCategoryDetailsId, delegationType: a.delegationId})),
            raciUnassignments: unassignments.map((a: DelegatedActivityCategoryDto) => ({ userId: a.userDetailsId, email: a.user.email, activityCategoryId: a.activityCategoryDetailsId, delegationType: a.delegationId}))
        }
    }
    
    public calculateChanges(activityCategories: ActivityCategoryDetailsDto[], changes: DelegatedActivityCategoryDto[], initial: DelegatedActivityCategoryDto[]): number {
        const request = this.prepareAssignmentRequest(activityCategories, changes, initial);
        
        return request.raciUnassignments.length + request.raciAssignments.length;
    }

    public userDetailsToRaciAssignment(user: UserDetailsDto, delegation: DelegationType, activityCategoryId: number): RaciAssignment {
        return {
            userId: user.id,
            email: user.email,
            delegationType: delegation,
            activityCategoryId: activityCategoryId
        }
    }

    public usersToDelegatedActivityCategoryDto(users: UserDetailsDto[], delegation: DelegationType, activityCategory: ActivityCategoryDetailsDto): DelegatedActivityCategoryDto[] {
        return users.map((user: UserDetailsDto) => ({
            user: user,
            userDetailsId: user.id,
            activityCategory: activityCategory,
            activityCategoryDetailsId: activityCategory.id,
            delegationId: delegation,
            delegation: { id: delegation, value: DelegationType[delegation], description: ""},
            id: 0
        }));
    }
    
    public delegatedActivityCategoryDtoToFormState(delegations: DelegatedActivityCategoryDto[]): RaciAssignmentForm {
        return {
            responsibleUsers: delegations.filter(u => u.delegationId === DelegationType.Responsible).map(u => u.user),
            accountableUsers: delegations.filter(u => u.delegationId === DelegationType.Accountable).map(u => u.user),
            consultedUsers: delegations.filter(u => u.delegationId === DelegationType.Consulted).map(u => u.user),
            informedUsers: delegations.filter(u => u.delegationId === DelegationType.Informed).map(u => u.user)
        }
    }
    
    public isDelegationInAssignmentList(dac: DelegatedActivityCategoryDto, assignmentList: RaciAssignment[]) {
        return assignmentList.some(u => u.delegationType === dac.delegationId && u.activityCategoryId === dac.activityCategoryDetailsId && u.userId === dac.userDetailsId);
    }

}

export const raciHelper: RaciHelper = new RaciHelper();
