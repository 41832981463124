import moment from "moment";

import { LookupDto, lookupDtoInitialState } from "../../../services/LookupService/models";
import { SectorsDto } from "../Sectors/models";
import { AuditDto } from "../../Common/audit";
import { AnswerValue } from "../models";
import {
    AnswerAdditionalDetailsDto,
    answerAdditionalDetailsInitialState,
} from "../QuestionAnswers/models";
import { ObligationCategoryDetailsDto } from "common/dtos/Obligations/models";

export interface LinkedQuestionDto {
    question: QuestionDto;
}

export interface QuestionDto {
    id: number;
    value: string;
    order: number;
}

export interface QuestionGroupToQuestionDto {
    id: number;
    questionGroup: QuestionGroupDto;
}

export interface QuestionDetailedAnswer extends AuditDto{
    answer: AnswerValue;
    AnswerWeight: number;
}

export interface QuestionGroupDto {
    id: number;
    name: string;
    extendedName: string;
}

export interface ActivityCategoryDetailsDto {
    id: number;
    name: string;
    extendedName: string;
    questionTypeId: number;
    questionType: LookupDto<string>;
}

export interface ObligationGroupDto {
    id: number;
    name: string;
    extendedName: string;
    obligationCategory: ObligationCategoryDetailsDto;
}


export interface QuestionNavigationDto {
    nextQuestionId: number | null;
    previousQuestionId: number | null;
}

export interface QuestionDetailsDto extends AuditDto {
    id: number;
    excelKey: string;
    billingTier: LookupDto<string>;
    questionStatus: QuestionStatus;
    questionStyle: QuestionStyle;
    complianceDescription: string;
    reportCategory: string;
    penalty: string;
    exposure: string;
    complianceReportCategory: string;
    complianceReportRequirements: string;
    obligationLink: string;
    frameworkGuidance: LookupDto<string>;
    question: string;
    activity: string;
    activityHeader: string;
    guidance: string;
    compliance: string;
    questionSubText: string;
    questionNavigationDto: QuestionNavigationDto;
    answerAdditionalDetails: AnswerAdditionalDetailsDto;
    answer: QuestionDetailedAnswer;
    sectors: SectorsDto[];
    linkedQuestions: LinkedQuestionDto[];
    questionGroupToQuestion: QuestionGroupToQuestionDto;
    questionHeader?: string;
}

export enum QuestionStatus {
    None = 0,
}

export enum QuestionStyle {
    None = 0,
}

export enum QuestionAnswers {
    Unknown = 1,
    NotImplemented = 2,
    Planned = 3,
    Underway = 4,
    AuditedWithIssues = 5,
    Implemented = 6,
    AuditedNoIssues = 7,
    Certified = 8
}

export enum QuestionType {
    Asirta = 1,
    Custom = 2
}

const questionGroupInitialState: QuestionGroupDto = {
    id: 0,
    name: "",
    extendedName: ""
}
export { questionGroupInitialState }

const activityCategoryInitialState: ActivityCategoryDetailsDto = {
    id: 0,
    name: "",
    extendedName: "",
    questionTypeId: 0,
    questionType: lookupDtoInitialState
}
export { activityCategoryInitialState }

const questionGroupToQuestionInitialState: QuestionGroupToQuestionDto = {
    id: 0,
    questionGroup: questionGroupInitialState
}

const questionDetailsDtoInitialState: QuestionDetailsDto = {
    id: 0,
    excelKey: "",
    billingTier: lookupDtoInitialState,
    questionStatus: QuestionStatus.None,
    questionStyle: QuestionStyle.None,
    complianceDescription: "",
    reportCategory: "",
    penalty: "",
    exposure: "",
    complianceReportCategory: "",
    complianceReportRequirements: "",
    obligationLink: "",
    frameworkGuidance: lookupDtoInitialState,
    answerAdditionalDetails: answerAdditionalDetailsInitialState,
    question: "",
    activity: "",
    activityHeader: "",
    guidance: "",
    compliance: "",
    questionSubText: "",
    sectors: [],
    questionNavigationDto: {
        nextQuestionId: null,
        previousQuestionId: null
    },
    answer: {
        answer: AnswerValue.NotAnswered,
        AnswerWeight: 0,
        lastUpdatedBy: "",
        createdBy: "",
        creationDate: moment().toDate(),
        lastUpdatedDate: moment().toDate()
    },
    lastUpdatedDate: new Date(),
    lastUpdatedBy: "",
    creationDate: new Date(),
    createdBy: "",
    linkedQuestions: [],
    questionGroupToQuestion: questionGroupToQuestionInitialState
}

export { questionDetailsDtoInitialState }