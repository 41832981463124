import React from 'react';

import {Card, Container, Grid, Typography} from "@mui/material";
import {CollapsibleSection} from "../../common/components/sections/CollapsibleSection/CollapsibleSection";

const GuidedPeerAssessment: React.FC = () => {
    return (
        <Container className={'topBottomGutters'} maxWidth={'lg'}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h5'} fontWeight={600}>Guided Peer Assessment</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CollapsibleSection title={"Placeholder Section 1"}>
                            <Typography>Placeholder section 1 content</Typography>
                        </CollapsibleSection>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CollapsibleSection title={"Placeholder Section 2"}>
                            <Typography>Placeholder section 2 content</Typography>
                        </CollapsibleSection>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}
export { GuidedPeerAssessment }
