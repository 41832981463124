import React, { useCallback, useEffect } from 'react';
import { useParams } from "react-router";

import { QuestionDetailsDto } from "common/dtos/Questions/QuestionDetails/models";
import { HistoryCommentRequest } from "common/dtos/Questions/QuestionComments/models";
import {
    HistoryItem,
    HistoryRequest,
    historyRequestInitialState,
    HistoryType
} from "common/dtos/History/models";

import { deleteQuestionComment, getFunctionQuestionHistory, postQuestionComment } from "../../actions";
import { History } from "common/components/content/History/History";

export interface QuestionHistoryProps {
    question: QuestionDetailsDto;
    history: HistoryItem[];
    setHistory: (history: HistoryItem[]) => void;
}

const QuestionHistory: React.FC<QuestionHistoryProps> = ({question, history, setHistory}) => {
    
    const [historyRequest] = React.useState<HistoryRequest>(historyRequestInitialState);

    const params: any = useParams();

    useEffect(() => {
        getFunctionQuestionHistory(params.questionId, historyRequest).then(response => {
            setHistory(response);
        })
    }, [params.questionId, historyRequest, setHistory]);

    
    const onCommentPosted = useCallback((value: string): Promise<HistoryItem> => {
        
        const request: HistoryCommentRequest = {
            commentMarkdown: value
        };

        return postQuestionComment(params.questionId, request).then(response => {
            return {
                id: response.id.toString(),
                type: HistoryType.Comment,
                userDetails: response.userDetails,
                content: response.commentMarkdown,
                additionalContent: "",
                createdBy: response.createdBy,
                creationDate: response.creationDate,
                lastUpdatedBy: response.lastUpdatedBy,
                lastUpdatedDate: response.lastUpdatedDate,
                previousVersion: undefined
            }
        })
    }, [params.questionId])
    
    const onCommentDeleted = async (commentId: number): Promise<void> => {
        return deleteQuestionComment(commentId).then(() => {
            setHistory(history.filter(c => c.id !== commentId.toString()));
        })
    }
    
    return (
        <History 
            history={history} 
            setHistory={setHistory} 
            onCommentPosted={onCommentPosted} 
            onCommentDeleted={onCommentDeleted}
        />
    )
}
export { QuestionHistory }