import React, { ReactElement } from 'react';

import { Grid, IconButton, Select, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import { HelpOutlineRounded } from "@mui/icons-material";
import { useValidPermission } from "auth/components/ValidateUserPermission";
import { Permission } from "auth/store/Model";

export interface SidepanelDropdownProps {
    label?: string;
    value: string | number;
    options: ReactElement[];
    onChange: (value: SelectChangeEvent<any>) => void;
    optionLabelSelect: (option: any) => string;
    optionValueSelect: (option: any) => number;
    icon: ReactElement;
    helperText?: string;
    sidepanelText?: string;
    sidepanelOpen: boolean;
}

const SidepanelDropdown: React.FC<SidepanelDropdownProps> = ({
                                                                 label,
                                                                 value,
                                                                 options,
                                                                 onChange,
                                                                 optionLabelSelect,
                                                                 optionValueSelect,
                                                                 icon,
                                                                 sidepanelText,
                                                                 sidepanelOpen,
                                                                 helperText
                                                             }) => {
    
    const userCanEdit = !useValidPermission(Permission.QuestionsEdit);

    const renderOpen = () => {
        return (
            <Grid container item>
                {label &&
                <Grid container item justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant={"caption"}>{label}</Typography>
                    </Grid>
                    {helperText &&
                    <Grid item>
                        <Tooltip arrow placement={"left"} title={helperText}>
                            <HelpOutlineRounded fontSize={"small"}/>
                        </Tooltip>
                    </Grid>
                    }
                </Grid>
                }
                <Grid item xs={12}>
                    <Select
                        disabled={userCanEdit}
                        fullWidth
                        value={options.length > 0 ? value : ""}
                        onChange={onChange}
                    >
                        {options}
                    </Select>
                </Grid>
            </Grid>
        );
    }

    const renderCollapsed = (): ReactElement => {

        return (
            <Grid container item>
                <Grid item container justifyContent={"center"}>
                    <IconButton size="large">
                        {icon}
                    </IconButton>
                </Grid>
                {sidepanelText &&
                <Grid item container justifyContent={"center"}>
                    <Tooltip title={sidepanelText}>
                        <Typography
                            variant={"caption"}
                            noWrap
                        >{sidepanelText}
                        </Typography>
                    </Tooltip>
                </Grid>
                }

            </Grid>
        );
    }

    return (
        sidepanelOpen ? renderOpen() : renderCollapsed()
    )
}
export
{
    SidepanelDropdown
}