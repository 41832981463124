import { AxiosApi } from "../../axios/axiosApi";
import { HistoryCommentDto, HistoryCommentRequest } from "../../dtos/Questions/QuestionComments/models";
import SnackbarUtils from "../../helpers/SnackBar/snackbar";

class QuestionCommentsService {
    private controllerName = "api/QuestionComments";

    public async getComments(questionId: number): Promise<HistoryCommentDto[]> {

        const url: string = `${this.controllerName}/${questionId}`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return;
        });
    }

    public async postComment(questionId: number, commentMarkdown: HistoryCommentRequest): Promise<HistoryCommentDto> {

        const url: string = `${this.controllerName}/${questionId}`;

        return await AxiosApi.post(url, JSON.stringify(commentMarkdown)).then(response => {
            SnackbarUtils.success('Comment added...');
            return response.data;
        }).catch(e => {
            return;
        });
    }
    
    public async deleteComment(commentId: number): Promise<void> {
        
        const url: string = `${this.controllerName}/${commentId}`;

        return await AxiosApi.delete(url).then(_ => {
            SnackbarUtils.success('Comment deleted...');
            return;
        }).catch(e => {
            return;
        });
    }
}

const questionCommentsService = new QuestionCommentsService();
export { questionCommentsService }