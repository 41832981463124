import React, { useCallback } from 'react';
import { Button, Grid } from "@mui/material";
import { RiskLabelTable } from "riskLabels/components/RiskLabelTable/RiskLabelTable";
import { RiskSettingsDto } from "riskLabels/models";
import { RiskLabelRows } from "riskLabels/components/RiskLabelTable/models";
import { WizardStepProps } from "../../../models";
import { RiskLabelsText } from "common/helpers/HelperText/HelperTexts";
import { Formik, FormikProps } from 'formik';
import {riskLabelsValidationSchema} from "../../../../settings/companies/components/RiskLabelEditing/models";

const RiskLabels: React.FC<WizardStepProps> = ({object, onBackClicked, onContinueClicked}) => {

    const onClick = (values: { riskLabels: RiskSettingsDto[] }) => {

        onContinueClicked({
            ...object,
            riskSettings: values.riskLabels
        });
    }

    const riskFromTurnover = useCallback((): RiskSettingsDto[] => {
        
        const turnoverSegment = object.company.additionalDetails.annualTurnover / RiskLabelRows.length;
        
        return RiskLabelRows.map((risk, index) => {
            
            return {
                ...risk,
                impactRange: turnoverSegment * index
            }
        })
    }, [object.company.additionalDetails]);

    return (
        <Formik
            initialValues={{riskLabels: riskFromTurnover()}}
            onSubmit={onClick}
            validationSchema={riskLabelsValidationSchema}
        >
            {(props: FormikProps<{ riskLabels: RiskSettingsDto[] }>) => {

                const {errors, touched, handleSubmit, handleChange, values, setFieldValue} = props;

                return (
                    <Grid xs={10} item container spacing={2}>
                        <Grid item xs={12}>
                            {RiskLabelsText}
                        </Grid>

                        <Grid item xs={12}>
                            <RiskLabelTable
                                riskSettings={values.riskLabels}
                                touched={touched}
                                errors={errors}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                turnover={object.company.additionalDetails.annualTurnover}
                            />
                        </Grid>

                        <Grid container xs={2} item justifyContent={"flex-start"}>
                            <Grid item>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"large"}
                                    onClick={onBackClicked}
                                >Previous</Button>
                            </Grid>
                        </Grid>
                        <Grid container xs={10} item justifyContent={"flex-end"}>
                            <Grid item>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"large"}
                                    onClick={() => handleSubmit()}
                                >Continue</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export { RiskLabels }