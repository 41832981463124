import { QuestionFiltersState } from "./model";
import { ActionTypes, UPDATE_QUESTION_FILTERS } from "./actionTypes";

const initialState: QuestionFiltersState = {
    questionFilters: {
        search: "",
        answerValue: null,
        questionState: 0,
        delegationTypes: [],
        sectors: [],
        obligationCategories: [],
        activityCategories: [],
        trackedQuestionGroups: false,
        trackedObligations: false,
        trackedActivities: false
    }
}

export function questionFilters(
    state = initialState,
    action: ActionTypes
): any {
    switch(action.type) {
        case UPDATE_QUESTION_FILTERS:
            return {
                questionFilters: action.payload
            }
        default:
            return state;
    }
}