import React from 'react';
import { useSelector } from "react-redux";

import { FunctionDetailsDto } from "../common/dtos/Businesses/FunctionDetailsDto";

import { GettingStarted } from "gettingStarted/GettingStarted";
import { Container, Grid } from "@mui/material";
import { WelcomeSummary } from "dashboard/components/WelcomeSummary/WelcomeSummary";

import { selectedFunctionLoadingSelector, selectedFunctionSelector } from "user/store/selectors";


import { ActivityComplianceRatingTable } from "dashboard/components/ActivityComplianceRatingTable/ActivityComplianceRatingTable";
import { TrackingYourComplianceScore } from "../reporting/TrackingYourComplianceScore";
import { ObligationStatusDistribution } from "../reporting/ObligationStatusDistribution";
import { ComplianceOverTimeByObligation } from "../reporting/ComplianceOverTimeByObligation";
import { OverallStatusDistribution } from "../reporting/OverallStatusDistribution";

export interface DashboardFunctionProps {
    selectedFunction: FunctionDetailsDto;
}

const Dashboard: React.FC = () => {
    const selectedFunction = useSelector(selectedFunctionSelector);
    const loading = useSelector(selectedFunctionLoadingSelector);
    
    return (loading ? null : selectedFunction ?
            <Container className={"topBottomGutters"} maxWidth={'xl'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <WelcomeSummary selectedFunction={selectedFunction}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <TrackingYourComplianceScore selectedFunction={selectedFunction}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <OverallStatusDistribution selectedFunction={selectedFunction}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4}>
                        <ComplianceOverTimeByObligation selectedFunction={selectedFunction}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} xl={5}>
                        <ObligationStatusDistribution selectedFunction={selectedFunction}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={7} xl={7}>
                        <ActivityComplianceRatingTable selectedFunction={selectedFunction}/>
                    </Grid>
                </Grid>
            </Container>
            : <GettingStarted/>
    )
}
export { Dashboard }