import { Validation } from "auth/store/Model";
import { AxiosApi } from "../../axios/axiosApi";

class AuthenticationService{

    private controllerName: string = "api/Authentication"
    
    public async validateUser(): Promise<Validation> {
        const response = await AxiosApi.post(
            `${this.controllerName}/ValidateUser`
            );
        
        return response.data as Validation;
    }
}

const authenticationService = new AuthenticationService();
export { authenticationService }