import { UserDetailsDto } from "common/dtos/Users/models";
import { isEmptyOrSpaces } from "common/helpers/Regex/regex";

export function getNameFromUser(user: UserDetailsDto): string {

    const fullName: string = `${user.firstName || ""} ${user.surname || ""}`;

    if(isEmptyOrSpaces(fullName)){
        return isEmptyOrSpaces(user.identityProviderUserName) ? user.email : user.identityProviderUserName;
    }
    
    return fullName;
}
