import { AxiosApi } from "../../axios/axiosApi";
import { CompanyProfileResponseDto, companyProfileResponseInitialState, CompanySearchResponseDto } from "./models";

class CompaniesHouseService {
    private controllerName: string = "api/CompaniesHouse";

    public async getCompaniesByName(query: string): Promise<CompanySearchResponseDto[]> {

        if( query.length <= 3 )
            return Promise.resolve([]);
        const url: string = `${this.controllerName}/${query}`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        }) ;
    }
    
    
    
    public async getCompanyByCompaniesHouseId(companiesHouseId: string): Promise<CompanyProfileResponseDto> {
        const url: string = `${this.controllerName}/GetCompanyByCompaniesHouseId/${companiesHouseId}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return companyProfileResponseInitialState;
        });
    }
}

const companiesHouseService = new CompaniesHouseService();
export { companiesHouseService }