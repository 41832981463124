import React, { ReactFragment } from "react";

import './styles.scss';

const ComputerMisuseAct: ReactFragment = ( 
    <React.Fragment>
        <div className={'computerMisuseAct'}>
            <p>
                This service is provided by The Common Framework Limited to allow access to its resources.
                Any unauthorised attempt to access this service constitutes criminal offence under the
                United Kingdom Computer Misuse Act 1990, and will be prosecuted.
            </p>
        </div>  
    </React.Fragment>)

export { ComputerMisuseAct }