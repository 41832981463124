import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import {
    Breadcrumbs,
    Button,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    TextField,
    Typography
} from "@mui/material";

import { Formik, FormikProps } from 'formik';
import moment from "moment";
import { nameof } from "ts-simple-nameof";

import { AssetDetailsDto } from "common/dtos/Assets/AssetDetailsDto";
import { UserDetailsDto } from "common/dtos/Users/models";
import { companyService } from "common/services/CompanyService/companyService";
import { isEmptyOrSpaces } from "common/helpers/Regex/regex";
import { raciMarks } from "common/models/Marks/model";
import { fieldProps } from "common/theming/models";
import { AssetDetailsForm, assetWriteValidationSchema } from "./models";
import { modifyAsset } from "../../store/actions";
import { RiskLabelTypeSelector } from "common/components/input/selectors/RiskLabelTypeSelector/RiskLabelTypeSelector";

import "./styles.scss";

export interface AssetWriteProps {
    asset: AssetDetailsDto;
    onSaveClicked: (asset: AssetDetailsDto) => void;
}

const AssetWrite: React.FC<AssetWriteProps> = ({asset, onSaveClicked}) => {

    const [users, setUsers] = useState<UserDetailsDto[]>([]);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
    const [savingChanges, setSavingChanges] = useState<boolean>(false);

    useEffect(() => {
        setLoadingUsers(true);
        companyService.getUsersAndGroups().then(response => {
            setUsers(response.map(u => u.user));
            setLoadingUsers(false);
        })
    }, [])

    const renderUserOptions = (): ReactElement[] => {
        return users.map((u, i) => {
            const name: string = isEmptyOrSpaces(u.firstName)
                ? u.identityProviderUserName
                : `${u.firstName} ${u.surname}`
            return (
                <MenuItem key={i} value={u.id}>{name}</MenuItem>
            )
        })
    }

    const saveChanges = (values: AssetDetailsForm) => {
        setSavingChanges(true)

        const responsibleOwner = users.find(u => u.id === values.responsibleOwnerId);
        const accountableOwner = users.find(u => u.id === values.accountableOwnerId);
        
        if(responsibleOwner === undefined || accountableOwner === undefined)
            return;
        
        const assetDetails: AssetDetailsDto = {
            ...asset,
            ...values,
            responsibleOwner: responsibleOwner,
            accountableOwner: accountableOwner
        }
        
        modifyAsset(assetDetails).then(_ => {
            setSavingChanges(false)
            onSaveClicked(assetDetails);
        });
    }

    return (
        <Formik
            initialValues={{
                ...asset,
                accountableOwnerId: asset.accountableOwner.id,
                responsibleOwnerId: asset.responsibleOwner.id
            }}
            onSubmit={saveChanges}
            enableReinitialize
            validationSchema={assetWriteValidationSchema}
        >
            {(props: FormikProps<AssetDetailsForm>) => {

                const {errors, touched, handleChange, handleSubmit, values, setFieldValue} = props;

                return (
                    <Grid container spacing={3}>
                        <Grid container item justifyContent={"space-between"}>
                            <Grid item>
                                <Breadcrumbs separator={"›"}>
                                    <Link color={"inherit"} to={"/assets/all"}>
                                        Assets
                                    </Link>
                                    <Typography color={"inherit"}>{asset.id}</Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item>
                                <Typography>{asset.assetSample.assetCategory.value}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>{`Last updated ${moment(asset.lastUpdatedDate).fromNow()} by ${asset.lastUpdatedBy}`}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider light={false}/>
                        </Grid>
                        <Grid container item justifyContent={"space-between"}>
                            <Grid container item xs={9} spacing={1}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>Responsible</InputLabel>
                                        <Select
                                            name={nameof<AssetDetailsForm>(a => a.responsibleOwnerId)}
                                            value={values.responsibleOwnerId}
                                            onChange={handleChange}
                                            fullWidth
                                            disableUnderline
                                        >
                                            {renderUserOptions()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>Accountable</InputLabel>
                                        <Select
                                            name={nameof<AssetDetailsForm>(a => a.accountableOwnerId)}
                                            title={"Accountable"}
                                            label={"Accountable"}
                                            value={values.accountableOwnerId}
                                            onChange={handleChange}
                                            fullWidth
                                            disableUnderline
                                        >
                                            {renderUserOptions()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={3} justifyContent={"flex-end"} spacing={1}>
                                <Grid item>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        className={"success-item-outlined"}
                                        variant={"outlined"}
                                        disabled={loadingUsers}
                                        endIcon={savingChanges &&
                                        <CircularProgress
                                            className={"success-item-outlined"}
                                            size={20}
                                        />
                                        }
                                    >
                                        Save Changes
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider light={false}/>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item>
                                <Chip label={"Active"} className={"success-item-filled"}/>
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    name={nameof<AssetDetailsForm>(a => a.alias)}
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.alias}
                                    error={touched.alias && Boolean(errors.alias)}
                                    helperText={errors.alias}
                                />

                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>
                                        Confidentiality Score
                                        <RiskLabelTypeSelector risk={values.confidentiality}/>
                                    </Typography>
                                    <Slider
                                        value={values.confidentiality}
                                        step={1}
                                        marks={raciMarks}
                                        min={1}
                                        max={5}
                                        onChange={(e, v) => setFieldValue(nameof<AssetDetailsForm>(a => a.confidentiality), v)}
                                        valueLabelDisplay="auto"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <br/>
                                    <TextField
                                        {...fieldProps}
                                        name={nameof<AssetDetailsForm>(a => a.confidentialityJustification)}
                                        label={"Confidentiality Justification"}
                                        placeholder={"Justification"}
                                        value={values.confidentialityJustification}
                                        onChange={handleChange}
                                        error={touched.confidentialityJustification && Boolean(errors.confidentialityJustification)}
                                        helperText={touched.confidentialityJustification && errors.confidentialityJustification}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>
                                        Integrity Score
                                        <RiskLabelTypeSelector risk={values.integrity}/>
                                    </Typography>
                                    <Slider
                                        value={values.integrity}
                                        step={1}
                                        marks={raciMarks}
                                        min={1}
                                        max={5}
                                        onChange={(e, v) => setFieldValue(nameof<AssetDetailsForm>(a => a.integrity), v)}
                                        valueLabelDisplay="auto"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <br/>
                                    <TextField
                                        {...fieldProps}
                                        name={nameof<AssetDetailsDto>(a => a.integrityJustification)}
                                        label={"Integrity Justification"}
                                        placeholder={"Justification"}
                                        value={values.integrityJustification}
                                        onChange={handleChange}
                                        error={touched.integrityJustification && Boolean(errors.integrityJustification)}
                                        helperText={touched.integrityJustification && errors.integrityJustification}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>
                                        Availability Score
                                        <RiskLabelTypeSelector risk={values.availability}/>
                                    </Typography>
                                    <Slider
                                        value={values.availability}
                                        step={1}
                                        marks={raciMarks}
                                        min={1}
                                        max={5}
                                        onChange={(e, v) => setFieldValue(nameof<AssetDetailsForm>(a => a.availability), v)}
                                        valueLabelDisplay="auto"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <br/>
                                    <TextField
                                        {...fieldProps}
                                        name={nameof<AssetDetailsDto>(a => a.availabilityJustification)}
                                        label={"Availability Justification"}
                                        placeholder={"Justification"}
                                        value={values.availabilityJustification}
                                        onChange={handleChange}
                                        error={touched.availabilityJustification && Boolean(errors.availabilityJustification)}
                                        helperText={touched.availabilityJustification && errors.availabilityJustification}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export { AssetWrite }
