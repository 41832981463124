export interface KpiMetrics {
    companyId: number;
    functionId: number;
    userDetailsId: number;
    answeredQuestions: string;
    questionTotal: number;
    complianceScore: number;
    responsible: number;
    accountable: number;
    consulted: number;
    informed: number;
}

export const kpiMetricsInitialState: KpiMetrics = {
    companyId: 0,
    userDetailsId: 0,
    functionId: 0,
    accountable: 0,
    complianceScore: 0,
    answeredQuestions: "",
    consulted: 0,
    informed: 0,
    questionTotal: 0,
    responsible: 0,
}