export interface LookupDto<T> {
    id: number;
    value: T;
    description: string;
}

const lookupDtoInitialState: LookupDto<string> = {
    id: 0,
    value: "",
    description: ""
}
export { lookupDtoInitialState }