import { QuestionDto } from "common/dtos/Questions/QuestionDetails/models";
import { AnswerValue } from "common/dtos/Questions/models";

export interface AssessmentQuestionDto {
    id: number;
    questionGroupId: number;
    assessmentSubQuestions: AssessmentSubquestionDto[]
}

export interface AssessmentSubquestionDto {
    id: number;
    assessmentQuestionId: number;
    priorityLevel: number;
    question: QuestionDto;
}

export interface AssessmentAnswer {
    id: number;
    questionGroupId: number;
    answers: AssessmentAnswerLookup[];
}

export interface AssessmentAnswerLookup {
    id: number;
    value: AnswerValue;
    description: string;
}

export const initialAssessmentAnswerLookup: AssessmentAnswerLookup = {
    id: 0,
    value: AnswerValue.NotAnswered,
    description: ""
};

export const defaultAssessmentAnswerLookup: AssessmentAnswerLookup = {
    id: 1,
    value: AnswerValue.NotAnswered,
    description: "I don't know the answer to this question right now"
}

export const assessmentAnswers: AssessmentAnswerLookup[] = [
    initialAssessmentAnswerLookup,
    defaultAssessmentAnswerLookup,
    {
        id: 2,
        value: AnswerValue.Unknown,
        description: "We aren't doing this at the moment"
    },
    {
        id: 3,
        value: AnswerValue.NotImplemented,
        description: "We have a plan to do this with budget and timescales agreed"
    },
    {
        id: 4,
        value: AnswerValue.Planned,
        description: "We are currently delivering this activity to the plan we have agreed"
    },
    {
        id: 5,
        value: AnswerValue.Underway,
        description: "We are doing this, but there has been no format audit to check it"
    },
    {
        id: 6,
        value: AnswerValue.Implemented,
        description: "We have had an audit to check we are doing this"
    },
    {
        id: 7,
        value: AnswerValue.Certified,
        description: "We have a formal certification with this activity in scope"
    }
]