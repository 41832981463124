import {ColumnDefinition, ColumnType} from "common/components/tables/TableDefinition/ColumnDefinition";
import {RiskSettingsDto} from "../../models";
import {ReactElement} from "react";

export interface RiskBracket {
    icon: ReactElement | undefined;
    level: ReactElement | undefined;
    label: ReactElement | undefined;
    impactRangeX: ReactElement | undefined;
    impactRangeY: ReactElement | undefined;
    impactRange: ReactElement | undefined;
    rangeDescription: ReactElement | undefined;
}

const RiskLabelColumns: ColumnDefinition[] = [
    {
        key: 1,
        name: "Icon",
        field: "icon",
        type: ColumnType.Default,
        hidden: false,
        width: 50,
        displayLabel: false
    },
    {
        key: 2,
        name: "Level",
        field: "level",
        type: ColumnType.Default,
        hidden: false,
        width: 50,
        displayLabel: true
    },
    {
        key: 3,
        name: "Custom Label",
        field: "label",
        type: ColumnType.Default,
        hidden: false,
        width: 500,
        displayLabel: true
    },
    {
        key: 4,
        name: "Impact Range From",
        field: "impactRangeX",
        type: ColumnType.Default,
        hidden: false,
        width: 200,
        displayLabel: true
    },
    {
        key: 5,
        name: "Impact Range To",
        field: "impactRangeY",
        type: ColumnType.Default,
        hidden: false,
        width: 200,
        displayLabel: true
    },
    {
        key: 6,
        name: "Impact Range",
        field: "impactRange",
        type: ColumnType.Default,
        hidden: true,
        width: 200,
        displayLabel: true
    },
]
export {RiskLabelColumns}

const RiskLabelRows: RiskSettingsDto[] = [
    {
        id: 0,
        level: 1,
        label: "Very Low",
        impactRangeX: 0,
        impactRangeY: 0,
        impactRange: 0,
    },
    {
        id: 0,
        level: 2,
        label: "Low",
        impactRangeX: 0,
        impactRangeY: 0,
        impactRange: 0,
    },
    {
        id: 0,
        level: 3,
        label: "Medium",
        impactRangeX: 0,
        impactRangeY: 0,
        impactRange: 0,
    },
    {
        id: 0,
        level: 4,
        label: "High",
        impactRangeX: 0,
        impactRangeY: 0,
        impactRange: 0,
    },
    {
        id: 0,
        level: 5,
        label: "Very High",
        impactRangeX: 0,
        impactRangeY: 0,
        impactRange: 0,
    },
]
export {RiskLabelRows}

