import { AssetState } from "./Model";
import { ActionTypes, SET_SAMPLE_ASSETS } from "./actionTypes";

const initialState: AssetState = {
    sampleAssets: []
}

export function assets(
    state = initialState,
    action: ActionTypes
): any {
    switch(action.type) {
        case SET_SAMPLE_ASSETS:
            return {
                ...state,
                sampleAssets: action.payload
            }
        default:
            return state;
    }
}