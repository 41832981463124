import { AssetSampleDto } from "common/dtos/Assets/AssetSampleDto";
import { AssetDetailsDto } from "common/dtos/Assets/AssetDetailsDto";

export const SET_SAMPLE_ASSETS = "SET_SAMPLE_ASSETS";
export const SET_FUNCTION_ASSET = "SET_FUNCTION_ASSET";

interface setSampleAsset {
    type: typeof SET_SAMPLE_ASSETS;
    payload: AssetSampleDto[]
}

interface setFunctionAssets {
    type: typeof SET_FUNCTION_ASSET;
    payload: AssetDetailsDto[]
}

export type ActionTypes = setSampleAsset | setFunctionAssets;