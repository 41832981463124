import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import ShowMoreText from 'react-show-more-text';
import { Checkbox, Grid, Typography } from "@mui/material";
import {
    Grid as GridTable,
    GridCellProps,
    GridColumn,
    GridColumnMenuFilter,
    GridDataStateChangeEvent,
    GridPageChangeEvent,
    GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { orderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";
import { nameof } from "ts-simple-nameof";

import {
    getObligationCompliance,
    getTrackedQuestionGroups,
    trackQuestionGroups
} from "../store/actions";
import { ObligationComplianceDto } from "../store/models";
import { LookupDto } from "common/services/LookupService/models";
import { lookupService } from "common/services/LookupService/lookupService";
import {TrackedQuestionGroupDto, TrackQuestionGroupsDto} from "common/dtos/Questions/TrackedQuestionGroupDto/models";
import { getRiskLabelDescription } from "common/helpers/HelperText/getRiskLabelDescription";
import { RatingGauge } from "common/components/content/RatingGauge/RatingGauge";

import "./styles.scss";

const ComplianceBreakdownTable: React.FC = () => {

    const initialSort: Array<SortDescriptor> = [
        { field: "obligation", dir: "asc" },
        { field: "complianceReportCategory", dir: "asc" }
    ];
    
    const [dataState, setDataState] = useState<State>({skip: 0, take: 20});
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [complianceBreakdown, setComplianceBreakdown] = useState<ObligationComplianceDto[]>([]);
    const [trackedQuestionGroups, setTrackedQuestionGroups] = useState<TrackedQuestionGroupDto[]>([]);
    const [riskLabelTypes, setRiskLabelTypes] = useState<LookupDto<string>[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const skip: number = dataState.skip!;
    const take: number = dataState.take!;

    useEffect(() => {
        getObligationCompliance().then((response) => {
            setComplianceBreakdown(response);
        })

        getTrackedQuestionGroups().then(response => {
            setTrackedQuestionGroups(response);
        })
        
    }, [setComplianceBreakdown, setTrackedQuestionGroups])
    
    useEffect(() => {
        lookupService.getRiskLabelTypes().then((response) => {
            setRiskLabelTypes(response);
        })
    }, [])

    const renderCheckbox = (props: GridCellProps): ReactElement => { 
        const isTracked = props.dataItem.questionGroupId.some((t: number) => trackedQuestionGroups.some(c => c.questionGroupId === t))
        return <td><Checkbox disabled={saving} checked={isTracked} onClick={onClick(props)}/></td>
    }

    const onClick = useCallback((props: GridCellProps) => (event: ChangeEvent<any>) => {
        const checked: boolean = event.target.checked;
        const questionGroupId: number[] = props.dataItem.questionGroupId;

        setSaving(true);
        
        const trackedGroups: TrackQuestionGroupsDto = {
            ids: questionGroupId,
            track: checked
        }

        trackQuestionGroups(trackedGroups).then((groups: TrackedQuestionGroupDto[]) => {
            const trackedGroups = checked
                ? [...trackedQuestionGroups, ...groups]
                : trackedQuestionGroups.filter(t => !groups.some(g => g.questionGroupId === t.questionGroupId))

            setTrackedQuestionGroups(trackedGroups)
        }).finally(() => setSaving(false));
    }, [setTrackedQuestionGroups, trackedQuestionGroups])

    const onPageChange = useCallback((event: GridPageChangeEvent) => {
        setDataState({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take
        })
    }, [setDataState, dataState])

    const onDataStateChange =  useCallback((event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
        setComplianceBreakdown((event.dataState.filter !== null)
            ? process([...complianceBreakdown], event.dataState).data
            : complianceBreakdown);
    },[setDataState, setComplianceBreakdown, complianceBreakdown])

    const onSortChange = useCallback((event: GridSortChangeEvent) => {
        setSort(event.sort);
    },[setSort])
    
    const formatText =  useCallback((text: string) => {
        return text ? text.split("\\n ").map((lines, index) => {
            return (
                <Typography key={index} className={'complianceFormatting'}>
                   {lines}
                </Typography>
            );
        }) : text        
    },[])
    
    return (
        <Grid container spacing={2}>
            <Grid item>
                <GridTable
                    data={orderBy(complianceBreakdown, sort).slice(skip, take + skip)}
                    {...dataState}
                    onPageChange={onPageChange}
                    onDataStateChange={onDataStateChange}
                    onSortChange={onSortChange}
                    total={complianceBreakdown.length}
                    pageable={true}
                    sortable={true}
                    sort={sort}
                >
                    <GridColumn field={nameof<ObligationComplianceDto>(a => a.obligation)} title={"Obligation"} filter={"text"}  width={"220px"}
                                columnMenu={GridColumnMenuFilter} />

                    <GridColumn field={nameof<ObligationComplianceDto>(a => a.complianceReportCategory)} title={"Report Category"} filter={"text"}  width={"350px"}
                                columnMenu={GridColumnMenuFilter}
                    />
                    <GridColumn field={nameof<ObligationComplianceDto>(a => a.complianceReportSubCategory)} title={"Report Sub-category"} filter={"text"}
                                columnMenu={GridColumnMenuFilter}
                                className={'complianceFormatting'}
                                cell={props => <td  className={'complianceFormatting'}>
                                    <ShowMoreText
                                        lines={4}
                                        more='Show more'
                                        less='Show less' 
                                        expanded={false}
                                    >
                                        {formatText(props.dataItem.complianceReportSubCategory)}
                                    </ShowMoreText>
                                </td>}
                    />
                    <GridColumn field={nameof<ObligationComplianceDto>(a => a.rating)} title={"Risk Rating"}
                                width={"150px"}
                                cell={props => <td><RatingGauge rating={(props.dataItem.rating)}/></td>}
                    />
                    <GridColumn field={nameof<ObligationComplianceDto>(a => a.rating)} title={"Effect"}
                                width={"280px"}
                                cell={props => <td>{getRiskLabelDescription(riskLabelTypes, Number(props.dataItem.rating))}</td>}
                    />                    
                    <GridColumn field={"Selected"} title={"Track"} cell={props => renderCheckbox(props)}
                                width={"100px"}/>
                </GridTable>
                
            </Grid>            
        </Grid>
    )
}
export { ComplianceBreakdownTable }