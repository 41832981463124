import React, { ChangeEvent, ReactElement } from "react";

import { ErrorOutlineRounded } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";

import { RiskBracket } from "./models";

import "./styles.scss"

export class RiskBracketBuilder {

    private icon: ReactElement | undefined;
    private level: ReactElement | undefined;
    private label: ReactElement | undefined;
    private impactRange: ReactElement | undefined;
    private impactRangeX: ReactElement | undefined;
    private impactRangeY: ReactElement | undefined;
    private rangeDescription: ReactElement | undefined;

    public WithLevel(level: number): RiskBracketBuilder {
        this.icon = <ErrorOutlineRounded className={`level${level}`}/>
        this.level = <p>{level}</p>
        return this;
    }

    public WithLabel(label: string, name: string, onChange: (event: ChangeEvent<any>) => void, error: string | undefined): RiskBracketBuilder {
        this.label = <TextField 
            name={name} 
            onChange={onChange} 
            value={label}
            error={error !== undefined}
            helperText={error}
        />
        return this;
    }

    public WithImpactRangeX(name: string, impactRange: number, onChange: (event: ChangeEvent<any>) => void, error: string | undefined): RiskBracketBuilder {

        const formattedImpactRange = Math.floor(impactRange); 

        this.impactRangeX = <TextField
            name={name}
            onChange={onChange}
            value={formattedImpactRange && (formattedImpactRange).toString()}
            error={error !== undefined}
            helperText={error}
        />
        return this;
    }
    
    public WithImpactRangeY(name: string,upperRange: number | null, onChange: (event: ChangeEvent<any>) => void, error: string | undefined): RiskBracketBuilder {
 

        this.impactRangeY = <TextField
            name={name}
            onChange={onChange}
            value={upperRange && upperRange.toString()}
            error={error !== undefined}
            helperText={error}
        /> 
        return this;
    }
    
    public WithImpactRange(name: string, impactRange: number, upperRange: number | null, onChange: (event: ChangeEvent<any>) => void, error: string | undefined): RiskBracketBuilder {

        const formattedImpactRange = Math.floor(impactRange);
        const formattedUpperRange = upperRange ? Math.floor(upperRange) : upperRange;

        this.impactRange = formattedUpperRange 
            ? <Typography>Between <b>£{formattedImpactRange}</b> and <b>£{formattedUpperRange}</b></Typography>
            : <Typography><b>£{formattedImpactRange}</b> and <b>above</b></Typography>
        return this;
    }

    public Build(): RiskBracket {
        return {
            icon: this.icon,
            impactRange: this.impactRange,
            impactRangeX: this.impactRangeX,
            impactRangeY: this.impactRangeY,
            label: this.label,
            level: this.level,
            rangeDescription: this.rangeDescription
        };
    }
}