import { ActivityCategoryDetailsDto } from "common/dtos/Questions/QuestionDetails/models";
import { activityCategoryService } from "common/services/ActivityCategoryService/activityCategoryService";
import { DelegatedActivityCategoryDto } from "common/dtos/Users/models";

export function getActivityCategories(): Promise<ActivityCategoryDetailsDto[]> {
    return activityCategoryService.getActivityCategories();
}

export function getUsersByActivityCategory(activityCategoryId: number): Promise<DelegatedActivityCategoryDto[]> {
    return activityCategoryService.getUsersByActivityCategory(activityCategoryId);
}