import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import {
    Avatar,
    Box,
    Card,
    Grid, 
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography
} from "@mui/material";
import { ChevronRightRounded } from "@mui/icons-material";

import { AssetDetailsDto } from "common/dtos/Assets/AssetDetailsDto";
import { getAssetsByUser } from "assetManagement/actions";
import { cardProps } from "common/theming/models";
import { history } from "../../../history";

import "./styles.scss";
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "../../../user/store/selectors";


const AssetsByUser: React.FC = () => {

    const [assets, setAssets] = useState<AssetDetailsDto[]>([])

    const selectedFunction = useSelector(selectedFunctionSelector);
    
    useEffect(() => {
        getAssetsByUser().then(response => {
            setAssets(response)
        })
    }, [selectedFunction]);


    return (
        <Card {...cardProps} className={"my-assets-list"}>
            <ListItem disableGutters dense>
                <ListItemText><Typography variant={"h6"}>My Assets</Typography></ListItemText>
                <ListItemSecondaryAction><Link to={`/assets/all`}>See all assets</Link></ListItemSecondaryAction>
            </ListItem>
            { assets.length > 0 &&
            <List dense className={"assets-list"}>
                {assets.map((asset, index) => {

                    const divider: boolean = index < assets.length - 1;

                    return (
                        <ListItem key={index} disableGutters dense divider={divider}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid container item xs={11} spacing={1} alignItems={"center"}>
                                    {asset.responsibleOwner &&
                                    <Grid item xs={1}>
                                        <Tooltip arrow title={"You are Responsible for this Asset"}>
                                            <Avatar className={"assignment"}><b>R</b></Avatar>
                                        </Tooltip>
                                    </Grid>
                                    }
                                    {asset.accountableOwner &&
                                    <Grid item xs={1}>
                                        <Tooltip arrow title={"You are Accountable for this Asset"}>
                                            <Avatar className={"assignment"}><b>A</b></Avatar>
                                        </Tooltip>
                                    </Grid>
                                    }
                                    <Grid item xs={9}>
                                        <Typography variant={"body2"} color={"textSecondary"}>{asset.alias}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title={"View"} arrow placement={"right"}>
                                        <IconButton
                                            color={"primary"}
                                            size={"small"}
                                            onClick={() => history.push(`/asset/${asset.id}`)}
                                        >
                                            <ChevronRightRounded/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                        </ListItem>
                    );
                })}
            </List>
            }
            { assets.length === 0 &&
            <Box width={"100%"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
                <Typography align={"center"} color={"textSecondary"}>No Assets Available</Typography>
                <Link to={"/assets/all"}><Typography align={"center"} variant={"body2"} className={"hyperlink"}>Click here to manage assets</Typography></Link>
            </Box>
            }
        </Card>
    );
}
export
{
    AssetsByUser
}