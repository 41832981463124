import React, { ReactElement } from 'react';
import { Grid, Button, Container, Slide, CircularProgress } from "@mui/material";

import "styles/components.scss";
import "./styles.scss";

export interface SaveChangesBarProps {
    expand: boolean;
    onClick: () => void;
    saving?: boolean;
    message?: string;
    buttonText?: string;
    additionalContent?: ReactElement;
}

const SaveChangesBar: React.FC<SaveChangesBarProps> = ({expand, onClick, saving, message, buttonText, additionalContent}) => {

    const text = buttonText || "Save Changes";

    return (
        <Slide in={expand} direction={"up"}>
            <Container className={"saveChanges"} maxWidth={false}>
                <Grid container justifyContent={"flex-end"} spacing={2}>
                    {message &&
                        <Grid item className={"changesText"}>
                            <p>{message}</p>
                        </Grid>
                    }
                    { additionalContent && 
                        <Grid item>
                            {additionalContent}
                        </Grid>
                    }
                    <Grid item>
                        <Button
                            size={"large"}
                            className={"successButton"}
                            disableElevation
                            onClick={onClick}
                            endIcon={saving && <CircularProgress size={25} color={"inherit"}/>}
                        >{text}</Button>
                    </Grid>
                </Grid>
            </Container>
        </Slide>
    );
}
export { SaveChangesBar }