import React, { ChangeEvent, useCallback } from 'react'
import { Box, Checkbox, Divider, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

import { HistoryFilter, HistoryFiltering, HistoryOrdering } from "common/dtos/History/models";

export interface QuestionFiltersProps {
    filters: HistoryFiltering;
    setFilters: (filters: HistoryFiltering) => void;
    showJustification: boolean;
    setShowJustification: (show: boolean) => void;
}

const HistoryFilters: React.FC<QuestionFiltersProps> = ({filters, setFilters, showJustification, setShowJustification}) => {

    const onFilterChange = (e: SelectChangeEvent<any>) => {
        setFilters({
            ...filters,
            filtering: e.target.value
        })
    }

    const onOrderChange = (e: ChangeEvent<any>) => {
        setFilters({
            ...filters,
            ordering: e.target.value
        })
    }

    const onShowAllJustificationChange = useCallback(() => {
        setShowJustification(!showJustification);
    }, [showJustification, setShowJustification])

    return (
        <Box p={"0.5em"} display={"flex"} width={"100%"} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"}>
                <Checkbox checked={showJustification} onClick={onShowAllJustificationChange}/>
                <Typography variant={"body2"}>Show All Justifications</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Select value={filters.ordering} onClick={onOrderChange} variant={"outlined"} fullWidth>
                            <MenuItem value={HistoryOrdering.Oldest} key={HistoryOrdering.Oldest}>Oldest
                                first</MenuItem>
                            <MenuItem value={HistoryOrdering.Newest} key={HistoryOrdering.Newest}>Newest
                                first</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item>
                        <Select onChange={onFilterChange} value={filters.filtering} variant={"outlined"} fullWidth>
                            <MenuItem value={HistoryFilter.ShowAll} key={HistoryFilter.ShowAll}>Show all
                                history</MenuItem>
                            <Divider light={false}/>
                            <MenuItem value={HistoryFilter.ShowCommentsOnly} key={HistoryFilter.ShowCommentsOnly}>Show
                                comments only</MenuItem>
                            <MenuItem value={HistoryFilter.ShowActivityOnly} key={HistoryFilter.ShowActivityOnly}>Show
                                activities only</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
export { HistoryFilters }