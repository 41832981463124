import React, { useCallback } from 'react';

import { Card, Fade, Grid, Modal, Tooltip, Typography } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";

import { ObligationRequest, ObligationCategoryDto } from "common/dtos/Obligations/models";

import { ObligationForm } from "obligations/components/ObligationForm/ObligationForm";
import { RiskSettingsDto } from "riskLabels/models";

import "./styles.scss";

export interface ObligationModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    obligationCategory: ObligationCategoryDto;
    riskSettings: RiskSettingsDto[];
    onSubmit: (request: ObligationRequest) => Promise<any>;
}

const ObligationModal: React.FC<ObligationModalProps> = ({open, setOpen, title, obligationCategory, riskSettings, onSubmit}) => {

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])
    
    return (
        <Modal
            className={"modal"}
            open={open}
            closeAfterTransition
        >
            <Fade in={open}>
                <Grid container item xs={10} sm={8} md={6}>
                    <Card className={"modal-content"}>
                        <Grid container justifyContent={"space-between"} spacing={2}>
                            <Grid item><Typography variant={"h6"}><b>{title}</b></Typography></Grid>
                            <Grid item><Tooltip title={"Create a new custom obligation"}><InfoRounded/></Tooltip></Grid>
                            <Grid container item>
                                <ObligationForm
                                    obligationCategory={obligationCategory}
                                    riskSettings={riskSettings}
                                    handleClose={handleClose}
                                    handleSubmitForm={onSubmit}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Fade>
        </Modal>
    );
}
export { ObligationModal }