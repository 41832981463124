import {FormikErrors, FormikTouched} from "formik";
import * as Yup from "yup";

class FormikHelper {

    constructor() {
        this.addUnique();
    }
    
    public getError(touched: FormikTouched<any>, errors: FormikErrors<any>, name: string) {
        return (touched[name] && errors[name]) || (this.getNested(touched, name) && this.getNested(errors, name));
    }

    public getNested(theObject: any, path: string, separator: string = ".") {
        try {
            separator = separator || '.';

            return path
                .replace('[', separator)
                .replace(']', '')
                .split(separator)
                .reduce(function (obj, property) {
                    return obj[property];
                }, theObject);
        } catch (err) {
            return undefined;
        }
    }
    
    public addUnique() {
        Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a:any) => a) {
            return this.test('unique', message, function (list) {
                return list?.length === new Set(list?.map(mapper)).size;
            });
        });
    }
}
export const formikHelper = new FormikHelper();
formikHelper.addUnique();
