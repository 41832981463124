import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { Grid, Typography } from "@mui/material";

import { ActivityCategoryDetailsDto } from "common/dtos/Questions/QuestionDetails/models";
import { CondensedListPanel } from "common/components/panels/CondensedListPanel/CondensedListPanel";
import { ConfirmationModal } from "common/components/modal/ConfirmationModal/ConfirmationModal";
import { StatusPage } from "common/components/pages/StatusPage/StatusPage";

import { getActivityCategories, getUsersByActivityCategory } from "activityCategories/actions";
import { RaciDelegation } from "../RaciDelegation/RaciDelegation";

import SelectionRequiredImage from "assets/illustrations/choose_option.svg";

import "./styles.scss";
import { raciHelper } from "raci/raciHelper";
import { SaveChangesBar } from "common/components/panels/SaveChangesBar/SaveChangesBar";
import { DelegatedActivityCategoryDto, UserDetailsDto } from "common/dtos/Users/models";
import { UserRaciToActivityCategoryRequest } from "common/dtos/ActivityCategories/models";
import { DelegationType } from "common/dtos/Common/delegation";

export interface RaciManagerProps {
    alwaysShowBar?: boolean;
    initialRaciChanges?: DelegatedActivityCategoryDto[];
    onSaveChanges: (request: UserRaciToActivityCategoryRequest, raciChanges: DelegatedActivityCategoryDto[]) => Promise<void>;
    saveChangesBarText?: string;
    saveChangesBarAdditions?: ReactElement;
    populateUsersFromApi: boolean;
    accountableReadonly?: boolean;
    accountableDefaultUser?: UserDetailsDto;
    getUsersByName: (query: string) => Promise<UserDetailsDto[]>;
    ignorePermissions: boolean;
}

const RaciManager: React.FC<RaciManagerProps> = ({
                                                     alwaysShowBar,
                                                     initialRaciChanges,
                                                     onSaveChanges,
                                                     saveChangesBarText,
                                                     saveChangesBarAdditions,
                                                     populateUsersFromApi,
                                                     accountableDefaultUser,
                                                     getUsersByName,
                                                     ignorePermissions
                                                 }) => {

    const [activityCategories, setActivityCategories] = useState<ActivityCategoryDetailsDto[]>([]);
    const [selectedActivityCategory, setSelectedActivityCategory] = useState<ActivityCategoryDetailsDto>();
    const [raciUserDelegations, setRaciDelegations] = useState<DelegatedActivityCategoryDto[]>([]);
    const [raciChanges, setRaciChanges] = useState<DelegatedActivityCategoryDto[]>(initialRaciChanges ?? []);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [retrievedActivityCategoryIds, setRetrievedActivityCategoryIds] = useState<number[]>([]);
    const [changeCount, setChangeCount] = useState<number>(0)

    const formRef = useRef<any>();

    useEffect(() => {
        getActivityCategories().then(response => {
            setActivityCategories(response);

            if (accountableDefaultUser && raciChanges.length === 0) {
                setRaciChanges(prevState => [...prevState,
                    ...response.map((ac): DelegatedActivityCategoryDto => ({
                        user: accountableDefaultUser,
                        userDetailsId: accountableDefaultUser.id,
                        activityCategoryDetailsId: ac.id,
                        delegationId: DelegationType.Accountable,
                        delegation: {
                            id: DelegationType.Accountable,
                            value: DelegationType[DelegationType.Accountable],
                            description: ""
                        },
                        activityCategory: ac,
                        id: 0
                    }))])
            }
        })
    }, [setActivityCategories, accountableDefaultUser, raciChanges.length])

    useEffect(() => {
        setChangeCount(raciHelper.calculateChanges(activityCategories, raciChanges, raciUserDelegations))
    }, [raciChanges, activityCategories, raciUserDelegations, setChangeCount]);


    useEffect(() => {

        if (populateUsersFromApi && selectedActivityCategory !== undefined && !retrievedActivityCategoryIds.some(id => id === selectedActivityCategory.id)) {
            getUsersByActivityCategory(selectedActivityCategory.id).then(response => {

                setRetrievedActivityCategoryIds((prevState) => [...prevState, selectedActivityCategory.id])

                setRaciChanges((prevState => [
                        ...prevState, ...response]
                ))

                setRaciDelegations((prevState => [
                    ...prevState, ...response
                ]))
            })
        }

    }, [selectedActivityCategory, retrievedActivityCategoryIds, populateUsersFromApi]);

    const onItemClicked = useCallback((ac: ActivityCategoryDetailsDto) => {

        if (selectedActivityCategory === undefined || formRef.current?.isValid)
            setSelectedActivityCategory(ac)

    }, [selectedActivityCategory, formRef])

    const onConfirmClicked = useCallback(() => {
        formRef.current?.handleSubmit();
    }, [formRef])

    const onFormSubmit = useCallback((changes: DelegatedActivityCategoryDto[], activityCategoryId: number): Promise<void> => {

        setRaciChanges((prevState) => [
            ...prevState.filter(c => c.activityCategoryDetailsId !== activityCategoryId),
            ...changes
        ])

        return Promise.resolve()
    }, [])

    const onSaveRaciChanges = useCallback(() => {

        if (formRef.current && !formRef.current.isValid)
            return;

        const request = raciHelper.prepareAssignmentRequest(activityCategories, raciChanges, raciUserDelegations);

        onSaveChanges(request, raciChanges).then(_ => {
            setRaciDelegations((prevState) => [
                ...prevState.filter(d => !raciHelper.isDelegationInAssignmentList(d, request.raciUnassignments)),
                ...raciChanges.filter(d => raciHelper.isDelegationInAssignmentList(d, request.raciAssignments))
            ])
            setChangeCount(0)

        })
    }, [activityCategories, raciChanges, raciUserDelegations, onSaveChanges])

    return (
        <Grid className={"raci"} container>
            <Grid className={"activityList"} container item xs={3}>
                <CondensedListPanel
                    title={"Activity Categories"}
                    items={activityCategories}
                    selected={selectedActivityCategory}
                    titleSelector={(ac: ActivityCategoryDetailsDto) => ac.name}
                    onItemClicked={onItemClicked}
                    idSelector={(ac: ActivityCategoryDetailsDto) => ac.id.toString()}
                />
            </Grid>
            <Grid container item xs={9} className={"page"} alignItems={"center"} justifyContent={"center"}>
                {selectedActivityCategory
                    ?
                    <Grid item xs={10} container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={"h5"}><b>{selectedActivityCategory.name}</b></Typography>
                        </Grid>
                        <RaciDelegation
                            activityCategory={selectedActivityCategory}
                            raciChanges={raciChanges}
                            userDelegations={raciUserDelegations}
                            onSubmit={onFormSubmit}
                            formRef={formRef}
                            getUsersByName={getUsersByName}
                            ignorePermissions={ignorePermissions}/>
                    </Grid>
                    :
                    <StatusPage
                        message={() => <img width={250} height={250} src={SelectionRequiredImage}
                                            alt={"Selection Required"}/>}
                        notice={() => <Grid>
                            <Typography align={'left'} color={"secondary"} variant={"h6"}>
                                Choose an Activity Category from the left panel<br/><br/>
                                This matrix controls what questions users would see in the, based on which activities categories are assigned to them.<br/>
                                We will give you all the questions to manage unless you wish to assign some now. <br/><br/>
                                <b>If you do not wish to give any of these categories to anyone, click next</b><br/><br/>
                                If an activity does not relate to you, please assign it to someone else (you may need to
                                invite them in first)
                            </Typography>
                        </Grid>}                        
                    />
                }
            </Grid>
            <ConfirmationModal
                open={openConfirmation}
                setOpen={setOpenConfirmation}
                onConfirmedClicked={onConfirmClicked}
                message={
                    <Grid container item spacing={1}>
                        <Grid item xs={12}>
                            <Typography align={"center"} variant={"h6"}>
                                Are you sure?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={"center"}>
                                You've made changes that haven't been saved!
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
            <SaveChangesBar
                onClick={onSaveRaciChanges}
                expand={alwaysShowBar || changeCount > 0}
                message={`You've made ${changeCount} change(s)`}
                buttonText={saveChangesBarText}
                additionalContent={saveChangesBarAdditions}
            />
        </Grid>
    );
}
export { RaciManager }