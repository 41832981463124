import { createSelector } from "reselect";

import { ApplicationState } from "../../store";
import { AssetState } from "./Model";
import { AssetSampleDto } from "common/dtos/Assets/AssetSampleDto";

const selectorState = (state: ApplicationState): AssetState => 
    state.assets;

export const sampleAssetsSelector = createSelector(
    selectorState,
    (assetState: AssetState): AssetSampleDto[] => {
        return assetState.sampleAssets;
    }
)