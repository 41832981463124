import React from 'react';
import { Grid, Typography } from "@mui/material";

import "./styles.scss";

export interface CondensedListPanelProps {
    title?: string;
    selected?: any;
    items: any[];
    idSelector: (item: any) => string;
    titleSelector: (item: any) => string;
    onItemClicked: (item: any) => void;
}

const CondensedListPanel: React.FC<CondensedListPanelProps> = ({title, selected, items, idSelector, titleSelector, onItemClicked}) => {
    return (
        <Grid container className={"listPanel"} alignContent={"flex-start"}>
            <Grid container item>
                { title && <Grid item xs={12}><Typography variant={"h6"}>{title}</Typography></Grid> }
            </Grid>
            <Grid container item className={"listItems"}>
                { items.map((item, i) => {

                    const selectedClass: string = selected && idSelector(item) === idSelector(selected) && "selected";

                    return <Grid item xs={12} key={i}>
                        <Typography variant={"body2"} className={selectedClass || ""} onClick={() => onItemClicked(item)}>{titleSelector(item)}</Typography>
                    </Grid>
                })}
            </Grid>
        </Grid>
    )
}
export { CondensedListPanel }