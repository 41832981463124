import React, { ReactElement } from "react";
import { StatusPage } from 'common/components/pages/StatusPage/StatusPage';

const Unauthorized: React.FC = () => {

    const renderMessage = (): ReactElement<any> => {
        return (
            <div>
                <p>You'r permissions have been revoked from performing this action.</p>
            </div>
        )
    }

    const renderNotice = (): ReactElement<any> => {
        return (
            <div>
                <p>But don't worry, if you believe you require these permissions contact your company administrator.</p>
                <p><a href={'mailto:()'}>Contact administrator</a> they can give you permissions to perform this action.</p>
            </div>
        )
    }

    return (
        <StatusPage
            title={"401"}
            message={renderMessage}
            notice={renderNotice}
        />
    );
};
export { Unauthorized };