import React, { ReactElement, useCallback } from 'react';

import { Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { HelpOutlineRounded } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";

import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

import { AnswerAdditionalDetailsDto } from "common/dtos/Questions/QuestionAnswers/models";
import { useValidPermission } from "auth/components/ValidateUserPermission";
import { Permission } from "auth/store/Model";

export interface QuestionReviewDatePickerProps {
    answerAdditionalDetails: AnswerAdditionalDetailsDto;
    onChange: (nextReview: Date) => void;
    label: string;
    helperText?: string;
    sidebarOpen: boolean;
}

const QuestionReviewDatePicker: React.FC<QuestionReviewDatePickerProps> = ({answerAdditionalDetails, label, onChange, helperText, sidebarOpen}) => {
    const userCanEdit = !useValidPermission(Permission.QuestionsEdit);
    
    const onDateChanged = useCallback((value: any) => {
        value && onChange(value.toDate());
    }, [onChange])

    const renderContent = () => {
        return (
            <Grid container item>
                <Grid container item justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant={"caption"}>{label}</Typography>
                    </Grid>
                    {helperText &&
                    <Grid item>
                        <Tooltip arrow placement={"left"} title={helperText}>
                            <HelpOutlineRounded fontSize={"small"}/>
                        </Tooltip>
                    </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        disabled={userCanEdit}
                        value={answerAdditionalDetails.nextReviewDate}
                        onChange={onDateChanged}
                        renderInput={(props) => <TextField fullWidth {...props}/>}
                        disablePast
                        inputFormat="DD/MM/yyyy"
                    />
                </Grid>
            </Grid>
        );
    }

    const renderIcon = (): ReactElement => {

        const nextReviewDate: string = `${answerAdditionalDetails
            ? moment.utc(answerAdditionalDetails.nextReviewDate).format("DD/MM/YY")
            : `TBA`
        }`;
        
        const reviewDateFromNow: string = moment.utc(answerAdditionalDetails.nextReviewDate).fromNow()
        
        return (
            <Grid container item justifyContent={"center"}>
                <Grid item>
                    <Tooltip arrow title={"Next Review Date"} placement={"left"}>
                        <IconButton size="large">
                            <FontAwesomeIcon icon={faCalendarAlt}/>
                        </IconButton>
                    </Tooltip>
                </Grid>
                {answerAdditionalDetails.nextReviewDate &&
                <Grid item>
                    <Tooltip arrow title={reviewDateFromNow} placement={"left"}>
                        <Typography
                            variant={"caption"}>{nextReviewDate}</Typography>
                    </Tooltip>
                </Grid>
                }

            </Grid>
        );
    }

    return (
        <React.Fragment>
            {sidebarOpen ? renderContent() : renderIcon()}
        </React.Fragment>
    )
}
export { QuestionReviewDatePicker }