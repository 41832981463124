import React, { useCallback, useRef } from 'react';
import { Button, Card, Fade, Grid, Modal, } from "@mui/material";
import { InviteUsersForm } from "users/InviteUsersForm/InviteUsersForm";
import { UserInvitationDto, userInvitationDtoInitialState } from "users/InviteUsersForm/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { CompanyUserDto } from "common/dtos/Companies/models";
import { useSelector } from "react-redux";
import { selectedCompanySelector } from "user/store/selectors";
import { inviteUsersToCompany } from "user/store/actions";

export interface InviteUsersModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    setCompanyUsers: React.Dispatch<React.SetStateAction<CompanyUserDto[]>>;
}

const InviteUsersModal: React.FC<InviteUsersModalProps> = ({open, setOpen, setCompanyUsers}) => {

    const userInviteFormRef = useRef<any>();
    
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const onUsersInvited = useCallback((invites: UserInvitationDto[]): Promise<void> => {
        return inviteUsersToCompany({invites: invites}).then((response) => {
            setCompanyUsers(prevState => [...prevState, ...response]);
            handleClose();
        });
    }, [setCompanyUsers, handleClose])

    const onInviteUsersClicked = useCallback(() => {
        userInviteFormRef.current?.handleSubmit();
    }, [])

    const company = useSelector(selectedCompanySelector)
    
    return (
        <Modal
            className={"modal"}
            open={open}
            onClose={handleClose}
        >
            <Fade in={open}>
                <Grid container item xs={10} sm={8} md={6}>
                    <Card className={"modal-content"} variant={"outlined"}>
                        <Grid container item spacing={2}>
                            <Grid item container justifyContent={"flex-end"}>
                                <Grid item>
                                    <InviteUsersForm
                                        infoText={`Invite new members by email to ${company?.name}`}
                                        initialState={{invites: [userInvitationDtoInitialState]}}
                                        onSubmit={onUsersInvited}
                                        formRef={userInviteFormRef}
                                        entryRequired={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        className={"success-item-outlined"}
                                        onClick={onInviteUsersClicked}
                                        variant={"outlined"}
                                        startIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                    >Send Invitations</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Fade>
        </Modal>
    );
}
export { InviteUsersModal }