import React from 'react';
import { Card, Grid, Typography } from "@mui/material";
import { nicifyString } from "common/helpers/Regex/regex";
import { Jurisdiction } from "wizards/company/components/CreationForm/models";
import { ConfirmationProps } from "wizards/company/components/Confirmation/Confirmation";
import { CompanyDetailsDto } from "common/dtos/Companies/models";
import { CurrentChallenges, EmployeeNumber } from "../../../AdditionalInfo/models";

const CompanyConfirmation: React.FC<ConfirmationProps> = ({object}) => {
    
    const companyCreation: CompanyDetailsDto = object as CompanyDetailsDto;
    const registered: boolean = companyCreation.additionalDetails.companiesHouseId !== undefined;
    const title: string = registered ? "Companies House Number" : "Accounting ID";

    const content: string | number | undefined = registered ?
        companyCreation.additionalDetails.companiesHouseId :
        companyCreation.additionalDetails.accountingId;

    return (
        <Card variant={"outlined"}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant={"h6"}><b>Details</b></Typography>
                </Grid>
                <Grid container item xs={6} spacing={1} alignContent={"flex-start"}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Name</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color={"secondary"} variant={"body2"}>{companyCreation.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>{title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"} color={"secondary"}>{content}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Address Line 1</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"} color={"secondary"}>{companyCreation.additionalDetails.address.addressLine1 || "Not specified"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Address Line 2</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}
                                        color={"secondary"}>{companyCreation.additionalDetails.address.addressLine2 || "Not specified"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Locality</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}
                                        color={"secondary"}>{companyCreation.additionalDetails.address.locality || "Not specified"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Postcode</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}
                                        color={"secondary"}>{companyCreation.additionalDetails.address.postcode || "Not specified"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Country</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}
                                        color={"secondary"}>{companyCreation.additionalDetails.address.country || "Not specified"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>VAT Number</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"} color={"secondary"}>{companyCreation.additionalDetails.vat || "Not specified"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={6} spacing={1} alignContent={"flex-start"}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Main Country of Operation</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}
                                        color={"secondary"}>{nicifyString(Jurisdiction[companyCreation.additionalDetails.jurisdiction].toString())}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Annual Turnover</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}
                                        color={"secondary"}>{companyCreation.additionalDetails.annualTurnover || "Not specified"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Number of Employees</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}
                                        color={"secondary"}>{nicifyString(EmployeeNumber[companyCreation.additionalDetails.employeeNumber].toString())}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant={"body1"}>Current Challenges</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}
                                        color={"secondary"}>{nicifyString(CurrentChallenges[companyCreation.additionalDetails.currentChallenges].toString())}
                            </Typography>                                        
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )

}
export { CompanyConfirmation }