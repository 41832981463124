import React, { ReactElement, ReactNode } from 'react';

import { Table, TableContainer } from "@mui/material";

import { ColumnDefinition } from "../TableDefinition/ColumnDefinition";
import { TableDefinition } from "../TableDefinition/TableDefinition";
import { TablePaginator } from "../TablePaginator/TablePaginator";
import { Pagination } from "../TablePaginator/models";
import { TableContent } from "../TableContent/TableContent";

export interface ResponsiveTableProps {
    columns: ColumnDefinition[];
    rows: any[];
    actions?: (row: any) => ReactElement;
    totalRows: number;
    pagination?: Pagination;
    onPageChanged?: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowPerPageChanged?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    loading: boolean;
}

const ResponsiveTable: React.FC<ResponsiveTableProps> = ({
                                                             columns,
                                                             rows,
                                                             actions,
                                                             totalRows,
                                                             pagination,
                                                             onPageChanged,
                                                             onRowPerPageChanged,
                                                             loading
                                                         }) => {

    const renderPagination = (): ReactElement | undefined => {
        if (pagination && onPageChanged && onRowPerPageChanged) {
            return <TablePaginator
                totalRows={totalRows}
                onPageChange={onPageChanged}
                onRowsPerPageChange={onRowPerPageChanged}
                pagination={pagination}
            />
        }
    }

    const renderColumnGroup = (): ReactNode => {
        return (
            <colgroup>
                {columns.filter(c => !c.hidden).map((c, i) => <col key={"colWidth_" + i} width={c.width}/>)}
            </colgroup>
        )
    }
    
    const paginatedRows = (): any[] => {
        
        if(pagination === undefined)
            return rows;
        
        const start: number = pagination.pageNumber * pagination.rowsPerPage;
        const endCount: number = start + pagination.rowsPerPage;
        const end: number = (endCount < rows.length) ? endCount : rows.length;
        
        return rows.slice(start, end)
    }
    
    return (
        <TableContainer>
            <Table>
                {renderColumnGroup()}
                <TableDefinition columns={columns}/>
                <TableContent columns={columns} rows={paginatedRows()} loading={loading} actions={actions}/>
                {renderPagination()}
            </Table>
        </TableContainer>
    )
}
export { ResponsiveTable }