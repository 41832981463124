import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Checkbox, Grid } from "@mui/material";

import {
    Grid as GridTable,
    GridCellProps,
    GridColumn,
    GridColumnMenuFilter,
    GridDataStateChangeEvent,
    GridPageChangeEvent,
    GridSortChangeEvent
} from '@progress/kendo-react-grid';
import { orderBy, process, State, SortDescriptor } from '@progress/kendo-data-query';
import { nameof } from "ts-simple-nameof";


import { sampleAssetsSelector } from "../../store/selectors";
import { assetService } from "common/services/AssetService/assetService";
import { AssetSampleDto } from "common/dtos/Assets/AssetSampleDto";
import { SaveChangesBar } from "common/components/panels/SaveChangesBar/SaveChangesBar";
import { getSampleAssets } from "../../store/actions";

import "../styles.scss";
import { selectedFunctionSelector } from "../../../user/store/selectors";

const SampleAssetsTable: React.FC = () => {
    const functionDetails = useSelector(selectedFunctionSelector);
    const sampleAssets = useSelector(sampleAssetsSelector);
    const dispatch = useDispatch();

    const [processedData, setProcessedData] = useState<any[]>(sampleAssets);
    const [dataState, setDatastate] = useState<State>({skip: 0, take: 20});
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [selected, setSelected] = useState<number[]>([]);
    const [transferring, setTransferring] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getSampleAssets());
        setProcessedData(sampleAssets)
    }, [sampleAssets, dispatch, functionDetails]);

    const renderCheckbox = (props: GridCellProps): ReactElement => {
        return <td><Checkbox checked={selected.includes(props.dataItem.id)} onClick={onClick(props)}/></td>
    }

    const onClick = (props: GridCellProps) => (event: ChangeEvent<any>) => {
        const checked: boolean = event.target.checked;

        setSelected(checked
            ? [...selected, props.dataItem.id]
            : selected.filter(s => s !== props.dataItem.id)
        );
    }

    const onPageChange = (event: GridPageChangeEvent) => {
        setDatastate({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take
        })
    }

    const onDataStateChange = (event: GridDataStateChangeEvent) => {
        setDatastate(event.dataState);
        setProcessedData((event.dataState.filter !== null)
            ? process([...sampleAssets], event.dataState).data
            : sampleAssets);
    }

    const onSortChange = (event: GridSortChangeEvent) => {
        setSort(event.sort);
    }

    const onTransferClicked = () => {
        setTransferring(true)
        assetService.transferSampleAssets(selected).then(_ => {
            setSelected([]);
            setTransferring(false);
        })
    }

    const skip: number = dataState.skip!;
    const take: number = dataState.take!;

    return (
        <Grid container spacing={2}>
            <Grid item>
                <GridTable
                    data={orderBy(processedData, sort).slice(skip, take + skip)}
                    {...dataState}
                    onPageChange={onPageChange}
                    onDataStateChange={onDataStateChange}
                    onSortChange={onSortChange}
                    total={processedData.length}
                    pageable={true}
                    sortable={true}
                    sort={sort}
                >
                    <GridColumn field={nameof<AssetSampleDto>(a => a.assetName.value)} title={"Name"} filter={"text"}
                                columnMenu={GridColumnMenuFilter}/>
                    <GridColumn field={nameof<AssetSampleDto>(a => a.assetType.value)} title={"Type"} filter={"text"}
                                columnMenu={GridColumnMenuFilter}
                    />
                    <GridColumn field={nameof<AssetSampleDto>(a => a.assetGroup.value)} title={"Group"} filter={"text"}
                                columnMenu={GridColumnMenuFilter}
                    />
                    <GridColumn field={nameof<AssetSampleDto>(a => a.assetCategory.value)} title={"Category"}
                                filter={"text"}
                                columnMenu={GridColumnMenuFilter}
                    />
                    <GridColumn field={"Selected"} title={"Track"} cell={props => renderCheckbox(props)}
                                width={"100px"}/>
                </GridTable>
            </Grid>
            <SaveChangesBar
                expand={selected.length > 0}
                onClick={onTransferClicked}
                message={`You've selected ${selected.length} asset(s)`}
                buttonText={"Transfer to Assets"}
                saving={transferring}
            />
        </Grid>
    )
}
export { SampleAssetsTable }