import React, { ReactElement, useCallback } from 'react';

import { Button, ClickAwayListener, Fade, Grid, Modal, Paper } from "@mui/material";

export interface ConfirmationModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    message: string | ReactElement;
    onConfirmedClicked: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({open, setOpen, message, onConfirmedClicked}) => {
    
    const onYesClicked = useCallback(() => {
        onConfirmedClicked();
        setOpen(false);
    }, [onConfirmedClicked, setOpen])

    const onNoClicked = useCallback(() => {
        setOpen(false);
    }, [setOpen])
    
    return (
        <Modal className={"document-modal"} open={open}>
            <Fade in={open}>
                <Grid container justifyContent={"center"}>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <Grid item xs={8} sm={6} md={4} lg={3}>R
                            <Paper className={"paper"}>
                                <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
                                    <Grid item>
                                        {message}
                                    </Grid>
                                    <Grid container item spacing={2} justifyContent={"center"}>
                                        <Grid item>
                                            <Button onClick={onYesClicked} size={"small"} className={"success-item-filled"}>Yes</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={onNoClicked} size={"small"} className={"error-item-filled"}>No</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </ClickAwayListener>
                </Grid>
            </Fade>
        </Modal>
    );
}
export { ConfirmationModal}