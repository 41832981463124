import React, { useCallback } from 'react';
import { Badge, Button, Divider, Grid, IconButton, Popover, Typography } from "@mui/material";
import { NotificationsActiveOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { notificationsSelector } from "user/store/selectors";
import { UserNotification } from "common/dtos/Users/models";

import { NotificationItem } from "common/components/panels/NotificationsPanel/components/NotificationItem/NotificationItem";
import { dismissNotifcation } from "user/store/actions";

import SimpleBar from "simplebar-react";
import 'simplebar/dist/simplebar.min.css';

import "./styles.scss";

const NotificationsPanel: React.FC = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const dispatch = useDispatch();

    const notifications = useSelector(notificationsSelector);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onDismissClicked = useCallback((index: number) => (): void => {
        dispatch(dismissNotifcation(index));
    }, [dispatch])

    const onDismissAllClicked = useCallback((): void => {
        notifications.map((n, i) => {
            return dispatch(dismissNotifcation(i));
        })
    }, [notifications, dispatch])

    const notificationCount = notifications.filter(n => !n.dismissed).length;

    return (
        <React.Fragment>
            <IconButton onClick={handleClick} color={"inherit"}>
                <Badge color={"error"} badgeContent={notificationCount}>
                    <NotificationsActiveOutlined/>
                </Badge>
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <Grid container className={"notificationPanel"}>
                    <Grid container item spacing={1}>
                        <Grid container item justifyContent={"space-between"}>
                            <Grid item>
                                <Typography variant={'h6'} fontWeight={700}>Notifications</Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={onDismissAllClicked}>Dismiss All</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        {notificationCount > 0
                            ? <SimpleBar className={"notificationList"} autoHide={false}>
                                <Grid container item spacing={2}>
                                    {notifications.filter(n => !n.dismissed).map((notification: UserNotification, i) => {

                                        const index: number = notifications.indexOf(notification);

                                        return (
                                            <>
                                                {i > 0 &&
                                                <Grid item xs={12} key={`divider-${index}`}>
                                                    <Divider/>
                                                </Grid>
                                                }
                                                <NotificationItem
                                                    notification={notification}
                                                    closeNotifications={handleClose}
                                                    dismissNotification={onDismissClicked(index)}
                                                    key={`notification-${index}`}
                                                />
                                            </>
                                        )
                                    })}
                                </Grid>
                            </SimpleBar>
                            : <Grid container justifyContent={"center"} pt={"2.5em"} pb={"2.5em"}>
                                <Grid item xs={12}>
                                    <Typography variant={"body1"} color={"textPrimary"} align={"center"} fontWeight={600}>
                                        Nothing to see here
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"body2"} color={"textSecondary"} align={"center"}>
                                        Keep up the good work!
                                    </Typography>
                                </Grid>
                            </Grid>

                        }
                    </Grid>
                </Grid>
            </Popover>
        </React.Fragment>
    )
}
export { NotificationsPanel }