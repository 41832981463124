import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";

import { reportingService } from "common/services/ReportingService/reportingService";
import { KpiMetrics } from "common/views/KpiMetrics";
import { userSelector } from "user/store/selectors";
import WelcomeImage from "assets/illustrations/hello.svg";

import "./styles.scss";
import { DashboardFunctionProps } from "../../Dashboard";

const WelcomeSummary: React.FC<DashboardFunctionProps> = ({ selectedFunction }) => {

    const [outstandingMetrics, setOutstandingMetricsd] = useState<KpiMetrics>();
    const [answeredMetrics, setAnsweredMetrics] = useState<KpiMetrics>();

    const user = useSelector(userSelector);

    useEffect(() => {
        reportingService.getKpiMetrics().then(metrics => {
            setOutstandingMetricsd(metrics.find(m => m.answeredQuestions === "Outstanding"))
            setAnsweredMetrics(metrics.find(m => m.answeredQuestions === "Answered"))
        })
    }, [setOutstandingMetricsd, setAnsweredMetrics, selectedFunction])

    return (
        <Paper className={"welcomeCard"} elevation={0}>
            <Grid item xs={12} container alignItems={"center"}>
                <Grid container item xs={4} alignItems={"center"}>
                    <Grid item xs={4}>
                        <img src={WelcomeImage} alt={"Welcome"} width={"100%"}/>
                    </Grid>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <Typography className={"welcomeHeading"} variant={"h6"}>Welcome
                                back {user.firstName}!</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={"welcomeValue"}>Let's see whats on the docket</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={8}>
                    <Box p={"1.0em"} width={"100%"}>
                        <Grid container spacing={4} alignItems={"center"} justifyContent={"center"}>
                            <Tooltip title={'Your company compliance score'}>
                                <Grid item>
                                    <Grid item>
                                        <Typography
                                            className={"welcomeHeading"}
                                            variant={"h6"}>{answeredMetrics?.complianceScore ?? "0"}%</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={"welcomeValue"}>Compliance Score</Typography>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                            <Tooltip title={'Remaining questions that are assigned to you'}>
                                <Grid item>
                                    <Grid item>
                                        <Typography
                                            className={"welcomeHeading"}
                                            variant={"h6"}>{outstandingMetrics?.questionTotal ?? "-"}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={"welcomeValue"}>Questions Remaining</Typography>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                            <Tooltip title={'Remaining questions that are assigned as Accountable to you'}>
                            <Grid item>
                                <Grid item>
                                    <Typography
                                        className={"welcomeHeading"}
                                        variant={"h6"}>{outstandingMetrics?.accountable ?? "-"}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={"welcomeValue"}>Accountable Questions</Typography>
                                </Grid>
                            </Grid>
                            </Tooltip>
                            <Tooltip title={'Remaining questions that are assigned as Consulted to you'}>
                            <Grid item>
                                <Grid item>
                                    <Typography
                                        className={"welcomeHeading"}
                                        variant={"h6"}>{outstandingMetrics?.consulted ?? "-"}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={"welcomeValue"}>Consulted Questions</Typography>
                                </Grid>
                            </Grid>
                            </Tooltip>
                            <Tooltip title={'Remaining questions that are assigned as Informed to you'}>
                            <Grid item>
                                <Grid item>
                                    <Typography
                                        className={"welcomeHeading"}
                                        variant={"h6"}>{outstandingMetrics?.informed ?? "-"}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={"welcomeValue"}>Informed Questions</Typography>
                                </Grid>
                            </Grid>
                            </Tooltip> 
                        </Grid>
                    </Box>

                </Grid>

            </Grid>
        </Paper>
    );
}
export { WelcomeSummary }