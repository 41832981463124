import React, { ReactElement, useCallback } from "react"
import { useSelector } from "react-redux";

import { AppBar, Chip, Divider, Grid, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { AddRounded } from "@mui/icons-material";

import { BillingTier, Permission } from "auth/store/Model";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";
import { selectedCompanySelector } from "user/store/selectors";

import { FunctionSelection } from "businessFunctions/FunctionSelection/FunctionSelection";
import { ValidateCompanyBillingTier } from "auth/components/ValidateCompanyBillingTier";

import "styles/layout.scss";
import { NotificationsPanel } from "common/components/panels/NotificationsPanel/NotificationsPanel";
import { history } from "../../../history";
import { AuthConfig } from "auth/AuthConfig";

const ContextBar: React.FC = () => {

    const selectedCompany = useSelector(selectedCompanySelector);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const renderMenuContent = (): ReactElement => {
        return (
            <Grid container alignItems={"center"} spacing={1}>
                <Grid className={"toolbar-item"} item>
                    <Chip label={
                        <Typography className={"white-item-outlined"}><b>Version:</b> {AuthConfig.version}</Typography>
                    } className={"white-item-outlined"} variant={"outlined"}/>
                </Grid>
                <Grid className={"toolbar-item"} item>
                    <NotificationsPanel/>
                </Grid>
            </Grid>
        )
    }

    const renderAddFunctionButton = (): ReactElement => {
        return (
            <ValidateCompanyBillingTier billingTiers={[BillingTier.Gold, BillingTier.Silver, BillingTier.Platinum]}>
                <ValidateUserPermission
                    permissions={[Permission.BusinessFunctionsCreate, Permission.CompanyContextsCreate]}>
                    <Tooltip title="New" aria-label="add">
                        <IconButton color="inherit" onClick={handleClick}>
                            <AddRounded/>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 0
                        }}
                        transformOrigin={{horizontal: 'left', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                    >
                        <MenuItem disabled>
                            <Typography fontWeight={700}>Create New</Typography>
                        </MenuItem>
                        <Divider/>
                        <ValidateUserPermission permissions={[Permission.CompanyContextsCreate]}>
                            <MenuItem onClick={() => history.push("/wizards/company")}>
                                <Typography>Company</Typography>
                            </MenuItem>
                        </ValidateUserPermission>
                        <ValidateUserPermission permissions={[Permission.BusinessFunctionsCreate]}>
                            <MenuItem onClick={() => history.push("/wizards/business-function")}>
                                <Typography>Business Function</Typography>
                            </MenuItem>
                        </ValidateUserPermission>
                    </Menu>
                </ValidateUserPermission>
            </ValidateCompanyBillingTier>
        )
    }

    return (
        <AppBar
            variant={"outlined"}
            position={"absolute"}
        >
            <Toolbar>
                <Grid container justifyContent={"space-between"}>
                    <Grid container item xs={5} alignItems={"center"} spacing={1}>
                        <Grid item xs={8}>
                            <FunctionSelection/>
                        </Grid>
                        <Grid item>
                            {selectedCompany !== undefined && renderAddFunctionButton()}
                        </Grid>
                    </Grid>
                    <Grid item alignItems={"center"}>
                        {renderMenuContent()}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}
export { ContextBar }