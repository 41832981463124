import React from 'react';
import { Card, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';

import "./styles.scss"

export interface TitleCardProps {
    title: string;
    subTitle?: string;
    hoverOver?: string;
    link?: string;
    linkText?: string;
    linkIcon?: boolean;
    loading?: boolean;
    height?: number;
}

const TitleCard: React.FC<TitleCardProps> = ({title, subTitle, hoverOver, link, linkText, linkIcon, loading, height, children}) => {
    return (
        <Card elevation={0} className={'titleCard'}>
            <Grid container item spacing={1} justifyContent={"space-between"}>
                <Grid item xs={11}>
                    <Typography variant={"h6"}><b>{title}</b></Typography>
                    {subTitle && <Typography variant={"subtitle2"}>{subTitle}</Typography>}
                </Grid>
                { linkIcon && 
                <Grid item xs={1}>
                    { hoverOver ?
                        (
                            <Tooltip title={hoverOver}>
                                <Link to={link}>
                                    <LinkIcon/>
                                </Link>
                            </Tooltip>
                        )
                        :
                        <Link to={link}>
                            <LinkIcon/>
                        </Link>
                    }
                </Grid>}
                { link &&
                <Grid item>
                    <Link to={link}><Typography className={"hyperlink"}>{linkText && "View More"}</Typography></Link>
                </Grid>
                }
                <Grid item xs={12} height={height}  className={'titleCard'}>
                    {!loading ? children : <CircularProgress size={50}/>}
                </Grid>
            </Grid>

        </Card>
    );
}
export { TitleCard }