import { reportingService } from "common/services/ReportingService/reportingService";
import { ObligationComplianceDto } from "./models";
import { TrackedObligationDto } from "common/dtos/Obligations/models";
import { obligationsService } from "common/services/ObligationsService/obligationsService";
import {TrackedQuestionGroupDto, TrackQuestionGroupsDto} from "common/dtos/Questions/TrackedQuestionGroupDto/models";
import { questionsService } from "common/services/QuestionService/questionService";

export function getObligationCompliance(): Promise<ObligationComplianceDto[]>{
    return reportingService.getObligationCompliance()
}

export function getTrackedObligations(): Promise<TrackedObligationDto[]> {
    return obligationsService.getTrackedObligations();
}

export function trackObligation(obligationId: number, track: boolean): Promise<TrackedObligationDto> {
    return obligationsService.trackObligation(obligationId, track);
}

export function trackQuestionGroup(questionGroupId: number, tracked: boolean): Promise<TrackedQuestionGroupDto> {
    return questionsService.trackQuestionGroup(questionGroupId, tracked);
}

export function trackQuestionGroups(trackingData: TrackQuestionGroupsDto): Promise<TrackedQuestionGroupDto[]> {
    return questionsService.trackQuestionGroups(trackingData);
}

export function trackActivityIds(activityQuestionId: number, tracked: boolean): Promise<TrackedQuestionGroupDto> {
    return questionsService.trackQuestionGroup(activityQuestionId, tracked);
}

export function getTrackedQuestionGroups(): Promise<TrackedQuestionGroupDto[]> {
    return questionsService.getTrackedQuestionGroups();
}