import { AssetSampleDto } from "../../dtos/Assets/AssetSampleDto";
import { AxiosApi } from "../../axios/axiosApi";
import { AssetDetailsDto } from "../../dtos/Assets/AssetDetailsDto";
import { PaginationResponseDto } from "../../dtos/Common/pagination";
import { LinkedObligationsDto } from "../../dtos/Assets/LinkedObligationsDto";
import SnackbarUtils from "../../helpers/SnackBar/snackbar";

import { AssetStatisticsDto, assetStatisticsInitialState } from "common/dtos/Assets/AssetStatisticsDto";
import { ObligationAssignedAssetsDto } from "common/dtos/Assets/ObligationAssignedAssetsDto";
import {
    AssetLinkedObligationsDto,
    assetLinkedObligationsInitialState
} from "common/dtos/Assets/AssetLinkedObligationsDto";
import { AssetObligationComplianceScoreDto } from "common/dtos/Assets/AssetObligationComplianceScoreDto";
import { AssetComplianceSummaryDto } from "common/dtos/Assets/AssetComplianceSummaryDto";

class AssetService {
    
    private controllerName: string = "api/Assets"
    
    public async getSampleAssets(): Promise<AssetSampleDto[]> {

        const url: string = `${this.controllerName}/SampleAssets`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async getAssetStatistics(): Promise<AssetStatisticsDto> {
        const url: string = `${this.controllerName}/GetAssetStatistics`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(() => {
            return assetStatisticsInitialState;
        })
    }
    
    public async getAssetsByUser(): Promise<AssetDetailsDto[]> {
        const url:string = `${this.controllerName}/GetAssetsByUser`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getObligationsFromAssignedAssets(): Promise<ObligationAssignedAssetsDto[]> {
        const url:string = `${this.controllerName}/GetObligationsFromAssignedAssets`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }
    
    public async getAssetById(assetId: number): Promise<AssetLinkedObligationsDto> {
        const url: string = `${this.controllerName}/GetAssetById/${assetId}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return assetLinkedObligationsInitialState;
        });
    }
    
    public async transferSampleAssets(sampleAssetIds: number[]): Promise<void> {
        const url: string = `${this.controllerName}/TransferSampleAssets`;
        
        return await AxiosApi.post(url, JSON.stringify(sampleAssetIds)).then(response => {
            SnackbarUtils.success('Sample assets transferred...');
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async getFunctionAssets(): Promise<PaginationResponseDto<AssetDetailsDto[]>> {
        return await AxiosApi.get(this.controllerName).then(response => {
            return response.data;
        }).catch(e => {
            return { data: [], total: 0 };
        });
        
    }
    
    public async linkObligationsToAssets(assetId: number, obligationIds: number[]): Promise<LinkedObligationsDto[]> {
        
        const url: string = `${this.controllerName}/LinkObligationsToAsset/${assetId}`;

        return await AxiosApi.post(url, JSON.stringify(obligationIds)).then(response => {
            SnackbarUtils.success('Obligations linked to asset...');
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async unlinkObligationById(assetId: number, obligationId: number): Promise<void> {

        const url: string = `${this.controllerName}/UnlinkObligationById/AssetId/${assetId}/ObligationId/${obligationId}`;

        return await AxiosApi.delete(url).then(response => {
            SnackbarUtils.success('Obligation link removed...');
            return response.data;
        }).catch(e => {
            return;
        });
        
    }
    
    public async duplicateAsset(assetId: number): Promise<AssetDetailsDto> {
        
        const url: string = `${this.controllerName}/DuplicateAssetById/${assetId}`;

        return await AxiosApi.post(url).then(response => {
            SnackbarUtils.success('Asset has been duplicated...');
            return response.data;
        }).catch(e => {
            return;
        });
        
    }
    
    public async modifyAsset(assetDto: AssetDetailsDto): Promise<void> {

        const url: string = `${this.controllerName}/ModifyAsset`;

        return await AxiosApi.patch(url, JSON.stringify(assetDto)).then(response => {
            SnackbarUtils.success('Asset modified...');
            return response.data;
        }).catch(e => {
            return;
        });
        
    }
    
    public async deleteAsset(assetId: number): Promise<void> {
        
        const url: string = `${this.controllerName}/DeleteAssetById/${assetId}`;
        
        return await AxiosApi.delete(url).then(response => {
            SnackbarUtils.success('Asset deleted...');
            return response.data;
        }).catch(e => {
            return;
        });
    }
    
    public async isAliasUnique(assetId: number, alias: string): Promise<boolean> {
        
        const url: string = `${this.controllerName}/IsAliasUnique/${assetId}/${alias}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return;
        });
    }
    
    public async getObligationComplianceScoresByAsset(assetId: number): Promise<AssetObligationComplianceScoreDto[]> {
        const url: string = `${this.controllerName}/GetObligationComplianceScoresByAsset/${assetId}`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return;
        })
    }

    public async getAssetComplianceSummary(assetId: number): Promise<AssetComplianceSummaryDto> {
        const url: string = `${this.controllerName}/GetAssetComplianceSummary/${assetId}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return;
        })
    }
}

const assetService = new AssetService();
export { assetService }