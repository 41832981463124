import { createSelector } from "reselect";
import { UserState } from "./Model";

import { ApplicationState } from "../../store";
import { UserDetailsDto, UserNotification, UserSettingsDto } from "common/dtos/Users/models";
import { CompanyDetailsDto } from "common/dtos/Companies/models";
import { FunctionDetailsDto } from "common/dtos/Businesses/FunctionDetailsDto";

const selectorState = (state: ApplicationState): UserState =>
  state.user;

export const userSelector = createSelector(
  selectorState,
  (userState: UserState): UserDetailsDto => {
    return userState.user;
  }
);

export const userSettingsSelector = createSelector(
    selectorState,
    (userState: UserState): UserSettingsDto => {
        return userState.userSettings;
    }
);

export const selectedCompanySelector = createSelector(
    selectorState,
    (userState: UserState): CompanyDetailsDto | undefined => {
        return userState.selectedCompany;
    }
)

export const selectedFunctionSelector = createSelector(
    selectorState,
    (userState: UserState): FunctionDetailsDto | undefined => {
        return userState.selectedFunction;
    }
);

export const selectedFunctionLoadingSelector = createSelector(
    selectorState,
    (userState: UserState): boolean => {
        return userState.selectedFunctionLoading;
    }
);

export const notificationsSelector = createSelector(
    selectorState,
    (userState: UserState): UserNotification[] => {
        return userState.notifications;
    }
)