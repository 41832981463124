import React from 'react';
import { Grid, IconButton, Tooltip } from "@mui/material";
import { DeleteRounded, FileCopyRounded, OpenInBrowserRounded } from "@mui/icons-material";
import { AssetDetailsDto } from "common/dtos/Assets/AssetDetailsDto";
import { Permission } from "auth/store/Model";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";

export interface FunctionAssetActionsProps{
    asset: AssetDetailsDto;
    onViewClicked: (assetId: number) => void;
    onDuplicateClicked: (assetId: number) => void;
    onDeleteClicked: (assetId: number) => void;
}

const FunctionAssetActions: React.FC<FunctionAssetActionsProps> = ({ asset, onViewClicked, onDuplicateClicked, onDeleteClicked }) => {
    return (
        <td>
            <Grid container item>
                <Grid item>
                    <Tooltip title={"View"}>
                        <IconButton onClick={() => onViewClicked(asset.id)} size="large"><OpenInBrowserRounded/></IconButton>
                    </Tooltip>
                </Grid>
                <ValidateUserPermission permissions={[Permission.AssetsCreate]}>
                    <Grid item>
                        <Tooltip title={"Duplicate"}>
                            <IconButton onClick={() => onDuplicateClicked(asset.id)} size="large"><FileCopyRounded/></IconButton>
                        </Tooltip>
                    </Grid>
                </ValidateUserPermission>
                <ValidateUserPermission permissions={[Permission.AssetsDelete]}>
                    <Grid item>
                        <Tooltip title={"Delete"}>
                            <IconButton onClick={() => onDeleteClicked(asset.id)} size="large"><DeleteRounded/></IconButton>
                        </Tooltip>
                    </Grid>
                </ValidateUserPermission>
            </Grid>
        </td>
    );
}
export { FunctionAssetActions }