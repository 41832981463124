import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { CompanyWizardSteps } from "./CompanyWizardSteps";
import { CompanyCreation, companyCreationInitialState, CompanyCreationResponse } from "./models";
import { history } from "../../history";

import { createCompany } from "./actions";
import { ComponentStepper } from "common/components/panels/ComponentStepper/ComponentStepper";
import {
    SelectCompanyAction,
    SelectFunctionAction,
    SetUserSettingsAction
} from "user/store/actions";
import { userSettingsSelector } from "user/store/selectors";
import BusinessImage from "assets/illustrations/business.svg";
import { getCompanyFunctions } from "businessFunctions/store/actions";

import '../styles.scss';
import { userService } from "../../common/services/UserService/userService";
import { SetUserPermissionsAction } from "../../auth/store/actions";

const CompanyWizard: React.FC = () => {

    const [companyCreation, setCompanyCreation] = useState<CompanyCreation>(companyCreationInitialState);
    
    const dispatch = useDispatch();
    const userSettings = useSelector(userSettingsSelector);

    const onNext = (object: CompanyCreation) => {
        setCompanyCreation(object);
    }

    const onComplete = (object: CompanyCreation): void => {
        createCompany(object).then(async (response: CompanyCreationResponse) => {
            if (object.defaultCompany) {
                await dispatch(SetUserSettingsAction({
                    ...userSettings,
                    defaultCompanyId: response.company.id
                }))
            }
            
            await dispatch(SelectCompanyAction(response.company))
            await dispatch(getCompanyFunctions(true))
            await dispatch(SelectFunctionAction(response.function))

            await userService.getUserPermissions(userSettings.userId).then(async permissions => {
                await dispatch(SetUserPermissionsAction(permissions))
            })
        });
    }

    const onFinish = (object: CompanyCreation) => {
        // TODO: Direct them to the Business Function they created upon completion of the Wizard.
        history.push(object.startAccessment ? "/questions/assessment" : "/")
    }

    return (
        <ComponentStepper bottomImage={BusinessImage} object={companyCreation} steps={CompanyWizardSteps}
                          onComplete={onComplete}
                          onNext={onNext} onFinish={onFinish}/>
    )
}
export { CompanyWizard }