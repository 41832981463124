import React from 'react';
import * as colours from "styles/colours.scss";
import GaugeChart from 'react-gauge-chart';

import "./styles.scss"

export interface RatingGaugeProps {
    rating: number;
}

const RatingGauge: React.FC<RatingGaugeProps> = ({rating}) => {
    const guageColours: string[] = [
        colours.level1,
        colours.level2,
        colours.level3,
        colours.level4,
        colours.level5
    ]

    const needleOffset: number = 0.1;
    
    return (
        <GaugeChart
            id={"gauge-chart"}
            nrOfLevels={5}
            colors={guageColours}
            percent={(0.2 * rating) - needleOffset}
            formatTextValue={(value) => rating.toString()}
            textColor={guageColours[rating - 1]}
        />
    )
}
export { RatingGauge }