import React, { ReactElement } from "react";

import "./styles.scss";

export interface StatusPageProps {
    title?: string;
    message?: () => ReactElement;
    notice?: () => ReactElement;
}

const StatusPage: React.FC<StatusPageProps> = ({
                                                   title,
                                                   message,
                                                   notice
                                               }) => {
    return (
        <div className={"status"}>
            <div>
                {title &&
                <h1>{title}<span>.</span></h1>
                }

                {message &&
                <div className={"message"}>
                    {message()}
                </div>
                }
                {notice &&
                <div className={"notice"}>
                    {notice()}
                </div>
                }
            </div>
        </div>
    )
}
export { StatusPage }