import React, { useCallback, useState } from 'react';
import { Grid } from "@mui/material";
import { AssetStatistics } from "assetManagement/components/AssetStatistics/AssetStatistics";
import { AssetObligationsOverview } from "assetManagement/components/AssetObligationsOverview/AssetObligationsOverview";
import { AssetsByUser } from "assetManagement/components/AssetsByUser/AssetsByUser";
import { AutoAppliedObligations } from "assetManagement/components/AutoAppliedObligations/AutoAppliedObligations";
import { BillingTier } from "auth/store/Model";
import { ValidateCompanyBillingTier } from "auth/components/ValidateCompanyBillingTier";

/* TODO:
- Add data persistence in Redux instead of triggering re-renders using a seed state (#187)
*/

const AssetOverview: React.FC = () => {

    const [updateSeed, setUpdateSeed] = useState<number>(0);

    const onDataUpdated = useCallback(() => {
        setUpdateSeed(Math.random())
    }, [])

    return (
        <ValidateCompanyBillingTier billingTiers={[BillingTier.Silver, BillingTier.Platinum]}>
            <Grid container className={"topBottomGutters"} xs={11}>
                <Grid container item spacing={2}>
                    <Grid container item spacing={2}>
                        <Grid item lg={7}>
                            <AssetStatistics/>
                        </Grid>
                        <Grid item className={"flexBox"} lg={5}>
                            <AssetsByUser/>
                        </Grid>
                    </Grid>
                    <Grid item className={"flexBox"} lg={4}>
                        <AssetObligationsOverview
                            dataUpdateSeed={updateSeed}
                        />
                    </Grid>
                    <Grid item className={"flexBox"} lg={3}>
                        <AutoAppliedObligations
                            onDataUpdated={onDataUpdated}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </ValidateCompanyBillingTier>

    )
}
export { AssetOverview }