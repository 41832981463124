import { FunctionDetailsDto } from "common/dtos/Businesses/FunctionDetailsDto";

export const SET_COMPANY_FUNCTIONS = "SET_COMPANY_FUNCTIONS";
export const ADD_COMPANY_FUNCTION = "ADD_COMPANY_FUNCTION";

interface setCompanyFunctions {
    type: typeof SET_COMPANY_FUNCTIONS,
    payload: FunctionDetailsDto[]
}

interface addCompanyFunction {
    type: typeof ADD_COMPANY_FUNCTION,
    payload: FunctionDetailsDto
}

export type ActionTypes = setCompanyFunctions | addCompanyFunction;
