import * as Yup from "yup";
import { assetService } from "common/services/AssetService/assetService";

export interface AssetDetailsForm {
    id: number;
    alias: string;
    assetSampleId: number;
    confidentiality: number;
    confidentialityJustification: string;
    integrity: number;
    integrityJustification: string;
    availability: number;
    availabilityJustification: string;
    accountableOwnerId: number;
    responsibleOwnerId: number;
    state: boolean;
}

function isAliasUnique(assetId: number, value: string | undefined) {

    if(value === undefined)
        return false;

    return assetService.isAliasUnique(assetId, value).then(response => {
        return response
    }).catch(error => {
        return false;
    })
}

const assetWriteValidationSchema = Yup.object().shape({
    alias: Yup.string()
        .required("Required")
        .test("isNameValid", "Alias already taken",
            function test(value){
                return isAliasUnique(this.parent.id, value);
            })
        .min(2, "Name too short")
        .max(100, "Name too long"),
    confidentiality: Yup.number()
        .min(1, "Must be between 1 and 5")
        .max(5, "Must be between 1 and 5")
        .required("Required"),
    integrity: Yup.number()
        .min(1, "Must be between 1 and 5")
        .max(5, "Must be between 1 and 5")
        .required("Required"),
    availability: Yup.number()
        .min(1, "Must be between 1 and 5")
        .max(5, "Must be between 1 and 5")
        .required("Required"),
})
export { assetWriteValidationSchema }