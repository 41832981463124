import { questionsService } from "common/services/QuestionService/questionService";
import {
    AnswerAdditionalDetailsDto, AnswerAdditionalDetailsRequest,
    QuestionAnswerRequest,
    SubquestionAnswersRequest
} from "common/dtos/Questions/QuestionAnswers/models";
import { getApplicationQuestionFiltersContext, getApplicationSelectionContext } from "store/selectors";
import { AnswerDto } from "common/dtos/Questions/models";
import { QuestionDetailsDto } from "common/dtos/Questions/QuestionDetails/models";
import { questionEvidenceService } from "common/services/QuestionEvidenceService/questionEvidenceService";
import { FileRoutesResponseDto } from "common/dtos/Questions/FileRoutesResponseDto/models";
import { QuestionDelegationRequest } from "common/dtos/Questions/Requests/models";
import { DelegatedQuestionDto } from "common/dtos/Users/models";
import { HistoryCommentDto, HistoryCommentRequest } from "common/dtos/Questions/QuestionComments/models";
import { questionCommentsService } from "common/services/QuestionCommentsService/questionCommentsService";
import { HistoryItem, HistoryRequest } from "common/dtos/History/models";
import { LookupDto } from "common/services/LookupService/models";
import { lookupService } from "common/services/LookupService/lookupService";
import { ObligationCategoryResponse } from "common/dtos/Obligations/models";
import { QuestionStatisticsDto } from "common/dtos/Questions/QuestionStatistics/models";
import { QuestionFilters } from "../Questions/components/QuestionFilters/models";
import { UPDATE_QUESTION_FILTERS } from "./actionTypes";
import { ApplicationState } from "../../store";

export function UpdateQuestionFilters(questionFilters: QuestionFilters) {
    return {
        type: UPDATE_QUESTION_FILTERS,
        payload: questionFilters
    }
}

export function getQuestionById(questionId: number): Promise<QuestionDetailsDto>{
    const filters: QuestionFilters = getApplicationQuestionFiltersContext().questionFilters;
    return questionsService.getQuestionById(questionId, filters);
}

export function getAnswerValues(): Promise<AnswerDto[]> {
    return questionsService.getAnswerValues();
}

export function getFunctionQuestionAnswer(question: QuestionDetailsDto){

    const selectionContext = getApplicationSelectionContext();
    
    const request: SubquestionAnswersRequest = {
        questionId: question.id, 
        questionGroupId: question.questionGroupToQuestion.questionGroup.id,
        functionId: selectionContext.selectedFunction.id,
        subquestionIds: question.linkedQuestions.map(lq => lq.question.id)
    }

    return questionsService.getFunctionQuestionAnswer(request);
}

export function saveFunctionQuestionAnswers(questionId: number, request: QuestionAnswerRequest): Promise<void> {
    return questionsService.answerFunctionQuestions(questionId, request);
}

export function updateAnswerAdditionalDetails(questionId: number, request: AnswerAdditionalDetailsRequest): Promise<void> {
    return questionsService.updateAnswerAdditionalDetails(questionId, request);
}

export function getFunctionObligationCategories(): Promise<ObligationCategoryResponse>{
    return questionsService.getFunctionObligationCategories();
}

export function getFileRoutes(functionId: number, questionId:number): Promise<FileRoutesResponseDto[]> {
    return questionEvidenceService.getFileRoutes(functionId, questionId);
}

export function uploadEvidence(functionId: number, questionId: number, file: File): Promise<void> {
    return questionEvidenceService.uploadEvidence(functionId, questionId, file);
}

export function deleteEvidence(functionId: number, questionId: number, evidenceName: string): Promise<void> {
    return questionEvidenceService.deleteEvidence(functionId, questionId, evidenceName);
}

export function getQuestionStatistics(): Promise<QuestionStatisticsDto> {
    return questionsService.getQuestionStatistics();
}

export function getQuestionDelegatedUsers(questionId: number): Promise<DelegatedQuestionDto[]> {
    return questionsService.getQuestionDelegatedUsers(questionId);
}

export function delegateUsersToQuestion(questionId: number, request: QuestionDelegationRequest): Promise<DelegatedQuestionDto[]> {
    return questionsService.delegateUsersToQuestion(questionId, request);
}

export function undelegateUsersFromQuestion(questionId: number, userIds: number[]): Promise<void> {
    return questionsService.undelegateUsersFromQuestion(questionId, userIds);
}

export function getQuestionComments(questionId: number): Promise<HistoryCommentDto[]> {
    return questionCommentsService.getComments(questionId);
}

export function postQuestionComment(questionId: number, commentRequest: HistoryCommentRequest): Promise<HistoryCommentDto> {
    return questionCommentsService.postComment(questionId, commentRequest);   
}

export function deleteQuestionComment(commentId: number): Promise<void> {
    return questionCommentsService.deleteComment(commentId);
}

export function getFunctionQuestionHistory(questionId: number, historyRequest: HistoryRequest) : Promise<HistoryItem[]> {
    return questionsService.getFunctionQuestionHistory(questionId, historyRequest);
}

export function getAnswerAdditionalDetails(questionId: number): Promise<AnswerAdditionalDetailsDto> {
    return questionsService.getAnswerAdditionalDetails(questionId);
}

export function getThirdPartyManaged(): Promise<LookupDto<string>[]> {
    return lookupService.getThirdPartyManaged();
}

export function getConductActivity(): Promise<LookupDto<string>[]> {
    return lookupService.getConductActivity()
}

export function getActivityFrequency(): Promise<LookupDto<string>[]> {
    return lookupService.getActivityFrequency();
}

export function getComplianceComplexity(): Promise<LookupDto<string>[]> {
    return lookupService.getComplianceComplexity();
}

export function getImplementationEffectiveness(): Promise<LookupDto<string>[]> {
    return lookupService.getImplementationEffectiveness();
}

export function updateQuestionFilters(questionFilters: QuestionFilters) {
    return async (dispatch: any, getState: () => ApplicationState) => {
        await dispatch(UpdateQuestionFilters(questionFilters));
    }
}