import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import AzureAD, { AuthenticationState } from "react-aad-msal";

import { history } from './history';

import { authProvider } from './auth/providers/MsalAuthProvider';

import configureStore from './store/configureStore';

import registerServiceWorker from './registerServiceWorker';
import { Landing } from './landing/Landing';
import { App } from 'App';
import { LayerLoader } from "./common/components/loader/layers/LayerLoader";
import { LoadingPage } from "./common/components/pages/LoadingPage/LoadingPage";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./common/helpers/SnackBar/snackbar";
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';

import { muiTheme } from "./common/theming/models";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const store = configureStore(history);

ReactDOM.render(
    <Provider store={store}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
            <ConnectedRouter history={history}>
                <AzureAD provider={authProvider} reduxStore={store}>
                    {({
                          login,
                          authenticationState,
                      }: {
                        login: any;
                        logout: any;
                        authenticationState: any;
                        error: any;
                        accountInfo: any;
                    }) => {
                        switch (authenticationState) {
                            case AuthenticationState.Authenticated:
                                return <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                                    <SnackbarUtilsConfigurator />
                                    <App/>
                                </SnackbarProvider>
                            case AuthenticationState.Unauthenticated:
                                return <Landing login={login}/>
                            case AuthenticationState.InProgress:
                                return <LoadingPage loadingIcon={LayerLoader}/>;
                        }
                    }}
                </AzureAD>
            </ConnectedRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();
