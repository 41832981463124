import React, { ReactElement, ReactNode } from 'react';

import { LinearProgress, TableBody, TableCell, TableRow } from "@mui/material";

import moment from 'moment';

import { ColumnDefinition, ColumnType } from "../TableDefinition/ColumnDefinition";
import { currencyFormat } from '../../../helpers/Regex/regex';
import { LookupDto } from "common/services/LookupService/models";
import { getProperty } from "common/helpers/Object/object";

export interface TableContentProps {
    columns: ColumnDefinition[];
    rows: any[];
    loading: boolean;
    actions?: (row: any) => ReactElement;
}

const TableContent: React.FC<TableContentProps> = ({columns, rows, loading, actions}) => {

    const renderRows = (): ReactNode[] => {
        return rows.map((r, i) => {
            return createRow(r, i);
        })
    }

    const renderEmpty = (): ReactNode => {
        return (
            <TableRow key={0}>
                <TableCell key={0}>No Content Found</TableCell>
            </TableRow>
        )
    }


    const createRow = (row: any, rowIndex: number): ReactNode => {
        return (
            <TableRow key={rowIndex}>
                {columns.filter(c => !c.hidden && c.type !== ColumnType.Button).map((column) => {
                    return <TableCell key={column.key}>{formatColumnValue(column.type, getProperty(row, column.field), row)}</TableCell>
                })}
            </TableRow>
        )
    }

    const formatColumnValue = (columnType: ColumnType, columnValue: any, row: any) => {
        
        switch (columnType) {
            case ColumnType.Default:
                return columnValue
            case ColumnType.Decimal:
                return currencyFormat(Number(columnValue), 2, "")
            case ColumnType.Number:
                return currencyFormat(Number(columnValue), 0, "")
            case ColumnType.ExchangeRates:
                return currencyFormat(Number(columnValue), 4, "")
            case ColumnType.Percentage:
                return currencyFormat(Number(columnValue), 2, "") + "%"
            case ColumnType.DateTime:
                return columnValue ? moment(new Date(columnValue)).format("DD-MM-YYYY") : "-";
            case ColumnType.User:
                let value: string = "Unassigned";
                
                if(columnValue){
                    value = columnValue.identityProviderUserName ?? columnValue.email
                }
                
                return value;
            case ColumnType.Lookup:
                const lookup: LookupDto<string> = columnValue;
                return lookup.value;
            case ColumnType.Actions:
                return actions && actions(row)
            default:
                break;
        }
    }

    const createLoadingRow = (): ReactNode => {
        return (
            <TableRow>
                <TableCell colSpan={columns.length}><LinearProgress/></TableCell>
            </TableRow>
        )
    }

    const isTableEmpty = rows.length === 0 && !loading

    const renderRowContent = () => {
        return loading ?
            createLoadingRow() :
            renderRows();
    }
    
    return (
        <TableBody>
            {isTableEmpty ?
                renderEmpty() :
                renderRowContent()
            }
        </TableBody>
    )
}
export { TableContent }