import * as Yup from "yup";

export interface ObligationFormState {
    id: number;
    name: string;
    extendedName: string;
    obligationTypeId: number;
    obligationAdditionalDetailsId: number;
    obligationOwnerId: number;
    description: string;
    obligationLink: string;
    obligationReference: string;
    confidentialityImpactId: number;
    confidentialityJustification: string;
    integrityImpactId: number;
    integrityJustification: string;
    availabilityImpactId: number;
    availabilityJustification: string;
    questionTypeId: number;
}

const obligationFormInitialState: ObligationFormState = {
    id: 0,
    name: "",
    extendedName: "",
    obligationTypeId: 0,
    obligationAdditionalDetailsId: 0,
    integrityJustification: "",
    availabilityJustification: "",
    confidentialityJustification: "",
    obligationOwnerId: 0,
    integrityImpactId: 0,
    availabilityImpactId: 0,
    confidentialityImpactId: 0,
    obligationLink: "",
    obligationReference: "",
    description: "",
    questionTypeId: 0
}
export { obligationFormInitialState }

const obligationFormValidationSchema  = Yup.object().shape({
    id: Yup.number().required("Required"),
    name: Yup.string().required("Required"),
    extendedName: Yup.string().required("Required"),
    description: Yup.string(),
    obligationLink: Yup.string(),
    obligationReference: Yup.string(),
    confidentialityImpactId: Yup.number().typeError("Required").required("Required"),
    confidentialityJustification: Yup.string().required("Required"),
    integrityImpactId: Yup.number().typeError("Required").required("Required"),
    integrityJustification: Yup.string().required("Required"),
    availabilityImpactId: Yup.number().typeError("Required").required("Required"),
    availabilityJustification: Yup.string().required("Required"),
    obligationOwnerId: Yup.number().required("Required"),
    obligationTypeId: Yup.number().required("Required"),
})
export { obligationFormValidationSchema }