import React, { useCallback, useEffect, useState } from 'react';
import { ChipSelector } from "../../fields/ChipSelector/ChipSelector";
import { lookupService } from "common/services/LookupService/lookupService";
import { LookupDto } from "common/services/LookupService/models";

export interface SectorSelectorProps {
    label: string,
    selectedSectors: any[];
    onChange: (option: any) => void;
}

const SectorSelector: React.FC<SectorSelectorProps> = ({label, selectedSectors, onChange}) => {

    const [sectors, setSectors] = useState<any[]>([]);
    
    useEffect(() => {
        lookupService.getSectors(false).then((response) => {
            setSectors(response);
        })
    }, [])

    const getOptionSelected = (option: LookupDto<string>, selected: LookupDto<string>[]) => selected.some(opt => opt.id === option.id);

    const validateSectors = useCallback((option: any) => {
        let selectedOptions: any[] = selectedSectors.some(s => s.id === option.id) ?
            selectedSectors.filter(o => o.id !== option.id) :
            [...selectedSectors, option];

        onChange(selectedOptions);
    }, [selectedSectors, onChange]);
    
    return (
        <ChipSelector label={label} options={sectors} selected={selectedSectors} getOptionSelected={getOptionSelected} onChange={validateSectors}/>
    )
}
export { SectorSelector }