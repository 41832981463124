export const raciMarks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 5,
        label: '5',
    },
];
