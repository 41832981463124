import React, { useEffect, useState } from 'react';

import {
    Grid as GridTable,
    GridColumn,
    GridColumnMenuFilter
} from '@progress/kendo-react-grid';

import '@progress/kendo-theme-material'
import { nameof } from "ts-simple-nameof";

import { getObligationRisks } from "../store/actions";
import { ObligationRiskDto } from "../store/models";
import { lookupService } from "common/services/LookupService/lookupService";
import { getRiskLabelDescription } from "common/helpers/HelperText/getRiskLabelDescription";

import "./styles.scss";

const ObligationRiskTableExport: React.FC = () => {

    const [obligationRisk, setObligationRisk] = useState<ObligationRiskDto[]>([]);
    const [riskLabelTypes, setRiskLabelTypes] = useState<any[]>([]);
     
    useEffect(() => {
        getObligationRisks().then((response) => {
            setObligationRisk(response);
        })
    }, [setObligationRisk])

    useEffect(() => {
        lookupService.getRiskLabelTypes().then((response) => {
            setRiskLabelTypes(response);
        })
    }, [])
          
    return (
        <GridTable
            data={obligationRisk}
            pageable={false}
            className={'pdfGridTable'}
            filterable={false}
        >
            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfGridColumn'} field={nameof<ObligationRiskDto>(a => a.obligation)} title={"Obligation"} 
                        columnMenu={GridColumnMenuFilter} width={'130px'} filterable={false} />

            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfGridColumn'} field={nameof<ObligationRiskDto>(a => a.penalty)} title={"Penalty"} 
                        columnMenu={GridColumnMenuFilter} width={'150px'}
            />
            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfGridColumn'} field={nameof<ObligationRiskDto>(a => a.exposure)} title={"Exposure"}
                        columnMenu={GridColumnMenuFilter} width={'295px'}
            />
            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfGridColumn'} field={nameof<ObligationRiskDto>(a => a.rating)} title={"Effect"}
                        width={'150px'}
                        cell={props => <td>{getRiskLabelDescription(riskLabelTypes, Number(props.dataItem.rating))}</td>}
            />
            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfGridColumnRating'} 
                        field={nameof<ObligationRiskDto>(a => a.rating)} title={"Rating"}
                        cell={props => <td className={'pdfGridColumn'}><p className={`ratingScore` + props.dataItem.rating}/></td>}
                        width={'55px'}
            />               
        </GridTable>
    )
}

export { ObligationRiskTableExport }