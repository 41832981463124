import React, { useEffect, useState } from 'react';
import { Card, Divider, Grid, Typography } from "@mui/material";
import { NumberDisplay } from "common/components/misc/NumberDisplay/NumberDisplay";
import { AssetStatisticsDto, assetStatisticsInitialState } from "common/dtos/Assets/AssetStatisticsDto";
import { getAssetStatistics } from "assetManagement/actions";
import { AssetStatisticsChart } from "assetManagement/components/AssetStatistics/components/AssetStatisticsChart/AssetStatisticsChart";
import { cardProps } from "common/theming/models";
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "../../../user/store/selectors";



const AssetStatistics: React.FC = () => {
    
    const [statistics, setStatistics] = useState<AssetStatisticsDto>(assetStatisticsInitialState);

    const selectedFunction = useSelector(selectedFunctionSelector);
    
    useEffect(() => {
        getAssetStatistics().then(response => setStatistics(response));
    }, [selectedFunction])
    
    return (
        <Card {...cardProps}>
            <Grid container spacing={2}>
                <Grid container item justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant={"h6"}>Assets Overview</Typography>
                    </Grid>
                </Grid>
                <Grid container item spacing={4}>
                    <Grid item>
                        <NumberDisplay
                            amount={statistics.totalAssets}
                            title={"Total"}
                            amountColor={"inherit"}
                            titleColor={"textSecondary"}
                        />
                    </Grid>
                    <Grid item>
                        <Divider orientation={"vertical"}/>
                    </Grid>
                    <Grid item>
                        <NumberDisplay
                            amount={statistics.responsibleAssets}
                            title={"Responsible"}
                            amountColor={"inherit"}
                            titleColor={"textSecondary"}
                        />
                    </Grid>
                    <Grid item>
                        <Divider orientation={"vertical"}/>
                    </Grid>
                    <Grid item>
                        <NumberDisplay
                            amount={statistics.accountableAssets}
                            title={"Accountable"}
                            amountColor={"inherit"}
                            titleColor={"textSecondary"}
                        />
                    </Grid>
                    <Grid item>
                        <Divider orientation={"vertical"}/>
                    </Grid>
                    <Grid item>
                        <NumberDisplay
                            amount={statistics.unassignedAssets}
                            title={"Assigned to Others"}
                            amountColor={"inherit"}
                            titleColor={"textSecondary"}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AssetStatisticsChart typeStats={statistics.typeStatistics}/>
                </Grid>
            </Grid>
        </Card>
    );
}
export { AssetStatistics }