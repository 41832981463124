import { AxiosApi } from "../../axios/axiosApi";
import { AssessmentQuestionDto } from "common/dtos/Questions/Assessment/models";
import { AssessmentQuestionsAnswerRequest } from "questions/Assessment/models";

class AsseessmentQuestionsService {
    
    private controllerName: string = "api/AssessmentQuestions"
    
    public async getAssessmentQuestions(): Promise<AssessmentQuestionDto[]> {

        return await AxiosApi.get(this.controllerName).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async answerAssessmentQuestions(assessmentQuestionsAnswers: AssessmentQuestionsAnswerRequest): Promise<void> {
        
        const url: string = `${this.controllerName}/AnswerAssessmentQuestions`
        
        return await AxiosApi.post(url, JSON.stringify(assessmentQuestionsAnswers)).then(_ => {
            return;
        })
    }
}

const assessmentQuestionsService = new AsseessmentQuestionsService();
export { assessmentQuestionsService }