import { authentication } from "../auth/store/reducer";
import { AuthenticationState } from "../auth/store/Model";
import { UserState } from "../user/store/Model";
import { user } from "../user/store/reducer";
import { FunctionState } from "../businessFunctions/store/Model";
import { businessFunctions } from "../businessFunctions/store/reducer";
import { AssetState } from "../assetManagement/store/Model";
import { assets } from "../assetManagement/store/reducer";
import { questionFilters } from "../questions/Question/reducer";
import { QuestionFiltersState } from "../questions/Question/model";

// The top-level state object
export interface ApplicationState {
    authentication: AuthenticationState;
    user: UserState;
    businessFunctions : FunctionState;
    assets: AssetState;
    questionFilters: QuestionFiltersState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    authentication,
    user,
    businessFunctions,
    assets,
    questionFilters
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
