import React, { useCallback } from 'react';

import { Card, Fade, Grid, Modal, Tooltip, Typography } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import { ActivityForm } from "activityCategories/components/ActivityForm/ActivityForm";
import { ActivityCategoryDetailsDto } from "common/dtos/Questions/QuestionDetails/models";

import "./styles.scss";

export interface ActivityModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    activityCategory: ActivityCategoryDetailsDto;
    onSubmit: (activityCategoryDetails: ActivityCategoryDetailsDto) => Promise<void>;
}

const ActivityModal: React.FC<ActivityModalProps> = ({open, setOpen, title, activityCategory, onSubmit}) => {

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    return (
        <Modal
            className={"modal"}
            open={open}
            closeAfterTransition
        >
            <Fade in={open}>
                <Grid container item xs={10} sm={8} md={6}>
                    <Card className={"modal-content"}>
                        <Grid container justifyContent={"space-between"} spacing={2}>
                            <Grid item><Typography variant={"h6"}><b>{title}</b></Typography></Grid>
                            <Grid item><Tooltip title={"Create a new custom activity"}><InfoRounded/></Tooltip></Grid>
                            <Grid container item>
                                <ActivityForm
                                    activityCategory={activityCategory}
                                    handleClose={handleClose}
                                    handleSubmit={onSubmit}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Fade>
        </Modal>
    );
}
export { ActivityModal }