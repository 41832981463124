import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function QuestionStatusDefinitions() {
    
    const implementationStatuses = [
                                        {id: 0, colour: '#efefef', status: 'Outstanding', definition:'No attempt has been made to answer this question'},
                                        {id: 1, colour: '#97999b', status: 'Unknown', definition:'The answer is known to be not known or unclear'},
                                        {id: 2, colour: '#e54343', status: 'Not Implemented', definition:'The answer is known that it is not implemented at this time'},
                                        {id: 3, colour: '#ed8b00', status: 'Planned', definition:'A plan has been created and a start date has been set to address the issue'},
                                        {id: 4, colour: '#ffcd00', status: 'Underway', definition:'The delivery plan is underway and a delivery date has been set'},
                                        {id: 5, colour: '#ffcd00', status: 'Audited With Issues', definition:'The control has been independently verified by an auditor, but did not pass the audit'},
                                        {id: 6, colour: '#84bd00', status: 'Implemented', definition:'The activity has been implemented but the activity has not been independently verified as working'},
                                        {id: 7, colour: '#75bf06', status: 'Audited No Issues', definition:'The control has been independently verified by an auditor (internal or external)'},
                                        {id: 8, colour: '#58cb51', status: 'Certified', definition:'The control is part of an external certification process and is maintained in accordance to the regime'}
                                    ]                               
    
    return (
        <div>
            
            {implementationStatuses.map((a) => {
                return (
                    <Accordion key={a.id}>
                        <AccordionSummary
                            style={{backgroundColor: a.colour}}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={a.status}
                            id={a.status}
                        >
                            <Typography>{a.status}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {a.definition}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    
                )
            })
            }
        </div>
    );
}