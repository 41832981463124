import * as Yup from "yup";
import { companyService } from "common/services/CompanyService/companyService";

export interface OfficerDto {
    officerId: string;
    name: string;
    email: string;
    occupation: string;
    officerRole: string;
    appointmentDate: Date | null;
    resignationDate: Date | null;
}

export enum CompanyStatus {
    None = 0,
    Active = 1,
    Dissolved = 2,
    Receivership = 4,
    Administration = 5,
    VoluntaryArrangement = 6,
    ConvertedClosed = 7,
    InsolvencyProceedings = 8,
    Open = 9,
    Closed = 10,
    ClosedOn = 11,
}

export enum Jurisdiction {
    None = 0,
    EnglandAndWales = 1,
    Wales = 2,
    Scotland = 3,
    NorthernIreland = 4,
    EuropeanUnion = 5,
    UnitedKingdom = 6,
    England = 7,
    NonEu = 8,
}

export const officerDtoInitialState: OfficerDto = {
    name: "",
    email: "",
    occupation: "",
    officerId: "",
    appointmentDate: null,
    resignationDate: null,
    officerRole: ""
}

function isNameUnique(value: string | undefined) {

    if(value === undefined)
        return false;

    return companyService.isNameUnique(value).then(response => {
        return response
    }).catch(error => {
        return false;
    })
}

function isCompaniesHouseNumberUnique(value: string | undefined) {

    if(value === undefined)
        return false;

    return companyService.isCompaniesHouseNumberUnique(value).then(response => {
        return response
    }).catch(error => {
        return false;
    })
}

function isAccountingIdUnique(value: string | undefined) {

    if(value === undefined)
        return false;

    return companyService.isAccountingIdUnique(value).then(response => {
        return response
    }).catch(error => {
        return false;
    })
}


const creationFormValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required")
        .test("isNameValid", "Name already taken", (value) => isNameUnique(value))
        .min(2, "Name too short")
        .max(100, "Name too long"),
    additionalDetails: Yup.object().shape({
        isRegistered: Yup.boolean().required(),
        address: Yup.object().shape({
            addressLine1: Yup.string().nullable(),
            addressLine2: Yup.string().nullable(),
            locality: Yup.string().nullable(),
            postcode: Yup.string().nullable(),
            country: Yup.string().nullable()
        }),
        companiesHouseId: Yup.string()
            .when("isRegistered", {
                is: true,
                then: Yup
                    .string()
                    .required("Required")
                    .test("isCompaniesHouseNumberValid", "Companies House Number already taken", (value) => isCompaniesHouseNumberUnique(value))
            }),
        accountingId: Yup.string()
            .when("isRegistered", {
                is: false,
                then: Yup
                    .string()
                    .required("Required")
                    .test("isAccountingIdValid", "Accounting ID already taken", (value) => isAccountingIdUnique(value))
            }),
        jurisdiction: Yup.number().moreThan(0, "Required"),
        employeeNumber: Yup.number().nullable().typeError("Employee Number must be a number"),
        vat: Yup.string().nullable().typeError("VAT Number must be a number"),
    })
})
export { creationFormValidationSchema }