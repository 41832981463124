import React from 'react'
import { Button, Grid, Tooltip, Typography } from "@mui/material";

import CompletionImage from "assets/illustrations/completion.svg";
import { WizardStepProps } from "wizards/models";
import { nicifyString } from "common/helpers/Regex/regex";
import { FunctionType } from "common/components/input/forms/BusinessFunctionCreationForm/models";
import { InfoRounded } from "@mui/icons-material";

// TODO: Make a generic version of this component as it's identical to wizards/company/components/Completion;

const Completion: React.FC<WizardStepProps> = ({object, onContinueClicked}) => {

    const nicifiedFunctionType: string = nicifyString(FunctionType[object.functionDetails.functionTypeId]);

    const message: string = `You've successfully created your new ${nicifiedFunctionType}.`

    const onClick = (startAssessment: boolean) => {
        onContinueClicked({
            ...object,
            startAssessment: startAssessment
        })
    }

    return (
        <Grid container item xs={10} justifyContent={"center"}>
            <Grid direction={"column"} item container xs={6} spacing={1}>
                <Grid item>
                    <Typography variant={"h5"}><b>Congratulations!</b></Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"inherit"} color={"secondary"}>{message}</Typography>
                </Grid>
                <Grid container item spacing={1}>
                    <Grid item>
                        <Button variant={"outlined"} color={"primary"} onClick={() => onClick(false)}>
                            Go to Dashboard
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant={"contained"} 
                            color={"primary"}
                            disableElevation
                            onClick={() => onClick(true)} 
                            endIcon={
                                <Tooltip title={"Lets start your rapid assessment to begin your compliance journey"}>
                                    <InfoRounded/>
                                </Tooltip>
                            }>
                            Take Assessment
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <img src={CompletionImage} alt={"Wizard completed"} width={400}/>
            </Grid>
        </Grid>
    );
}
export { Completion }