import React, { useCallback, useRef, useState } from 'react';
import { userSelector } from "user/store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { UserDetailsFormState } from "settings/general/components/UserDetailsForm/models";
import { FormikProps } from "formik";
import { UserDetailsForm } from '../UserDetailsForm/UserDetailsForm';
import { Button, CircularProgress, Grid } from "@mui/material";
import { userService } from "common/services/UserService/userService";
import { SetUserAction } from "user/store/actions";

const UserDetailsEditing: React.FC = () => {

    const [saving, setSaving] = useState<boolean>(false);
    const [changes, setChanges] = useState<boolean>(false);
    
    const user = useSelector(userSelector);
    const formikRef = useRef<FormikProps<UserDetailsFormState>>(null);
    const dispatch = useDispatch();

    const onSaveChangesClicked = useCallback(() => {
        formikRef.current?.submitForm();
    }, [])
    
    const onFormSubmission = useCallback((formState: UserDetailsFormState) => {

        setSaving(true);
        userService.updateUserDetails(formState).then(response => {
            setChanges(false);
            dispatch(SetUserAction(response));
        }).finally(() => {
            setSaving(false)
        })
    }, [dispatch])

    return (
        <Grid container spacing={2} justifyContent={"flex-end"}>
            <Grid item>
                <UserDetailsForm
                    formikRef={formikRef}
                    onSubmit={onFormSubmission}
                    user={user}
                    onChange={() => setChanges(true)}
                />
            </Grid>
            <Grid item>
                <Button
                    variant={"outlined"}
                    className={changes ? "success-item-outlined" : ""}
                    onClick={onSaveChangesClicked}
                    endIcon={saving && <CircularProgress size={25} className={"success-item-outlined"}/>}
                    disabled={!changes}
                >Save Changes</Button>
            </Grid>
        </Grid>
    );
}
export { UserDetailsEditing }
