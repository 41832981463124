import React from 'react';
import { ResponsiveRadar } from '@nivo/radar'
import "./styles.scss"

export interface RadarChartProps {
    data: object[],
    keyList: string[]
}

const RadarChart: React.FC<RadarChartProps> = ({data, keyList}) => {
    
    const commonProps = {
        minValue:0
    }

    return (
        <div className={'ResponsiveBarChart'}>
            <ResponsiveRadar
                {...commonProps}
                data={data}
                keys={keyList}
                indexBy= {'status'}
                maxValue={'auto'}
                margin={{ top: 30, right: 10, bottom: 20, left: 80 }}
                curve="cardinalClosed"
                borderWidth={2}
                borderColor={{ from: 'color' }}
                gridLevels={3}
                gridShape="linear" 
                gridLabelOffset={9}
                enableDots={true}
                dotSize={8}
                dotColor={{ theme: 'background' }}
                dotBorderWidth={2}
                dotBorderColor={{ from: 'color' }}
                enableDotLabel={false}
                dotLabel="value"
                dotLabelYOffset={-12}
                colors={{ scheme: 'set2' }}
                fillOpacity={0.25}
                blendMode="multiply"
                animate={true}
                isInteractive={true}
                legends={[
                    {
                        anchor: 'top-left',
                        direction: 'column',
                        translateX: -80,
                        translateY: -20,
                        itemWidth: 80,
                        itemHeight: 20,
                        itemTextColor: '#000000',
                        symbolSize: 12,
                        symbolShape: 'square',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
                
            />
        </div>
    )
}

export { RadarChart }