import React from 'react';

import { Container } from "@mui/material";

import { QuestionReviewTable } from "./components/questionReview/QuestionReviewTable";
import { ReportingHeader } from "./components/reportingHeader/ReportingHeader";
import { QuestionReviewTableExport } from "./components/questionReview/QuestionReviewTableExport";

const QuestionReview: React.FC = () => {

    return (
        <Container className={"topBottomGutters"} maxWidth={"xl"}>
            <ReportingHeader name={'Detailed Question Review'} 
                             pdfReportTitle={'Detailed Question Review'} 
                             pdfLandscape={true}
                             exportable={true}
                             pdfComponent={<QuestionReviewTableExport/>}/>
            <QuestionReviewTable/>
        </Container>
    )
}
export { QuestionReview }