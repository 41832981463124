import React from 'react';
import { Card, Grid, Typography } from "@mui/material";
import { ConfirmationProps } from "wizards/company/components/Confirmation/Confirmation";
import { UserInvitationDto } from "users/InviteUsersForm/models";
import { UserRoles } from "common/dtos/Common/userRoles";

import "../../styles.scss";

const UserInvitationConfirmation: React.FC<ConfirmationProps> = ({object}) => {

    const invites: UserInvitationDto[] = object as UserInvitationDto[];

    return (
        <Card className={"card"} variant={"outlined"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={"h6"}><b>User Invitations</b></Typography>
                </Grid>
                <Grid item container justifyContent={"flex-start"} spacing={1}>
                    {invites.map((invite: UserInvitationDto, index: number) => {
                        return (
                            <Grid item container key={index} spacing={2}>
                                <Grid item xs={4}>
                                    <Grid item>
                                        <Typography variant={"body1"}>Email</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"} color={"secondary"}>{invite.email}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid item>
                                        <Typography variant={"body1"}>Role</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"} color={"secondary"}>{UserRoles[Number(invite.role)]}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid item>
                                        <Typography variant={"body1"}>Note</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"}
                                                    color={"secondary"}>{invite.note || "Not Specified"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Card>
    );
}
export
    {
        UserInvitationConfirmation
    }