import { ApplicationState } from "./index";
import { store } from "../index"
import { QuestionFiltersState } from "../questions/Question/model";

const getApplicationState = (): ApplicationState => {
    return store.getState()
}

//Todo: access the redux state to get the current company context id and service context id
const getApplicationSelectionContext = () : any => {
    return getApplicationState().user;
}

const getApplicationQuestionFiltersContext = () : QuestionFiltersState => {
    return getApplicationState().questionFilters;
}

export {
    getApplicationSelectionContext,
    getApplicationQuestionFiltersContext
}

