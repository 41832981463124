import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";

import { Container } from "@mui/material";

import { assetService } from "common/services/AssetService/assetService";
import { AssetDetailsDto } from "common/dtos/Assets/AssetDetailsDto";

import { AssetWrite } from "./components/AssetWrite/AssetWrite";
import { AssetReadOnly } from "./components/AssetReadOnly/AssetReadOnly";
import {
    AssetLinkedObligationsDto,
    assetLinkedObligationsInitialState
} from "common/dtos/Assets/AssetLinkedObligationsDto";

import "./styles.scss";

const Asset: React.FC = () => {

    const [editMode, setEditMode] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [asset, setAsset] = useState<AssetLinkedObligationsDto>(assetLinkedObligationsInitialState);
    const params: any = useParams();

    useEffect(() => {
        assetService.getAssetById(params.assetId).then(async (asset) => {
            setAsset(asset);
            setLoading(false);
        })
    }, [params.assetId])
    
    const saveChanges = (updatedAsset: AssetDetailsDto) => {
        setEditMode(false)
        setAsset({
            ...asset,
            assetDetails: updatedAsset
        });
    }
    
    return (
        <Container className={"topBottomGutters"} maxWidth={"md"}>
            {
                editMode
                    ? <AssetWrite onSaveClicked={saveChanges} asset={asset.assetDetails}/>
                    : <AssetReadOnly 
                        onEditClicked={() => setEditMode(true)} 
                        asset={asset}
                        setAsset={setAsset}
                        loading={loading}
                    />
            }
        </Container>
    )
}

export { Asset }