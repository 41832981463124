import React from "react";

import { Button, Grid } from "@mui/material";

import { ComputerMisuseAct } from "common/fragments/ComputerMisuseAct";
import { Copyright } from "common/fragments/Copyright";

import AppBanner from '../assets/logos/ASIRTA_LowRes.png'
import LoginPageImage from '../assets/animations/Login.gif'

import './styles.scss';

export interface LandingProps {
    login?: () => void;
}

const Landing: React.FC<LandingProps> = ({login}) => {
    return (
        <Grid container>
            <Grid item container md={8}  justifyContent={'center'} alignContent={"center"} className={"banner"}>
                <Grid>
                    <img className={"iconBorder"} alt={"Dashboard"} src={LoginPageImage}/>
                </Grid>
            </Grid>
            <Grid container item md={4} alignItems={'center'} justifyContent={'center'}>
                    <Grid md={10} item container spacing={10} justifyContent={"center"} alignContent={"center"}>

                        <Grid container item xs={12} justifyContent={"center"}>
                            <img alt={"Logo"} src={AppBanner}/>
                        </Grid>
                        <Grid item md={12}>
                            <Button fullWidth size={"large"} variant={"contained"} color={"primary"} onClick={login}>Sign in</Button>
                        </Grid>
                        <Grid md={10}>
                            { ComputerMisuseAct }
                            { Copyright }
                        </Grid>
                    </Grid>
                   
            </Grid>
        </Grid>
    );
}
export { Landing }
 