import React, { ReactElement, useCallback, useState } from 'react';
import {
    HistoryFilter,
    HistoryFiltering,
    historyFilteringInitialState,
    HistoryItem,
    HistoryOrdering,
    HistoryType
} from "common/dtos/History/models";
import { Divider, Grid } from "@mui/material";
import { HistoryComment } from "./components/HistoryComment/HistoryComment";
import { HistoryActivity } from "./components/HistoryActivity/HistoryActivity";
import { MarkdownEditor } from "common/components/input/fields/MarkdownEditor/MarkdownEditor";
import { orderByDate } from "common/helpers/Object/object";
import { HistoryFilters } from "./components/HistoryFilters/HistoryFilters";
import { HistoryFilterWarning } from "./components/HistoryFilterWarning/HistoryFilterWarning";
import { HistoryAssignment } from "./components/HistoryActivity/HistoryAssignment";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HistoryEvidence } from "./components/HistoryActivity/HistoryEvidence";
import { HistoryAnswer } from "./components/HistoryActivity/HistoryAnswer";
import { CheckRounded } from "@mui/icons-material";

import * as colours from "../../../../styles/colours.scss";

import "./styles.scss"
import { Permission } from "auth/store/Model";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";

export interface HistoryProps {
    history: HistoryItem[];
    setHistory: (history: HistoryItem[]) => void;
    onCommentPosted: (value: string) => Promise<HistoryItem>;
    onCommentDeleted: (commentId: number) => Promise<void>;
}

const History: React.FC<HistoryProps> = ({history, setHistory, onCommentPosted, onCommentDeleted}) => {

    const [filters, setFilters] = React.useState<HistoryFiltering>(historyFilteringInitialState);
    const [showJustification, setShowJustification] = useState<boolean>(false);

    const sortAndFilterHistory = (history: HistoryItem[]): HistoryItem[] => {
        const newest = filters.ordering === HistoryOrdering.Newest;

        return history
            .filter(a => isHistoryValid(a))
            .sort((a, b) => orderByDate(a.lastUpdatedDate, b.lastUpdatedDate, newest))
    }

    const onPostClicked = useCallback((value: string): Promise<void> => {
        return onCommentPosted(value).then(historyItem => {
            setHistory([...history, historyItem]);
        })
    }, [history, setHistory, onCommentPosted]);

    const isHistoryValid = (history: HistoryItem): boolean => {
        switch (filters.filtering) {
            case HistoryFilter.ShowAll:
                return true;
            case HistoryFilter.ShowCommentsOnly:
                return history.type === HistoryType.Comment;
            case HistoryFilter.ShowActivityOnly:
                return history.type === HistoryType.Answer;
        }
    }

    const renderHistory = (filteredHistory: HistoryItem[]): ReactElement => {
        
        return (
            <Grid className={"history"} item container>
                {filteredHistory.map((c, i) => {
                    const currentType = filteredHistory[i].type;
                    const timelineBelow: boolean = i < filteredHistory.length - 1 && (currentType !== HistoryType.Comment || filteredHistory[i + 1].type !== HistoryType.Comment);
                    const timelineAbove: boolean = i > 0 && filteredHistory[i - 1].type === HistoryType.Comment;
                    
                    switch (c.type) {
                        case HistoryType.Comment:
                            return renderComment(c, i, timelineAbove, timelineBelow)
                        case HistoryType.Answer:
                            return <HistoryActivity
                                key={i}
                                component={<HistoryAnswer historyItem={c} options={{ "showJustification": showJustification }}/>}
                                icon={<CheckRounded fontSize={"small"} color={"secondary"}/>}
                                showLine={timelineBelow}
                            />
                        case HistoryType.QuestionEvidence:
                            return <HistoryActivity
                                key={i}
                                component={<HistoryEvidence historyItem={c}/>} 
                                icon={<FontAwesomeIcon fontSize={"xs"} color={colours.secondary} icon={faFile}/>}
                                showLine={timelineBelow}
                            />
                        case HistoryType.QuestionUserDelegation:
                            return <HistoryActivity
                                key={i}
                                component={<HistoryAssignment historyItem={c}/>}
                                icon={<FontAwesomeIcon size={"xs"} color={colours.secondary} icon={faUserCheck}/>}
                                showLine={timelineBelow}
                            />
                        default:
                            return <div key={i}/>
                    }
                })}
                {filters.filtering === HistoryFilter.ShowActivityOnly &&
                <HistoryFilterWarning
                    showLine={filteredHistory.length > 0}
                    onShowAllClicked={() => setFilters({...filters, filtering: HistoryFilter.ShowAll})}
                    onShowCommentsClicked={() => setFilters({...filters, filtering: HistoryFilter.ShowCommentsOnly})}
                />
                }
            </Grid>
        )
    }

    const renderComment = (historyItem: HistoryItem, key: number, timelineAbove: boolean, timelineBelow: boolean): ReactElement => {
        return (
            <HistoryComment
                key={key}
                comment={{
                    ...historyItem,
                    id: parseInt(historyItem.id),
                    commentMarkdown: historyItem.content,
                }}
                onDeleteClicked={onCommentDeleted}
                lineAbove={timelineAbove}
                lineBelow={timelineBelow}
            />
        )
    }

    const renderMarkdown = (): ReactElement => {
        return (
            <ValidateUserPermission permissions={[Permission.QuestionsAddNotes]}>
                <Grid container item>
                    <MarkdownEditor
                        onPostClicked={onPostClicked}
                        buttonText={"Comment"}
                    />
                </Grid>
            </ValidateUserPermission>
        )
    }

    const filteredHistory = sortAndFilterHistory(history);
    
    return (
        <Grid container item spacing={2}>
            <Grid item container spacing={1}>
                <Grid xs={12} item>
                    <Divider/>
                </Grid>
                <HistoryFilters
                    filters={filters}
                    setFilters={setFilters}
                    showJustification={showJustification}
                    setShowJustification={setShowJustification}
                />
            </Grid>
            {filters.ordering === HistoryOrdering.Newest && filters.filtering !== HistoryFilter.ShowActivityOnly &&
                renderMarkdown()
            }
            <Grid container item>
                {renderHistory(filteredHistory)}
            </Grid>
            {filters.ordering === HistoryOrdering.Oldest && filters.filtering !== HistoryFilter.ShowActivityOnly &&
                renderMarkdown()
            }
        </Grid>
    );
}
export { History }