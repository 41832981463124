import { ColumnDefinition, ColumnType } from "common/components/tables/TableDefinition/ColumnDefinition";

export const autoAppliedObligationTableDefinition: ColumnDefinition[] = [
    {
        key: 0,
        name: "Name",
        field: "name",
        displayLabel: true,
        hidden: false,
        type: ColumnType.Default,
        width: "30%"
    },
    {
        key: 1,
        name: "Group",
        field: "extendedName",
        displayLabel: true,
        hidden: false,
        type: ColumnType.Default,
        width: "20%"
    },
    {
        key: 2,
        name: "Type",
        field: "obligationType",
        displayLabel: true,
        hidden: false,
        type: ColumnType.Lookup,
        width: "20%"
    },
    {
        key: 3,
        name: "Owner",
        field: "additionalDetails.obligationOwner.identityProviderUserName",
        displayLabel: true,
        hidden: false,
        type: ColumnType.Default,
        width: "20%"
    },
    {
        key: 4,
        name: "Auto Apply",
        field: "action",
        displayLabel: true,
        hidden: false,
        type: ColumnType.Actions,
        width: "10%"
    },
]