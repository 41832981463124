import React, {ChangeEvent, ReactElement, RefObject, useCallback, useState} from 'react';

import {
    Checkbox,
    ClickAwayListener, Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField,
    Typography
} from "@mui/material";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import _ from 'lodash';

import { useStyles } from "./selectAll";
import "./styles.scss";

export interface SearchableMenuProps {
    open: boolean;
    items: any[];
    selectedItems: any[];
    setSelectedItems: (items: any[]) => void;
    disabledItems?: any[];
    handleInput?: (input: string) => void;
    handleClose: () => void;
    anchorElement: RefObject<any>;
    idSelector: (item: any) => any;
    titleSelector: (input: any) => any;
    searchSelector?: (item: any) => any;
    searchable?: boolean;
    placeholder?: string;
    readOnly: boolean;
}

const SearchableMenu: React.FC<SearchableMenuProps> = ({
                                                           open,
                                                           items,
                                                           selectedItems,
                                                           setSelectedItems,
                                                           titleSelector,
                                                           disabledItems = [],
                                                           handleClose,
                                                           handleInput,
                                                           anchorElement,
                                                           searchable,
                                                           idSelector,
                                                           searchSelector,
                                                           placeholder,
                                                           readOnly
                                                       }) => {

    const [filter, setFilter] = useState<string>("");
    const debounceInput = _.debounce(input => handleInput && handleInput(input), 500);
    const classes = useStyles();
    const isAllSelected = items.length > 0 && selectedItems.length === items.length;

    const isSelected = (item: any): boolean => {
        const ids = selectedItems.map(i => idSelector(i));

        return ids.includes(idSelector(item))
    }

    const onOptionClicked = (item: any) => (e: ChangeEvent<any>) => {
        const found = selectedItems.some(i => idSelector(i) === idSelector(item));
        setSelectedItems(found
            ? selectedItems.filter(selectedItem => idSelector(selectedItem) !== idSelector(item))
            : [...selectedItems, item]
        );
    }

    const onChange = (e: ChangeEvent<any>) => {
        setFilter(e.target.value);
        debounceInput(e.target.value);
    }

    const renderOptions = (): ReactElement[] => {

        const data = searchable && searchSelector ? items.filter(oc => oc.name.toLowerCase().includes(filter.toLowerCase())).slice(0, 100) : items;

        return data.map((oc, i) => {
            const disabled: boolean = disabledItems?.includes(oc) ?? false;
            return (
                <MenuItem key={i} disabled={disabled} onClick={onOptionClicked(oc)}>
                    <ListItemIcon>
                        <Checkbox color={"primary"} checked={isSelected(oc)} size={"small"} disabled={readOnly}/>
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        {titleSelector(oc)}
                    </Typography>
                </MenuItem>
            )
        })
    }
    
    const selectAll = useCallback(() => {
        setSelectedItems(isAllSelected ? disabledItems : items);
    }, [items, isAllSelected, disabledItems, setSelectedItems])

    return (
        <Popper className={"obligationMenu"} open={open} anchorEl={anchorElement.current} placement={"bottom-end"} transition>
            {({TransitionProps}) => (
                <Grow {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener onClickAway={() => handleClose()}>
                            <Grid container item spacing={1}>
                                <Grid item xs={12}>
                                    <TextField fullWidth value={filter} onChange={onChange} variant={"outlined"}
                                               size={"small"}
                                               placeholder={placeholder || "Search..."}
                                               disabled={readOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <MenuList>
                                        <MenuItem
                                            value="all"
                                            classes={{
                                                root: isAllSelected ? classes.selectedAll : ""
                                            }}
                                            onClick={selectAll}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    classes={{indeterminate: classes.indeterminateColor}}
                                                    checked={isAllSelected}
                                                    indeterminate={
                                                        selectedItems.length > 0 && selectedItems.length < items.length
                                                    }
                                                    disabled={readOnly}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{primary: classes.selectAllText}}
                                                primary={`${isAllSelected ? "Deselect" : "Select"} All`}
                                            />
                                        </MenuItem>
                                        {renderOptions()}
                                    </MenuList>
                                </Grid>
                            </Grid>
                        </ClickAwayListener>
                    </Paper>
                </Grow>

            )}
        </Popper>
    )
}
export
{
    SearchableMenu
}