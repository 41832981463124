import React from "react";
import { Container, Grid } from "@mui/material";
import { WelcomeMessage } from "gettingStarted/components/WelcomeMessage/WelcomeMessage";
import { UpdateProfileMessage } from "gettingStarted/components/UpdateProfileMessage/UpdateProfileMessage";
import { CreateCompanyMessage } from "gettingStarted/components/CreateCompanyMessage/CreateCompanyMessage";

const GettingStarted: React.FC = () => {
    
    return (
        <Container maxWidth={'lg'} disableGutters className={"topBottomGutters"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <WelcomeMessage/>
                </Grid>
                <Grid item xs={12}>
                    <UpdateProfileMessage/>
                </Grid>
                <Grid item xs={12}>
                    <CreateCompanyMessage/>
                </Grid>
            </Grid>
        </Container>
    );
};
export { GettingStarted };