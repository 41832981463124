import {ActivityCategoryDetailsDto, QuestionAnswers} from "common/dtos/Questions/QuestionDetails/models";
import { DelegationType } from "common/dtos/Common/delegation";
import { ObligationCategoryDetailsDto } from "common/dtos/Obligations/models";
import { LookupDto } from "common/services/LookupService/models";

export interface QuestionFilters {
    search: string;
    answerValue: QuestionAnswers | null;
    questionState: QuestionState;
    delegationTypes: DelegationType[];
    sectors: LookupDto<string>[];
    obligationCategories: ObligationCategoryDetailsDto[];
    activityCategories: ActivityCategoryDetailsDto[];
    trackedQuestionGroups: boolean;
    trackedObligations: boolean;
    trackedActivities: boolean;
}

export enum QuestionState {
    AllQuestions = 0,
    AnsweredQuestions = 1,
    OutstandingQuestions = 2
}

export const questionFiltersState: QuestionFilters = {
    search: "",
    answerValue: null,
    questionState: QuestionState.AllQuestions,
    delegationTypes: [],
    sectors: [],
    obligationCategories: [],
    activityCategories: [],
    trackedQuestionGroups: false,
    trackedObligations: false,
    trackedActivities: false
}



