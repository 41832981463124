import React, {ChangeEvent, ReactElement, useCallback, useMemo} from 'react';

import {Grid, IconButton, InputAdornment, TextField, Tooltip, Typography} from "@mui/material";
import {HelpOutlineRounded} from "@mui/icons-material";

import _ from "lodash";
import { useValidPermission } from "auth/components/ValidateUserPermission";
import { Permission } from "auth/store/Model";

export interface SidepanelDropdownProps {
    label?: string;
    name?: string;
    value: string | number;
    onChange: (e: ChangeEvent<any>) => void;
    onDebouncedChange?: (e: ChangeEvent<any>) => void;
    icon: ReactElement;
    helperText?: string;
    sidepanelText?: string;
    sidepanelOpen: boolean;
    debounceTime?: number;
    errors?: boolean;
    errorText?: string | false;
}

const SidepanelTextField: React.FC<SidepanelDropdownProps> = ({
                                                                  label,
                                                                  name,
                                                                  value,
                                                                  onChange,
                                                                  onDebouncedChange,
                                                                  icon,
                                                                  helperText,
                                                                  sidepanelText,
                                                                  sidepanelOpen,
                                                                  debounceTime,
                                                                  errors,
                                                                  errorText
                                                              }) => {
    const userCanEdit = !useValidPermission(Permission.QuestionsEdit);
    
    const debounceInput = useMemo(() => 
        _.debounce((val) => onDebouncedChange && onDebouncedChange(val), debounceTime || 500), [debounceTime, onDebouncedChange])    
    
    const onTextChanged = useCallback((e: ChangeEvent<any>) => {
        onChange(e);
        debounceInput(e);
    }, [debounceInput, onChange])
    
    const renderOpen = () => {
        return (
            <Grid container item>
                <Grid container item justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant={"caption"}>{label}</Typography>
                    </Grid>
                    {helperText &&
                    <Grid item>
                        <Tooltip arrow placement={"left"} title={helperText}>
                            <HelpOutlineRounded fontSize={"small"}/>
                        </Tooltip>
                    </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={userCanEdit}
                        name={name}
                        fullWidth
                        value={value}
                        onChange={onTextChanged}
                        error={errors}
                        helperText={errorText}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {icon}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    const renderCollapsed = (): ReactElement => {

        return (
            <Grid container item justifyContent={"center"}>
                <Grid item>
                    <IconButton size="large">
                        {icon}
                    </IconButton>
                </Grid>
                {sidepanelText &&
                <Grid item container justifyContent={"center"}>
                    <Tooltip title={sidepanelText}>
                        <Typography
                            variant={"caption"}
                            noWrap>{sidepanelText}
                        </Typography>
                    </Tooltip>
                </Grid>
                }
            </Grid>
        );
    }
    return (
        sidepanelOpen ? renderOpen() : renderCollapsed()
    )
}
export {SidepanelTextField}