import React from 'react';

import { Container } from "@mui/material";

import { DetailedActivityRisk } from "./components/detailedActivityRisks/DetailedActivityRisk";
import { ReportingHeader } from "./components/reportingHeader/ReportingHeader";

const DetailedActivityRisks: React.FC = () => {

    return (
        <Container className={"topBottomGutters"} maxWidth={"xl"}>
            <ReportingHeader name={'Detailed obligation risk report'} pdfReportTitle={'Detailed obligation risk report'} pdfLandscape={true}/>
            <DetailedActivityRisk/>
        </Container>
    )
}
export { DetailedActivityRisks }