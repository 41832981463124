import React, {ChangeEvent} from 'react';

import { Container} from "@mui/material";

import { ResponsiveTable } from "common/components/tables/ResponsiveTable/ResponsiveTable";
import { RiskBracket, RiskLabelColumns, RiskLabelRows } from "./models";
import { RiskBracketBuilder } from "./RiskBracketBuilder";
import { RiskSettingsDto } from "../../models";
import { FormikErrors, FormikTouched, getIn } from "formik";

import "./styles.scss" 

export interface RiskLabelTableProps {
    riskSettings: RiskSettingsDto[],
    touched: FormikTouched<{riskLabels: RiskSettingsDto[]}>,
    errors:  FormikErrors<{riskLabels: RiskSettingsDto[]}>,
    handleChange: (e: ChangeEvent) => void,
    setFieldValue: (name: string, value: any) => void,
    turnover: number;
}

const RiskLabelTable: React.FC<RiskLabelTableProps> = ({riskSettings, touched, errors, handleChange, setFieldValue, turnover}) => {

    const createRows = (): RiskBracket[] => {
        const bracketBuilder = new RiskBracketBuilder();

        return riskSettings.map((option, index) => {
            
            let errorLabel: string | undefined = undefined;
            let errorImpactRangeX: string | undefined = undefined;
            let errorImpactRangeY: string | undefined = undefined;
            
            if(touched.riskLabels && touched.riskLabels[index] && errors.riskLabels && errors.riskLabels[index])
            {
                errorLabel = touched.riskLabels[index].label && getIn(errors, `riskLabels[${index}].label`);
                errorImpactRangeX = touched.riskLabels[index].impactRangeX && getIn(errors, `riskLabels[${index}]`);
                errorImpactRangeY = touched.riskLabels[index].impactRangeY && getIn(errors, `riskLabels[${index}]`);
            }
            
            return bracketBuilder
                .WithImpactRangeX(`riskLabels[${index}].impactRangeX`,  option.impactRangeX,  handleChange, errorImpactRangeX)
                .WithImpactRangeY(`riskLabels[${index}].impactRangeY`,  option.impactRangeY,  handleChange, errorImpactRangeY)
                .WithLabel(option.label, `riskLabels[${index}].label`, handleChange, errorLabel)
                .WithLevel(option.level)
                .Build();
        })
    }
   
    return (
        <Container disableGutters>
            <ResponsiveTable
                columns={RiskLabelColumns}
                rows={createRows()}
                totalRows={RiskLabelRows.length}
                loading={false} 
            />
        </Container>
    )
}
export { RiskLabelTable }