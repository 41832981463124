import * as Yup from "yup";

export interface UserDetailsFormState {
    identityProviderUserName: string;
    firstName: string;
    surname: string;
}

export const userDetailsFormInitialState: UserDetailsFormState = {
    identityProviderUserName: "",
    firstName: "",
    surname: ""
}

export const userDetailsFormValidationSchema  = Yup.object().shape({
    identityProviderUserName: Yup.string().typeError("Required").required("Required"),
    firstName: Yup.string().typeError("Required").required("Required"),
    surname: Yup.string().typeError("Required").required("Required"),
})