import React from 'react';

import { Container } from "@mui/material";
import { ComplianceBreakdownTable } from "./components/ComplianceBreakdownTable";
import { ReportingHeader } from "../reporting/components/reportingHeader/ReportingHeader";
import { ComplianceBreakdownTableExport } from "./components/ComplianceBreakdownTableExport";

const ComplianceBreakdown: React.FC = () => {
    
    return (
        <Container className={"topBottomGutters"} maxWidth={"xl"}>
            <ReportingHeader
                name={'Obligation compliance guidelines'}
                pdfReportTitle={'Obligation compliance guidelines'}
                pdfLandscape={true}
                exportable={true}
                pdfComponent={<ComplianceBreakdownTableExport/>}
            >
                <ComplianceBreakdownTable/>
            </ReportingHeader>
        </Container>
    )
}
export { ComplianceBreakdown }