import React, {ChangeEvent, ReactElement, useCallback, useEffect, useState} from 'react';
import moment from "moment";
import { nameof } from "ts-simple-nameof";

import {
    ObligationComplianceRatingSummaryDto,
    ObligationComplianceRatingDto,
} from "common/services/ReportingService/models";
import { getComplianceScoreClassName } from "common/helpers/Object/getComplianceScoreClassName";
import { getObligationComplianceRatingSummary, getObligationComplianceRating } from "../../store/actions";
import { ObligationRiskDetailExpanded } from './ObligationRiskDetailExpanded'
import { currencyFormat } from "common/helpers/Regex/regex";

import { Checkbox, Grid } from "@mui/material";

import {
    Grid as GridTable, 
    GridCellProps,
    GridColumn,
    GridColumnMenuFilter, 
    GridDataStateChangeEvent, 
    GridPageChangeEvent, 
    GridSortChangeEvent,
    GridExpandChangeEvent,
} from '@progress/kendo-react-grid';
import { orderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";
import { obligationsService } from "common/services/ObligationsService/obligationsService";
import {getTrackedObligations} from "../../../obligationRisks/store/actions";

import "./styles.scss";

const ObligationRiskDetail: React.FC = () => {
    const [obligationRisk, setObligationRisk] = useState<ObligationComplianceRatingSummaryDto[]>([]);
    const [obligationRiskDetail, setObligationRiskDetail] = useState<ObligationComplianceRatingDto[]>([]);
    const [detail, setDetail] = useState<ObligationComplianceRatingDto[]>([]);
    const [pagingState, setPagingState] = useState<State>({skip: 0, take: 20});
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [selectedForFilter, setSelectedForFilter] = useState<number[]>([]);

    const skip: number = pagingState.skip!;
    const take: number = pagingState.take!;


    useEffect(() => {
        getObligationComplianceRatingSummary().then((response) => {
            const data = response && response
                .sort((a,b) => (a.questionsTotal - a.compliancePercentage  <= b.questionsTotal - b.compliancePercentage ) ? 1 : -1)
            setObligationRisk(data);
        })
    }, [setObligationRisk])

    useEffect(() => {
        getObligationComplianceRating().then((response) => {
            setObligationRiskDetail(response);
        })
    }, [setObligationRiskDetail])

    useEffect(() => {
        getTrackedObligations().then((response) => {
            setSelectedForFilter([...response.map(t => t.obligationCategoryDetailsId)])
        })
    }, [])


    const renderCheckbox = (props: GridCellProps): ReactElement => {
        return <td><Checkbox checked={selectedForFilter.includes(props.dataItem.obligationCategoryDetailsId)} onClick={onClick(props)}/></td>
    }

    const onClick = (props: GridCellProps) => (event: ChangeEvent<any>) => {
        const checked: boolean = event.target.checked;

        obligationsService.trackObligation(props.dataItem.obligationCategoryDetailsId, checked).then(() => {
            setSelectedForFilter(checked
                ? [...selectedForFilter, props.dataItem.obligationCategoryDetailsId]
                : selectedForFilter.filter(s => s !== props.dataItem.obligationCategoryDetailsId)
            );
        })
    }

    const onPageChange = (event: GridPageChangeEvent) => {
        setPagingState({
            ...pagingState,
            skip: event.page.skip,
            take: event.page.take
        })
    }

    const onDataStateChange = (event: GridDataStateChangeEvent) => {
        setPagingState(event.dataState);
        setObligationRisk((event.dataState.filter !== null)
            ? process([...obligationRisk], event.dataState).data
            : obligationRisk);
    }

    const onSortChange = (event: GridSortChangeEvent) => {
        setSort(event.sort);
    }
    
    const  change = useCallback((obligation: ObligationComplianceRatingSummaryDto, details: ObligationComplianceRatingDto[] ) => {
        obligation.expanded = !obligation.expanded;
        obligation.details =  details;
    },[])
    
    const expandChange = (event: GridExpandChangeEvent) => {
        let details = obligationRiskDetail.filter(f => f.obligation === event.dataItem.obligation).map((item: ObligationComplianceRatingDto) => {
            if (item.obligation === event.dataItem.obligation) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setDetail(details);
        change(event.dataItem, details);
    };
    
    return (
        <Grid container spacing={2}>
            <Grid item>
                <GridTable
                    data={orderBy(obligationRisk, sort).slice(skip, take + skip)}
                    {...pagingState}
                    onPageChange={onPageChange}
                    onDataStateChange={onDataStateChange}
                    onSortChange={onSortChange}
                    total={obligationRisk.length}
                    pageable={true}
                    sortable={true}
                    sort={sort}
                    expandField={detail && "expanded"}
                    onExpandChange={expandChange}
                    detail={ObligationRiskDetailExpanded}
                >
                    <GridColumn field={nameof<ObligationComplianceRatingSummaryDto>(a => a.obligation)} title={"Obligation"} filter={"text"}  width={"350px"}
                                columnMenu={GridColumnMenuFilter} />

                    <GridColumn field={nameof<ObligationComplianceRatingSummaryDto>(a => a.lastResponse)} title={"Last Response"} filter={"date"}
                                columnMenu={GridColumnMenuFilter}
                                cell={props => <td>{props.dataItem.lastResponse ? moment.utc(props.dataItem.lastResponse).format("DD/MM/YYYY"): '-'}</td>}
                    />
                    <GridColumn field={nameof<ObligationComplianceRatingSummaryDto>(a => a.lastResponseBy)} title={"Last Response By"}
                               
                    />
                    <GridColumn field={nameof<ObligationComplianceRatingSummaryDto>(a => a.questionsTotal)} title={"Question Total"}
                    />
                    <GridColumn field={nameof<ObligationComplianceRatingSummaryDto>(a => a.questionsAddressed)} title={"Questions Addressed"}
                    />
                    <GridColumn field={nameof<ObligationComplianceRatingSummaryDto>(a => a.compliancePercentage)} title={"Compliance"}
                                cell={props => <td>
                                    <span className={getComplianceScoreClassName(Number(props.dataItem.compliancePercentage))}>{currencyFormat(Number(props.dataItem.compliancePercentage),0)}%</span></td>} filter={"numeric"}
                                columnMenu={GridColumnMenuFilter}
                    />                    
                    <GridColumn field={"Selected"} title={"Track"} cell={props => renderCheckbox(props)}
                                width={"100px"}/>
                
                </GridTable>
                
            </Grid>            
        </Grid>
    )
}
export { ObligationRiskDetail }