import React from "react";

import { Container, Grid, IconButton, Tooltip } from "@mui/material";
import '@progress/kendo-theme-material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { PDFExport } from '@progress/kendo-react-pdf';
import { PdfFileOutput } from "./exportToPdf/PdfFileOutput";

import './styles.scss'

export interface ReportingHeaderProps {
    name: string,
    pdfReportTitle: string,
    pdfLandscape: boolean,
    pdfComponent?: any,
    interactable?: boolean | false,
    exportable?: boolean | false
}

const ReportingHeader: React.FC<ReportingHeaderProps> = ({ interactable, exportable, name, pdfReportTitle, pdfLandscape, pdfComponent, children }) => {

    const pdfExportComponent = React.useRef<PDFExport>(null);

    const exportPDFWithComponent = () => {
        if(pdfExportComponent.current){
            pdfExportComponent.current.save();
        }
    }
    
    return (
        <Container className={"headerWrapper"} maxWidth={"xl"}>
            <Container className={"headerContainer"} maxWidth={"xl"}>
                <Grid container spacing={1} justifyContent={'space-evenly'}>
                    <Grid item xs={10}>
                        <h3>{name}</h3>  
                    </Grid>
                    <Grid item xs={2} className={'headerIconGroup'}>
                        {interactable && 
                        <Tooltip title="Interact with this">
                            <IconButton size={'small'}>
                                <ExitToAppIcon className={'headerIcon'}/>
                            </IconButton>
                        </Tooltip>
                        }
                        {exportable && 
                        <Tooltip title="Download this data as a PDF">
                            <IconButton size={'small'} onClick={exportPDFWithComponent}>
                                <PictureAsPdfIcon className={'headerIcon'}/>
                            </IconButton>
                        </Tooltip>
                        }
                    </Grid>
                </Grid>
                <PdfFileOutput  
                    pdfExportComponent={pdfExportComponent} 
                    pdfReportTitle={pdfReportTitle}
                    pdfLandscape={pdfLandscape}
                    pdfComponent={pdfComponent}
                />
            </Container>
            <React.Fragment>
                {children}    
            </React.Fragment>
        </Container>
    );
}

export { ReportingHeader }