import { UserDetailsDto } from "common/dtos/Users/models";
import { companyService } from "common/services/CompanyService/companyService";
import { CompanyUserDto } from "common/dtos/Companies/models";

export function getUsersAndGroups(): Promise<CompanyUserDto[]> {
    return companyService.getUsersAndGroups();
}

export function getUnclaimedUsers(): Promise<UserDetailsDto[]> {
    return companyService.getUnclaimedUsers();
}

export function setCompanyUserRole(userId: number, roleId: number): Promise<void> {
    return companyService.setCompanyUserRole(userId, roleId);
}