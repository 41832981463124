import React from 'react';

import { Typography } from "@mui/material";

import moment from "moment";

import { HistoryItem } from "../../../../../dtos/History/models";

export interface HistoryAssignmentProps {
    historyItem: HistoryItem;
}

const HistoryEvidence: React.FC<HistoryAssignmentProps> = ({historyItem}) => {
        
    return (
        <Typography variant={"body2"}>
            <b>{historyItem.userDetails.identityProviderUserName}</b> uploaded <b>{historyItem.content}</b> as evidence <span className={"date"}>{moment.utc(historyItem.creationDate).fromNow()}</span>
        </Typography>
    )
}
export { HistoryEvidence }