import { IAccountInfo } from "react-aad-msal";

import { AAD_LOGIN_SUCCESS, ActionTypes, SET_COMPANY_USER_PERMISSIONS, VALIDATE_USER } from "./actionTypes";

import { authenticationService } from "common/services/AuthenticationService/authenticationService"

import { UserCompanyPermission, Validation } from "./Model";
import {
    notifyUser,
    selectCompany,
    SelectFunctionAction,
    SelectFunctionLoadingAction,
    SetUserAction,
    SetUserSettingsAction
} from "user/store/actions";
import { ApplicationState } from "store";
import { getCompanyFunctions } from "businessFunctions/store/actions";
import { functionService } from "common/services/FunctionService/functionService";
import { userService } from "common/services/UserService/userService";
import { getAssessmentQuestions } from "questions/Assessment/actions";
import { NotificationType } from "common/dtos/Users/models";

export function AuthenticationAction(auth: IAccountInfo): ActionTypes {
    return {
        type: AAD_LOGIN_SUCCESS,
        payload: auth
    }
}

export function ValidateUserAction(validated: boolean): ActionTypes {
    return {
        type: VALIDATE_USER,
        payload: validated
    }
}

export function SetUserPermissionsAction(permissions: UserCompanyPermission[]): ActionTypes {
    return {
        type: SET_COMPANY_USER_PERMISSIONS,
        payload: permissions
    }
}

export function validateUser() {
    return async (dispatch: any, getState: () => ApplicationState) => {
        await authenticationService.validateUser().then(async (result: Validation) => {

            await dispatch(ValidateUserAction(result.validated));
            await dispatch(SetUserAction(result.user))
            await dispatch(SetUserSettingsAction(result.userSettings))

            if (getState().user.selectedCompany)
                return;

            if (result.userSettings.defaultCompanyId) {
                if (getState().user.selectedCompany === undefined) {
                    await dispatch(selectCompany(result.userSettings.defaultCompanyId));
                    
                    await userService.getUserPermissions(result.user.id).then(async permissions => {
                        await dispatch(SetUserPermissionsAction(permissions))
                    })
                }

                if (getState().user.selectedFunction === undefined) {
                    await functionService.getSelectCompanyDefaultFunction().then(async functionDetails => {
                        await dispatch(SelectFunctionAction(functionDetails));
                    })
                }

                if (getState().businessFunctions.companyFunctions.length === 0)
                    await dispatch(getCompanyFunctions());

                checkUserNotifications(dispatch);
            }
        }).catch(async e => {
            await dispatch(ValidateUserAction(false));
            await dispatch(SelectFunctionLoadingAction(false));
        });
        await dispatch(SelectFunctionLoadingAction(false));
    }
    
    function checkUserNotifications(dispatch: any){
        getAssessmentQuestions().then(response => {
            if (response.length > 0) {
                dispatch(notifyUser({
                    icon: NotificationType.ALERT,
                    title: "Complete your Assessment",
                    message: "There are Assessment Questions waiting for you!",
                    dismissed: false,
                    link: "/questions/assessment"
                }));
            }
        });
     }
}