import React, { useCallback, useEffect, useState } from 'react';

import { Grid as GridTable, GridColumn } from '@progress/kendo-react-grid';

import '@progress/kendo-theme-material'
import { nameof } from "ts-simple-nameof";
import { Typography } from "@mui/material";

import { getObligationCompliance } from "../store/actions";
import { ObligationComplianceDto } from "../store/models";
import { LookupDto } from "common/services/LookupService/models";
import {lookupService} from "common/services/LookupService/lookupService";
import { getRiskLabelDescription } from "common/helpers/HelperText/getRiskLabelDescription";

import "./styles.scss";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";

const ComplianceBreakdownTableExport: React.FC = () => {

    const initialSort: Array<SortDescriptor> = [
        { field: "obligation", dir: "asc" },
        { field: "complianceReportCategory", dir: "asc" }
    ];
    
    const [complianceBreakdown, setComplianceBreakdown] = useState<ObligationComplianceDto[]>([]);
    const [riskLabelTypes, setRiskLabelTypes] = useState<LookupDto<string>[]>([]);
    
    useEffect(() => {
        getObligationCompliance().then((response) => {
            setComplianceBreakdown(response);
        })
    }, [setComplianceBreakdown])

    useEffect(() => {
        lookupService.getRiskLabelTypes().then((response) => {
            setRiskLabelTypes(response);
        })
    }, [])

    const formatText =  useCallback((text: string) => {
        return text ? text.split("\\n ").map((lines, index) => {
            return (
                <Typography key={index} className={'pdfComplianceTextSplit'}>
                    {lines}
                </Typography>
            );
        }) : text
    },[])
    
    return (
        <GridTable
            data={orderBy(complianceBreakdown, initialSort)}
            sortable={true}
            sort={initialSort}
            pageable={false}
            className={'pdfGridTable'}
            filterable={false}
        >
            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfGridColumn'} field={nameof<ObligationComplianceDto>(a => a.obligation)} title={"Obligation"} width={"130px"}
            />
            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfGridColumn'} field={nameof<ObligationComplianceDto>(a => a.complianceReportCategory)} title={"Report Category"} width={"150px"}
            />
            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfComplianceTextSplit'} field={nameof<ObligationComplianceDto>(a => a.complianceReportSubCategory)} title={"Report Sub-category"}
                        width={'295px'}
                        cell={props => <td  className={'pdfComplianceTextSplit'}>
                                {formatText(props.dataItem.complianceReportSubCategory)}
                        </td>}
            />
            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfGridColumn'} field={nameof<ObligationComplianceDto>(a => a.rating)} title={"Effect"}
                        width={'150px'}
                        cell={props => <td className={'pdfGridColumn'}>{getRiskLabelDescription(riskLabelTypes, Number(props.dataItem.rating))}</td>}
            />
            <GridColumn headerClassName={'pdfGridColumnHeader'} className={'pdfGridColumnRating'} 
                        field={nameof<ObligationComplianceDto>(a => a.rating)} title={"Rating"}
                        cell={props => <td className={'pdfGridColumn'}><p className={`ratingScore` + props.dataItem.rating}/></td>} 
                        width={'55px'}
            />               
        </GridTable>
    )
}
export { ComplianceBreakdownTableExport }