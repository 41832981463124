import * as Yup from "yup";
import { functionService } from "common/services/FunctionService/functionService";

function isFunctionUnique(value: string | undefined, excludingCurrent: boolean) {
    
    if(value === undefined)
        return false;
    
    return functionService.isFunctionUnique(value.toString(), excludingCurrent).then(response => {
        return response
    }).catch(error => {
        return false;
    })
}

const baseCreationFormValidation = {
    type: Yup.number(),
    ownerId: Yup.number().required("Required").min(1, "Required").typeError("Required"),
    maintainerId: Yup.number().required("Required").min(1, "Required").typeError("Required"),
    confidentialityJustification: Yup.string()
        .required("Required")
        .typeError("Required")
        .min(2, "Justification too short")
        .max(255, "Justification too long"),
    integrityJustification: Yup.string()
        .required("Required")
        .typeError("Required")
        .min(2, "Justification too short")
        .max(255, "Justification too long"),
    availabilityJustification: Yup.string()
        .required("Required")
        .typeError("Required")
        .min(2, "Justification too short")
        .max(255, "Justification too long"),
}

const creationFormValidationSchema = Yup.object().shape({
    name: Yup.string().required("Required").test("isFunctionValid", "Name already taken", (value) => isFunctionUnique(value, false))
        .min(2, "Name too short")
        .max(100, "Name too long"),
    ...baseCreationFormValidation
})

const editorialFormValidationSchema = Yup.object().shape({
    name: Yup.string().required("Required").test("isFunctionValid", "Name already taken", (value) => isFunctionUnique(value, true))
        .min(2, "Name too short")
        .max(100, "Name too long"),
    ...baseCreationFormValidation
});


export { creationFormValidationSchema, editorialFormValidationSchema }