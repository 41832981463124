import React, { ReactElement, useState } from 'react'

import { Avatar, Button, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";

import { DelegatedQuestionDto } from "../../../dtos/Users/models";
import { getUserInitials } from "../../../helpers/Regex/regex";

import "./styles.scss";

export interface UserAvatarProps {
    delegate: DelegatedQuestionDto;
    onUnassignClicked: (user: DelegatedQuestionDto) => Promise<void>;
}

const UserDelegateAvatar: React.FC<UserAvatarProps> = ({delegate, onUnassignClicked }) => {
    
    const [processing, setProcessing] = useState<boolean>(false);
    
    const onClick = () => {
        setProcessing(true)
        onUnassignClicked(delegate).finally(() => setProcessing(false));
    }
    
    const renderTooltip = (): ReactElement => {
        return (
            <Grid container>
                <Grid container item spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <Avatar>{getUserInitials(delegate.user)}</Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant={"subtitle2"} color={"inherit"}>{delegate.user.email}</Typography>
                        {// @ts-ignore
                            <Typography variant={"caption"} color={"inherit"}>{delegate.delegation.value}</Typography>
                        }
                    </Grid>
                    <Grid item>
                        <Button 
                            onClick={onClick} 
                            className={"error-item-filled"} 
                            size={"small"}
                            endIcon={processing && <CircularProgress color={"inherit"} size={20}/>}
                        >Unassign</Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    
    return (
        <Tooltip arrow title={renderTooltip()}>
            <Avatar>{getUserInitials(delegate.user)}</Avatar>
        </Tooltip>
        
    )
}
export { UserDelegateAvatar }
    