import React, { useCallback } from 'react';
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

import { Button, CircularProgress, Grid } from "@mui/material";

import { isEmptyOrSpaces } from "../../../../helpers/Regex/regex";

import "./styles.scss";


export interface MarkdownEditorProps {
    onPostClicked: (value: string) => Promise<void>;
    buttonText: string;
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({onPostClicked, buttonText}) => {
    
    const [markdown, setMarkdown] = React.useState<string>("");
    const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");
    const [posting, setPosting]= React.useState<boolean>(false);
    
    const onChange = useCallback((value: string) => {
        setMarkdown(value)
    }, [setMarkdown])
    
    const onClick = useCallback(() => {
        if(isEmptyOrSpaces(markdown))
            return;
        
        setMarkdown("");
        setPosting(true);
        onPostClicked(markdown).finally(() => setPosting(false));
    }, [markdown, setMarkdown, onPostClicked])
    
    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ReactMde
                        value={markdown}
                        onChange={onChange}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown source={markdown} />) }                        
                    />
                </Grid>
                <Grid container item justifyContent={"flex-start"}>
                    <Grid item>
                        <Button 
                            onClick={onClick}
                            disabled={isEmptyOrSpaces(markdown) || posting}
                            variant={"contained"}
                            disableElevation
                            color={"primary"}
                            endIcon={posting && <CircularProgress color={"inherit"} size={20}/>}
                        >
                            {buttonText}</Button>
                    </Grid>
                </Grid>
            </Grid>
            
            
        </React.Fragment>
    );
}
export { MarkdownEditor }