import React from "react";
import { Grid, Typography } from "@mui/material";
import { RiskLabelTypeSelector } from "common/components/input/selectors/RiskLabelTypeSelector/RiskLabelTypeSelector";
import { RatingGauge } from "common/components/content/RatingGauge/RatingGauge";

export interface ImpactRatingProps {
    rating: number;
    title: string;
    description?: string;
}

const ImpactRating: React.FC<ImpactRatingProps> = ({rating, title, description}) => {
    return (
        <Grid container item spacing={1}>
            <Grid item xs={12}>
                <RatingGauge rating={rating}/>
            </Grid>
            <Grid item container>
                <Grid item xs={12}>
                    <Typography variant={"h6"} align={"center"}>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"body2"} color={"textSecondary"} align={"center"}>{description ?? "No Justification"}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <RiskLabelTypeSelector risk={rating} align={"center"}/>
            </Grid>
        </Grid>
    )
}
export { ImpactRating }