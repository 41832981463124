import React from 'react';
import { MenuOptionProps } from "common/components/panels/MenuOption/MenuOption";

import { authProvider } from 'auth/providers/MsalAuthProvider';
import { history } from "../history";
import { BillingTier, Permission } from "auth/store/Model";

import {
    AccountTreeOutlined,
    AssessmentOutlined,
    ContentCopyOutlined,
    DashboardOutlined,
    ExitToAppOutlined,
    FactCheckOutlined,
    NewReleasesOutlined,
    QuestionAnswerOutlined,
    SettingsOutlined
} from "@mui/icons-material";

//todo:: Add in permissions at a full component access level
    
const ContentOptions: MenuOptionProps[] = [
    {
        title: "Dashboard",
        icon: <DashboardOutlined/>,
        onClick: () => {
            history.push("/")
        },
        showText: true,
        selected: false,
        hidden: false,
        hideOnNoFunction: false
    },
    {
        title: "Questions",
        icon: <QuestionAnswerOutlined/>,
        onClick: (e) => {
            e.stopPropagation();
            history.push("/questions")
        },
        showText: true,
        selected: false,
        hidden: false,
        hideOnNoFunction: true,
        restrictedBillingTiers: [ BillingTier.Bronze, BillingTier.Gold],
        permission: Permission.QuestionsView
   },
    {
        title: "Questions",
        icon: <QuestionAnswerOutlined/>,
        onClick: (e) => {
            e.stopPropagation();
            history.push("/questions")
        },
        showText: true,
        selected: false,
        hidden: false,
        hideOnNoFunction: true,
        permission: Permission.QuestionsView,
        restrictedBillingTiers: [ BillingTier.Platinum, BillingTier.Silver],
        options: [
            {
                title: "List",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/questions")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: true,
            },
            {
                title: "Import",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/questions/import")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: true,
            }
        ]
    },
    {
        title: "Obligation Risks",
        icon: <NewReleasesOutlined/> ,
        onClick: () => {
            history.push("/obligationRisks")
        },  
        showText: true,
        selected: false,
        hidden: false,
        permission: Permission.RisksView,
        hideOnNoFunction: true
    },
    {
        title: "Compliance Breakdown",
        icon: <FactCheckOutlined/>,
        onClick: () => {
            history.push("/complianceBreakdown")
        },
        showText: true,
        selected: false,
        hidden: false,
        permission: Permission.GuideLinesView,
        hideOnNoFunction: true
    },
    {
        title: "Reporting",
        icon: <AssessmentOutlined/>,
        onClick: (e) => {
            e.stopPropagation();
            history.push("/reporting/detailedActivityRisks")
        },
        showText: true,
        selected: false,
        permission: Permission.ReportsView,
        hideOnNoFunction: true,
        options: [
            {
                title: "Question Review",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/reporting/questionReview")
                },
                showText: true,
                selected: false,
                hidden: true,
                hideOnNoFunction: true
            },
            {
                title: "Detailed obligation risk",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/reporting/detailedActivityRisks")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: true
            },
            {
                title: "High level obligation risk overview",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/reporting/highLevelObligationRiskOverview")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: true
            },
            {
                title: "Low level obligation risk overview",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/reporting/detailedObligationRisks")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: true
            },
            {
                title: "Company Compliance Timeline",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/reporting/complianceOverTime")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: true
            },
            {
                title: "Obligation Compliance Timeline",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/reporting/complianceOverTimeByObligation")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: true
            }
        ],
        hidden: false
    },
    {
        title: "Assets",
        icon: <ContentCopyOutlined/>,
        onClick: (e) => {
            e.stopPropagation();
            history.push("/assets/overview")
        },
        showText: true,
        selected: false,
        hidden: false,
        restrictedBillingTiers: [ BillingTier.Silver, BillingTier.Platinum],
        hideOnNoFunction: true,
        permission: Permission.AssetsView,
        options: [
            {
                title: "Overview",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/assets/overview")
                },
                showText: true,
                selected: false,
                hidden: false,
                restrictedBillingTiers: [ BillingTier.Silver, BillingTier.Platinum],
                hideOnNoFunction: true
            },
            {
                title: "Manage Assets",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/assets/all")
                },
                showText: true,
                selected: false,
                hidden: false,
                restrictedBillingTiers: [ BillingTier.Silver, BillingTier.Platinum],
                hideOnNoFunction: true,
            },
            {
                title: "Manage Obligations",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/categories/obligations")
                },
                showText: true,
                selected: false,
                hidden: false,
                restrictedBillingTiers: [ BillingTier.Silver, BillingTier.Platinum],
                hideOnNoFunction: true,
            },
            {
                title: "Manage Activities",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/categories/activities")
                },
                showText: true,
                selected: false,
                hidden: false,
                restrictedBillingTiers: [ BillingTier.Silver, BillingTier.Platinum],
                hideOnNoFunction: true
            },
        ],
    },
    {
        title: "RACI",
        icon: <AccountTreeOutlined/>,
        onClick: () => {
            history.push("/raci-management/")
        },
        showText: true,
        selected: false,
        hidden: false,
        permission: Permission.RACIView,
        hideOnNoFunction: true
    }
];
const FooterOptions: MenuOptionProps[] = [
    {
        title: "Settings",
        icon: <SettingsOutlined/>,
        onClick: () => {
            history.push("/settings/companies")
        },
        showText: true,
        selected: false,
        hideOnNoFunction: false,
        options: [

            {
                title: "Users",
                onClick: () => {
                    history.push("/users/overview/")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: true,
                permission: Permission.UserView,
                options: [
                    {
                        title: "Overview",
                        onClick: (e) => {
                            e.stopPropagation();
                            history.push("/users/overview/")
                        },
                        showText: true,
                        selected: false,
                        hidden: false,
                        hideOnNoFunction: true
                    },

                    {
                        title: "Edit My User Account",
                        onClick: (e) => {
                            e.stopPropagation();
                            history.push("/settings/user")
                        },
                        showText: true,
                        selected: false,
                        hidden: false,
                        hideOnNoFunction: true
                    }]
            }
,
            
            {
                title: "Organisations/Services",
                restrictedBillingTiers: [ BillingTier.Silver, BillingTier.Platinum],
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/settings/functions")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: true
            } ,
            {
                title: "Companies",
                onClick: (e) => {
                    e.stopPropagation();
                    history.push("/settings/companies")
                },
                showText: true,
                selected: false,
                hidden: false,
                hideOnNoFunction: false
            }
        ],
        hidden: false
    },
    {
        title: "Logout",
        icon: <ExitToAppOutlined/>,
        onClick: authProvider.logout,
        showText: true,
        selected: false,
        hidden: false,
        hideOnNoFunction: false
    },
];
export interface SearchMenuOptions {
    title: string;
    depth: number;
}
const hideMenuOption = (menuOptionProps: MenuOptionProps[], searchMenuOptions: SearchMenuOptions): MenuOptionProps[] => {
    menuOptionProps.forEach(menuOption => {
        if (searchMenuOptions.depth === 0) {
            if (menuOption.title === searchMenuOptions.title) {
                menuOption.hidden = true;
                return;
            }
        }
    });
    return menuOptionProps;
}
export { ContentOptions, FooterOptions, hideMenuOption };