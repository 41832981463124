import React, {ReactElement, useCallback, useState} from 'react';

import { CircularProgress, Grid, IconButton, Link, Tooltip } from "@mui/material";

import "./styles.scss"

export interface EvidenceItemProps {
    label: string;
    href?: string;
    onIconClicked?: () => Promise<void>;
    icon: ReactElement;
    iconTooltip?: string;
}

const ReferenceItem: React.FC<EvidenceItemProps> = ({label, href, onIconClicked, icon, iconTooltip}) => {
    
    const [executingAction, setExecutingAction] = useState<boolean>(false);
    
    const onClick = useCallback(() => {

        if(onIconClicked === undefined)
            return;
        
        setExecutingAction(true)

        onIconClicked().finally(() => {
            setExecutingAction(false);
        })
        
    }, [onIconClicked])
    
    return (
        <Grid className={"referenceItem"} container item justifyContent={"space-between"} alignItems={"center"}>
            <Grid item> 
                <Link href={href} target={"_blank"}>{label}</Link>
            </Grid>
            <Grid item>
                <Tooltip title={iconTooltip || ""}>
                    <IconButton onClick={onClick} disabled={executingAction} size={"small"}>
                        { executingAction ? 
                        <CircularProgress color={"secondary"} size={15} /> : icon
                        }
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
}
export { ReferenceItem }