import React from 'react';
import { ConfirmationProps } from "wizards/company/components/Confirmation/Confirmation";
import { Card, Grid, Typography } from "@mui/material";
import { RiskSettingsDto } from "riskLabels/models";

import "../../styles.scss";

const RiskLabelsConfirmation: React.FC<ConfirmationProps> = ({object}) => {
    
    const riskSettings: RiskSettingsDto[] = object as RiskSettingsDto[];
    
    return (
        <Card className={"card"} variant={"outlined"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={"h6"}><b>Risk Labels</b></Typography>
                </Grid>
                <Grid item container justifyContent={"flex-start"} spacing={2}>
                    {riskSettings.map((rl, i) => {
                        const rangeX: string = `£${rl.impactRangeX}`;
                        const rangeY: string = `£${rl.impactRangeY}`;
                        
                        return (
                            <Grid key={i} item container justifyContent={"flex-start"} spacing={2}>
                                <Grid container item spacing={2}>
                                    <Grid item>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"}>Level</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} color={"secondary"}>{rl.level}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"}>Custom Label</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} color={"secondary"}>{rl.label}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"}>Impact Range From</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} color={"secondary"}>{rangeX}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"}>Impact Range To</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} color={"secondary"}>{rangeY}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Card>
    );
}
export { RiskLabelsConfirmation }