import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid } from "@mui/material";
import { initialAssessmentAnswerLookup } from "common/dtos/Questions/Assessment/models";
import { AssessmentQuestion } from "questions/Assessment/components/Questions/AssessmentQuestion/AssessmentQuestion";
import {
    AssessmentQuestionsAnswerRequest,
    AssessmentQuestionsAnswers,
    AssessmentSubquestionAnswer,
    Question
} from "questions/Assessment/models";
import { answerAssessmentQuestions, AssessmentState, getAssessmentQuestions } from "questions/Assessment/actions";
import { AnswerValue } from "common/dtos/Questions/models";
import { ProgressCounter } from "common/components/content/ProgressCounter/ProgressCounter";

export interface AssessmentQuestionsProps {
    setAssessmentState: (state: AssessmentState) => void;
}

const AssessmentQuestions: React.FC<AssessmentQuestionsProps> = ({setAssessmentState}) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [assessmentQuestions, setAssessmentQuestions] = useState<Question[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);

    const lastQuestion: boolean = currentQuestionIndex === assessmentQuestions.length - 1;

    useEffect(() => {
        getAssessmentQuestions().then(response => {

            if (response.length === 0) {
                setAssessmentState(AssessmentState.NO_ACTION);
                return;
            }

            const questions: Question[] = response.map((question) => {
                return {
                    id: question.id,
                    questionGroupId: question.questionGroupId,
                    subquestions: question.assessmentSubQuestions.map((subquestion): AssessmentSubquestionAnswer => {
                        return {
                            details: subquestion,
                            answer: initialAssessmentAnswerLookup
                        }
                    })
                }
            })

            setLoading(false);
            setAssessmentQuestions(questions);
        })
    }, [setAssessmentState])

    const onPreviousClicked = useCallback(() => {
        setCurrentQuestionIndex(prevState => prevState - 1);
    }, [setCurrentQuestionIndex])

    const onNexClicked = useCallback(() => {
        setCurrentQuestionIndex(prevState => prevState + 1);
    }, [setCurrentQuestionIndex])

    const onFinishClicked = useCallback(() => {

        const answers: AssessmentQuestionsAnswers[] = assessmentQuestions
            .filter((q) => !q.subquestions.every(s => s.answer === initialAssessmentAnswerLookup))
            .map((q): AssessmentQuestionsAnswers => {
                return {
                    questionId: q.id,
                    answer: getLowestAnswerFromSubquestions(q.subquestions)
                }
            })

        const request: AssessmentQuestionsAnswerRequest = {
            assessmentQuestionsAnswers: answers
        }

        setLoading(true);

        answerAssessmentQuestions(request).then(_ => {
            setAssessmentState(lastQuestion ? AssessmentState.COMPLETE : AssessmentState.COME_BACK_LATER);
        }).finally(() => setLoading(true))

    }, [assessmentQuestions, lastQuestion, setAssessmentState])

    const getLowestAnswerFromSubquestions = (subquestions: AssessmentSubquestionAnswer[]): AnswerValue => {
        const lastSubquestion = subquestions.sort(s => s.answer.value).pop();

        return lastSubquestion !== undefined ? lastSubquestion.answer.value : AnswerValue.Unknown;
    }

    const onUpdateQuestion = useCallback((question: Question) => {
        let updatedQuestions = [...assessmentQuestions];
        updatedQuestions[currentQuestionIndex] = question;
        setAssessmentQuestions(updatedQuestions);
    }, [setAssessmentQuestions, assessmentQuestions, currentQuestionIndex])

    return (
        <Grid item container spacing={2} justifyContent={"center"}>
            {!loading && assessmentQuestions.length > 0 ?
                <Grid container item justifyContent={"center"} alignItems={"center"} spacing={2}>
                    <Grid item xs={12}>
                        <ProgressCounter current={currentQuestionIndex + 1} total={assessmentQuestions.length}/>
                    </Grid>
                    <AssessmentQuestion
                        question={assessmentQuestions[currentQuestionIndex]}
                        onUpdateQuestion={onUpdateQuestion}
                    />
                    <Grid container item justifyContent={"center"} spacing={2}>
                        <Grid item>
                            <Button
                                variant={"outlined"}
                                disabled={currentQuestionIndex === 0}
                                color={"primary"}
                                onClick={onPreviousClicked}
                            >Previous</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                disabled={lastQuestion}
                                disableElevation
                                onClick={onNexClicked}
                            >Next</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={lastQuestion ? "contained" : "outlined"}
                                className={lastQuestion ? "success-item-filled" : "success-item-outlined"}
                                disableElevation
                                onClick={onFinishClicked}
                            >{lastQuestion ? "Finish" : "Come back later"}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid item>
                    <CircularProgress/>
                </Grid>
            }
        </Grid>
    );
}
export
{
    AssessmentQuestions
}