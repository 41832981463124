import React from "react";

import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {CircularProgress, Grid, Typography} from "@mui/material";

import "./styles.scss";

export interface MenuUserProps {
  fullName: string;
  companyName: string;
  showText: Boolean;
  iconUrl?: string | null;
}

const MenuUser: React.FC<MenuUserProps> = ({
  fullName,
  companyName,
  showText,
  iconUrl
}) => {

  const renderProfileIcon = () => {
    if(iconUrl === undefined){
      return <CircularProgress />
    }

    return iconUrl !== null ? <img src={iconUrl} alt={"Profile Icon"}/> : <PersonRoundedIcon fontSize={"large"}/>
  }
  
  const justify = showText ? "flex-start" : "center";
  
  return (
    <Grid container item justifyContent={justify} alignItems={"center"} className={"user_container"}>
      <Grid item className={"user_icon"}>
        { renderProfileIcon() }
      </Grid>
      { showText && (
        <Grid item className={"user_text"}>
          <Grid item xs={12}>
            <Typography variant={"body1"} color={"inherit"} noWrap><b>{companyName}</b></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"body2"} color={"secondary"}>{fullName}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { MenuUser };
