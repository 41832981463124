import * as Yup from "yup";

export enum EmployeeNumber {
    None = 0,
    '1-5' = 1,
    '5-25' = 2,
    '25-50' = 3,
    '50-100' = 4,
    '100-500' = 5,
    '500-1000' = 6,
    '1000+' = 7,
} 

export enum CurrentChallenges {
    None = 0,
    AssessingAgainstTheBasics = 1,
    GainingCertificationToSell = 2,
    GainingVisibilityOfMySupplyChain= 3,
    UnderstandingLegalObligations = 4,
    AssessingMyRisks = 5
}

const creationFormValidationSchema = Yup.object().shape({
    
    additionalDetails: Yup.object().shape({     
        currentChallenges: Yup.string().required("Required"),
        employeeNumber: Yup.number().nullable().typeError("Employee Number must be a number"),
        annualTurnover: Yup.number().required().min(1, "Annual Turnover must be more than £0").typeError("Annual Turnover must be a number"),
      })
})
export { creationFormValidationSchema }