import React from 'react';

import { Container } from "@mui/material";

import { ObligationRiskTable } from "./components/ObligationRiskTable";
import { ReportingHeader } from "../reporting/components/reportingHeader/ReportingHeader";
import { ObligationRiskTableExport } from "./components/ObligationRiskTableExport";

const ObligationRisk: React.FC = () => {
    
    return (
        <Container className={"topBottomGutters"} maxWidth={"xl"}>
            <ReportingHeader 
                name={'Obligation risks'} 
                pdfReportTitle={'Obligations risks'}
                pdfLandscape={true}
                exportable={true}
                pdfComponent={<ObligationRiskTableExport/>}
            >
                <ObligationRiskTable/>
            </ReportingHeader> 
        </Container>
    )
}

export { ObligationRisk }