import { AxiosApi } from "../../axios/axiosApi";
import { FileRoutesResponseDto } from "../../dtos/Questions/FileRoutesResponseDto/models";
import SnackbarUtils from "../../helpers/SnackBar/snackbar";

class QuestionEvidenceService {
    
    private controllerName: string = "api/QuestionEvidence"
    
    public async getFileRoutes(functionId: number, questionId: number): Promise<FileRoutesResponseDto[]> {

        const url: string = `${this.controllerName}/FileRoutes/function/${functionId}/question/${questionId}`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async uploadEvidence(functionId: number, questionId: number, file: File): Promise<void> {
        
        const data: FormData = new FormData();
        data.append("file", file);
        data.append("questionId", questionId.toString());
        data.append("functionId", functionId.toString());
                
        return await AxiosApi.post(this.controllerName, data).then(response => {
            SnackbarUtils.success('Evidence uploaded...');
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async deleteEvidence(functionId: number, questionId: number, evidenceName: string): Promise<void> {

        const url: string = `${this.controllerName}/function/${functionId}/question/${questionId}/evidenceName/${evidenceName}`;

        return await AxiosApi.delete(url).then(response => {
            SnackbarUtils.success('Evidence deleted...');
            return response.data;
        }).catch(e => {
            return [];
        });
    }
}

const questionEvidenceService = new QuestionEvidenceService();
export { questionEvidenceService }