import { Formik, FormikProps } from 'formik';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { NotchedSelect } from "common/components/input/fields/NotchedSelect/NotchedSelect";
import { renderAsMenuItem, renderLookupsAsMenuItem } from "common/helpers/Object/object";
import {
    ObligationRequest,
    ObligationCategoryDto, obligationRequestInitialState
} from "common/dtos/Obligations/models";
import { LookupDto } from "common/services/LookupService/models";
import { lookupService } from "common/services/LookupService/lookupService";
import { nameof } from "ts-simple-nameof";
import { fieldProps } from "common/theming/models";
import { UserDetailsDto } from "common/dtos/Users/models";
import { getUsersAndGroups } from "company/actions";
import { RiskSettingsDto } from "riskLabels/models";
import {
    obligationFormInitialState,
    ObligationFormState,
    obligationFormValidationSchema
} from "obligations/components/ObligationForm/models";
import { QuestionType } from "common/dtos/Questions/QuestionDetails/models";

export interface ObligationFormProps {
    obligationCategory: ObligationCategoryDto;
    riskSettings: RiskSettingsDto[];
    handleClose: () => void;
    handleSubmitForm: (request: ObligationRequest) => Promise<ObligationCategoryDto>;
}

const ObligationForm: React.FC<ObligationFormProps> = ({
                                                           obligationCategory,
                                                           riskSettings,
                                                           handleClose,
                                                           handleSubmitForm
                                                       }) => {

    const [obligationTypes, setObligationTypes] = useState<LookupDto<string>[]>([]);
    const [candidates, setCandidates] = useState<UserDetailsDto[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        lookupService.getObligationTypes().then(response => {
            setObligationTypes(response);
        })
        getUsersAndGroups().then(data => {
            setCandidates(data.map(u => u.user));
        })
    }, [])

    const renderRiskSettings = (): ReactElement[] => {
        
        return riskSettings.map((settings, i) => {

            const nextRiskSetting = riskSettings[i + 1];
            
            const label: string = (nextRiskSetting !== undefined)
                ?`Between £${settings.impactRange} and £${nextRiskSetting.impactRange - 1}`
                : `Above £${settings.impactRange}`;
            
            return (
                <MenuItem value={settings.id} key={i}>{label}</MenuItem>
            )
        })
    }
    
    const onSubmitClicked = useCallback((values: ObligationFormState) => {
        
        setSubmitting(true)
        
        const autoApplied: boolean = obligationCategory.additionalDetails 
            ? obligationCategory.additionalDetails.autoApplied 
            : false;

        handleSubmitForm({
            ...obligationRequestInitialState,
            ...values,
            id: obligationCategory.details.id,
            obligationAdditionalDetailsId: obligationCategory.additionalDetails ? obligationCategory.additionalDetails.id : 0,
            autoApplied: autoApplied,
        }).then(() => {
              handleClose();
        }).finally(() => setSubmitting(false));
    }, [obligationCategory.details.id, handleClose, handleSubmitForm, obligationCategory.additionalDetails])

    return (
        <Formik
            initialValues={{
                ...obligationFormInitialState,
                ...obligationCategory.details,
                ...obligationCategory.additionalDetails,
                obligationTypeId: obligationCategory.details.obligationType.id,
                ownerId: obligationCategory.additionalDetails && obligationCategory.additionalDetails.obligationOwnerId,
                obligationAdditionalDetailsId: obligationCategory.additionalDetails && obligationCategory.additionalDetails.id,
                confidentialityImpactId: obligationCategory.additionalDetails && obligationCategory.additionalDetails.confidentialityImpactId,
                integrityImpactId: obligationCategory.additionalDetails && obligationCategory.additionalDetails.integrityImpactId,
                availabilityImpactId: obligationCategory.additionalDetails && obligationCategory.additionalDetails.availabilityImpactId
            }}
            onSubmit={onSubmitClicked}
            validationSchema={obligationFormValidationSchema}
        >
            {(props: FormikProps<ObligationFormState>) => {

                const {errors, touched, handleSubmit, handleChange, values} = props;
                
                return (
                    <Grid container item spacing={2}>
                        <Grid container item spacing={2}>
                            <Grid item xs={4}>
                                <NotchedSelect
                                    name={nameof<ObligationFormState>(o => o.obligationTypeId)}
                                    label={"Type"}
                                    value={values.obligationTypeId}
                                    onChange={handleChange}
                                    disabled={values.questionTypeId === QuestionType.Asirta}
                                    error={touched.obligationTypeId && Boolean(errors.obligationTypeId)}
                                    helperText={touched.obligationTypeId && errors.obligationTypeId}
                                >
                                    {renderLookupsAsMenuItem(obligationTypes)}
                                </NotchedSelect>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...fieldProps}
                                    name={nameof<ObligationFormState>(e => e.name)}
                                    label={"Name"}
                                    value={values.name}
                                    onChange={handleChange}
                                    disabled={values.questionTypeId === QuestionType.Asirta}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...fieldProps}
                                    name={nameof<ObligationFormState>(e => e.extendedName)}
                                    label={"Common Name"}
                                    value={values.extendedName}
                                    onChange={handleChange}
                                    disabled={values.questionTypeId === QuestionType.Asirta}
                                    error={touched.extendedName && Boolean(errors.extendedName)}
                                    helperText={touched.extendedName && errors.extendedName}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <NotchedSelect
                                    {...fieldProps}
                                    name={nameof<ObligationFormState>(e => e.obligationOwnerId)}
                                    label={"Owner"}
                                    value={values.obligationOwnerId > 0 ? values.obligationOwnerId : ""}
                                    onChange={handleChange}
                                    error={touched.obligationOwnerId && Boolean(errors.obligationOwnerId)}
                                    helperText={touched.obligationOwnerId && errors.obligationOwnerId}
                                >
                                    {renderAsMenuItem(
                                        candidates,
                                        (item: UserDetailsDto) => item.id,
                                        (item: UserDetailsDto) => item.identityProviderUserName ?? item.email,
                                        true
                                    )}
                                </NotchedSelect>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...fieldProps}
                                    name={nameof<ObligationFormState>(e => e.obligationLink)}
                                    label={"Obligation Link"}
                                    value={values.obligationLink}
                                    onChange={handleChange}
                                    error={touched.obligationLink && Boolean(errors.obligationLink)}
                                    helperText={touched.obligationLink && errors.obligationLink}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...fieldProps}
                                    name={nameof<ObligationFormState>(e => e.obligationReference)}
                                    label={"Obligation Reference"}
                                    value={values.obligationReference}
                                    onChange={handleChange}
                                    error={touched.obligationReference && Boolean(errors.obligationReference)}
                                    helperText={touched.obligationReference && errors.obligationReference}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    {...fieldProps}
                                    name={nameof<ObligationFormState>(e => e.description)}
                                    label={"Description"}
                                    value={values.description}
                                    onChange={handleChange}
                                    error={touched.description && Boolean(errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid container item spacing={1}>
                                <Grid item xs={12}><Typography>Confidentiality</Typography></Grid>
                                <Grid item xs={4}>
                                    <NotchedSelect
                                        {...fieldProps}
                                        name={nameof<ObligationFormState>(e => e.confidentialityImpactId)}
                                        label={"Impact"}
                                        value={values.confidentialityImpactId ? values.confidentialityImpactId : ""}
                                        onChange={handleChange}
                                        error={touched.confidentialityImpactId && Boolean(errors.confidentialityImpactId)}
                                        helperText={touched.confidentialityImpactId && errors.confidentialityImpactId}
                                    >
                                        <MenuItem disabled value={""} key={0}>None</MenuItem>
                                        {renderRiskSettings()}
                                    </NotchedSelect>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        {...fieldProps}
                                        name={nameof<ObligationFormState>(e => e.confidentialityJustification)}
                                        label={"Justification"}
                                        value={values.confidentialityJustification}
                                        onChange={handleChange}
                                        error={touched.confidentialityJustification && Boolean(errors.confidentialityJustification)}
                                        helperText={touched.confidentialityJustification && errors.confidentialityJustification}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid item xs={12}><Typography>Integrity</Typography></Grid>
                                <Grid item xs={4}>
                                    <NotchedSelect
                                        {...fieldProps}
                                        name={nameof<ObligationFormState>(e => e.integrityImpactId)}
                                        label={"Impact"}
                                        value={values.integrityImpactId ? values.integrityImpactId : ""}
                                        onChange={handleChange}
                                        error={touched.integrityImpactId && Boolean(errors.integrityImpactId)}
                                        helperText={touched.integrityImpactId && errors.integrityImpactId}
                                    >
                                        <MenuItem disabled value={""} key={0}>None</MenuItem>
                                        {renderRiskSettings()}
                                    </NotchedSelect>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        {...fieldProps}
                                        name={nameof<ObligationFormState>(e => e.integrityJustification)}
                                        label={"Justification"}
                                        value={values.integrityJustification}
                                        onChange={handleChange}
                                        error={touched.integrityJustification && Boolean(errors.integrityJustification)}
                                        helperText={touched.integrityJustification && errors.integrityJustification}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid item xs={12}><Typography>Availability</Typography></Grid>
                                <Grid item xs={4}>
                                    <NotchedSelect
                                        {...fieldProps}
                                        name={nameof<ObligationFormState>(e => e.availabilityImpactId)}
                                        label={"Impact"}
                                        value={values.availabilityImpactId ? values.availabilityImpactId : ""}
                                        onChange={handleChange}
                                        error={touched.availabilityImpactId && Boolean(errors.availabilityImpactId)}
                                        helperText={touched.availabilityImpactId && errors.availabilityImpactId}
                                    >
                                        <MenuItem disabled value={""} key={0}>None</MenuItem>
                                        {renderRiskSettings()}
                                    </NotchedSelect>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        {...fieldProps}
                                        name={nameof<ObligationFormState>(e => e.availabilityJustification)}
                                        label={"Justification"}
                                        value={values.availabilityJustification}
                                        onChange={handleChange}
                                        error={touched.availabilityJustification && Boolean(errors.availabilityJustification)}
                                        helperText={touched.availabilityJustification && errors.availabilityJustification}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={"flex-end"} spacing={1}>
                            <Grid item><Button onClick={handleClose} variant={"outlined"}
                                               className={"error-item-outlined"}>Cancel</Button></Grid>
                            <Grid item>
                                <Button 
                                    variant={"contained"} 
                                    onClick={() => handleSubmit()} 
                                    disableElevation
                                    className={"success-item-filled"}
                                    endIcon={submitting && <CircularProgress size={20} color={"inherit"}/>}
                                >Save Changes</Button></Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export { ObligationForm }