import React, { useCallback, useState } from "react";
import { Pagination } from "common/components/tables/TablePaginator/models";
import { ResponsiveTable } from "common/components/tables/ResponsiveTable/ResponsiveTable";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
    ActivityCategoryDetailsDto,
    activityCategoryInitialState,
    QuestionType
} from "common/dtos/Questions/QuestionDetails/models";
import { ActivityModal } from "activityCategories/components/ActivityModal/ActivityModal";
import { activityCategoryTableDefinition } from "activityCategories/components/ActivitiesTable/models";
import { Permission } from "auth/store/Model";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";

export interface ActivitiesTableProps {
    activityCategories: ActivityCategoryDetailsDto[];
    updateActivityCategory: (updatedActivity: ActivityCategoryDetailsDto) => Promise<void>;
    deleteActivityCategory: (activityCategoryId: number) => Promise<void>;
    loading: boolean;
}

const ActivitiesTable: React.FC<ActivitiesTableProps> = ({ activityCategories, updateActivityCategory, deleteActivityCategory, loading }) => {

    const [pagination, setPagination] = useState<Pagination>({pageNumber: 0, rowsPerPage: 10});
    const [editMode, setEditMode] = useState<boolean>(false);
    const [selectedActivity, setSelectedActivity] = useState<ActivityCategoryDetailsDto>(activityCategoryInitialState);

    const onPageChanged = useCallback((e, newPage) => {
        setPagination(prevState => {
            return {
                ...prevState,
                pageNumber: newPage
            }
        })
    }, [])

    const onRowPerPageChanged = useCallback((e) => {
        setPagination(prevState => {
            return {
                ...prevState,
                rowsPerPage: e.target && e.target.value
            }
        })
    }, [])

    const onEditClicked = useCallback((activity: ActivityCategoryDetailsDto) => {
        setEditMode(true)
        setSelectedActivity(activity)
    }, [setEditMode, setSelectedActivity])

    const onSubmitClicked = useCallback((request: ActivityCategoryDetailsDto): Promise<void>  => {
        return updateActivityCategory(request);
    }, [updateActivityCategory])

    return (
        <React.Fragment>
            <ResponsiveTable
                columns={activityCategoryTableDefinition}
                rows={activityCategories}
                loading={loading}
                totalRows={activityCategories.length}
                onPageChanged={onPageChanged}
                onRowPerPageChanged={onRowPerPageChanged}
                pagination={pagination}
                actions={(row: ActivityCategoryDetailsDto) => {
                    const editable = row.questionTypeId === QuestionType.Custom;
                    return editable ? 
                        <Grid container spacing={1}>
                            <ValidateUserPermission permissions={[Permission.CustomActivitiesEdit]}>
                            <Grid item>
                                <Tooltip title={"Edit"} key={0}>
                                    <IconButton onClick={() => onEditClicked(row)} size={"small"}><FontAwesomeIcon className={"warning-button-outlined"} icon={faPencilAlt}/></IconButton>
                                </Tooltip>
                            </Grid>
                            </ValidateUserPermission>
                            <ValidateUserPermission permissions={[Permission.CustomActivitiesDelete]}>
                            <Grid item>
                                <Tooltip title={"Delete"} key={0}>
                                    <IconButton 
                                        onClick={() => deleteActivityCategory(row.id)} 
                                        size={"small"}
                                    ><FontAwesomeIcon className={"warning-button-outlined"} icon={faTrash}/></IconButton>
                                </Tooltip>
                            </Grid>
                        </ValidateUserPermission>
                        </Grid>
                    : <></>
                }}
            />  
            <ActivityModal
                title={`Edit ${selectedActivity.name}`}
                open={editMode}
                setOpen={setEditMode}
                activityCategory={selectedActivity}
                onSubmit={onSubmitClicked}
            />
        </React.Fragment>
    )
}
export { ActivitiesTable }