import React, { useCallback, useEffect, useState } from 'react';

import { Formik, FormikProps } from 'formik';
import {Button, CircularProgress, Grid, TextField} from "@mui/material";
import { CompanyCreationForm } from "common/components/input/forms/CompanyCreationForm/CompanyCreationForm";
import {
    creationFormValidationSchema
} from "wizards/company/components/CreationForm/models";
import { companyService } from "common/services/CompanyService/companyService";
import { SelectCompanyAction } from "user/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectedCompanySelector } from "user/store/selectors";
import {AdditionalDetailsDto, CompanyDetailsDto, companyDetailsInitialState} from "common/dtos/Companies/models";
import { Permission } from "auth/store/Model";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";
import {fieldProps} from "../../../../common/theming/models";
import {nameof} from "ts-simple-nameof";

const CompanyDetailsEditing: React.FC = () => {

    const [companyFormState, setCompanyFormState] = useState<CompanyDetailsDto>(companyDetailsInitialState);
    const [registered, setRegistered] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);

    const dispatch = useDispatch();

    const company = useSelector(selectedCompanySelector);

    useEffect(() => {
        if (company)
            setCompanyFormState(company)
    }, [company])

    const saveChanges = useCallback((values: CompanyDetailsDto) => {
        setSaving(true);

        companyService.updateCompanyDetails(values).then(company => {
            dispatch(SelectCompanyAction(company))
        }).finally(() => {
            setSaving(false);
        })
    }, [dispatch])

    return (
        <Formik
            initialValues={{
                ...companyFormState,
                additionalDetails: {
                    ...companyFormState.additionalDetails,
                    isRegistered: registered
                }
            }}
            validationSchema={creationFormValidationSchema}
            enableReinitialize
            onSubmit={(values) => {
                saveChanges(values)
            }}
        >
            {({
                  values,
                  handleChange,
                  handleSubmit,
                  setValues,
                  setFieldValue,
                  touched,
                  errors
              }: FormikProps<CompanyDetailsDto>) => {

                // @ts-ignore
                return (
                    <Grid container justifyContent={"flex-end"} spacing={2}>
                        <Grid item container>
                            <CompanyCreationForm registered={registered} isRegistered={setRegistered}
                                                 handleChange={handleChange} setFieldValue={setFieldValue}
                                                 values={values} errors={errors} touched={touched}
                                                 setValues={setValues}
                            />
                        </Grid>
                        <Grid item container>
                            <TextField
                                {...fieldProps}
                                name={`${nameof<CompanyDetailsDto>(c => c.additionalDetails)}.${nameof<AdditionalDetailsDto>(d => d.annualTurnover)}`}
                                label={"Organisation Turnover"}
                                value={values.additionalDetails.annualTurnover}
                                onChange={handleChange}
                                error={touched.additionalDetails?.annualTurnover && Boolean(errors.additionalDetails?.annualTurnover)}
                            />
                        </Grid>
                        <ValidateUserPermission permissions={[Permission.CompanyContextsEdit]}>
                            <Grid item>
                                <Button
                                    variant={"outlined"}
                                    onClick={() => handleSubmit()}
                                    className={"success-item-outlined"}
                                    endIcon={saving &&
                                    <CircularProgress
                                        size={25}
                                        className={"success-item-outlined"}
                                    />
                                    }
                                >Save Changes</Button>
                            </Grid>
                        </ValidateUserPermission>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export { CompanyDetailsEditing }