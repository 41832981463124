import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Container, Grid, Typography } from "@mui/material";
import { ValidateCompanyBillingTier } from "auth/components/ValidateCompanyBillingTier";
import { BillingTier, Permission } from "auth/store/Model";
import { ActivityCategoryDetailsDto, activityCategoryInitialState } from "common/dtos/Questions/QuestionDetails/models";
import { ActivitiesTable } from "activityCategories/components/ActivitiesTable/ActivitiesTable";
import { activityCategoryService } from "common/services/ActivityCategoryService/activityCategoryService";
import { ActivityModal } from "activityCategories/components/ActivityModal/ActivityModal";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "../user/store/selectors";

const ActivityCategories: React.FC = () => {
    const functionDetails = useSelector(selectedFunctionSelector);
    const [activityCategories, setActivityCategories] = useState<ActivityCategoryDetailsDto[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [createMode, setCreateMode] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        activityCategoryService.getActivityCategories().then(response => {
            setActivityCategories(response)
            setLoading(false)
        })
    }, [functionDetails])
    
    const createActivityCategory = useCallback((activity: ActivityCategoryDetailsDto): Promise<void> => {
        return activityCategoryService.createActivityCategory(activity).then(response => {
            setActivityCategories(prevState => {
                let activities = [...prevState, response];
                return activities.sort((a, b) => a.name.localeCompare(b.name));
            });
        })
    }, []);

    const updateActivityCategory = useCallback((activity: ActivityCategoryDetailsDto): Promise<void> => {
        return activityCategoryService.editActivityCategory(activity).then(response => {
            setActivityCategories(prevState => {
                const index = prevState.findIndex(o => o.id === response.id);

                let activities = [ ...prevState ]
                activities[index] = activity;
                return activities;
            })
            
            return Promise.resolve();
        })
    }, []);

    const deleteActivityCategory = useCallback((activityCategoryId: number): Promise<void> => {
        return activityCategoryService.deleteActivityCategory(activityCategoryId).then(_ => {
            setActivityCategories(prevState => prevState.filter(o => o.id !== activityCategoryId))
            return Promise.resolve();
        })
    }, []);
    
    return (
        <ValidateCompanyBillingTier billingTiers={[BillingTier.Silver, BillingTier.Platinum]}>
            <Container className={"topBottomGutters"} maxWidth={'xl'}>
                <Card variant={"outlined"}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            <Typography variant={"h6"}><b>Activities</b></Typography>
                        </Grid>
                        <ValidateUserPermission permissions={[Permission.CustomActivitiesCreate]}>
                            <Grid item>
                                <Button 
                                    variant={"outlined"} 
                                    className={"success-item-outlined"}
                                    onClick={() => setCreateMode(true)}
                                >Create Activity</Button>
                            </Grid>
                        </ValidateUserPermission>
                        <Grid item xs={12}>
                            <ActivitiesTable
                                activityCategories={activityCategories}
                                updateActivityCategory={updateActivityCategory}
                                deleteActivityCategory={deleteActivityCategory}
                                loading={loading}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Container>
            <ValidateUserPermission permissions={[Permission.CustomActivitiesCreate]}>
                <ActivityModal
                    title={"Create New Activity"}
                    open={createMode}
                    setOpen={setCreateMode}
                    activityCategory={activityCategoryInitialState}
                    onSubmit={createActivityCategory}
                />
            </ValidateUserPermission>
        </ValidateCompanyBillingTier>
    );
}
export { ActivityCategories }