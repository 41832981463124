import { AuditDto } from "../Common/audit";
import { UserDetailsDto } from "../Users/models";

export enum HistoryType {
    Unkown = 0,
    QuestionEvidence = 10,
    Comment = 20,
    Answer = 30,
    QuestionUserDelegation = 40
}

export enum HistoryFilter {
    ShowAll,
    ShowCommentsOnly,
    ShowActivityOnly
}

export enum HistoryOrdering {
    Oldest,
    Newest
}

export interface HistoryItem extends AuditDto{
    id: string;
    userDetails: UserDetailsDto;
    type: HistoryType;
    content: string;
    additionalContent: string;
    previousVersion?: HistoryItem
}

export interface HistoryRequest{
    includePreviousVersion: boolean;
    orderByAscending: boolean;
    historySize: number;
}

export interface HistoryFiltering {
    ordering: HistoryOrdering;
    filtering: HistoryFilter;
}

const historyRequestInitialState: HistoryRequest = {
    historySize: 50,
    includePreviousVersion: true,
    orderByAscending: true
}
export { historyRequestInitialState }

const historyFilteringInitialState: HistoryFiltering = {
    filtering: HistoryFilter.ShowAll,
    ordering: HistoryOrdering.Newest
}
export { historyFilteringInitialState }