import React, { useEffect } from 'react';
import { useSelector } from "react-redux";

import { CompanyDetailsDto } from "common/dtos/Companies/models";

import { BillingTier } from "../store/Model";
import {selectedCompanySelector} from "../../user/store/selectors";

interface ValidateCompanyBillingTierProps {
    billingTiers: BillingTier[]
}

const ValidateCompanyBillingTier: React.FC<ValidateCompanyBillingTierProps> = ({ billingTiers, children}) => {

    const [validated, setValidated] = React.useState<boolean>(false);

    const companyBillingTier = useSelector(selectedCompanySelector)

    const requiredBillingTierExists = (billingTiers: BillingTier[], companyBillingTier: CompanyDetailsDto | undefined): boolean => {
        if(companyBillingTier) {
            return billingTiers.some(p => p === companyBillingTier.settings.billingTiersId);
        }
        
        return false
    }

    useEffect( () => {
        setValidated(requiredBillingTierExists(billingTiers, companyBillingTier));
    },[companyBillingTier, billingTiers])

    return (
        <>
            { validated && children }
        </>
    )
}
export { ValidateCompanyBillingTier }