import { LookupDto, lookupDtoInitialState } from "../../services/LookupService/models";

export interface AssetSampleDto {
    id: number;
    assetName: LookupDto<string>;
    assetType: LookupDto<string>;
    assetGroup: LookupDto<string>;
    assetCategory: LookupDto<string>;
    state: boolean;
}

const assetSampleInitialState: AssetSampleDto = {
    id: 0,
    state: true,
    assetName: lookupDtoInitialState,
    assetType: lookupDtoInitialState,
    assetCategory: lookupDtoInitialState,
    assetGroup: lookupDtoInitialState,
}
export { assetSampleInitialState }