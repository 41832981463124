import React, { useCallback, useState } from 'react';

import { Button, Collapse, Divider, Grid, Typography } from "@mui/material";

import "./styles.scss";

export interface CollapsibleSectionProps {
    title: string;
    subtitle?: string;
    divider?: boolean;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({title, subtitle, divider, children}) => {

    const [expand, setExpand] = useState<boolean>(false);
    const [hide, setHide] = useState<boolean>(true);

    const toggleExpand = () => {
        setExpand(!expand);
    }
    
    const onEnter = useCallback(() => {
        setHide(false);
    }, [setHide])
    
    const onExited = useCallback(() => {
        setHide(true)
    }, [setHide])
    
    const buttonText = expand ? "Collapse" : "Expand";
    return (
        <Grid className={"collapsible-section"} container justifyContent={"space-between"} spacing={1}>
            <Grid item>
                <Typography className={"title"} variant={"h6"} color={"textPrimary"} onClick={toggleExpand}>{title}</Typography>
                {subtitle && <Typography color={"textSecondary"}>{subtitle}</Typography>}
            </Grid>
            <Grid item className={"button"}>
                <Button onClick={toggleExpand} variant={"outlined"}>{buttonText}</Button>
            </Grid>
            <Grid container item hidden={hide} display={"block"}>
                <Collapse in={expand} onEnter={onEnter} onExited={onExited}>
                    <Grid container pt={"1.0em"}>
                        {children}
                    </Grid>
                </Collapse>
            </Grid>
            {divider &&
            <Grid item xs={12}>
                <Divider light={false}/>
            </Grid>
            }

        </Grid>
    );
}
export { CollapsibleSection }