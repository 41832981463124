import { UserDetailsDto } from "common/dtos/Users/models";
import * as Yup from "yup";

export interface RaciAssignmentForm {
    responsibleUsers: UserDetailsDto[];
    accountableUsers: UserDetailsDto[];
    consultedUsers: UserDetailsDto[];
    informedUsers: UserDetailsDto[];
}

const raciAssignmentFormInitialState: RaciAssignmentForm = {
    responsibleUsers: [],
    informedUsers: [],
    accountableUsers: [],
    consultedUsers: [],
}
export { raciAssignmentFormInitialState }

function checkClaimedUsers(users: UserDetailsDto[] | undefined) {
    
    if(users === undefined)
        return false;
    
    return users.every(u => u.claimed);
}

const raciAssignmentValidationSchema = Yup.object().shape({
    responsibleUsers: Yup.array(),
    accountableUsers: Yup.array().min(1, "Required").test("isUserClaimed", "User must be claimed", (users: UserDetailsDto[] | undefined) => checkClaimedUsers(users)),
    consultedUsers: Yup.array(),
    informedUsers: Yup.array()
})
export { raciAssignmentValidationSchema }