import React, { ReactElement } from 'react';

import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";

import './styles.scss';
import { nicifyString } from "../../../../helpers/Regex/regex";

export interface ChipSelectorProps {
    label: string;
    options: any[];
    selected: any[];
    getOptionSelected: (option: any, selected: any) => boolean;
    onChange: (selectedOptions: any[]) => void;
}

const ChipSelector: React.FC<ChipSelectorProps> = ({label, options, selected, getOptionSelected, onChange}) => {

    const handleClick = (option: any) => () => {
        onChange(option)
    }
    
    const renderTooltip = (option: any): ReactElement => {
        return (
            <Tooltip  title={option.description}>
                <InfoRounded color={"primary"}/>
            </Tooltip>
        )
    }

    const renderOptions = (): ReactElement[] => {
        return options.map((o: any, index: number) => {
            const isSelected: boolean = getOptionSelected(o, selected);
            const variant = isSelected ? "filled" : "outlined";
            return (
                <Grid item key={`grid-${index}`}>
                    <Chip
                        className={"chip"}
                        key={index} 
                        label={nicifyString(o.value)} 
                        icon={o.description && renderTooltip(o)} 
                        variant={variant} 
                        onClick={handleClick(o)}
                        color={"primary"}/>
                </Grid>
            )
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"h6"}>{label}</Typography>
            </Grid>
            {renderOptions()}
        </Grid>
    )

}
export {ChipSelector}