import React, { useEffect } from "react";
import { Switch } from "react-router";

import { useDispatch, useSelector } from "react-redux";

import { validatedSelector } from "auth/store/selectors";
import { validateUser } from "auth/store/actions";

import { ValidationRoute } from "common/auth/routes/ValidationRoute";

import Layout from "./layout/Layout";
import { Dashboard } from 'dashboard/Dashboard';
import { CompanyWizard } from "wizards/company/CompanyWizard";
import { BusinessFunctionWizard } from "wizards/businessFunctions/BusinessFunctionWizard";
import { CompaniesSettings } from "settings/companies/CompaniesSettings";
import { Questions } from "questions/Questions/Questions";
import { Question } from "questions/Question/Question";
import { PageNotFound } from "status/PageNotFound/PageNotFound";
import { AssetManagement } from "assetManagement/AssetManagement";
import { Asset } from "assetManagement/Asset";
import { Unauthorized } from "status/Unauthorized/Unauthorized";
import { Raci } from "raci/Raci";
import { Obligations } from "obligations/Obligations";
import { Assessment } from "questions/Assessment/Assessment";
import { ObligationRisk } from 'obligationRisks/ObligationRisk';
import { ComplianceBreakdown } from "./complianceBreakdown/ComplianceBreakdown";
import { DetailedActivityRisks } from "./reporting/DetailedActivityRisks";
import { DetailedObligationRisks } from "./reporting/DetailedObligationRisks";
import { HighLevelObligationRiskOverview } from "./reporting/HighLevelObligationRiskOverview";
import { ComplianceOverTime } from "./reporting/ComplianceOverTime"; 
import { QuestionReview } from "./reporting/QuestionReview";
import { AssetOverview } from "assetManagement/AssetOverview/AssetOverview";
import { UsersOverview } from "users/UsersOverview";
import { UserSettings } from "settings/general/UserSettings";
import { FunctionsSettings } from "settings/functions/FunctionsSettings";
import { QuestionImporting } from "questions/QuestionImporting/QuestionImporting";
import { ActivityCategories } from "activityCategories/ActivityCategories";
import { ObligationComplianceOverTime } from "./reporting/ObligationComplianceOverTime";

import "./styles/kendo-theme-custom.scss";
import {GuidedPeerAssessment} from "./assessments/GuidedPeerAssessment/GuidedPeerAssessment";


const App: React.FC = () => {
    const dispatch = useDispatch();
    const validated = useSelector(validatedSelector)

    useEffect(() => {
        dispatch(validateUser());
    }, [validated, dispatch])

    const authenticated = validated ? validated : false;
    const isLoading = validated === undefined;

    return (
        <Layout>
            <Switch>
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/'
                                 component={Dashboard} requiresFunctionContext={false}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/questions'
                                 component={Questions} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/questions/import'
                                 component={QuestionImporting} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path={"/questions/assessment"}
                                 component={Assessment} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path={"/questions/:questionId"}
                                 component={Question} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/obligationRisks'
                                 component={ObligationRisk} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/complianceBreakdown'
                                 component={ComplianceBreakdown} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/reporting/detailedActivityRisks'
                                 component={DetailedActivityRisks} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/reporting/detailedObligationRisks'
                                 component={DetailedObligationRisks} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/reporting/highLevelObligationRiskOverview'
                                 component={HighLevelObligationRiskOverview} requiresFunctionContext={true}/>
                

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/reporting/complianceOverTime'
                                 component={ComplianceOverTime} requiresFunctionContext={true}/>
                
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/reporting/complianceOverTimeByObligation'
                                 component={ObligationComplianceOverTime} requiresFunctionContext={true}/>
                
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path={"/asset/:assetId"}
                                 component={Asset} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path={"/categories/obligations"}
                                 component={Obligations} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path={"/categories/activities"}
                                 component={ActivityCategories} requiresFunctionContext={true}/>
                
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path={"/assets/overview"}
                                 component={AssetOverview} requiresFunctionContext={true}/>
                
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path={"/assets/all"}
                                 component={AssetManagement} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/settings/companies'
                                 component={CompaniesSettings}/>
                
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/settings/user'
                                 component={UserSettings}/>
                
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/settings/functions'
                                 component={FunctionsSettings}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/wizards/company'
                                 component={CompanyWizard}/>
                
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact path='/reporting/questionReview'
                                 component={QuestionReview}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path='/wizards/business-function'
                                 component={BusinessFunctionWizard}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path='/raci-management'
                                 component={Raci}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path={'/users/overview'}
                                 component={UsersOverview} requiresFunctionContext={true}/>

                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path={'/assessments/guided-peer'}
                                 component={GuidedPeerAssessment} requiresFunctionContext={true}/>
                                 
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path='/unauthorized-request'
                                 component={Unauthorized}/>
                                 
                <ValidationRoute authenticated={authenticated} loading={isLoading} exact
                                 path=''
                                 component={PageNotFound}/>
            </Switch>
        </Layout>
    )
};

export { App }
