import React from 'react';
import { Card, Grid } from "@mui/material";
import { CollapsibleSection } from "common/components/sections/CollapsibleSection/CollapsibleSection";

import "./styles.scss";
import { selectedCompanySelector, selectedFunctionSelector } from "user/store/selectors";
import { useSelector } from "react-redux";
import { SectorEditing } from "settings/functions/components/SectorEditing/SectorEditing";
import { FunctionDetailsEditing } from "settings/functions/components/FunctionDetailsEditing/FunctionDetailsEditing";

const FunctionsSettings: React.FC = () => {

    var selectedCompany = useSelector(selectedCompanySelector);
    var selectedFunction = useSelector(selectedFunctionSelector);

    return (
        <Grid className={"topBottomGutters"} justifyContent={"center"} alignContent={"flex-start"} container>
            <Grid item container xs={12} sm={10} md={8} spacing={2}>
                {selectedCompany && selectedFunction &&
                <>
                    <Grid item xs={12}>
                        <Card variant={"outlined"}>
                            <CollapsibleSection
                                title={"Function Details"}
                                subtitle={`View/ Edit information relating to the ${selectedFunction?.name}.`}
                            >
                                <FunctionDetailsEditing/>
                            </CollapsibleSection>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant={"outlined"}>
                            <CollapsibleSection
                                title={"Types & Sectors"}
                                subtitle={`View/ Edit the type and the sectors ${selectedFunction?.name} operates under.`}
                            >
                                <SectorEditing/>
                            </CollapsibleSection>
                        </Card>
                    </Grid>
                </>
                }
            </Grid>
        </Grid>
    );

}
export { FunctionsSettings }