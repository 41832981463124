import {
    ActivityCategoryDetailsDto,
    QuestionDetailsDto,
    questionDetailsDtoInitialState
} from "../Questions/QuestionDetails/models";
import { DelegationType } from "common/dtos/Common/delegation";
import { LookupDto, lookupDtoInitialState } from "common/services/LookupService/models";

export interface UserSettingsDto{
    id: number;
    userId: number;
    defaultCompanyId?: number;
}

const userSettingsInitialState: UserSettingsDto = {
    id: 0,
    userId: 0,
    defaultCompanyId: undefined,
}
export { userSettingsInitialState }

export interface UserDetailsDto {
    id: number;
    identityProviderId: string;
    tenantId: string;
    identityProviderUserName: string;
    firstName: string;
    surname: string;
    email: string;
    claimed: boolean;
}

const userDetailsInitialState: UserDetailsDto = {
    id: 0,
    identityProviderId: "",
    tenantId: "",
    identityProviderUserName: "",
    firstName: "",
    surname: "",
    email: "",
    claimed: false
}
export { userDetailsInitialState }

export interface UserDelegationDto {
    id: number;
    userDetailsId: number;
    user: UserDetailsDto;
    delegationId: DelegationType;
    delegation: LookupDto<string>;
}

export interface UserNotification {
    icon: NotificationType;
    title: string;
    message: string;
    dismissed: boolean;
    link?: string;
}

export interface DelegatedQuestionDto extends UserDelegationDto {
    questionDetailsId: number;
    questionDetails: QuestionDetailsDto;
}

export interface DelegatedActivityCategoryDto extends UserDelegationDto {
    activityCategoryDetailsId: number;
    activityCategory: ActivityCategoryDetailsDto;
}

const delegatedUserInitialState: DelegatedQuestionDto = {
    id: 0,
    userDetailsId: 0,
    user: userDetailsInitialState,
    questionDetailsId: 0,
    questionDetails: questionDetailsDtoInitialState,
    delegationId: DelegationType.Accountable,
    delegation: lookupDtoInitialState
}
export { delegatedUserInitialState }

export enum NotificationType {
    ALERT
}