import { AxiosApi } from "../../axios/axiosApi";
import { LookupDto } from "./models";

class LookupService {
    private controllerName: string = "api/Lookup";

    public async getUserRoles(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetUserRoles`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async getTypes(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/getTypes`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getSectors(includeMainSectors: boolean = true, filterBasedOnFunction: boolean = false): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/getSectors?includeMainSectors=${includeMainSectors}&filterBasedOnFunction=${filterBasedOnFunction}`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getRiskLabelTypes(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetRiskLabelTypes`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async getThirdPartyManaged(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetThirdPartyManaged`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getConductActivity(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetConductActivity`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getActivityFrequency(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetActivityFrequency`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getComplianceComplexity(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetComplianceComplexity`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getImplementationEffectiveness(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetImplementationEffectiveness`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getObligationTypes(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetObligationTypes`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async getUserPermissions(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetUserPermissions`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getBillingTiers(): Promise<LookupDto<string>[]> {
        const url: string = `${this.controllerName}/GetBillingTiers`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
}

const lookupService = new LookupService();
export { lookupService }