import { ColumnDefinition, ColumnType } from "common/components/tables/TableDefinition/ColumnDefinition";
import { ActivityComplianceRating } from "common/views/ActivityComplianceRating";
import { nameof } from "ts-simple-nameof";

export const activityComplianceRatingTableColumns: ColumnDefinition[] = [
    {
        key: 0,
        name: "Activity Name",
        field: nameof<ActivityComplianceRating>(m => m.activity),
        hidden: false,
        type: ColumnType.Default,
        displayLabel: true,
        width: ""
    },
    {
        key: 1,
        name: "Response By",
        field: nameof<ActivityComplianceRating>(m => m.lastResponseBy),
        hidden: false,
        type: ColumnType.Default,
        displayLabel: true,
        width: ""
    },
    {
        key: 2,
        name: "Response Date",
        field: nameof<ActivityComplianceRating>(m => m.lastResponse),
        hidden: false,
        type: ColumnType.DateTime,
        displayLabel: true,
        width: ""
    },
    {
        key: 3,
        name: "Compliance",
        field: nameof<ActivityComplianceRating>(m => m.compliancePercentage),
        hidden: false,
        type: ColumnType.Percentage,
        displayLabel: true,
        width: ""
    }
]