import React, { useEffect, useState } from 'react';

import { TitleCard } from "common/components/panels/TitleCard/TitleCard";
import { reportingService } from "common/services/ReportingService/reportingService";

import { RadarChart } from "./components/charts/radarChart/RadarChart";
import { DashboardFunctionProps } from "../dashboard/Dashboard";

const ObligationStatusDistribution: React.FC<DashboardFunctionProps> = ({ selectedFunction }) => {
    const [obligationStatusData, setObligationStatusData] = useState<any[]>([]);
    const [obligationNames, setObligationNames] = useState<string[]>([]);

    useEffect(() => {
        reportingService.getObligationStatusDistribution().then(response => {
            const obligationStatus = response && response.trackingYourComplianceScore.map((status) => {

                let obligations = {};

                status.obligations
                    .forEach((a: any) => {
                        obligations = {
                            ...obligations,
                            status: status.status,
                            [a.obligation]: a.answers
                        }
                    })

                return {
                    ...obligations
                };
            });

            setObligationStatusData(obligationStatus);
            setObligationNames(response.obligationNames)
        })
    }, [setObligationStatusData, selectedFunction])

        return (
        <TitleCard
            title={'Distribution of answers by status'}
            height={330}
            linkIcon={true}
            link={'reporting/detailedObligationRisks'}
            hoverOver={'View how your key Obligations are mapped against maturity'}
        >
            <RadarChart data={obligationStatusData} keyList={obligationNames} />
        </TitleCard>
    )
}
export { ObligationStatusDistribution }