import {
    ActionTypes,
    SET_DEFAULT_COMPANY,
    SET_SELECTED_COMPANY,
    SET_SELECTED_FUNCTION,
    SET_SELECTED_FUNCTION_LOADING,
    SET_USER,
    SET_USER_NOTIFICATIONS,
    SET_USER_SETTINGS
} from "./actionTypes";
import { NotificationType, UserDetailsDto, UserNotification, UserSettingsDto } from "common/dtos/Users/models";
import { userService } from "common/services/UserService/userService";
import { CompanyDetailsDto, CompanyUserDto } from "common/dtos/Companies/models";
import { companyService } from "common/services/CompanyService/companyService";
import { functionService } from "common/services/FunctionService/functionService";
import { FunctionDetailsDto } from "common/dtos/Businesses/FunctionDetailsDto";
import { CreateUnclaimedUserRequest } from "common/services/UserService/models";
import { ApplicationState } from "store";
import { getFunctionObligationCategories } from "questions/Question/actions";
import { BillingTier } from "auth/store/Model";
import { UserRoles } from "common/dtos/Common/userRoles";

export function SetUserAction(user: UserDetailsDto): ActionTypes {
    return {
        type: SET_USER,
        payload: user
    }
}

export function SetUserSettingsAction(userSettings: UserSettingsDto): ActionTypes {
    return {
        type: SET_USER_SETTINGS,
        payload: userSettings
    }
}

export function SelectDefaultCompanyAction(companyId: number): ActionTypes {
    return {
        type: SET_DEFAULT_COMPANY,
        payload: companyId
    }
}

export function SelectCompanyAction(company: CompanyDetailsDto | undefined): ActionTypes {
    return {
        type: SET_SELECTED_COMPANY,
        payload: company
    }
}

export function SelectFunctionAction(functionDetails: FunctionDetailsDto | undefined): ActionTypes {
    return {
        type: SET_SELECTED_FUNCTION,
        payload: functionDetails
    }
}

export function SelectFunctionLoadingAction(loading: boolean): ActionTypes {
    return {
        type: SET_SELECTED_FUNCTION_LOADING,
        payload: loading
    }
}

export function SetUserNotificationAction(notifications: UserNotification[]): ActionTypes {
    return {
        type: SET_USER_NOTIFICATIONS,
        payload: notifications
    }
}

export function selectCompany(companyId: number) {
    return async (dispatch: any, getState: () => ApplicationState) => {
        await companyService.getCompanyById(companyId).then(async company => {
            await dispatch(SelectCompanyAction(company))
            await dispatch(selectDefaultFunction());

            const user = await companyService.getCompanyUser(companyId, getState().user.user.id);
            const billing = company.settings.billingTiersId;

            if (user.roleId === UserRoles.Admin && (billing === BillingTier.Silver || billing === BillingTier.Platinum))
                getFunctionObligationCategories().then((response) => {
                    if (response.obligationCategory.some(o => o.additionalDetails === null)) {
                        dispatch(notifyUser({
                            icon: NotificationType.ALERT,
                            title: `Unassigned Obligations`,
                            message: `There are Obligations waiting to be assigned for ${company.name}`,
                            dismissed: false,
                            link: "/categories/obligations"
                        }));
                    }
                })
        })
    }
}

export function updateCompanyBillingTier(billingTierId:number) {
    
    return async (dispatch: any, getState: () => ApplicationState) => {
        
        const selectedCompany = getState().user.selectedCompany;
        
        if(selectedCompany === undefined)
            return;
        
        await companyService.updateBillingTier(billingTierId).then(async billingTier => {
            let company: CompanyDetailsDto = {...selectedCompany};
            
            company.settings.billingTiersId = billingTier.id;
            company.settings.billingTiers = billingTier;
            
            dispatch(SelectCompanyAction(company));
        });
    }
}

export function selectDefaultCompany(companyId: number) {
    return async (dispatch: any) =>
        await userService.setDefaultCompany(companyId).then(async _ => {
            await dispatch(SelectDefaultCompanyAction(companyId));
        })
}

export function selectFunction(functionDetails: FunctionDetailsDto | undefined) {
    return async (dispatch: any) => {
        await dispatch(SelectFunctionAction(functionDetails))
    }
}

export function selectDefaultFunction() {
    return async (dispatch: any) => {
        await functionService.getSelectCompanyDefaultFunction().then(async fd => {
            await dispatch(selectFunction(fd));
        })
    }
}

export function getUsersByName(name: string): Promise<UserDetailsDto[]> {
    return userService.getUsersByName(name);
}

export function inviteUsersToCompany(request: CreateUnclaimedUserRequest): Promise<CompanyUserDto[]> {
    return userService.inviteUsersToCompany(request);
}

export function isEmailUnique(email: string): Promise<boolean> {
    return userService.isEmailUnique(email);
}

export function notifyUser(notification: UserNotification) {
    return async (dispatch: any, getState: () => ApplicationState) => {

        const notificationExists = getState().user.notifications.some((n) => n.message === notification.message);

        if (notificationExists && notification.title !== "Test Notification")
            return;

        await dispatch(SetUserNotificationAction([...getState().user.notifications, notification]))
    }
}

export function dismissNotifcation(index: number) {
    return async (dispatch: any, getState: () => ApplicationState) => {
        const notifications: UserNotification[] = [...getState().user.notifications];
        notifications[index].dismissed = true;

        await dispatch(SetUserNotificationAction(notifications));
    }
}