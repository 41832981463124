import React from 'react';
import { nicifyString } from "../../../../../helpers/Regex/regex";
import moment from "moment";
import { Typography } from "@mui/material";
import { HistoryActivityComponentProps } from "./models";
import { getNameFromUser } from "common/helpers/Naming/naming";

const HistoryAssignment: React.FC<HistoryActivityComponentProps> = ({historyItem}) => {
    return (
        <Typography variant={"body2"}>
            <b>{getNameFromUser(historyItem.userDetails)}</b> was assigned to <b>{nicifyString(historyItem.content)}</b> by {historyItem.createdBy} {moment.utc(historyItem.creationDate).fromNow()}
        </Typography>
    )
}
export { HistoryAssignment }