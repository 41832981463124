import React, { useEffect, useState } from 'react';

import { Grid } from "@mui/material";

import { ChipSelector } from "../../fields/ChipSelector/ChipSelector";
import { LookupDto } from "../../../../services/LookupService/models";
import { lookupService } from "../../../../services/LookupService/lookupService";

import "./styles.scss";

export interface TypeSelectorProps {
    label: string,
    selectedTypes: any[];
    onChange: (option: any) => void;
    errorText: string;
}

const TypeSelector: React.FC<TypeSelectorProps> = ({label, selectedTypes, onChange, errorText}) => {

    const [types, setTypes] = useState<any[]>([]);
    
    useEffect(() => {
        lookupService.getTypes().then((response) => {
            setTypes(response)
        })
    }, [])

    const getOptionSelected = (option: LookupDto<string>, selected: LookupDto<string>[]) => selected.some(opt => opt.id === option.id);

    const validateType = (option: any) => {
        
        const isAlreadyEnabled: boolean = selectedTypes.some(opt => opt.id === option.id);

        let selectedOptions = isAlreadyEnabled ?
            selectedTypes.filter(o => o.id !== option.id) :
            [...selectedTypes, option];

        onChange(selectedOptions);
    }
    
    return (
        <Grid container>
            <ChipSelector label={label} options={types} selected={selectedTypes} getOptionSelected={getOptionSelected} onChange={validateType}/>
            <p className={"error"}>{errorText}</p>
        </Grid>
        
    )
}
export { TypeSelector }