import { UserDetailsDto, userDetailsInitialState } from "../Users/models";
import { FunctionDetailsDto, functionDetailsInitialState } from "../Businesses/FunctionDetailsDto";
import { AssetSampleDto, assetSampleInitialState } from "./AssetSampleDto";
import { LinkedObligationsDto } from "./LinkedObligationsDto";
import { AuditDto } from "../Common/audit";

export interface AssetDetailsDto extends AuditDto {
    id: number;
    alias: string;
    assetSampleId: number;
    assetSample: AssetSampleDto;
    confidentiality: number;
    confidentialityJustification: string;
    integrity: number;
    integrityJustification: string;
    availability: number;
    availabilityJustification: string;
    accountableOwner: UserDetailsDto;
    responsibleOwner: UserDetailsDto;
    state: boolean;
    functionDetails: FunctionDetailsDto;
    linkedObligations: LinkedObligationsDto[];
}

const assetDetailsInitialState: AssetDetailsDto = {
    id: 0,
    alias: "",
    state: true,
    availability: 0,
    integrity: 0,
    confidentiality: 0,
    availabilityJustification: "",
    confidentialityJustification: "",
    integrityJustification: "",
    accountableOwner: userDetailsInitialState,
    responsibleOwner: userDetailsInitialState,
    assetSampleId: 0,
    assetSample: assetSampleInitialState,
    functionDetails: functionDetailsInitialState,
    linkedObligations: [],
    createdBy: "System",
    creationDate: new Date(),
    lastUpdatedDate: new Date(),
    lastUpdatedBy: "System"
    
}
export { assetDetailsInitialState }