import React, { useState } from 'react';

import { Button, Grid } from "@mui/material";

import { SectorSelector } from "common/components/input/selectors/SectorSelector/SectorSelector";
import { WizardStepProps } from "../../../models";
import { AssignSectorsText } from "common/helpers/HelperText/HelperTexts";

const AssignSectors: React.FC<WizardStepProps> = ({onBackClicked, onContinueClicked, object}) => {
    
    const [selectedSectors, setSelectedSectors] = useState<any[]>(object.sectors);
        
    const onClick = () => {
        onContinueClicked({
            ...object,
            sectors: selectedSectors
        })
    }
    
    return (
        <Grid xs={10} item container spacing={2}>
            <Grid item xs={12}>
                { AssignSectorsText }
            </Grid>
                        
            <Grid item xs={12}>
                <SectorSelector
                    label={`What sectors does ${object.company.name} operate under?`}
                    selectedSectors={selectedSectors}
                    onChange={setSelectedSectors}
                />
            </Grid>
            
            <Grid container item xs={12}>
                <Grid container item xs={2} justifyContent={"flex-start"}>
                    <Grid item>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            onClick={onBackClicked}
                        >Previous</Button>
                    </Grid>
                </Grid>
                <Grid container xs={10} item justifyContent={"flex-end"}>
                    <Grid item>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            onClick={onClick}
                        >Continue</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export { AssignSectors }