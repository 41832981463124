import { Formik, FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { nameof } from "ts-simple-nameof";
import { fieldProps } from "common/theming/models";
import { ActivityCategoryDetailsDto } from "common/dtos/Questions/QuestionDetails/models";
import { activityFormValidationSchema } from "activityCategories/components/ActivityForm/models";

export interface ActivityFormProps {
    activityCategory: ActivityCategoryDetailsDto;
    handleClose: () => void;
    handleSubmit: (value: ActivityCategoryDetailsDto) => Promise<void>;
}

const ActivityForm: React.FC<ActivityFormProps> = ({
                                                       activityCategory,
                                                       handleClose,
                                                       handleSubmit
                                                   }) => {
    
    const [submitting, setSubmitting] = useState<boolean>(false);

    const onSubmitClicked = useCallback((values: ActivityCategoryDetailsDto) => {

        setSubmitting(true)

        handleSubmit(values).then(() => {
            handleClose();
        }).finally(() => setSubmitting(false));
    }, [handleClose, handleSubmit])

    return (
        <Formik
            initialValues={activityCategory}
            onSubmit={onSubmitClicked}
            validationSchema={activityFormValidationSchema}
        >
            {(props: FormikProps<ActivityCategoryDetailsDto>) => {

                const {errors, touched, handleSubmit, handleChange, values} = props;
                
                return (
                    <Grid container item spacing={2}>
                        <Grid container item spacing={2}>
                            <Grid item xs={5}>
                                <TextField
                                    {...fieldProps}
                                    name={nameof<ActivityCategoryDetailsDto>(e => e.name)}
                                    label={"Name"}
                                    value={values.name}
                                    onChange={handleChange}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    {...fieldProps}
                                    name={nameof<ActivityCategoryDetailsDto>(e => e.extendedName)}
                                    label={"Extended Name"}
                                    value={values.extendedName}
                                    onChange={handleChange}
                                    error={touched.extendedName && Boolean(errors.extendedName)}
                                    helperText={touched.extendedName && errors.extendedName}
                                />
                            </Grid>
                        </Grid>
                            <Grid container item justifyContent={"flex-end"} spacing={1}>
                                <Grid item><Button onClick={handleClose} variant={"outlined"}
                                                   className={"error-item-outlined"}>Cancel</Button></Grid>
                                <Grid item>
                                    <Button
                                        variant={"contained"}
                                        onClick={() => handleSubmit()}
                                        disableElevation
                                        className={"success-item-filled"}
                                        endIcon={submitting && <CircularProgress size={20} color={"inherit"}/>}
                                    >Save Changes</Button></Grid>
                            </Grid>
                        </Grid>
                );
                        }}
                    </Formik>
    );
            }
                export {ActivityForm}