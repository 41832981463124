import React, { ReactElement } from "react";
import { StatusPage } from 'common/components/pages/StatusPage/StatusPage';

const ComingSoon: React.FC = () => {

  const renderMessage = (): ReactElement<any> => {
    return (
      <div>
        <p>This page isn't quite ready yet!</p>
        <p>We're still tinkering things on our end, keep an eye out for updates.</p>
      </div>   
    )
  }

  const renderNotice = (): ReactElement<any> => {
    return (
      <div>
        <p>We'll notify you when you can checkout this shiny new page!</p>
      </div>
    )
  }

    return (
      <StatusPage
        title={"Coming Soon"}
        message={renderMessage}
        notice={renderNotice}
      />
    );
  };
  export { ComingSoon };