import React from 'react';
import { Button, Grid, Paper, Typography } from "@mui/material";

import "./styles.scss";
import { nicifyString } from "../../../../../helpers/Regex/regex";
import { HistoryFilter } from "../../../../../dtos/History/models";
import { VerticalLine } from "../../../../misc/VerticalLine/VerticalLine";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

export interface HistoryFilterWarningProps {
    showLine: boolean
    onShowCommentsClicked: () => void;
    onShowAllClicked: () => void;
}

const HistoryFilterWarning: React.FC<HistoryFilterWarningProps> = ({showLine, onShowCommentsClicked, onShowAllClicked}) => {

    return (
        <React.Fragment>
            { showLine && <VerticalLine/> }
            <Paper variant={"outlined"} className={"paper"}>
                <Grid container>
                    <Grid item>
                        <Grid item className={"activityLabel"}>
                            <FontAwesomeIcon
                                icon={faExclamation}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={"activityContent"} spacing={1}>
                        <Grid item>
                            <Typography align={"center"}>
                                You're currently filtering by <b>Activity Only</b>, to make a comment select one of the
                                following options:
                            </Typography>
                        </Grid>
                        <Grid container item justifyContent={"center"} spacing={1}>
                            <Grid item>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"small"}
                                    onClick={onShowAllClicked}
                                >{nicifyString(HistoryFilter[HistoryFilter.ShowAll])}</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"small"}
                                    onClick={onShowCommentsClicked}
                                >{nicifyString(HistoryFilter[HistoryFilter.ShowCommentsOnly])}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
}
export { HistoryFilterWarning }