import React, { useEffect, useState } from 'react';
import { TitleCard } from "common/components/panels/TitleCard/TitleCard";
import { activityComplianceRatingTableColumns } from "dashboard/components/ActivityComplianceRatingTable/models";
import { reportingService } from "common/services/ReportingService/reportingService";
import { ResponsiveTable } from "common/components/tables/ResponsiveTable/ResponsiveTable";
import { ActivityComplianceRating } from "common/views/ActivityComplianceRating";
import { DashboardFunctionProps } from "../../Dashboard";

const ActivityComplianceRatingTable: React.FC<DashboardFunctionProps> = ({ selectedFunction })  => {
    
    const [activityComplianceRatings, setActivityComplianceRatings] = useState<ActivityComplianceRating[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        reportingService.getActivityComplianceRatingSummary().then(metrics => {
            setActivityComplianceRatings(metrics)
        }).finally(() => setLoading(false))
    }, [selectedFunction])

    const data = activityComplianceRatings && activityComplianceRatings
        .sort((a,b) => (a.questionsTotal - a.compliancePercentage  <= b.questionsTotal - b.compliancePercentage ) ? 1 : -1)
        .slice(0,5)
    
    return (
        <TitleCard
            title={"Top 5 obligation risks"}
            linkIcon={true}
            link={'reporting/detailedActivityRisks'}
            hoverOver={'Track your progress against obligation questions remaining, to give you a better overview, of what your estate risks are.'}
        >
            <ResponsiveTable
                columns={activityComplianceRatingTableColumns}
                rows={data}
                totalRows={data.length}
                loading={loading}
            />
        </TitleCard>
    )
}
export { ActivityComplianceRatingTable }