import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import { Checkbox, Grid } from "@mui/material";
import {
    Grid as GridTable,
    GridCellProps,
    GridColumn,
    GridColumnMenuFilter,
    GridDataStateChangeEvent,
    GridPageChangeEvent,
    GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { orderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";
import { nameof } from "ts-simple-nameof";
 
import { ObligationRiskDto } from "../store/models";
import { RatingGauge } from "common/components/content/RatingGauge/RatingGauge";
import { lookupService } from "common/services/LookupService/lookupService";
import { getRiskLabelDescription } from "common/helpers/HelperText/getRiskLabelDescription";
import { LookupDto } from "common/services/LookupService/models";
import {TrackedQuestionGroupDto, TrackQuestionGroupsDto} from "common/dtos/Questions/TrackedQuestionGroupDto/models";
import {
    getObligationRisks,
    getTrackedQuestionGroups,
} from "../store/actions";

import "./styles.scss";
import {trackQuestionGroups} from "../../complianceBreakdown/store/actions";

const ObligationRiskTable: React.FC = () => {

    const initialSort: Array<SortDescriptor> = [
        { field: "obligation", dir: "asc" },
        { field: "penalty", dir: "asc" }
    ];

    const [dataState, setDataState] = useState<State>({skip: 0, take: 20});
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [obligationRisk, setObligationRisk] = useState<ObligationRiskDto[]>([]);
    const [trackedQuestionGroups, setTrackedQuestionGroups] = useState<TrackedQuestionGroupDto[]>([]);
    const [riskLabelTypes, setRiskLabelTypes] = useState<LookupDto<string>[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const skip: number = dataState.skip!;
    const take: number = dataState.take!;

    useEffect(() => {
        getObligationRisks().then((response) => {
            setObligationRisk(response);
        })

        getTrackedQuestionGroups().then(response => {
            setTrackedQuestionGroups(response);
        })

    }, [setObligationRisk, setTrackedQuestionGroups])

    useEffect(() => {
        lookupService.getRiskLabelTypes().then((response) => {
            setRiskLabelTypes(response);
        })
    }, [])

    const renderCheckbox = (props: GridCellProps): ReactElement => {
        const isTracked = props.dataItem.questionGroupId.every((t: number) => trackedQuestionGroups.some(c => c.questionGroupId === t))
        return <td><Checkbox disabled={saving} checked={isTracked} onClick={onClick(props)}/></td>
    }

    const onClick = useCallback((props: GridCellProps) => (event: ChangeEvent<any>) => {
        const checked: boolean = event.target.checked;
        const questionGroupId: number[] = props.dataItem.questionGroupId;

        setSaving(true);

        const trackedGroups: TrackQuestionGroupsDto = {
            ids: questionGroupId,
            track: checked
        }

        trackQuestionGroups(trackedGroups).then((groups: TrackedQuestionGroupDto[]) => {
            const trackedGroups = checked
                ? [...trackedQuestionGroups, ...groups]
                : trackedQuestionGroups.filter(t => !groups.some(g => g.questionGroupId === t.questionGroupId))

            setTrackedQuestionGroups(trackedGroups)
        }).finally(() => setSaving(false));
    }, [setTrackedQuestionGroups, trackedQuestionGroups])

    const onPageChange = useCallback((event: GridPageChangeEvent) => {
        setDataState({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take
        })
    }, [setDataState, dataState])

    const onDataStateChange =  useCallback((event: GridDataStateChangeEvent) => {
        setDataState(event.dataState);
        setObligationRisk((event.dataState.filter !== null)
            ? process([...obligationRisk], event.dataState).data
            : obligationRisk);
    },[setDataState, setObligationRisk, obligationRisk])

    const onSortChange = useCallback((event: GridSortChangeEvent) => {
        setSort(event.sort);
    },[setSort])
    
    return (
        <Grid container spacing={2}>
            <Grid item>
                <GridTable
                    data={orderBy(obligationRisk, sort).slice(skip, take + skip)}
                    {...dataState}
                    onPageChange={onPageChange}
                    onDataStateChange={onDataStateChange}
                    onSortChange={onSortChange}
                    total={obligationRisk.length}
                    pageable={true}
                    sortable={true}
                    sort={sort}
                >
                    <GridColumn field={nameof<ObligationRiskDto>(a => a.obligation)} title={"Obligation"} filter={"text"}  width={"250px"}
                                columnMenu={GridColumnMenuFilter} />

                    <GridColumn field={nameof<ObligationRiskDto>(a => a.penalty)} title={"Penalty"} filter={"text"}
                                columnMenu={GridColumnMenuFilter}
                    />
                    <GridColumn field={nameof<ObligationRiskDto>(a => a.exposure)} title={"Exposure"} filter={"text"}  width={"450px"}
                                columnMenu={GridColumnMenuFilter}
                    />
                    <GridColumn field={nameof<ObligationRiskDto>(a => a.rating)} title={"Risk Rating"}
                                width={"150px"}
                                cell={props => <td><RatingGauge rating={(props.dataItem.rating)}/></td>}
                    />
                    <GridColumn field={nameof<ObligationRiskDto>(a => a.rating)} title={"Effect"}
                                width={"250px"}
                                cell={props => <td>{getRiskLabelDescription(riskLabelTypes, Number(props.dataItem.rating))}</td>}
                    />                    
                    <GridColumn field={"Selected"} title={"Track"} cell={props => renderCheckbox(props)}
                                width={"100px"}/>
                </GridTable>
                
            </Grid>            
        </Grid>
    )
}
export { ObligationRiskTable }