import React, { useCallback, useRef } from 'react';
import { Button, Grid, Typography } from "@mui/material";
import { WizardStepProps } from "wizards/models";
import { OfficerDto } from "wizards/company/components/CreationForm/models";
import { OfficerSelectionForm } from "common/components/input/forms/OfficerSelectionForm/OfficerSelectionForm";

const OfficerSelection: React.FC<WizardStepProps> = ({onContinueClicked, onBackClicked, object}) => {
    
    const formRef = useRef<any>();

    const onSubmit = useCallback((officers: OfficerDto[]): void => {

        onContinueClicked({
            ...object,
            company: {
                ...object.company,
                officers: officers
            }
        });

    }, [object, onContinueClicked])

    const onNext = useCallback(() => {
        formRef.current?.handleSubmit();
    }, [formRef])

    return (
        <React.Fragment>
            <Grid xs={10} container item alignItems={"center"} spacing={1}>
                <Grid item>
                    <Typography variant={"h6"}>Manage Officers</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color={"textSecondary"}>{`Manage the Officers of ${object.company.name}`}</Typography>
                </Grid>
            </Grid>
            <Grid xs={10} container item>
                <OfficerSelectionForm
                    initialState={{officers: object.company.officers}}
                    formRef={formRef} 
                    onSubmit={onSubmit}
                />
            </Grid>
            <Grid item xs={10} container justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
                <Grid item>
                    <Button
                        color={"primary"}
                        variant={"outlined"}
                        onClick={onBackClicked}
                    >Previous</Button>
                </Grid>
                <Grid item>
                    <Button
                        color={"primary"}
                        variant={"outlined"}
                        onClick={onNext}
                    >Next</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export { OfficerSelection }