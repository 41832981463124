import React, { RefObject, useCallback, useEffect, useState } from 'react';

import { Grid } from "@mui/material";

import { Formik, FormikProps } from 'formik';
import { nameof } from "ts-simple-nameof";

import { RaciUserList } from "./components/RaciUserList/RaciUserList";

import { ActivityCategoryDetailsDto } from "common/dtos/Questions/QuestionDetails/models";
import { DelegationType } from "common/dtos/Common/delegation";
import { DelegatedActivityCategoryDto, UserDetailsDto } from "common/dtos/Users/models";
import { RaciAssignmentForm, raciAssignmentFormInitialState, raciAssignmentValidationSchema } from "./models";
import { raciHelper } from "../raciHelper";

import "./styles.scss";
import { useValidPermission } from "auth/components/ValidateUserPermission";
import { Permission } from "auth/store/Model";

export interface RaciAssignmentProps {
    activityCategory: ActivityCategoryDetailsDto;
    raciChanges: DelegatedActivityCategoryDto[];
    userDelegations: DelegatedActivityCategoryDto[];
    onSubmit: (request: DelegatedActivityCategoryDto[], activityCategoryId: number) => Promise<void>;
    getUsersByName: (query: string) => Promise<UserDetailsDto[]>;
    formRef: RefObject<any>;
    ignorePermissions: boolean;
}

const RaciDelegation: React.FC<RaciAssignmentProps> = ({
                                                           activityCategory,
                                                           raciChanges,
                                                           userDelegations,
                                                           onSubmit,
                                                           getUsersByName,
                                                           formRef,
                                                           ignorePermissions
                                                       }) => {

    const userCanEditAccountable = !useValidPermission(Permission.RACIAccountableEdit);
    const userCanEditConsulted = !useValidPermission(Permission.RACIConsultedEdit);
    const userCanEditInformed= !useValidPermission(Permission.RACIInformedEdit);
    const userCanEditResponsible = !useValidPermission(Permission.RACIResponsibleEdit);
    
    const [formInitialState, setFormInitialState] = useState<RaciAssignmentForm>(raciAssignmentFormInitialState);

    useEffect(() => {

        if (raciChanges.length === 0) {
            setFormInitialState(raciHelper.delegatedActivityCategoryDtoToFormState(userDelegations.filter(c => c.activityCategoryDetailsId === activityCategory.id)))
            return;
        }

        const delegationChanges = raciChanges.filter(c => c.activityCategoryDetailsId === activityCategory.id);

        setFormInitialState(raciHelper.delegatedActivityCategoryDtoToFormState(delegationChanges))

    }, [activityCategory.id, raciChanges, userDelegations]);

    const saveRaciDelegationChanges = useCallback((additions: RaciAssignmentForm) => {
        const changes: DelegatedActivityCategoryDto[] = [
            ...raciHelper.usersToDelegatedActivityCategoryDto(additions.accountableUsers, DelegationType.Accountable, activityCategory),
            ...raciHelper.usersToDelegatedActivityCategoryDto(additions.consultedUsers, DelegationType.Consulted, activityCategory),
            ...raciHelper.usersToDelegatedActivityCategoryDto(additions.informedUsers, DelegationType.Informed, activityCategory),
            ...raciHelper.usersToDelegatedActivityCategoryDto(additions.responsibleUsers, DelegationType.Responsible, activityCategory),
        ]

        onSubmit(changes, activityCategory.id).then(() => {
            setFormInitialState(additions);
        })
        
    }, [activityCategory, onSubmit])

    return (
        <React.Fragment>
            <Formik
                initialValues={formInitialState}
                enableReinitialize
                onSubmit={saveRaciDelegationChanges}
                validateOnChange={false}
                validationSchema={raciAssignmentValidationSchema}
                innerRef={formRef}
            >
                {(props: FormikProps<RaciAssignmentForm>) => {

                    const {errors, touched, handleSubmit, setFieldValue, setFieldTouched, values} = props;
                    
                    return (
                        <Grid container item spacing={2}>
                            <Grid container item spacing={2}>
                                <Grid item xs={6}>
                                    <RaciUserList
                                        name={nameof<RaciAssignmentForm>(f => f.responsibleUsers)}
                                        title={"Responsible"}
                                        subtitle={"Users are able to view, edit and assign questions to other delegates"}
                                        delegatedUsers={values.responsibleUsers}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        onMenuClosed={handleSubmit}
                                        getUsersByName={getUsersByName}
                                        readonly={ignorePermissions ? false : userCanEditResponsible}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <RaciUserList
                                        name={nameof<RaciAssignmentForm>(f => f.accountableUsers)}
                                        title={"Accountable"}
                                        subtitle={"User is fully accountable and has full ownership and control of the questions"}
                                        delegatedUsers={values.accountableUsers}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        error={touched.accountableUsers && Boolean(errors.accountableUsers)}
                                        helpText={touched.accountableUsers && String(errors.accountableUsers)}
                                        onMenuClosed={handleSubmit}
                                        getUsersByName={getUsersByName}
                                        readonly={ignorePermissions ? false : userCanEditAccountable}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <RaciUserList
                                        name={nameof<RaciAssignmentForm>(f => f.consultedUsers)}
                                        title={"Consulted"}
                                        subtitle={"Users are able to view and add notes, but unable to change the status of the questions"}
                                        delegatedUsers={values.consultedUsers}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        onMenuClosed={handleSubmit}
                                        getUsersByName={getUsersByName}
                                        readonly={ignorePermissions ? false : userCanEditConsulted}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <RaciUserList
                                        name={nameof<RaciAssignmentForm>(f => f.informedUsers)}
                                        title={"Informed"}
                                        subtitle={"Users are able to view questions only"}
                                        delegatedUsers={values.informedUsers}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        onMenuClosed={handleSubmit}
                                        getUsersByName={getUsersByName}
                                        readonly={ignorePermissions ? false : userCanEditInformed}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}
export { RaciDelegation }