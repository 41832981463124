import React from 'react';

import { Button, Grid, Typography } from "@mui/material";

import { WizardStepProps } from "wizards/models";

const Welcome: React.FC<WizardStepProps> = ({object, onContinueClicked}) => {

    const onClick = () => {
        onContinueClicked(object);
    }

    return (
        <Grid container item xs={10} spacing={2}>
            <Grid container item>
                <Grid item xs={12}>
                    <Typography variant={"h4"}><b>Welcome to ASIRTA™</b></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"h6"} color={"secondary"}>The compliance portal to help organisations make compliance simple.</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    As part of the onboarding process of your organisation, you will be asked to define the sectors
                    your organisation operates within (both in terms of the organisation itself and its customers). This
                    selection will generate will generate activities derived from sample obligations that your
                    organisation
                    may face for information management and are generated from the options you select for sectors that
                    you/your customers operate within.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Dependant on the tier selected, these activities will start at 38, but may exceed 100 and are
                    grouped in up to 21 activity categories.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    You will be able to create and assign users (subject to licences purchased) to either entire
                    categories (using the RACI matrix as part of the onboarding process) or within individuals using the
                    question
                    interfaces related to the activities themselves.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    The questions from these activities allow capture of current status, evidence and ownership in
                    terms of maturity, and some have pre-qualification questions during the onboarding process which
                    will follow
                    after the selection of the organisational sectors.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    The output of this process will be a series of dashboard and reports, presenting your current
                    compliance against these activities in terms of exposure and alignment to common obligations such
                    the NCSC cyber
                    top ten, applied UK GDPR/Data Protection Act and Network and Information Systems Regulations (the
                    latter
                    where applicable).
                </Typography>
            </Grid>

            <Grid container justifyContent={"flex-end"}>
                <Grid item>
                    <Button color={'primary'} variant={"outlined"} size={"large"} onClick={onClick}>Let's do
                        this...</Button>
                </Grid>
            </Grid>

        </Grid>
    );
}
export { Welcome }