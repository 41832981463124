import {
  AAD_LOGIN_SUCCESS,
  ActionTypes, 
  SET_COMPANY_USER_PERMISSIONS,
  VALIDATE_USER
} from "./actionTypes";

import { AuthenticationState } from "./Model"

const initialState: AuthenticationState = {
  userClaims: {
    jwtAccessToken: "",
    jwtIdToken: "",
    account: {
      accountIdentifier: "",
      homeAccountIdentifier: "",
      userName: "",
      name: "",
      idToken: {},
      idTokenClaims: {},
      sid: "",
      environment: ""
    }
  },
  validated: undefined,
  companyUserPermission: []
  
}

export function authentication(
  state = initialState,
  action: ActionTypes
): any {
  switch (action.type) {
    case AAD_LOGIN_SUCCESS: 
      return { 
        ...state,
        userClaims: action.payload 
      };
    case VALIDATE_USER: 
      return { 
        ...state, 
        validated: action.payload,
      };
    case SET_COMPANY_USER_PERMISSIONS:
      return {
        ...state,
        companyUserPermission: action.payload
      };
    default:
      return state
  }
}
