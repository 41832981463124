import React, {ChangeEvent, ReactElement, useCallback, useEffect, useState} from 'react';
import moment from "moment";
import { nameof } from "ts-simple-nameof";

import { ActivityComplianceRatingSummaryDto, ActivityComplianceRatingDto } from "common/services/ReportingService/models";
import { getComplianceScoreClassName } from "common/helpers/Object/getComplianceScoreClassName";
import { getActivityComplianceSummary, getActivityComplianceRating } from "../../store/actions";
import { ActivityRiskDetailExpanded } from './DetailedActivityRisksExpanded'
import { currencyFormat } from "common/helpers/Regex/regex";

import { Checkbox, Grid } from "@mui/material";

import {
    Grid as GridTable, 
    GridColumn,
    GridColumnMenuFilter, 
    GridDataStateChangeEvent, 
    GridPageChangeEvent, 
    GridSortChangeEvent,
    GridExpandChangeEvent,
} from '@progress/kendo-react-grid';
import { orderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";

import { activityCategoryService } from "common/services/ActivityCategoryService/activityCategoryService";
import { TrackedActivityCategoryDto } from "common/dtos/ActivityCategories/models";

import "./styles.scss";
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "../../../user/store/selectors";
import {getTrackedActivities} from "../../../obligationRisks/store/actions";

const DetailedActivityRisk: React.FC = () => {
    const functionDetails = useSelector(selectedFunctionSelector);
    const [activityRisk, setActivityRisk] = useState<ActivityComplianceRatingSummaryDto[]>([]);
    const [activityRiskDetail, setActivityRiskDetail] = useState<ActivityComplianceRatingDto[]>([]);
    const [trackedActivityCategories, setTrackedActivityCategories] = useState<TrackedActivityCategoryDto[]>([]);
    const [detail, setDetail] = useState<ActivityComplianceRatingDto[]>([]);
    const [pagingState, setPagingState] = useState<State>({skip: 0, take: 20});
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const skip: number = pagingState.skip!;
    const take: number = pagingState.take!;

    useEffect(() => {
        getActivityComplianceSummary().then((response) => {
            const data = response && response
                .sort((a,b) => (a.questionsTotal - a.compliancePercentage  <= b.questionsTotal - b.compliancePercentage ) ? 1 : -1)
            setActivityRisk(data);
        })
    }, [setActivityRisk, functionDetails])

    useEffect(() => {
        getActivityComplianceRating().then((response) => {
            setActivityRiskDetail(response);
        })
    }, [setActivityRiskDetail, functionDetails])

    useEffect(() => {
        getTrackedActivities().then((response) => {
            setTrackedActivityCategories(response)
        })
    }, [setTrackedActivityCategories])

    const onClick =  useCallback((activityCategoryId: number) => (event: ChangeEvent<any>) => {
        const checked: boolean = event.target.checked;
        activityCategoryService.trackActivityCategory(activityCategoryId, checked).then((trackedActivityCategory: TrackedActivityCategoryDto) => {
            setTrackedActivityCategories(checked
                ? [...trackedActivityCategories, trackedActivityCategory]
                : [...trackedActivityCategories.filter(t => t.id !== trackedActivityCategory.id)])
        })

    },[setTrackedActivityCategories, trackedActivityCategories])
    
    const renderCheckbox = (props: any): ReactElement => {
        const activityCategoryId: number = props.dataItem.activityCategoryDetailsId
        const isTracked = trackedActivityCategories.find(o => o.activityCategoryDetailsId === activityCategoryId);

        return <td><Checkbox checked={isTracked !== undefined} onClick={onClick(activityCategoryId)}/></td>
    } 

    const onPageChange = (event: GridPageChangeEvent) => {
        setPagingState({
            ...pagingState,
            skip: event.page.skip,
            take: event.page.take
        })
    }

    const onDataStateChange = (event: GridDataStateChangeEvent) => {
        setPagingState(event.dataState);
        setActivityRisk((event.dataState.filter !== null)
            ? process([...activityRisk], event.dataState).data
            : activityRisk);
    }

    const onSortChange = (event: GridSortChangeEvent) => {
        setSort(event.sort);
    }
    
    const  change = useCallback((activity: ActivityComplianceRatingSummaryDto, details: ActivityComplianceRatingDto[] ) => {
        activity.expanded = !activity.expanded;
        activity.details =  details;
    },[])
    
    const expandChange = (event: GridExpandChangeEvent) => {
        let details = activityRiskDetail.filter(f => f.activity === event.dataItem.activity).map((item: ActivityComplianceRatingDto) => {
            if (item.activity === event.dataItem.activity) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setDetail(details);
        change(event.dataItem, details);
    };
  
    return (
        <Grid container spacing={2}>
            <Grid item>
                <GridTable
                    data={orderBy(activityRisk, sort).slice(skip, take + skip)}
                    {...pagingState}
                    onPageChange={onPageChange}
                    onDataStateChange={onDataStateChange}
                    onSortChange={onSortChange}
                    total={activityRisk.length}
                    pageable={true}
                    sortable={true}
                    sort={sort}
                    expandField={detail && "expanded"}
                    onExpandChange={expandChange}
                    detail={ActivityRiskDetailExpanded}
                >
                    <GridColumn field={nameof<ActivityComplianceRatingSummaryDto>(a => a.activity)} title={"Obligation"} filter={"text"}  width={"350px"}
                                columnMenu={GridColumnMenuFilter} />

                    <GridColumn field={nameof<ActivityComplianceRatingSummaryDto>(a => a.lastResponse)} title={"Last Response"} filter={"date"}
                                columnMenu={GridColumnMenuFilter}
                                cell={props => <td>{props.dataItem.lastResponse ? moment.utc(props.dataItem.lastResponse).format("DD/MM/YYYY"): '-'}</td>}
                    />
                    <GridColumn field={nameof<ActivityComplianceRatingSummaryDto>(a => a.lastResponseBy)} title={"Last Response By"}
                               
                    />
                    <GridColumn field={nameof<ActivityComplianceRatingSummaryDto>(a => a.questionsTotal)} title={"Question Total"}
                    />
                    <GridColumn field={nameof<ActivityComplianceRatingSummaryDto>(a => a.questionsAddressed)} title={"Questions Addressed"}
                    />
                    <GridColumn field={nameof<ActivityComplianceRatingSummaryDto>(a => a.compliancePercentage)} title={"Compliance"}
                                cell={props => <td>
                                    <span className={getComplianceScoreClassName(Number(props.dataItem.compliancePercentage))}>{currencyFormat(Number(props.dataItem.compliancePercentage),0)}%</span></td>} filter={"numeric"}
                                columnMenu={GridColumnMenuFilter}
                    />                    
                    <GridColumn field={"Selected"} title={"Track"} cell={props => renderCheckbox(props)}
                                width={"100px"}/>
                
                </GridTable>
                
            </Grid>            
        </Grid>
    )
}
export { DetailedActivityRisk }