import { SET_SAMPLE_ASSETS } from "./actionTypes";
import { ApplicationState } from "../../store";
import { AssetSampleDto } from "common/dtos/Assets/AssetSampleDto";
import { assetService } from "common/services/AssetService/assetService";
import { AssetDetailsDto } from "common/dtos/Assets/AssetDetailsDto";
import { PaginationResponseDto } from "common/dtos/Common/pagination";
import { LinkedObligationsDto } from "common/dtos/Assets/LinkedObligationsDto";

export function SetSampleAssetsAction(sampleAssets: AssetSampleDto[]) {
    return {
        type: SET_SAMPLE_ASSETS,
        payload: sampleAssets
    }
}

export function getSampleAssets() {
    return async (dispatch: any, getState: () => ApplicationState) => {

        if (getState().assets.sampleAssets.length > 0)
            return;

        await assetService.getSampleAssets().then(async response => {
            await dispatch(SetSampleAssetsAction(response));
        })
    }
}

export function getFunctionAssets(): Promise<PaginationResponseDto<AssetDetailsDto[]>> {
    return assetService.getFunctionAssets();
}

export function modifyAsset(assetDto: AssetDetailsDto): Promise<void> {
    return assetService.modifyAsset(assetDto);
}

export function duplicateAsset(assetId: number): Promise<AssetDetailsDto> {
    return assetService.duplicateAsset(assetId);
}

export function linkObligationsToAssets(assetId: number, obligationIds: number[]): Promise<LinkedObligationsDto[]> {
    return assetService.linkObligationsToAssets(assetId, obligationIds);
}

export function unlinkObligationById(assetId: number, obligationId: number): Promise<void> {
    return assetService.unlinkObligationById(assetId, obligationId);
}

export function deleteAsset(assetId: number): Promise<void> {
    return assetService.deleteAsset(assetId);
}