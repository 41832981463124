import React, { ReactElement, useEffect, useState } from 'react';

import {
    Avatar, Card,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography
} from "@mui/material";
import { EditRounded } from "@mui/icons-material";

import { UserDetailsDto } from "common/dtos/Users/models";
import { SearchableMenu } from "common/components/panels/SearchableMenu/SearchableMenu";
import { UserSkeletonLoader } from "common/components/loader/UserSkeletonLoader/UserSkeletonLoader";
import { getUserInitials } from "common/helpers/Regex/regex";
import { useValidPermission } from "auth/components/ValidateUserPermission";
import { Permission } from "auth/store/Model";

import "./styles.scss";

export interface RaciUserListProps {
    name: string;
    title: string;
    subtitle?: string;
    setFieldValue: (name: string, value: any) => void;
    setFieldTouched: (name: string, touched: boolean) => void;
    delegatedUsers: UserDetailsDto[];
    onMenuClosed?: () => void;
    getUsersByName: (query: string) => Promise<UserDetailsDto[]>;
    error?: boolean;
    helpText?: string | false;
    loading?: boolean;
    readonly?: boolean;
}

const RaciUserList: React.FC<RaciUserListProps> = ({
                                                       name,
                                                       title,
                                                       subtitle,
                                                       delegatedUsers,
                                                       setFieldValue,
                                                       setFieldTouched,
                                                       onMenuClosed,
                                                       getUsersByName,
                                                       error,
                                                       helpText,
                                                       loading,
                                                       readonly
                                                   }) => {
    const userCanEdit = !useValidPermission(Permission.RACIAccountableEdit);
    
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [users, setUsers] = useState<UserDetailsDto[]>([]);
    const [query, setQuery] = useState<string>("");

    const anchorRef = React.useRef<HTMLButtonElement>(null);
    
    console.log(error, helpText)

    useEffect(() => {
        getUsersByName(query).then(response => {
            setUsers(response);
        })
    }, [query, getUsersByName]);

    const handleInput = (input: string) => {
        setQuery(input)
    }

    const handleMenu = (open: boolean) => {
        setOpenMenu(open);

        if (!open)
            onMenuClosed && onMenuClosed();
    }

    const renderContent = (): ReactElement => {
        return (
            <Grid container item justifyContent={"center"} alignItems={"center"}>
                {loading && renderLoaders()}
                {!loading && delegatedUsers.length > 0 && renderUsers()}
                {!loading && delegatedUsers.length === 0 && renderEmpty()}
            </Grid>
        );
    }

    const renderLoaders = (): ReactElement => {
        return (
            <Grid container item xs={12} spacing={4}>
                <Grid item xs={12}>
                    <UserSkeletonLoader/>
                </Grid>
                <Grid item xs={12}>
                    <UserSkeletonLoader/>
                </Grid>
                <Grid item xs={12}>
                    <UserSkeletonLoader/>
                </Grid>
                <Grid item xs={12}>
                    <UserSkeletonLoader/>
                </Grid>
                <Grid item xs={12}>
                    <UserSkeletonLoader/>
                </Grid>
            </Grid>
        )
    }

    const renderUsers = (): ReactElement[] => {
        return delegatedUsers.map((item, i) => {
            return (
                <ListItem key={i} role="listitem" button>
                    <Grid container spacing={1} alignItems={"center"}>
                        <Grid item>
                            <Avatar>{getUserInitials(item)}</Avatar>
                        </Grid>
                        <Grid item>
                            <ListItemText primary={item.identityProviderUserName ?? item.email}/>
                        </Grid>
                    </Grid>
                </ListItem>
            )
        })
    }

    const renderEmpty = (): ReactElement => {
        return <Grid container>
            <Grid item>
                <Typography variant={"caption"}>No Users Delegated</Typography>
            </Grid>
        </Grid>
    }

    return <>
        <Card className={"raciList"} variant={"outlined"}>
            <Grid container>
                <Grid container item justifyContent={"space-between"}>
                    <Grid item xs={11}>
                        <Typography variant={"h6"}>{title}</Typography>
                        { subtitle && <p className={'raciSubtitle'}><i>{subtitle}</i></p> }
                    </Grid>
                    {!readonly &&
                        <Grid item xs={1}>
                            <Tooltip title={`Modify ${title} Users`}>
                                <IconButton ref={anchorRef} size={"small"} onClick={() => handleMenu(true)}>
                                    <EditRounded/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }

                </Grid>
                <Grid className={"userList"} container item xs={12} alignItems={"flex-start"}>
                    <List>
                        {renderContent()}
                    </List>
                </Grid>
                {error && helpText &&
                    <Grid container item xs={12} alignItems={"center"}>
                        <Grid item>
                            <Typography variant={"caption"} color={"error"}>{helpText.toString()}</Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Card>
        <SearchableMenu
            readOnly={userCanEdit}
            placeholder={"Search Users..."}
            handleInput={handleInput}
            items={users}
            selectedItems={delegatedUsers}
            setSelectedItems={(i) => {
                setFieldValue(name, i);
                setFieldTouched(name, true)
            }}
            open={openMenu}
            anchorElement={anchorRef}
            titleSelector={(item: UserDetailsDto) => item.identityProviderUserName ?? item.email}
            handleClose={() => handleMenu(false)}
            idSelector={(item: UserDetailsDto) => item.email}
        />
    </>;
}
export { RaciUserList }