import React, { ReactElement } from 'react';

import { FormControl, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { LinkedQuestionDto } from "common/dtos/Questions/QuestionDetails/models";
import { AnswerDto, AnswerValue } from "common/dtos/Questions/models";
import { nicifyString } from "common/helpers/Regex/regex";
import { formProps } from "common/theming/models";

export interface SubquestionProps {
    name: string;
    keyName: string;
    subquestion: LinkedQuestionDto;
    answer: AnswerValue;
    answerValues: AnswerDto[];
    handleChange: (e: SelectChangeEvent<any>) => void;
    readOnly: boolean;
}

const Subquestion: React.FC<SubquestionProps> = ({name, keyName, subquestion, answer, answerValues, handleChange, readOnly}) => {

    const renderOptions = (): ReactElement[] => {
        return answerValues.map((a, i) => (
            <MenuItem key={`answer-${i}`} value={a.id}>{nicifyString(AnswerValue[a.id].toString())}</MenuItem>
        ))
    }

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={1} key={keyName} alignItems={"center"}>
                <Grid item xs={9}>
                    <pre className={"questionValue"}>{subquestion.question.value}</pre></Grid>
                <Grid container item xs={3} alignContent={"center"}>
                    <FormControl {...formProps}>
                        <Select name={name} value={answer} onChange={handleChange} readOnly={readOnly}>
                            <MenuItem key={"answer-0"} value={AnswerValue.NotAnswered} disabled selected>Not
                                Answered</MenuItem>
                            { renderOptions() }
                        </Select>
                    </FormControl>

                </Grid>
            </Grid>
        </Grid>

    )
}
export { Subquestion }