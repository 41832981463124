import React from "react";

const CreationFormText = (
    <React.Fragment>
        <h3>Within ASIRTA™, you can create any of the below as a business function: </h3>
            <ul/>a <b>Project</b> <i>(discrete activity to delivery something new)</i>
            <ul/>a <b>Programme</b> <i>(one or more projects)</i>
            <ul/>a <b>Business Process</b> <i>(internal service)</i>
            <ul/>a <b>Service</b> <i>(external service)</i>
                        
            <h3>
                This will allow you to define obligations, business impacts, linked assets and assign ownership to allow granular assessment when the organisation is ready to do so.<br/><br/>
                This feature will allow your organisation to provide a sandbox for obligations and services that are not yet live or even map your compliance to the operational reality of the way that the organisation does business.
            </h3>
    </React.Fragment>)


const AssignCompanyTypesText = (
    <React.Fragment>
        <h1>Are you Private, Public or 3rd Sector</h1>        
    </React.Fragment>)
const AssignSectorsText = (
    <React.Fragment>
            <h1>Assign Sectors to your Organisation</h1>
            <h3>
                Within ASIRTA™ we use the sectors you can select from in this page to select obligations (and associated questions for Cyber and Information Risk management) that may apply to your organisation.  You will be able to select sectors that apply to your customers later in which allows you to generate questions related to your customer obligations.<br/><br/>
                Please note that all questions generated are from sample obligations and it is your responsibility to validate that all obligations and questions apply to you.
            </h3>
            <h3>
                If none of these sectors apply to you, select Continue
            </h3>
    </React.Fragment>)

const RiskLabelsText = (
        <React.Fragment>
            <h1>Risk Management Labels</h1>
            <h3>
                The values on this screen relate to what is called a 5x5 risk matrix and is the one most commonly used within corporate governance.<br/><br/>
                Within ASIRTA™, we use this in higher billing tiers to impact assess the obligations and map them to assets to determine the nett impact (and ultimately the obligation risk).<br/><br/>
                Please select the correct labels and financial ranges for each level.
            </h3>
        </React.Fragment>)

export { CreationFormText,AssignSectorsText,RiskLabelsText,AssignCompanyTypesText }