import * as Yup from "yup";

import { AnswerValue } from "../models";
import { LookupDto, lookupDtoInitialState } from "common/services/LookupService/models";

export interface QuestionAnswerDto {
    questionDetailsId: number;
    questionGroupId: number;
    subquestionAnswers: SubquestionAnswersDto[];
    answerAdditionalDetails: AnswerAdditionalDetailsDto;
}

export interface SubquestionAnswersDto {
    questionId: number;
    justification: string;
    answer: AnswerValue;
}

export interface SubquestionAnswersRequest {
    questionId: number;
    questionGroupId: number;
    functionId: number;
    subquestionIds: number[];
}

export interface QuestionAnswerForm {
    answers: SubquestionAnswersDto[];
    additionalDetailsRequest: AnswerAdditionalDetailsRequest;
}

export interface QuestionAnswerRequest {
    answers: QuestionAnswerDto;
}

export interface AnswerAdditionalDetailsRequest {
    costOfCompliance: number;
    complianceComplexityId: number;
    thirdPartyManagedId: number;
    conductActivityId: number;
    activityFrequencyId: number;
    implementationEffectivenessId: number;
    relevantPolicies: string,
    nextReviewDate?: Date;
}

export interface AnswerAdditionalDetailsDto extends AnswerAdditionalDetailsRequest {
    id: number;
    questionDetailsId: number;
    functionDetailsId: number;
    costOfCompliance: number;
    complianceComplexity: LookupDto<string>;
    thirdPartyManaged: LookupDto<string>;
    conductActivity: LookupDto<string>;
    activityFrequency: LookupDto<string>;
    implementationEffectiveness: LookupDto<string>;
}

const answerAdditionalDetailsRequestInitialState: AnswerAdditionalDetailsRequest = {
    conductActivityId: 1,
    activityFrequencyId: 1,
    implementationEffectivenessId: 1,
    complianceComplexityId: 1,
    costOfCompliance: 0,
    nextReviewDate: undefined,
    thirdPartyManagedId: 1,
    relevantPolicies: ""
}

const answerAdditionalDetailsInitialState: AnswerAdditionalDetailsDto = {
    id: 0,
    functionDetailsId: 0,
    nextReviewDate: undefined,
    thirdPartyManagedId: 1,
    complianceComplexityId: 1,
    activityFrequencyId: 1,
    implementationEffectivenessId: 1,
    conductActivityId: 1,
    costOfCompliance: 0,
    questionDetailsId: 0,
    complianceComplexity: lookupDtoInitialState,
    activityFrequency: lookupDtoInitialState,
    implementationEffectiveness: lookupDtoInitialState,
    conductActivity: lookupDtoInitialState,
    thirdPartyManaged: lookupDtoInitialState,
    relevantPolicies: ""
}

const questionAnswerInitialState: QuestionAnswerDto = {
    questionDetailsId: 0,
    questionGroupId: 0,
    subquestionAnswers: [],
    answerAdditionalDetails: answerAdditionalDetailsInitialState
}

const questionAnswerFormInitialState: QuestionAnswerForm = {
    answers: [],
    additionalDetailsRequest: answerAdditionalDetailsRequestInitialState
}

const questionAnswerFormValidationSchema = Yup.object().shape({
    answers: Yup.array()
        .of(Yup.object().shape({
            questionId: Yup.number().required(),
            justification: Yup.string(),
            answer: Yup.number()
        })),
    additionalDetailsRequest: Yup.object().shape({
       implementationEffectiveness: Yup.number(),
        relevantPolicies: Yup.string(),
        costOfCompliance: Yup.number(),
        complianceComplexityId: Yup.number(),
        thirdPartyManagedId: Yup.number(),
        conductActivityId: Yup.number(),
        activityFrequencyId: Yup.number(),
        nextReviewDate: Yup.date().nullable()
    })
})

export {
    questionAnswerFormInitialState,
    answerAdditionalDetailsInitialState,
    answerAdditionalDetailsRequestInitialState,
    questionAnswerInitialState,
    questionAnswerFormValidationSchema
}