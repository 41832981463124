import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { lookupService } from "../../../../services/LookupService/lookupService"; 

export interface RiskLabelTypeSelectorProps {
    risk: number;
    align?: "right" | "left" | "inherit" | "center" | "justify";
}

const RiskLabelTypeSelector: React.FC<RiskLabelTypeSelectorProps> = ({risk, align}) => {
    
    const [riskLabelTypes, setRiskLabelTypes] = useState<any[]>([]);

    useEffect(() => {
        lookupService.getRiskLabelTypes().then((response) => {
            setRiskLabelTypes(response);
        })
    }, [])
    
    const selectedRiskLabel = riskLabelTypes && riskLabelTypes.find(a => a.id === risk)

    return (
        <Typography variant="caption" display="block" align={align}>
           <i>{selectedRiskLabel ? selectedRiskLabel.description : ''}</i>
        </Typography>
    )
}
    
export { RiskLabelTypeSelector }