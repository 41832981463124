import React, { useRef, useState } from 'react';
import { CircularProgress, Grid, Link, Typography } from "@mui/material";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./styles.scss";

export interface AttachmentDropperProps {
    onFilesDropped: (files: FileList) => Promise<void>;
    uploadDescription?: string;
}

const AttachmentDropper: React.FC<AttachmentDropperProps> = ({onFilesDropped, uploadDescription}) => {

    const inputFile: any = useRef(undefined);

    const [uploading, setUploading] = useState<boolean>(false);
    const [dragOver, setDragOver] = useState<number>(0);

    const onDragChange = (e: React.DragEvent<HTMLDivElement>, enter: boolean) => {
        e.preventDefault();
        e.stopPropagation();

        let dragover = enter ? dragOver + 1 : dragOver - 1;
        setDragOver(dragover);
    }

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        
        uploadFiles(event.dataTransfer.files);
    }
    
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUploading(true);
        
        const files: FileList | null = event.target.files;
        
        if(files){
            uploadFiles(files)
        }
    }
    
    const uploadFiles = (files: FileList) => {
        setUploading(true);
        onFilesDropped(files).then(_ => {
            setDragOver(0)
            setUploading(false)
        });
    }
    
    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }

    const dragOverClass: string = (dragOver) ? "dragover" : "";

    return (
            <Grid container item className={`dropzone ${dragOverClass}`}
                  onDrop={e => onDrop(e)}
                  onDragOver={e => onDragOver(e)}
                  onDragEnter={e => onDragChange(e, true)} 
                  onDragLeave={e => onDragChange(e, false)}>
                <Grid container item spacing={1} justifyContent={"center"} alignItems={"center"}>
                    <Grid item className={"dropzone-content"}>
                        { uploading
                            ? <CircularProgress size={21}/>
                            : <FontAwesomeIcon fontSize={5} icon={faFileUpload}/> }

                    </Grid>
                    <Grid item className={"dropzone-content"}>
                        {uploading
                            ? <Typography variant={"body2"}>Uploading...</Typography>
                            : <Typography variant={"body2"}>Drop or <Link onClick={() => inputFile.current.click()}>Upload</Link> {uploadDescription && uploadDescription}</Typography>
                        }

                    </Grid>
                </Grid>
                <input type={"file"} onChange={onChange} ref={inputFile} hidden/>
            </Grid>
    )
}
export
{
    AttachmentDropper
}