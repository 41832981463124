import React, { ReactNode } from 'react';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    Select, SelectChangeEvent
} from "@mui/material";

import { formProps } from "../../../../theming/models";

export interface NotchedSelectProps {
    name: string;
    label: string;
    multiple?: boolean;
    displayEmpty?: boolean;
    value: any | any[];
    disabled?: boolean;
    onChange: (event: SelectChangeEvent<{ name?: string | undefined; value: unknown; }>, child: ReactNode) => void;
    renderValue?: (value: unknown) => ReactNode;
    error?: boolean;
    helperText?: any;
    startAdornment?: React.ReactNode
    endAdornment?: React.ReactNode
}

const NotchedSelect: React.FC<NotchedSelectProps> = ({
                                                         name,
                                                         label,
                                                         value,
                                                         disabled,
                                                         onChange,
                                                         multiple,
                                                         displayEmpty,
                                                         renderValue,
                                                         error,
                                                         helperText,
                                                         startAdornment,
                                                         endAdornment,
                                                         children
                                                     }) => {

    const inputLabel: React.RefObject<HTMLLabelElement> = React.useRef(null);

    return (
        <FormControl {...formProps}>
            <InputLabel shrink ref={inputLabel} error={error}>
                {label}
            </InputLabel>
            <Select {...formProps}
                    fullWidth
                    name={name}
                    disabled={disabled}
                    multiple={multiple}
                    onChange={onChange}
                    label={label}
                    value={value}
                    displayEmpty={displayEmpty}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                    renderValue={renderValue}
                    error={error}
                    MenuProps={{disablePortal: true, elevation: 1}}
            >
                {children}
            </Select>
            <FormHelperText error={error}>
                {helperText}
            </FormHelperText>
        </FormControl>
    )
}
export { NotchedSelect }
