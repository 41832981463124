import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Accordion, AccordionSummary } from "@mui/material";
import QuestionStatusDefinitions from "./QuestionStatusDefinitions/QuestionStatusDefinitions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";

const Help: React.FC = () => {
    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
            <Button
                color={"warning"}
                variant={"outlined"}
                size={"small"}
                onClick={handleClickOpen}>
               Question Help
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Question Help</DialogTitle>
                <DialogContent>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Implementation Status Definitions</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <QuestionStatusDefinitions/>
                        </AccordionDetails>
                    </Accordion>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export { Help }