export function getArrayDifference(source: any[], comparison: any[]){
    return source.filter(item => !comparison.some(c => c === item))
}

export function getArrayDifferenceById(source: any[], comparison: any[], idSelector: (item: any) => any){
    return source.filter(item => !comparison.some(c => idSelector(c) === idSelector(item)))
}

export function getArrayDifferencesByComparator(source: any[], comparison: any[], comparator: (source: any, target: any) => boolean){
    return source.filter(item => !comparison.some(c => comparator(item, c)))
}