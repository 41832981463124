import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { selectedCompanySelector } from "user/store/selectors";
import { LookupDto, lookupDtoInitialState } from "common/services/LookupService/models";
import { renderLookupsAsMenuItem } from "common/helpers/Object/object";
import { lookupService } from "common/services/LookupService/lookupService";
import { NotchedSelect } from "common/components/input/fields/NotchedSelect/NotchedSelect";
import { Formik, FormikProps } from "formik";
import { updateCompanyBillingTier } from "user/store/actions";
import {AuthConfig} from "../../../../auth/AuthConfig";

const BillingTierManagement: React.FC = () => {

    const selectedCompany = useSelector(selectedCompanySelector);
    const [billingTier, setBillingTier] = useState<LookupDto<string>>(lookupDtoInitialState);
    const [billingTiers, setBillingTiers] = useState<LookupDto<string>[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedCompany?.settings !== undefined) {
            const billingTierValue = selectedCompany.settings?.billingTiers;
            setBillingTier(billingTierValue);
        }
    }, [selectedCompany])

    useEffect(() => {
        lookupService.getBillingTiers().then((response) => {
            setBillingTiers(response);
        })
    }, [])

    const onClick = async (values: {billingTierId: number}) => {
        
        setSaving(true);
        await dispatch(updateCompanyBillingTier(values.billingTierId));
        setSaving(false);
    }

    return (
        <Formik
            initialValues={{"billingTierId": billingTier.id}}
            onSubmit={onClick}
            enableReinitialize
        >
            {(props: FormikProps<{billingTierId: number}>) => {

                const {handleChange, handleSubmit, values, initialValues} = props;

                return (
                    <Grid container justifyContent={"flex-end"} spacing={2}>
                        <Grid item xs={5}>
                            <NotchedSelect
                                name={"billingTierId"}
                                onChange={handleChange}
                                label={"Your Billing Tier"}
                                value={values.billingTierId}
                                disabled={AuthConfig.version !== "Local"}
                            >
                                {renderLookupsAsMenuItem(billingTiers)}
                            </NotchedSelect>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant={'subtitle2'}>
                                For all changes to billing and licence settings, please reach out to us.
                                We're more than happy to help you out.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => handleSubmit()}
                                variant={"outlined"}
                                color={"success"}
                                disabled={values.billingTierId === initialValues.billingTierId}
                                endIcon={saving && <CircularProgress color={"success"} size={25}/>}
                            >Save Changes</Button>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export { BillingTierManagement }