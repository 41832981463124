import React from 'react';

import { Button, Container, Grid } from "@mui/material";
import { WizardStepProps } from "../../../models";
import { CreationFormText } from "common/helpers/HelperText/HelperTexts";

const Welcome: React.FC<WizardStepProps> = ({object, onContinueClicked}) => {

    const onClick = () => {
        onContinueClicked(object);
    }

    return (
        <Container>
            <h1>What is a Business Function?</h1>

            { CreationFormText }
            <br/>
            
            <Grid container justifyContent={"center"}>
                <Grid item>
                    <Button color={'primary'} variant={"outlined"} size={"large"} onClick={onClick}>Let's do
                        this...</Button>
                </Grid>
            </Grid>

        </Container>
    );
}
export { Welcome }