import React, { useCallback, useEffect, useState } from 'react';

import { Formik, FormikProps } from 'formik';
import { Button, CircularProgress, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BusinessFunctionCreationForm } from "common/components/input/forms/BusinessFunctionCreationForm/BusinessFunctionCreationForm";
import { UserDetailsDto } from "common/dtos/Users/models";
import { getUsersAndGroups } from "company/actions";
import {
    editorialFormValidationSchema
} from "wizards/businessFunctions/components/CreationForm/models";
import { selectFunction } from "user/store/actions";
import { FunctionDetailsDto, functionDetailsInitialState } from "common/dtos/Businesses/FunctionDetailsDto";
import { selectedFunctionSelector } from "user/store/selectors";
import { updateFunctionDetails } from "businessFunctions/store/actions";
import { Permission } from "auth/store/Model";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";

const FunctionDetailsEditing: React.FC = () => {
    
    const [saving, setSaving] = useState<boolean>(false);
    const [candidates, setCandidates] = useState<UserDetailsDto[]>([]);

    const functionDetails = useSelector(selectedFunctionSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        getUsersAndGroups().then(response => {
            setCandidates(response.map(c => c.user));
        })
    }, [functionDetails])

    const saveChanges = useCallback((values: FunctionDetailsDto) => {
        setSaving(true);
        
        updateFunctionDetails(values).then(() => {
            dispatch(selectFunction(values))
        }).finally(() => {
            setSaving(false);
        })
    }, [dispatch])
    
    return (
        <Formik
            initialValues={functionDetails ?? functionDetailsInitialState}
            validationSchema={editorialFormValidationSchema}
            enableReinitialize
            onSubmit={(values) => {
                saveChanges(values)
            }}
        >
            {({
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  errors
              }: FormikProps<FunctionDetailsDto>) => {
                
                return (
                    <Grid container justifyContent={"flex-end"} spacing={2}>
                        <Grid item container>
                            <BusinessFunctionCreationForm
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                values={values}
                                errors={errors}
                                touched={touched}
                                candidates={candidates}
                            />
                        </Grid>
                        <ValidateUserPermission permissions={[Permission.BusinessFunctionsEdit]}>
                            <Grid item>
                                <Button
                                    variant={"outlined"}
                                    onClick={() => handleSubmit()}
                                    className={"success-item-outlined"}
                                    endIcon={saving &&
                                    <CircularProgress
                                        size={25}
                                        className={"success-item-outlined"}
                                    />
                                    }
                                >Save Changes</Button>
                            </Grid>
                        </ValidateUserPermission>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export { FunctionDetailsEditing }