import React, { ReactElement } from "react";
import { StatusPage } from 'common/components/pages/StatusPage/StatusPage';
import { LayerLoader } from "common/components/loader/layers/LayerLoader";

const Loading: React.FC = () => {

  const renderLoading = (): ReactElement<any> => {
    return (
      <LayerLoader/>
    )
  }

  return (
    <StatusPage
      message={renderLoading}
    />
  );
};
export { Loading };