import { reportingService } from "common/services/ReportingService/reportingService";
import { ObligationRiskDto } from "./models";
import { TrackedObligationDto } from "common/dtos/Obligations/models";
import { obligationsService } from "common/services/ObligationsService/obligationsService";
import { TrackedQuestionGroupDto } from "common/dtos/Questions/TrackedQuestionGroupDto/models";
import { questionsService } from "common/services/QuestionService/questionService";
import {TrackedActivityCategoryDto} from "../../common/dtos/ActivityCategories/models";
import {activityCategoryService} from "../../common/services/ActivityCategoryService/activityCategoryService";

export function getObligationRisks(): Promise<ObligationRiskDto[]>{
    return reportingService.getObligationRisk()
}

export function getTrackedObligations(): Promise<TrackedObligationDto[]> {
    return obligationsService.getTrackedObligations();
}

export function getTrackedActivities(): Promise<TrackedActivityCategoryDto[]> {
    return activityCategoryService.getTrackedActivityCategories();
}

export function trackObligation(obligationId: number, track: boolean): Promise<TrackedObligationDto> {
    return obligationsService.trackObligation(obligationId, track);
}

export function trackQuestionGroup(questionGroupId: number, tracked: boolean): Promise<TrackedQuestionGroupDto> {
    return questionsService.trackQuestionGroup(questionGroupId, tracked);
}
export function trackActivityIds(activityQuestionId: number, tracked: boolean): Promise<TrackedQuestionGroupDto> {
    return questionsService.trackQuestionGroup(activityQuestionId, tracked);
}

export function getTrackedQuestionGroups(): Promise<TrackedQuestionGroupDto[]> {
    return questionsService.getTrackedQuestionGroups();
}