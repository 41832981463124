import * as Yup from "yup";
import { SchemaOf } from "yup";
import { RiskLabelsUpdateRequest } from "common/requests/Companies/RiskLabelsUpdateRequest";

// @ts-ignore
export const riskLabelsValidationSchema: SchemaOf<RiskLabelsUpdateRequest> = Yup.object().shape({
    riskLabels: Yup.array().of(
        Yup.object().shape({
            label: Yup.string().required("Required"), 
            impactRangeX: Yup.number().required("Required"),
            impactRangeY: Yup.number().required("Required"),
         }).test("X less than Y Check", "X must be lower than Y", (risk, context)=> {

             if(risk.impactRangeX === undefined || risk.impactRangeY === undefined)
                 return true;
             
            return risk.impactRangeY > risk.impactRangeX
        }).test("Continuous Range", "Range must flow in a logical order", (risk, context)=> {

            const index = context.parent.indexOf(risk)
            
            if(index === 0)
                return true;
            
            const prevRisk = context.parent[index - 1]
            
            return risk.impactRangeX === prevRisk.impactRangeY
        })
    )
})    