import React, { useCallback } from 'react';
import { Grid, TextField, Typography } from "@mui/material";
import { isEmptyOrSpaces, nicifyString } from "common/helpers/Regex/regex";
import { AnswerValue } from "common/dtos/Questions/models";
import { fieldProps } from "common/theming/models";
import { QuestionDetailsDto } from "common/dtos/Questions/QuestionDetails/models";
import { Field, FieldProps, FormikErrors, FormikTouched, getIn } from "formik";
import { QuestionAnswerForm, SubquestionAnswersDto } from "common/dtos/Questions/QuestionAnswers/models";
import { nameof } from "ts-simple-nameof";

export interface SubquestionJustificationProps {
    name: string;
    question: QuestionDetailsDto;
    initialAnswers: SubquestionAnswersDto[];
    handleChange: (e: any) => void;
    touched?: FormikTouched<QuestionAnswerForm>;
    errors?: FormikErrors<QuestionAnswerForm>
    answerIndex: number;
    answers: SubquestionAnswersDto[];
}

const SubquestionJustification: React.FC<SubquestionJustificationProps> = ({
                                                                               name,
                                                                               question,
                                                                               initialAnswers,
                                                                               handleChange,
                                                                               touched,
                                                                               errors,
                                                                               answerIndex,
                                                                               answers
                                                                           }) => {

    const fieldName: string = `${name}[${answerIndex}].${nameof<SubquestionAnswersDto>(q => q.justification)}`;

    const justificationTouched = getIn(touched, fieldName)
    const justificationError = getIn(errors, fieldName)

    const validateJustification = useCallback((value: string) => {
        return isEmptyOrSpaces(value) ? "Required" : "";
    }, [])
    
    return (
        <Grid container item justifyContent={"space-between"} spacing={1}>
            <Grid item container>
                <Typography>{question.linkedQuestions[answerIndex].question.value}</Typography>
            </Grid>
            <Grid item container xs={4} spacing={1} alignItems={"center"}>
                <Grid item><Typography
                    className={"error-item-outlined"}>{nicifyString(AnswerValue[initialAnswers[answerIndex].answer])}</Typography></Grid>
                <Grid item><Typography
                    color={"secondary"}>{">"}</Typography></Grid>
                <Grid item><Typography
                    className={"success-item-outlined"}>{nicifyString(AnswerValue[answers[answerIndex].answer])}</Typography></Grid>
            </Grid>
            <Grid item container xs={8}>
                <Field
                    name={fieldName}
                    validate={validateJustification}
                    render={(props: FieldProps) => (
                        <TextField
                            {...fieldProps}
                            name={props.field.name}
                            label={"Justification for this answer"}
                            value={props.field.value}
                            onChange={handleChange}
                            error={justificationTouched && Boolean(justificationError)}
                            placeholder={justificationTouched && justificationError}
                            multiline={true}
                            rows={2}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
export { SubquestionJustification }