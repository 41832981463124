import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { history } from "../../history";

import { BusinessFunctionWizardSteps } from "./BusinessFunctionWizardSteps";
import { FunctionCreation, functionCreationInitialState } from "./models";
import { ComponentStepper } from "common/components/panels/ComponentStepper/ComponentStepper";
import FactoryImage from "assets/illustrations/factory.svg";
import AircraftImage from "assets/illustrations/aircraft.svg";
import { functionService } from "common/services/FunctionService/functionService";
import { addCompanyFunction } from "businessFunctions/store/actions";
import { SelectFunctionAction } from "user/store/actions";
import { selectedCompanySelector } from "user/store/selectors";

const BusinessFunctionWizard: React.FC = () => {

    const [functionCreation, setFunctionCreation] = useState<FunctionCreation>(functionCreationInitialState);

    const company = useSelector(selectedCompanySelector);
    const dispatch = useDispatch();
    
    const onNext = (object: FunctionCreation) => {
        setFunctionCreation(object);
    }

    const onComplete = (object: FunctionCreation): void => {
        
        if(company === undefined)
            return;
        
        object.functionDetails.companyId = company?.id;
        
        functionService.createFunction(object).then((response) => {
            dispatch(addCompanyFunction(response));
            dispatch(SelectFunctionAction(response))
        });
    }
    
    const onFinish = (object: FunctionCreation) => {
        // TODO: Direct them to the Business Function they created upon completion of the Wizard.
        history.push(object.startAssessment ? "/questions/assessment" : "/")
    }

    return (
        <ComponentStepper topImage={AircraftImage} bottomImage={FactoryImage} object={functionCreation} steps={BusinessFunctionWizardSteps} onComplete={onComplete}
                          onNext={onNext} onFinish={onFinish}/>
    )
}
export { BusinessFunctionWizard }