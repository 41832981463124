import React, { ReactElement } from 'react';

import { Card, Chip, Grid, Typography } from "@mui/material";

import { ConfirmationProps } from "wizards/company/components/Confirmation/Confirmation";
import { nicifyString } from "common/helpers/Regex/regex";

import "../../styles.scss";

const SectorConfirmation: React.FC<ConfirmationProps> = ({object}) => {

    const renderChips = (elements: any[]): ReactElement[] => {
        return (
            elements.map((t, i) => {
                return <Grid item key={i}><Chip variant={"outlined"} color={"primary"} label={nicifyString(t.value)}/></Grid>
            })
        )
    } 
    
    return (
        <Card className={"card"} variant={"outlined"}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant={"h6"}><b>Sectors</b></Typography>
                </Grid>
                <Grid item container justifyContent={"flex-start"} spacing={1}>
                    <Grid item xs={12}><Typography variant={"body1"}>Company Types</Typography></Grid>
                    <Grid container item spacing={1}>
                        {renderChips(object.types)}
                    </Grid>
                </Grid>
                <Grid item container justifyContent={"flex-start"} spacing={1}>
                    <Grid item xs={12}><Typography variant={"body1"}>Organisational</Typography></Grid>
                    <Grid container item spacing={1}>
                        {renderChips(object.sectors)}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}
export { SectorConfirmation }