import axios, { AxiosRequestConfig } from "axios";
import { authProvider } from "../../auth/providers/MsalAuthProvider";
import { getApplicationSelectionContext } from "../../store/selectors";
import SnackbarUtils from "../helpers/SnackBar/snackbar";
import { history } from "../../history"
const axiosApi = axios.create({
  baseURL: window.location.origin.toString(),
  headers: { "Content-Type": "application/json" }
});

axiosApi.interceptors.request.use((request) => appendAuthHeaders(request));
axiosApi.interceptors.response.use(
    Request => {
      return Request
    },
    error => {
      if(error.response.status === 401)
      {
        history.push("/unauthorized-request");
      }
      
      console.log(error)
      SnackbarUtils.error('Something went wrong...');
      return Promise.reject(Error)
    }
);


const appendAuthHeaders = async (request : any) => {
  if (isAuthEnabled(request)) {

    setApplicationSelectionHeaders(request);
    
    const accessToken = await authProvider.getAccessToken();
    request.headers.authorization = "Bearer " + accessToken.accessToken;
  }

  return request;
};

const isAuthEnabled = (config : any = {}) => {
  return config.headers.disableHandler === undefined;
};

const setApplicationSelectionHeaders = (request: AxiosRequestConfig) => {
  let applicationSelectionContext = getApplicationSelectionContext() 
  
  request.headers.CompanyContextId = applicationSelectionContext.selectedCompany?.id;
  request.headers.FunctionContextId = applicationSelectionContext.selectedFunction?.id;
}

export const AxiosApi = axiosApi