import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { Grid, Button, Typography, Checkbox } from "@mui/material";

import { WizardStepProps } from "wizards/models";
import { CompanyConfirmation } from "wizards/company/components/Confirmation/components/CompanyConfirmation/CompanyConfirmation";
import { SectorConfirmation } from "wizards/company/components/Confirmation/components/SectorConfirmation/SectorConfirmation";
import { RiskLabelsConfirmation } from "wizards/company/components/Confirmation/components/RiskLabelsConfirmation/RiskLabelConfirmation";
import { UserInvitationConfirmation } from "wizards/company/components/Confirmation/components/UserInvitationConfirmation/UserInvitationConfirmation";
import { userSettingsSelector } from "user/store/selectors";

import './styles.scss';

export interface ConfirmationProps {
    object: any;
}

const Confirmation: React.FC<WizardStepProps> = ({object, onBackClicked, onContinueClicked}) => {

    const [defaultCompany, setDefaultCompany] = useState<boolean>(false);

    const userSettings = useSelector(userSettingsSelector);

    useEffect(() => {
        setDefaultCompany && setDefaultCompany(userSettings.defaultCompanyId === null)
    }, [userSettings.defaultCompanyId])


    const onClick = () => {
        onContinueClicked({
            ...object,
            defaultCompany: defaultCompany
        });
    }

    const handleChange = (event: any) => {
        setDefaultCompany && setDefaultCompany(event.target.checked);
    }

    return (
        <Grid className={"confirmation topBottomGutters"} container item xs={10} spacing={2}>
            <Grid item container>
                <Grid item xs={12}>
                    <Typography variant={"h4"}><b>Confirmation</b></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color={"secondary"} variant={"h6"}>Please confirm all the details below are
                        correct</Typography>
                </Grid>
            </Grid>

            <Grid container item>
                <CompanyConfirmation object={object.company}/>
            </Grid>

            <Grid container item>
                <SectorConfirmation object={object}/>
            </Grid>

            <Grid container item>
                <RiskLabelsConfirmation object={object.riskSettings}/>
            </Grid>

            <Grid container item>
                <UserInvitationConfirmation object={object.invites}/>
            </Grid>

            <Grid container item justifyContent={"space-between"} alignContent={"center"}>
                <Grid item container xs={6} justifyContent={"flex-start"}>
                    <Button
                        color={"primary"}
                        variant={"outlined"}
                        size={"large"}
                        onClick={onBackClicked}
                    >Previous</Button>
                </Grid>
                <Grid item container xs={6} justifyContent={"flex-end"} spacing={2}>
                    <Grid item>
                        <Typography variant={"caption"}>Set this as my default Company</Typography>
                        <Checkbox
                            disabled={userSettings.defaultCompanyId === null}
                            checked={defaultCompany}
                            onChange={handleChange}
                            name={"defaultCompany"}
                            color={"primary"}
                            size={"small"}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            onClick={onClick}
                        >Create Company</Button>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}
export { Confirmation }