import {
    ActionTypes,
    SET_DEFAULT_COMPANY,
    SET_SELECTED_COMPANY,
    SET_SELECTED_FUNCTION,
    SET_SELECTED_FUNCTION_LOADING,
    SET_USER,
    SET_USER_NOTIFICATIONS,
    SET_USER_SETTINGS
} from "./actionTypes";

import { UserState } from "./Model"
import { userDetailsInitialState, userSettingsInitialState } from "common/dtos/Users/models";

const initialState: UserState = {
    user: userDetailsInitialState,
    userSettings: userSettingsInitialState,
    selectedCompany: undefined,
    selectedFunction: undefined,
    selectedFunctionLoading: true,
    notifications: []
}

export function user(
    state = initialState,
    action: ActionTypes
): any {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };
        case SET_USER_SETTINGS:
            return {
                ...state,
                userSettings: action.payload
            }
        case SET_DEFAULT_COMPANY:
            return {
                ...state,
                userSettings: {
                    ...state.userSettings,
                    defaultCompanyId: action.payload
                }
            }
        case SET_SELECTED_COMPANY:
            return {
                ...state,
                selectedCompany: action.payload
            }
        case SET_SELECTED_FUNCTION:
            return {
                ...state,
                selectedFunction: action.payload
            }
        case SET_SELECTED_FUNCTION_LOADING:
            return {
                ...state,
                selectedFunctionLoading: action.payload
            }
        case SET_USER_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            }
        default:
            return state
    }
}
