import { reportingService } from "common/services/ReportingService/reportingService";
import {
    ActivityComplianceRatingSummaryDto,
    ActivityComplianceRatingDto,
    ObligationComplianceRatingDto,
    ObligationComplianceRatingSummaryDto,
    ComplianceOverTimeDto,
    QuestionReviewDto,
    TrackingYourComplianceScoreDto,
    OverallStatusDistributionDto, ObligationStatusDto
} from "common/services/ReportingService/models";

export function getActivityComplianceSummary(): Promise<ActivityComplianceRatingSummaryDto[]>{
    return reportingService.getActivityComplianceSummary()
}

export function getActivityComplianceRating(): Promise<ActivityComplianceRatingDto[]>{
    return reportingService.getActivityComplianceRating()
}

export function getCompanyComplianceOverTime(): Promise<ComplianceOverTimeDto[]>{
    return reportingService.getCompanyComplianceOverTime()
}

export function getComplianceOverTimeByObligationTop5(): Promise<ComplianceOverTimeDto[]>{
    return reportingService.getComplianceOverTimeByObligationTop5()
}

export function getComplianceOverTimeByObligation(): Promise<ComplianceOverTimeDto[]>{
    return reportingService.getComplianceOverTimeByObligation()
}

export function getHighLevelObligationComplianceRatingSummary(): Promise<ObligationComplianceRatingSummaryDto[]>{
    return reportingService.getHighLevelObligationComplianceRatingSummary()
}

export function getHighLevelObligationComplianceRating(): Promise<ObligationComplianceRatingDto[]>{
    return reportingService.getHighLevelObligationComplianceRating()
}

export function getObligationComplianceRatingSummary(): Promise<ObligationComplianceRatingSummaryDto[]>{
    return reportingService.getObligationComplianceRatingSummary()
}
 
export function getObligationComplianceRating(): Promise<ObligationComplianceRatingDto[]>{
    return reportingService.getObligationComplianceRating()
}
export function getQuestionsReviewList(): Promise<QuestionReviewDto[]>{
    return reportingService.getQuestionsReviewList()
}

export function getTrackingYourComplianceScore(): Promise<TrackingYourComplianceScoreDto[]>{
    return reportingService.getTrackingYourComplianceScore()
}

export function getObligationStatusDistribution(): Promise<ObligationStatusDto>{
    return reportingService.getObligationStatusDistribution()
}
export function getOverallStatusDistribution(): Promise<OverallStatusDistributionDto[]>{
    return reportingService.getOverallStatusDistribution()
}


