import React, { useEffect, useState } from 'react';

import { Container } from '@mui/material';

import { getCompanyComplianceOverTime } from "../../store/actions";
import { ComplianceOverTimeDto } from "common/services/ReportingService/models";
import { BumpChart } from "./bumpChart/BumpChart";

import "./styles.scss"

const CompanyComplianceOverTime = () => {

    const [companyCompliance, setCompanyCompliance] = useState<ComplianceOverTimeDto[]>([]);

    useEffect(() => {
        getCompanyComplianceOverTime().then((response) => {
            setCompanyCompliance(response);
        })
    }, [setCompanyCompliance])

    return (
        companyCompliance &&
            <Container className={"containerWrapper"} maxWidth={"xl"}>
                <BumpChart data={companyCompliance}/>
            </Container>
       )
}

export { CompanyComplianceOverTime }