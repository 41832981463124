import React from 'react'
import { Typography } from "@mui/material";

export interface NumberDisplayProps {
    title: string;
    amount: number;
    amountColor: "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error" | undefined;
    titleColor: "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error" | undefined;
}

const NumberDisplay: React.FC<NumberDisplayProps> = ({title, titleColor, amount, amountColor}) => {
    
    return (
        <React.Fragment>
            <Typography variant={"h5"} color={amountColor}><b>{amount}</b></Typography>
            <Typography color={titleColor}>{title}</Typography>
        </React.Fragment>
    )
}
export { NumberDisplay }