import React, { useCallback } from 'react';
import { NotificationType, UserNotification } from "common/dtos/Users/models";
import { ErrorOutlineRounded } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { history } from '../../../../../../history';

export interface NotificationItemProps {
    notification: UserNotification;
    closeNotifications: () => void;
    dismissNotification: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({notification, closeNotifications, dismissNotification}) => {
        
    const getIconType = useCallback((type: NotificationType) => {
        switch (type) {
            case NotificationType.ALERT:
                return <ErrorOutlineRounded fontSize={"large"}/>
        }
    }, [])
    
    const onGoToClicked = useCallback(() => {
        
        if(notification.link)
            history.push(notification.link);

        dismissNotification();
        closeNotifications();
    }, [dismissNotification, closeNotifications, notification.link])
    
    const onDismissClicked = useCallback(() => {
        dismissNotification();
    }, [dismissNotification])
    

    return (
        <Grid container item justifyItems={"space-between"} alignItems={"center"} spacing={1}>
            <Grid item xs={1}>
                {getIconType(notification.icon)}
            </Grid>
            <Grid container item xs={9}>
                <Grid item xs={12}>
                    <Typography variant={"body1"} color={"textPrimary"}
                                fontWeight={600}>{notification.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"body2"} color={"textSecondary"}>{notification.message}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={2}>
                {notification.link &&
                <Grid item xs={12}>
                    <Button onClick={onGoToClicked} size={"small"}>Go To</Button>
                </Grid>
                }
                <Grid item xs={12}>
                    <Button onClick={onDismissClicked} size={"small"}>Dismiss</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
export { NotificationItem }