import React, { RefObject } from 'react';
import { FormControl, Grid, TextField } from "@mui/material";
import { fieldProps } from "common/theming/models";
import { AvatarButton } from "common/components/input/fields/AvatarButton/AvatarButton";
import { getUserInitials } from "common/helpers/Regex/regex";
import { Formik, FormikProps } from "formik";
import {
    userDetailsFormInitialState,
    UserDetailsFormState,
    userDetailsFormValidationSchema
} from "settings/general/components/UserDetailsForm/models";
import { nameof } from "ts-simple-nameof";
import { UserDetailsDto } from "common/dtos/Users/models";

export interface UserDetailsFormProps {
    user: UserDetailsDto;
    formikRef: RefObject<FormikProps<UserDetailsFormState>>;
    onSubmit: (formState: UserDetailsFormState) => void;
    onChange?: () => void;
}

const UserDetailsForm: React.FC<UserDetailsFormProps> = ({user, onSubmit, formikRef, onChange}) => {

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                ...userDetailsFormInitialState,
                ...user
            }}
            onSubmit={onSubmit}
            validateOnChange={false}
            validationSchema={userDetailsFormValidationSchema}
            enableReinitialize
        >
            {(props: FormikProps<UserDetailsFormState>) => {

                const {errors, touched, handleChange, values} = props;
                
                const onFieldChange = (e: any) => {
                    onChange && onChange();
                    handleChange(e);   
                }

                return (
                    <FormControl size={"small"} variant={"outlined"}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <AvatarButton
                                    onClick={() => {
                                    }}
                                    text={getUserInitials(user)}
                                />
                            </Grid>
                            <Grid container xs={10} item spacing={2} justifyContent={"flex-end"}>
                                <Grid item xs={12}>
                                    <TextField
                                        {...fieldProps}
                                        name={nameof<UserDetailsFormState>(u => u.identityProviderUserName)}
                                        label={"Username"}
                                        value={values.identityProviderUserName}
                                        onChange={onFieldChange}
                                        error={touched.identityProviderUserName && Boolean(errors.identityProviderUserName)}
                                        helperText={touched.identityProviderUserName && errors.identityProviderUserName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        {...fieldProps}
                                        name={nameof<UserDetailsFormState>(u => u.firstName)}
                                        label={"First Name"}
                                        value={values.firstName}
                                        onChange={onFieldChange}
                                        error={touched.firstName && Boolean(errors.firstName)}
                                        helperText={touched.firstName && errors.firstName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        {...fieldProps}
                                        name={nameof<UserDetailsFormState>(u => u.surname)}
                                        label={"Surname"}
                                        value={values.surname}
                                        onChange={onFieldChange}
                                        error={touched.surname && Boolean(errors.surname)}
                                        helperText={touched.surname && errors.surname}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField  {...fieldProps}
                                                label={"Email"}
                                                value={user.email}
                                                disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormControl>
                );
            }}
        </Formik>
    );
}
export { UserDetailsForm }