import React, { useCallback, useState } from 'react';

import { Card, Fade, Grid, Modal, Switch, Typography } from "@mui/material";

import {
    ObligationCategoryAdditionalDetailsDto,
    ObligationCategoryDetailsDto,
    ObligationCategoryDto
} from "common/dtos/Obligations/models";
import { flattenObject } from "common/helpers/Object/object";
import { ResponsiveTable } from "common/components/tables/ResponsiveTable/ResponsiveTable";
import { Pagination } from "common/components/tables/TablePaginator/models";

import { autoAppliedObligationTableDefinition } from "assetManagement/components/AutoAppliedObligations/models";

export interface AutoAppliedObligationModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    obligations: ObligationCategoryDto[];
    onAutoAssignClicked: (obligation: ObligationCategoryDto, checked: boolean) => Promise<void>;
}

const AutoAppliedObligationModal: React.FC<AutoAppliedObligationModalProps> = ({open, setOpen, obligations, onAutoAssignClicked}) => {

    const [pagination, setPagination] = useState<Pagination>({pageNumber: 0, rowsPerPage: 10});

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const onPageChanged = useCallback((e, newPage) => {
        setPagination(prevState => {
            return {
                ...prevState,
                pageNumber: newPage
            }
        })
    }, [])

    const onRowPerPageChanged = useCallback((e) => {
        setPagination(prevState => {
            return {
                ...prevState,
                rowsPerPage: e.target && e.target.value
            }
        })
    }, [])
    
    const onSwitchClicked = useCallback((obligation: ObligationCategoryDto) => (e: any) => {
        
        const checked = e.target.checked;

        onAutoAssignClicked(obligation, checked).then(_ => {
            
        }).catch(_ => {
            e.target.checked = !checked;
        })
        
    }, [onAutoAssignClicked]);

    return (
        <Modal
            className={"modal"}
            open={open}
            onClose={handleClose}
        >
            <Fade in={open}>
                <Grid container item xs={10} sm={8} md={6}>
                    <Card className={"modal-content"}>
                        <Grid container justifyContent={"space-between"} spacing={2}>
                            <Grid item>
                                <Grid item>
                                    <Typography variant={"h6"}><b>Automatically Applied Obligations</b></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"body1"} color={"textSecondary"}>
                                        Manage Obligations to be automatically applied to both <b>new</b> and <b>existing</b> assets relating to this company function.
                                    </Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        Please note only Obligations with an Owner assigned against them, will appear here.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <ResponsiveTable
                                    columns={autoAppliedObligationTableDefinition}
                                    rows={obligations.map(o => flattenObject(o, 1))}
                                    totalRows={obligations.length}
                                    loading={false}
                                    onPageChanged={onPageChanged}
                                    onRowPerPageChanged={onRowPerPageChanged}
                                    pagination={pagination}
                                    actions={(row: ObligationCategoryDetailsDto | ObligationCategoryAdditionalDetailsDto) => {

                                        const obligationCategory: ObligationCategoryDto | undefined = obligations.find(o => o.details.id === row.id);

                                        if (obligationCategory === undefined)
                                            return <></>

                                        const autoAssigned = obligationCategory.additionalDetails?.autoApplied ?? false;
                                        
                                        return (
                                            <Switch
                                                checked={autoAssigned}
                                                onChange={onSwitchClicked(obligationCategory)}
                                                name="Auto Assigned"
                                                color={"primary"}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Fade>
        </Modal>
    );
}
export { AutoAppliedObligationModal }