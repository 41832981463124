import React from 'react';

import { Grid, TextField } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik";

import { AddressDto } from "./models";
import {fieldProps} from "../../../../theming/models";
import { nameof } from "ts-simple-nameof";

export interface AddressFormProps {
    handleChange: (e: any) => void;
    values: AddressDto;
    errors?: FormikErrors<AddressDto>;
    touched?: FormikTouched<AddressDto>;
    variant: "outlined" | "standard" | "filled" | undefined;
    namePrefix: string;
}

const AddressForm: React.FC<AddressFormProps> = ({
                                                     handleChange,
                                                     values,
                                                     errors,
                                                     touched,
                                                     namePrefix,
                                                     variant,
                                                 }) => {
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    {...fieldProps}
                    name={`${namePrefix}.${nameof<AddressDto>(a => a.addressLine1)}`}
                    label={"Address Line 1"}
                    value={values?.addressLine1 || ""}
                    onChange={handleChange}
                    error={touched?.addressLine1 && Boolean(errors?.addressLine1)}
                    helperText={touched?.addressLine1 && errors?.addressLine1}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...fieldProps}
                    name={`${namePrefix}.${nameof<AddressDto>(a => a.addressLine2)}`}
                    label={"Address Line 2"}
                    value={values?.addressLine2 || ""}
                    onChange={handleChange}
                    error={touched?.addressLine2 && Boolean(errors?.addressLine2)}
                    helperText={touched?.addressLine2 && errors?.addressLine2}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    {...fieldProps}
                    name={`${namePrefix}.${nameof<AddressDto>(a => a.locality)}`}
                    label={"Locality"}
                    value={values?.locality || ""}
                    onChange={handleChange}
                    error={touched?.locality && Boolean(errors?.locality)}
                    helperText={touched?.locality && errors?.locality}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    {...fieldProps}
                    name={`${namePrefix}.${nameof<AddressDto>(a => a.postcode)}`}
                    label={"Postcode"}
                    value={values?.postcode || ""}
                    onChange={handleChange}
                    error={touched?.postcode && Boolean(errors?.postcode)}
                    helperText={touched?.postcode && errors?.postcode}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    {...fieldProps}
                    name={`${namePrefix}.${nameof<AddressDto>(a => a.country)}`}
                    label={"Country"}
                    value={values?.country || ""}
                    onChange={handleChange}
                    error={touched?.country && Boolean(errors?.country)}
                    helperText={touched?.country && errors?.country}
                />
            </Grid>
        </Grid>
    )
}
export { AddressForm }