import { AxiosApi } from "../../axios/axiosApi";
import SnackbarUtils from "../../helpers/SnackBar/snackbar";
import { UserDetailsDto, userDetailsInitialState } from "../../dtos/Users/models";
import { nameof } from "ts-simple-nameof";
import { isEmptyOrSpaces } from "../../helpers/Regex/regex";
import { odataUrlBuilder } from "../../helpers/ODataUrlBuilder/ODataUrlBuilder";
import {CreateUnclaimedUserRequest} from "./models";
import { PermissionsRequest, UserCompanyPermission } from "auth/store/Model";
import { CompanyUserDto } from "common/dtos/Companies/models";
import { UserDetailsFormState } from "settings/general/components/UserDetailsForm/models";

class UserService {
    private controllerName = "api/User";

    public async setDefaultCompany(companyId: number): Promise<void> {

        const url: string = `${this.controllerName}/SetDefaultCompany?companyId=${companyId}`;
        
        return await AxiosApi.post(url).then(_ => {
            SnackbarUtils.success('Default company set...')
            return;
        }).catch(e => {
            return;
        });
    }

    public async setDefaultFunction(functionId: number): Promise<void> {

        const url: string = `${this.controllerName}/SetDefaultFunction?functionId=${functionId}`;

        return await AxiosApi.post(url).then(_ => {
            SnackbarUtils.success('Default function set...')
            return;
        }).catch(e => {
            return;
        });
    }
    
    public async inviteUsersToCompany(request: CreateUnclaimedUserRequest): Promise<CompanyUserDto[]> {

        const url: string = `${this.controllerName}/InviteUsersToCompany`;

        return await AxiosApi.post(url, JSON.stringify(request)).then(response => {
            SnackbarUtils.success('Invitations Sent Successfully!');
            return response.data;
        }).catch(e => {
            return [];
        });
        
    }
    
    public async getUsersByName(name: string): Promise<UserDetailsDto[]> {
        
        odataUrlBuilder.Reset();

        if(!isEmptyOrSpaces(name))
            odataUrlBuilder.Filters([
                { key: nameof<UserDetailsDto>(u => u.firstName), value: name },
                { key: nameof<UserDetailsDto>(u => u.surname), value: name },
                { key: nameof<UserDetailsDto>(u => u.identityProviderUserName), value: name },
        ]);

        const url: string = odataUrlBuilder
            .Skip(0)
            .Top(10)
            .Build(`${this.controllerName}/GetUsersByName`);
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return;
        })
    }

    public async getUserPermissions(userId: number): Promise<UserCompanyPermission[]> {
        
        const url: string = `${this.controllerName}/UserPermissions/${userId}`; 
            
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return;
        })
    }
    
    public async updateUserPermissions(request: PermissionsRequest): Promise<UserCompanyPermission[]> {
        const url: string = `${this.controllerName}/UpdateCompanyUserPermissions`;

        return await AxiosApi.post(url, JSON.stringify(request)).then(response => {
            SnackbarUtils.success('Permissions Updated Successfully!');
            return response.data;
        }).catch(e => {
            return;
        })
    }
    
    public async isEmailUnique(email: string): Promise<boolean> {
        
         const url: string = `${this.controllerName}/IsEmailUnique?email=${email}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return false;
        })
    }
    
    public async updateUserDetails(request: UserDetailsFormState): Promise<UserDetailsDto> {
        const url: string = `${this.controllerName}/UpdateUserDetails`;
        
        return await AxiosApi.post(url, JSON.stringify(request)).then(response => {
            return response.data;
        }).catch(e => {
            return userDetailsInitialState;
        })
    }
}

const userService = new UserService();
export { userService }