import React, { useEffect, useState } from 'react';

import { Button, Checkbox, FormControl, Grid, TextField, Typography } from "@mui/material";

import { nameof } from "ts-simple-nameof";

import {
    getValuesFromEnum,
    renderEnumAsMenuItem,
} from "common/helpers/Object/object";
import { ActivityCategoryDetailsDto, QuestionAnswers } from "common/dtos/Questions/QuestionDetails/models";

import {
    QuestionFilters,
    QuestionState
} from "questions/Questions/components/QuestionFilters/models";
import { DelegationType } from "common/dtos/Common/delegation";
import { getFunctionObligationCategories } from "questions/Question/actions";
import Autocomplete from '@mui/material/Autocomplete';
import { fieldProps } from "common/theming/models";
import { NotchedSelect } from "common/components/input/fields/NotchedSelect/NotchedSelect";

import { ObligationCategoryDetailsDto } from "common/dtos/Obligations/models";
import { getActivityCategories } from "activityCategories/actions";

import "./styles.scss"

export interface FiltersFormProps {
    onSubmit: (questionFilters: QuestionFilters) => void,
    filters: QuestionFilters,
    clearFilter: () => void
}

export const FiltersForm: React.FC<FiltersFormProps> = ({onSubmit, filters, clearFilter}) => {
    // const [sectors, setSectors] = useState<any[]>([]);
    const [obligationCategories, setObligationCategories] = useState<ObligationCategoryDetailsDto[]>([]);
    const [activityCategories, setActivityCategories] = useState<ActivityCategoryDetailsDto[]>([]);

    // useEffect(() => {
    //     lookupService.getSectors(true, true).then((response) => {
    //         setSectors(response);
    //     })
    // }, [setSectors])

    useEffect(() => {
        getFunctionObligationCategories().then(response => {
            setObligationCategories(response.obligationCategory.map(o => o.details));
        });
    }, [setObligationCategories])

    useEffect(() => {
        getActivityCategories().then(response => {
            setActivityCategories(response);
        });
    }, [setActivityCategories])

    const onFormSubmit = (values: any) => {
        onSubmit(values)
    }

    const onChange = (event: any) => {
        if(event.target.hasOwnProperty("checked"))
        {
            onSubmit({
                ...filters,
                [event.target.name]: event.target.checked
            });

            return;
        }

        onSubmit({
            ...filters,
            [event.target.name]: event.target.value
        });
    }

    const onChangeList = (event: any, value: any, name: string) => {
        onSubmit({
            ...filters,
            [name]: value
        });
    }

    const onClearFilters = () => {
        clearFilter();
    }

    return (
        <form className={"filters"} onSubmit={() => {onFormSubmit(filters)}}>
            <FormControl size={"small"} variant={"outlined"}>
                <Grid container item spacing={2}>
                    <Grid item xs={12}>
                        <NotchedSelect
                            label={"State"}
                            name={nameof<QuestionFilters>((q) => q.questionState)}
                            value={filters.questionState}
                            displayEmpty
                            onChange={onChange}
                        >
                            {renderEnumAsMenuItem(QuestionState)}
                        </NotchedSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <NotchedSelect
                            label={"Implementation Status"}
                            name={nameof<QuestionFilters>((q) => q.answerValue)}
                            value={filters.answerValue || "All"}
                            displayEmpty
                            onChange={onChange}
                        >
                            {renderEnumAsMenuItem(QuestionAnswers, undefined, true, "All")}
                        </NotchedSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            getOptionLabel={(option: DelegationType) => DelegationType[option]}
                            value={filters.delegationTypes}
                            options={getValuesFromEnum(DelegationType)}
                            onChange={(e, value) => onChangeList(e,value, nameof<QuestionFilters>((q) => q.delegationTypes))}
                            multiple
                            renderInput={(params) => {
                                return (<TextField label={"Delegation Type"} {...params} {...fieldProps} />)
                            }}
                        />
                    </Grid> 
                    {/*<Grid item xs={12}>*/}
                    {/*    <Autocomplete*/}
                    {/*        getOptionLabel={(option: LookupDto<string>) => option.value}*/}
                    {/*        value={filters.sectors}*/}
                    {/*        options={sectors}*/}
                    {/*        onChange={(e, value) => onChangeList(e,value, nameof<QuestionFilters>((q) => q.sectors))}*/}
                    {/*        multiple*/}
                    {/*        renderInput={(params) => {*/}
                    {/*            return (<TextField label={"Sectors"} {...params} {...fieldProps} />)*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <Autocomplete
                            getOptionLabel={(option: ObligationCategoryDetailsDto) => option.name}
                            value={filters.obligationCategories}
                            options={obligationCategories}
                            onChange={(e, value) => onChangeList(e,value, nameof<QuestionFilters>((q) => q.obligationCategories))}
                            multiple
                            renderInput={(params) => {
                                return (<TextField label={"Obligation Category"} {...params} {...fieldProps} />)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            getOptionLabel={(option: ActivityCategoryDetailsDto) => option.name}
                            value={filters.activityCategories}
                            options={activityCategories}
                            onChange={(e, value) => onChangeList(e,value, nameof<QuestionFilters>((q) => q.activityCategories))}
                            multiple
                            renderInput={(params) => {
                                return (<TextField label={"Activity Category"} {...params} {...fieldProps} />)
                            }}
                        />
                    </Grid>
                    {/*<Grid container item justifyContent={"space-between"} alignItems={"center"}>*/}
                    {/*    <Grid item>*/}
                    {/*        <Typography variant={"body2"}>Risks</Typography>*/}
                    {/*        <Typography variant={"caption"}>Show Tracked Activities</Typography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item>*/}
                    {/*        <Checkbox*/}
                    {/*            name={nameof<QuestionFilters>((q) => q.trackedActivities)}*/}
                    {/*            color={"primary"}*/}
                    {/*            checked={filters.trackedActivities}*/}
                    {/*            onChange={onChange}*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid container item justifyContent={"space-between"} alignItems={"center"}>*/}
                    {/*        <Grid item>*/}
                    {/*            <Typography variant={"caption"}>Show Tracked Obligations</Typography>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid item>*/}
                    {/*            <Checkbox*/}
                    {/*                name={nameof<QuestionFilters>((q) => q.trackedObligations)}*/}
                    {/*                color={"primary"}*/}
                    {/*                checked={filters.trackedObligations}*/}
                    {/*                onChange={onChange}*/}
                    {/*            />*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Typography variant={"body2"}>Tracked Questions</Typography>
                            <Typography variant={"caption"}>Show Tracked Questions</Typography>
                        </Grid>
                        <Grid item>
                            <Checkbox
                                name={nameof<QuestionFilters>((q) => q.trackedQuestionGroups)}
                                color={"primary"}
                                checked={filters.trackedQuestionGroups}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant={"outlined"} color={"warning"} fullWidth onClick={onClearFilters}>Clear</Button>
                    </Grid>
                </Grid>
            </FormControl>
        </form>
    );
};
