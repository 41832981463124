import { AuthenticationState, UserCompanyPermission } from "./Model";
import { createSelector } from "reselect";
import { IAccountInfo } from "react-aad-msal";
import { ApplicationState } from "../../store";

const selectorState = (state: ApplicationState): AuthenticationState =>
  state.authentication;

export const userSelector = createSelector(
  selectorState,
  (authenticationState: AuthenticationState): IAccountInfo => {
    return authenticationState.userClaims;
  }
);

export const validatedSelector = createSelector(
  selectorState,
  (authenticationState: AuthenticationState): boolean | undefined => {
    return authenticationState.validated;
  }
);

export const companyUserPermissions = createSelector(
    selectorState,
    (authenticationState: AuthenticationState): UserCompanyPermission[] => {
        return authenticationState.companyUserPermission;
    }
);
