import React, { useCallback, useEffect, useState } from 'react';
import moment from "moment";
import { Box, Collapse, Grid, Link, Typography } from "@mui/material";
import { HistoryActivityComponentProps } from "./models";

import "./styles.scss"
import { nicifyString } from "common/helpers/Regex/regex";

const HistoryAnswer: React.FC<HistoryActivityComponentProps> = ({historyItem, options}) => {
    
    const [showJustification, setShowJustification] = useState<boolean>(false);
    
    const toggleJustification = useCallback(() => { setShowJustification(prevState =>  !prevState) }, [])
    
    useEffect(() => {
        setShowJustification(options.showJustification)
    }, [options])
    
    return (
        <Grid container>
            <Grid item container alignContent={"center"} spacing={2}>
                <Grid item>
                    <Typography variant={"body2"}>
                        <b>{historyItem.userDetails.identityProviderUserName}</b> answered <b>Question {historyItem.id}</b> with <b>{nicifyString(historyItem.content)}</b> <span className={"date"}>{moment.utc(historyItem.lastUpdatedDate).fromNow()}</span>
                    </Typography>
                </Grid>
                <Grid item>
                    <Link component={"button"} onClick={toggleJustification}>{!showJustification ? "Show" : "Hide"} Justification</Link>
                </Grid>
            </Grid>
            <Collapse in={showJustification} collapsedSize={0}>
                <Grid item xs={12}>
                    <Box className={"justificationContainer"}>
                        <Typography variant={"body2"}>
                            {historyItem.additionalContent}
                        </Typography>
                    </Box>
                </Grid>
            </Collapse>
        </Grid>
        
    )
}
export { HistoryAnswer }