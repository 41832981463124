import { AxiosApi } from "../../axios/axiosApi";
import { FunctionDetailsDto, functionDetailsInitialState } from "../../dtos/Businesses/FunctionDetailsDto";
import SnackbarUtils from "../../helpers/SnackBar/snackbar";
import { SectorTypesDto } from "common/dtos/Questions/Sectors/models";
import { FunctionSectorsResponse } from "common/responses/FunctionSectorsResponse";
import { FunctionCreation } from "wizards/businessFunctions/models";

class FunctionService {
    private controllerName = "api/Functions";

    public async createFunction(functionCreation: FunctionCreation): Promise<FunctionDetailsDto> {
        const url: string = `${this.controllerName}`;

        return await AxiosApi.post(url, JSON.stringify(functionCreation)).then(response => {
            SnackbarUtils.success('Function has been created...');
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getCompanyFunctions(): Promise<FunctionDetailsDto[]> {
        const url: string = `${this.controllerName}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getFunctionById(functionId: number): Promise<FunctionDetailsDto> {
        const url: string = `${this.controllerName}/GetFunctionById/${functionId}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return functionDetailsInitialState;
        })
    }

    public async updateFunctionDetails(functionDetails: FunctionDetailsDto): Promise<void> {
        const url: string = `${this.controllerName}/UpdateFunctionDetails`;
        SnackbarUtils.success("Updated successfully")

        return await AxiosApi.post(url, JSON.stringify(functionDetails)).then(response => {
            SnackbarUtils.success('Function details successfully updated!');
            return response.data
        }).catch(e => {
            return;
        });
    }

    public async getSelectCompanyDefaultFunction(): Promise<FunctionDetailsDto> {
        const url: string = `${this.controllerName}/GetSelectedCompanyDefaultFunction`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return false;
        })
    }

    public async isFunctionUnique(name: string, excludingCurrent: boolean): Promise<boolean> {
        const url: string = `${this.controllerName}/IsFunctionUnique/${name}?excludingCurrent=${excludingCurrent}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return false;
        })
    }

    public async getFunctionSectors(): Promise<FunctionSectorsResponse> {
        const url: string = `${this.controllerName}/GetFunctionSectors`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async setFunctionSectors(sectors: SectorTypesDto[]): Promise<void> {
        const url: string = `${this.controllerName}/SetFunctionSectors`;
        SnackbarUtils.success("Updated successfully")

        return await AxiosApi.post(url, JSON.stringify(sectors)).then(_ => {
            return;
        });
    }
}

const functionService = new FunctionService();
export { functionService }