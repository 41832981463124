import React from 'react'
import { Grid} from "@mui/material";

import './styles.scss';

const TermsSettings: React.FC = () => {

    return (
        <React.Fragment>
            <Grid xs={12} container item>
                
                <div className={'termsDivSettings'}>
                
                <h3>Purpose</h3>
                <p>This application is intended to present sample information management activities that are likely to be relevant to the type and jurisdiction of organization that you state, including from customer sectors that you feel that you may operate within, and is intended to provide a reference point for assessment of governance maturity regarding information management within organizations. 
                <br/><br/>Its continued use is to be taken as an explicit acceptance of the conditions detailed below.</p>
                    <br/> 
                <h3>Disclaimer</h3>

                    <p></p>

                    <p>These activities are drawn from sample information management obligations your organization has from the legal requirements held within the legislation of England &amp; Wales, Scotland and Northern Ireland.&#8239;&#8239;It does not consider other obligations that would be deemed to be contractual (e.g. PCI DSS, PSN etc.), nor European directives that have not been integrated into these statues.</p>

                    <p></p>

                    <p>This application is designed to provide a general demonstration of the issues and problems that surround the information management obligations of organizations. It does not purport to be comprehensive in any way and is not intended to provide legal advice of any nature.</p>

                    <p></p>

                    <p>You must always consult a legal professional for advice regarding your legal obligations.</p>

                    <p></p>

                    <p>Any information provided is indicative only.&#8239;&#8239;It is based on the information that you provide to a list of questions that are representative of some of the questions that you need to address.&#8239;&#8239;The answers are based on an example subset of the requirements within the United Kingdom.&#8239;&#8239;This list of activities used is in no way comprehensive and is purposely designed not to be comprehensive.</p>
 
                    <br/> 
                <h3>Privacy</h3> 
                <p>The information processed within this portal is subject to the privacy notice published at https://common-framework.com/privacy/</p>
                    <br/> 
                <h3>Licence</h3>
                    <p>The information provided within this application, and all outputs generated by this application, are the copyright of The Common Framework Limited. We take no responsibility for the information that you input, or for any of the output that this application generates as a result of your input.</p>
                    <p>We do not grant you a licence to use or copy the information that this application contains, or the outputs that it generates, other than to allow you to see a general demonstration of the activities required to meet the information management obligations that may affect organizations.</p>
                    <p>All IPRs in the Software other than those expressly granted to the user of this software under this licence or in the Agreement are reserved to The Common Framework.</p>
                    <p>All goodwill generated by any use of the Software accrues to The Common Framework.</p>
                    <p>It is a condition of the licence that the user of this software shall not:</p>

                <ol className={'orderedList'}>
                    <li>permit the Software to be used by more than the specified number of users without first –</li>
                    <li>issuing to The Common Framework a PO covering the required increase in the number of users; </li>
                    <li>obtaining The Common Framework's express prior written consent (which The Common Framework will not unreasonably withhold or delay) to increase the number of users permitted to use the Software; and </li>
                    <li>following The Common Framework's reasonable system requirements; </li>
                    <li>except as provided in subclause (iii) and (iv) below, make back-up copies of the Software;</li>
                    <li>decompile, copy, or adapt any part of the Software except to correct errors in the Software;</li>
                    <li>except for the purposes expressly permitted under section 50 C or section 296 A(1) of the Copyright, Designs and Patents Act, copy, adapt or decompile any part of the Software, and if the user of this software wishes to decompile any part of the Software under its rights under the Copyright, Designs and Patents Act 1988, then the user of this software shall give 14 days' prior written notice to The Common Framework of its intent to decompile the Software and allow representatives of The Common Framework to attend the decompilation;</li>
                    <li>assign, transfer, sell, sub-license, lease, rent, charge, or otherwise deal in or encumber the Software or use the Software or Documentation on behalf of any third party or hold on trust for or make the Software or Documentation available to any third party; or </li>
                    <li>remove or alter any copyright or other proprietary notice on any of the Software or Documentation. </li>
                </ol>
                
                <p>The user of this software may make one back-up copy of the Software, and any copy that the user of this software makes shall be deemed to form part of the Software and shall be subject to the Agreement.
                <br/>Without the prior written authorisation of The Common Framework, the user of this software may not copy any part of the Documentation.
                <br/>Each paid output is licenced to the responsible officer and organisation entered through the process of the application for the period of three hundred and sixty-five days after purchase. Any changes to organisation name or responsible officer will require a new licence, as will the expiry of the licence itself.
                <br/>If you do make any other use of the information then you do so in breach of our copyright, and we take no responsibility for any loss or damage that you may suffer, whether directly or otherwise, as a consequence of that use.
                <br/>Contains public sector information from the National Cyber Security Centre, licensed under the Open Government Licence v3.0.</p>
                    <br/><br/>
                <h3>Declarations</h3>
                <p>Contains public sector information from the National Cyber Security Centre, licensed under the Open Government Licence v3.0.<br/>
                Contains public sector information from the Department of Health and Social Care, licensed under the Open Government Licence v3.0.<br/>
                Contains public sector information from the Information Commissioner's Office, licensed under the Open Government Licence v3.0.</p>
                <p>See http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/ for more details</p>
                    
                </div>                
            </Grid>
            
        </React.Fragment>
    );
}
export { TermsSettings }