import React, { useCallback, useEffect, useState } from 'react'

import { SettingsRounded } from "@mui/icons-material";
import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    ObligationRequest,
    obligationAdditionalDetailsRequestInitialState,
    ObligationCategoryDto
} from "common/dtos/Obligations/models";

import * as colours from "../../../styles/colours.scss";
import { cardProps } from "common/theming/models";
import { AutoAppliedObligationModal } from "assetManagement/components/AutoAppliedObligations/AutoAppliedObligationModal";
import { obligationsService } from "common/services/ObligationsService/obligationsService";
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "../../../user/store/selectors";

export interface AutoAppliedObligationsProps {
    onDataUpdated?: () => void;
}

const AutoAppliedObligations: React.FC<AutoAppliedObligationsProps> = ({onDataUpdated}) => {
    
    const [obligations, setObligations] = useState<ObligationCategoryDto[]>([])
    const [openModal, setOpenModal] = useState<boolean>(false);

    const selectedFunction = useSelector(selectedFunctionSelector);
    
    useEffect(() => {
        obligationsService.getObligationCategoriesWithAdditionalDetails(true).then(response => {
            setObligations(response.obligationCategory)
        })
    }, [selectedFunction]);
    
    const onSettingsClicked = useCallback(() => {
        setOpenModal(true)
    }, []);
    
    const onAutoAssignClicked = useCallback((obligation: ObligationCategoryDto, checked: boolean): Promise<void> => {
        
        const request: ObligationRequest = {
            ...obligationAdditionalDetailsRequestInitialState,
            ...obligation.details,
            ...obligation.additionalDetails,
            id: obligation.details.id,
            obligationTypeId: obligation.details.obligationType.id,
            autoApplied: checked
        }
        
        return obligationsService.updateObligation(request).then(_ => {
            
            const index = obligations.findIndex((o) => o.details.id === obligation.details.id)
            const newObligation = { ...obligation }
            
            newObligation.additionalDetails.autoApplied = checked;
            
            const newObligations = [ ...obligations ]
            newObligations[index] = newObligation;
            
            setObligations(newObligations);

            onDataUpdated && onDataUpdated();
        })
        
    }, [onDataUpdated, obligations])
    
    return (
        <Card {...cardProps}>
            <Grid container justifyContent={"flex-end"}>
                <Grid item>
                    <IconButton onClick={onSettingsClicked} size={"small"}><SettingsRounded/></IconButton>
                </Grid>
                <Grid container item alignItems={"center"} justifyContent={"center"} spacing={2}>
                    <Grid item>
                        <Box p={"2.0em"} borderRadius="undefinedpx" bgcolor={colours.primary}><FontAwesomeIcon color={"white"} size={"4x"} icon={faBook}/></Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align={"center"} variant={"body2"} color={"textSecondary"}>Obligations Applied to All Assets</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align={"center"} variant={"h3"}>
                            <b>{obligations.filter(o => o.additionalDetails?.autoApplied).length}</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <AutoAppliedObligationModal
                open={openModal}
                setOpen={setOpenModal}
                obligations={obligations}
                onAutoAssignClicked={onAutoAssignClicked}
            />
        </Card>
    );
}
export { AutoAppliedObligations }