import { UserDetailsDto, UserNotification, UserSettingsDto } from "common/dtos/Users/models";
import { CompanyDetailsDto } from "common/dtos/Companies/models";
import { FunctionDetailsDto } from "common/dtos/Businesses/FunctionDetailsDto";

export const SET_USER = "SET_USER";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const SET_DEFAULT_COMPANY = "SET_DEFAULT_COMPANY";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const SET_SELECTED_FUNCTION = "SET_SELECTED_FUNCTION";
export const SET_SELECTED_FUNCTION_LOADING = "SET_SELECTED_FUNCTION_LOADING";
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS"


interface setUser {
    type: typeof SET_USER
    payload: UserDetailsDto
}

interface setUserSettings {
    type: typeof SET_USER_SETTINGS
    payload: UserSettingsDto
}

interface setDefaultCompany {
    type: typeof SET_DEFAULT_COMPANY
    payload: number
}

interface setSelectedCompany {
    type: typeof SET_SELECTED_COMPANY,
    payload: CompanyDetailsDto | undefined
}

interface setSelectedFunction {
    type: typeof SET_SELECTED_FUNCTION,
    payload: FunctionDetailsDto | undefined
}

interface setSelectedFunctionLoading {
    type: typeof SET_SELECTED_FUNCTION_LOADING,
    payload: boolean
}

interface setUserNotifications {
    type: typeof SET_USER_NOTIFICATIONS,
    payload: UserNotification[];
}

export type ActionTypes = setUser | setUserSettings | setDefaultCompany | setSelectedCompany | setSelectedFunction | setSelectedFunctionLoading | setUserNotifications;
