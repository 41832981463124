class ODataUrlBuilder{

    private count: boolean = false;
    private skip: number = 0;
    private top: number = 0;
    private orderBy: string = "";
    private orderDirection: string = "";
    private filters: Record<string, string>[] = [];
    private totalAppendages: number = 0;
    private currentAppendages: number = 0;

    public Reset(): ODataUrlBuilder{

        this.count = false;
        this.skip = 0;
        this.top = 0;
        this.orderBy = "";
        this.filters = [];
        this.totalAppendages = 0;
        this.currentAppendages = 0;

        return this;
    }

    // Return a count of the matching resources in the response
    public Count(): ODataUrlBuilder {
        if(!this.count)
            this.totalAppendages++;

        this.count = true;
        return this;
    }

    // The number of matching resources to be skipped, excluding the from the response
    public Skip(value: number): ODataUrlBuilder {
        if(this.skip === 0)
            this.totalAppendages++;

        this.skip = value;
        return this;
    }

    // The number of matching resources to be included in the response (from the skip number)
    public Top(value: number): ODataUrlBuilder {
        if(this.top === 0)
            this.totalAppendages++;

        this.top = value;
        return this;
    }

    // The field in which the response elements should be ordered
    public OrderBy(value: string, descending: boolean = false): ODataUrlBuilder {
        if(this.orderBy === "")
            this.totalAppendages++;

        this.orderBy = value;
        this.orderDirection = !descending ? "asc" : "desc"
        return this;
    }

    // The list of filters to match against existing resources
    public Filters(value: Record<string, string>[]): ODataUrlBuilder {
        if(this.filters.length === 0){
            this.totalAppendages++;
        }

        this.filters = this.filters.concat(value);
        return this;
    }

    public Build(prefix: string): string {

        if(this.totalAppendages === 0)
            return prefix;

        let output: string = prefix;

        if(this.count){
            output+= this.formatAppendage("count=true");
            this.currentAppendages++;
        }
        if(this.skip > 0){
            output+= this.formatAppendage(`skip=${this.skip}`);
            this.currentAppendages++;
        }
        if(this.top > 0){
            output+= this.formatAppendage(`top=${this.top}`)
            this.currentAppendages++;
        }
        if(this.orderBy !== ""){
            output+= this.formatAppendage(`orderby=${this.orderBy} ${this.orderDirection}`)
            this.currentAppendages++;
        }
        if(this.filters.length > 0){
            this.filters.forEach((f, i) => {
                output+= (i === 0) ? this.formatAppendage(`filter=`) : " or ";
                output+= `contains(cast(${f.key}, 'Edm.String'),'${f.value}')`
            })

            this.currentAppendages++;
        }

        this.Reset();
        
        return output;
    }

    private formatAppendage(input: string): string {
        return this.currentAppendages === 0 ? `?$${input}` : `&$${input}`
    }
}
const odataUrlBuilder = new ODataUrlBuilder();
export { odataUrlBuilder }