import React, { useState, ReactElement } from "react";

import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";

import { MenuOption, MenuOptionProps } from "../MenuOption/MenuOption";
import { MenuUser, MenuUserProps } from "../MenuUser/MenuUser";

import "./styles.scss";

export interface SidePanelProps {
    logoPath: string;
    shorthandLogoPath: string;
    menuUserProps: MenuUserProps;
    menuOptions: MenuOptionProps[];
    footerOptions: MenuOptionProps[];
    collapsible: boolean;
    hidden: boolean;
    determineSelected?: () => void;
}

const SidePanel: React.FC<SidePanelProps> = ({
                                                 logoPath,
                                                 shorthandLogoPath,
                                                 menuUserProps,
                                                 menuOptions,
                                                 footerOptions,
                                                 collapsible,
                                                 hidden
                                             }) => {

    const [collapse, setCollapse] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>();

    const renderMenuOptions = (
        menuOptionProps: MenuOptionProps[]
    ): ReactElement[] => {
        return menuOptionProps.filter(option => !option.hidden).map((option, index) => {
            return (
                <MenuOption title={option.title}
                            icon={option.icon}
                            options={option.options}
                            selected={option.title === selected}
                            key={index}
                            onClick={(e) => handleOnClick(e, option)}
                            showText={!collapse}
                            hidden={option.hidden}
                            hideOnNoFunction={option.hideOnNoFunction}
                />
            )
        });
    };

    const handleOnClick = (e: React.MouseEvent, option: MenuOptionProps) => {
        option.onClick(e);
        setSelected(option.title);
    }

    return (
        <div hidden={hidden} className={"sidebar"}>
            <div className={"container"}>
                <div className={"header"}>
                    {!collapse ?
                        <img src={logoPath} alt={"Logo"}/> :
                        <img src={shorthandLogoPath} alt={"Shorthand Logo"}/>
                    }

                    <MenuUser
                        {...menuUserProps}
                        showText={!collapse}
                    />
                    <div className={"options"}>{renderMenuOptions(menuOptions)}</div>
                </div>
                <div className={"footer"}>
                    {renderMenuOptions(footerOptions)}
                    {collapsible && (
                        <MenuOption
                            title={"Collapse"}
                            icon={!collapse ? <ChevronLeftOutlined/> : <ChevronRightOutlined/>}
                            key={"collapse"}
                            onClick={() => setCollapse(!collapse)}
                            showText={!collapse}
                            selected={false}
                            hidden={false}
                            hideOnNoFunction={false}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
export { SidePanel };
