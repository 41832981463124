import React, { ReactElement } from "react";
import { StatusPage } from 'common/components/pages/StatusPage/StatusPage';

const PageNotFound: React.FC = () => {

    const renderMessage = (): ReactElement<any> => {
      return (
        <div>
          <p>That's strange, we couldn't find this page.</p>
          <p>But don't worry, you can find plenty of other things on our <a href={'/'}>homepage</a>.</p>
        </div>   
      )
    }

    const renderNotice = (): ReactElement<any> => {
      return (
        <div>
          <p>Do you believe you should be seeing something here?</p>
          <p><a href={'mailto:()'}>Contact us</a> and we can resolve any issues you're having.</p>
        </div>
      )
    }

    return (
      <StatusPage
        title={"404"}
        message={renderMessage}
        notice={renderNotice}
      />
    );
  };
export { PageNotFound };