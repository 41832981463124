export interface AssetTypeStatisticsDto {
    name: string;
    share: number;
    userShare: number;
}

export interface AssetStatisticsDto {
    totalAssets: number;
    accountableAssets: number;
    responsibleAssets: number;
    unassignedAssets: number;
    typeStatistics: AssetTypeStatisticsDto[];
}

export const assetStatisticsInitialState: AssetStatisticsDto = {
    accountableAssets: 0,
    responsibleAssets: 0,
    totalAssets: 0,
    unassignedAssets: 0,
    typeStatistics: []
}

export const assetTypeStatisticInitialState: AssetTypeStatisticsDto = {
    name: "",
    share: 0,
    userShare: 0
}