import React, {ChangeEvent, ReactElement, useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import {Grid, IconButton, Link, Typography} from "@mui/material";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser as faUserRegular} from "@fortawesome/free-regular-svg-icons/faUser";
import {faUser as faUserSolid} from "@fortawesome/free-solid-svg-icons/faUser";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

import {AvatarChip} from "common/components/content/AvatarChip/AvatarChip";
import {DelegatedQuestionDto} from "common/dtos/Users/models";
import {QuestionDelegationRequest} from "common/dtos/Questions/Requests/models";
import {UserDelegateAvatar} from "common/components/content/UserDelegateAvatar/UserDelegateAvatar";
import {historyHelper} from "common/components/content/History/historyHelper";
import {HistoryItem} from "common/dtos/History/models";

import {delegateUsersToQuestion, getQuestionDelegatedUsers} from "../../../../actions";
import {QuestionDelegation} from "../QuestionDelegation/QuestionDelegation";
import {userSelector} from "user/store/selectors";

import "./styles.scss";
import { getNameFromUser } from "common/helpers/Naming/naming";

export interface QuestionAssigneesProps {
    questionId: number;
    onUnassignClicked: (u: DelegatedQuestionDto) => Promise<void>;
    history: HistoryItem[];
    setHistory: (historyItems: HistoryItem[]) => void;
    sidebarOpen: boolean;
    assigneeDropdownOpen: boolean;
    setAssigneeDropdownOpen: (open: boolean) => void;
}

const QuestionAssignees: React.FC<QuestionAssigneesProps> = ({
                                                                 questionId,
                                                                 onUnassignClicked,
                                                                 history,
                                                                 setHistory,
                                                                 sidebarOpen,
                                                                 assigneeDropdownOpen,
                                                                 setAssigneeDropdownOpen
                                                             }) => {

    const [delegatedUsers, setDelegatedUsers] = useState<DelegatedQuestionDto[]>([]);
    const user = useSelector(userSelector)

    useEffect(() => {
        getQuestionDelegatedUsers(questionId).then(response => {
            setDelegatedUsers(response);
        })
    },[questionId]);

    const delegateUsers = async (request: QuestionDelegationRequest): Promise<void> => {
        await delegateUsersToQuestion(questionId, request).then(response => {
            toggleDropdown(false);

            setDelegatedUsers([
                ...delegatedUsers.filter(u => !request.unassigneeIds.includes(u.userDetailsId) && !request.assignees.some(a => a.userId === u.userDetailsId)),
                ...response
            ]);

            setHistory([
                ...history,
                ...historyHelper.delegatedQuestionsToHistoryItems(response, user)
            ]);
        }).finally(() => setAssigneeDropdownOpen(false))
    }

    const toggleDropdown = useCallback((open: boolean) => (e: ChangeEvent<any>) => {
        e.preventDefault()

        setAssigneeDropdownOpen(open)
    }, [setAssigneeDropdownOpen])

    const renderOpen = (): ReactElement => {
        return (
            <Grid container item spacing={1}>
                <Grid container item>
                    <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Typography variant={"caption"}>Assignee(s)</Typography>
                        </Grid>
                        <Grid item>
                            <Link className={`assigneeLink`} color={assigneeDropdownOpen ? "secondary" : "primary"}
                                  onClick={toggleDropdown(!assigneeDropdownOpen)}
                                  variant={"caption"}>{assigneeDropdownOpen ? "Cancel" : "Edit"}</Link>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        {delegatedUsers.length > 0 ?
                            <Grid item>
                                <AvatarChip
                                    label={"Assignees"}
                                    avatars={delegatedUsers.map((u, i) =>
                                        <UserDelegateAvatar
                                            key={i}
                                            delegate={u}
                                            onUnassignClicked={onUnassignClicked}
                                        />
                                    )}
                                />
                            </Grid>
                            :
                            <Grid item>
                                <Typography>None</Typography>
                            </Grid>
                        }

                        {delegatedUsers.length === 1 && delegatedUsers.map((dq, i) => {
                            return (
                                <Grid item key={i}>
                                    <Typography>{getNameFromUser(dq.user)}</Typography>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={12}>
                        <QuestionDelegation
                            questionId={questionId}
                            open={assigneeDropdownOpen}
                            delegatedUsers={delegatedUsers}
                            onUsersDelegated={delegateUsers}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const renderCollapsed = (): ReactElement => {

        let userIcon: IconDefinition = faUserRegular;

        if (delegatedUsers.length === 1)
            userIcon = faUserSolid;

        if (delegatedUsers.length > 1)
            userIcon = faUsers;

        return (
            <Grid container item justifyContent={"center"}>
                <Grid item>
                    <IconButton size="large">
                        <FontAwesomeIcon icon={userIcon}/>
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant={"caption"}>{delegatedUsers.length} Assignee(s)</Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {sidebarOpen ? renderOpen() : renderCollapsed()}
        </>
    );
}
export {QuestionAssignees}