import React from "react";

import { ComponentStep } from "common/components/panels/ComponentStepper/ComponentStep";

import { Welcome } from "./components/Welcome/Welcome";
import { CreationForm } from "./components/CreationForm/CreationForm";
import { AdditionalInfo } from "./components/AdditionalInfo/AdditionalInfo";
import { RiskLabels } from "./components/RiskLabels/RiskLabels";
import { CompanySelectionTypes } from "./components/AssignSectors/CompanySelectionTypes";
import { AssignSectors } from "./components/AssignSectors/AssignSectors";
import { Completion } from "./components/Completion/Completion";
import { Confirmation } from "./components/Confirmation/Confirmation";
import { InviteUsers } from "./components/InviteUsers/InviteUsers";
import { RaciAssignments } from "wizards/company/components/RaciAssignments/RaciAssignments";
import { OfficerSelection } from "wizards/company/components/OfficerSelection/OfficerSelection";
import { Terms } from "./components/Terms/Terms";
// import { Billing } from "./components/Billing/Billing";
import { companyCreationInitialState } from "wizards/company/models";

const CompanyWizardSteps: ComponentStep[] = [
    {
        label: "Welcome",
        component: <Welcome object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Terms and Conditions",
        component: <Terms object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Create a Company",
        component: <CreationForm object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Additional Information",
        component: <AdditionalInfo object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Manage Officers",
        component: <OfficerSelection object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Company Type",
        component: <CompanySelectionTypes object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Applicable Sectors",
        component: <AssignSectors object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Risk Labels",
        component: <RiskLabels object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "User Invitations",
        component: <InviteUsers object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "RACI Delegation",
        component: <RaciAssignments object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    // {
    //     label: "Billing",
    //     component: <Billing object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
    //     isVisible: true
    // },
    {
        label: "Confirmation",
        component: <Confirmation object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Completion",
        component: <Completion object={companyCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: false
    },
]
export { CompanyWizardSteps }