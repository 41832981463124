import React, { ReactElement } from 'react';

import { VerticalLine } from "common/components/misc/VerticalLine/VerticalLine";

import "./styles.scss";

export interface HistoryActivityProps {
    component: ReactElement;
    icon: ReactElement;
    showLine?: boolean;
}

const HistoryActivity: React.FC<HistoryActivityProps> = ({component, icon, showLine}) => {
    
    return (
        <React.Fragment>
            <div className={"historyActivity"}>
                <div className={"iconContainer"}>
                    <div className={"activityLabel"}>{icon}</div>
                    {showLine && <VerticalLine/>}
                </div>
                <div className={`contentContainer`}>
                    {component}
                </div>
            </div>
        </React.Fragment>
    )
}
export { HistoryActivity }