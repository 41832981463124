import React, { ReactElement } from 'react';
import { SectorsDto } from "../../../dtos/Questions/Sectors/models";
import { Chip, Grid } from "@mui/material";
import { nicifyString } from "../../../helpers/Regex/regex";

export interface SectorChipsProps {
    sectors: SectorsDto[];
}

const SectorChips: React.FC<SectorChipsProps> = ({sectors}) => {
    
    const renderSectors = (): ReactElement[] =>
        sectors.map((o: any, index: number) => {
            return (
                <Grid item key={`grid-${index}`}>
                    <Chip
                        className={"chip"}
                        key={index}
                        label={nicifyString(o.type.value)}
                        icon={o.description}
                        variant={"outlined"}
                        color={"primary"}/>
                </Grid>
            )
        })
    
    return (
        <Grid container item spacing={1}>
            {renderSectors()}
        </Grid>
    )
    
}
export { SectorChips }