import React from 'react';

import {
    Chart,
    ChartLegend,
    ChartArea,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
} from "@progress/kendo-react-charts";

import "hammerjs";
import { nameof } from "ts-simple-nameof";

import { AssetTypeStatisticsDto } from "common/dtos/Assets/AssetStatisticsDto";
import * as colours from "styles/colours.scss";

export interface AssetStatisticsChartProps {
    typeStats: AssetTypeStatisticsDto[];
}

const AssetStatisticsChart: React.FC<AssetStatisticsChartProps> = ({typeStats}) => {

    const labelContent = (props: any) => {
        let formatedShare = Number(props.dataItem.share).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 1,
        });

        let formatedUserShare = Number(props.dataItem.userShare).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 1,
        });

        return `${props.dataItem.name}: ${formatedShare}\n(${formatedUserShare} managed by you)`;
    }
    
    return (
            <Chart renderAs={"canvas"}>
                <ChartLegend visible={false}/>
                <ChartArea background={"none"}/>
                <ChartSeries>
                    <ChartSeriesItem
                        type={"donut"}
                        data={typeStats}
                        categoryField={nameof<AssetTypeStatisticsDto>(t => t.name)}
                        field={nameof<AssetTypeStatisticsDto>(t => t.share)}
                        color={colours.primary.toString()}
                    >
                        <ChartSeriesLabels
                            position="outsideEnd"
                            background={"none"}
                            content={labelContent}
                        />
                    </ChartSeriesItem>
                </ChartSeries>
            </Chart>
    )
}
export { AssetStatisticsChart }