import React, { useCallback, useState } from 'react';

import { DataResult, process, State } from "@progress/kendo-data-query";
import { Grid as GridTable } from "@progress/kendo-react-grid/dist/npm/Grid"
import { nameof } from "ts-simple-nameof";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { obligationsService } from "common/services/ObligationsService/obligationsService";
import { useValidPermission } from "auth/components/ValidateUserPermission";
import { ObligationModal } from "obligations/components/ObligationModal/ObligationModal";

import {
    ObligationCategoryDto,
    obligationCategoryInitialState,
    ObligationRequest,
} from "common/dtos/Obligations/models";

import { RiskSettingsDto } from "riskLabels/models";
import { Permission } from "auth/store/Model";

import {
    GridColumn,
    GridColumnMenuCheckboxFilter,
    GridColumnMenuProps,
    GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";

import "./styles.scss";

export interface ObligationsTableProps {
    obligationCategories: ObligationCategoryDto[];
    updateObligationCategory: (updatedObligation: ObligationCategoryDto) => void;
    deleteObligationCategory: (obligation: ObligationCategoryDto) => Promise<void>;
    loading: boolean;
    riskSettings: RiskSettingsDto[];
}

const ObligationsTable: React.FC<ObligationsTableProps> = ({
                                                               obligationCategories,
                                                               updateObligationCategory,
                                                               deleteObligationCategory,
                                                               loading,
                                                               riskSettings
                                                           }) => {

    const skip: number = 0;
    const take: number = 20;

    let initialState = obligationCategories && {
        data: obligationCategories,
        total: obligationCategories.length,
        slice: {
            take: take,
            skip: skip,
        }
    }

    const [editMode, setEditMode] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<number | undefined>(undefined);
    const [selectedObligation, setSelectedObligation] = useState<ObligationCategoryDto>(obligationCategoryInitialState);
    const [dataState, setDataState] = React.useState<State>(initialState.slice);
    const [result, setResult] = React.useState<DataResult>(initialState)

    const createDataState = (dataState: State) => {
        return {
            result: process(obligationCategories, dataState),
            dataState: dataState,
        };
    };

    const onEditClicked = useCallback((obligation: ObligationCategoryDto) => {
        setEditMode(true)
        setSelectedObligation(obligation)
    }, [setEditMode, setSelectedObligation])

    const onDeleteClicked = useCallback((obligation: ObligationCategoryDto) => {
        setDeleting(obligation.details.id)
        return deleteObligationCategory(obligation).then(_ => {
        }).finally(() => {
            setDeleting(undefined);
        })
    }, [deleteObligationCategory])

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const onSubmitClicked = useCallback((request: ObligationRequest): Promise<any> => {
        return obligationsService.updateObligation(request).then((response) => {
            updateObligationCategory(response)
        });

    }, [updateObligationCategory])

    const ColumnMenuCheckboxFilter = (props: GridColumnMenuProps) => {
        return (
            <div>
                <GridColumnMenuCheckboxFilter {...props} uniqueData={true} data={obligationCategories} expanded={true}/>
            </div>
        );
    }

    const userCanEdit = useValidPermission(Permission.ObligationsEdit);
    const userCanDelete = useValidPermission(Permission.ObligationsDelete);

    return (
        <Grid container spacing={2} className={'obligationsTableContainer'}>
            <ObligationModal
                title={`Edit ${selectedObligation.details.name}`}
                open={editMode}
                setOpen={setEditMode}
                obligationCategory={selectedObligation}
                riskSettings={riskSettings}
                onSubmit={onSubmitClicked}
            />
            <Grid item xs={12}>
                <GridTable
                    data={result.data.length > 0 ? result : obligationCategories}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={true}
                >
                    <GridColumn columnMenu={ColumnMenuCheckboxFilter}
                                field={nameof<ObligationCategoryDto>(a => a.details.name)} filter={"text"}
                                title={"Name"} width={"400px"}/>
                    <GridColumn columnMenu={ColumnMenuCheckboxFilter}
                                field={nameof<ObligationCategoryDto>(a => a.details.extendedName)} filter={"text"}
                                title={"Group"}/>
                    <GridColumn columnMenu={ColumnMenuCheckboxFilter}
                                field={nameof<ObligationCategoryDto>(a => a.details.obligationType.value)}
                                filter={"text"} title={"Type"}/>
                    <GridColumn columnMenu={ColumnMenuCheckboxFilter}
                                field={nameof<ObligationCategoryDto>(a => a.additionalDetails.obligationOwner.identityProviderUserName)}
                                filter={"text"} title={"Owner"}/>
                    <GridColumn columnMenu={ColumnMenuCheckboxFilter}
                                field={nameof<ObligationCategoryDto>(a => a.additionalDetails.autoApplied)}
                                filter={"boolean"} title={"Applied to all assets"}/>

                    {(userCanEdit || userCanDelete) &&
                    <GridColumn title={"Actions"} width={"120px"}
                                cell={row => {

                                    const obligation: ObligationCategoryDto = row.dataItem;

                                    return (
                                        <td>
                                            <Grid container spacing={1}>
                                                {userCanEdit &&
                                                <Grid item>
                                                    <Tooltip title={"Edit"} key={0}>
                                                        <IconButton onClick={() => onEditClicked(row.dataItem)}
                                                                    size={"small"}><FontAwesomeIcon
                                                            icon={faPencilAlt}/></IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                }
                                                {userCanDelete &&
                                                <Grid item>
                                                    <Tooltip title={"Delete"} key={1}>
                                                        {deleting === obligation.details.id
                                                            ? <CircularProgress color={"error"} size={25}/>
                                                            : <span><IconButton
                                                                onClick={() => onDeleteClicked(obligation)}
                                                                size={"small"}
                                                                disabled={obligation.details.companyId === null}>
                                                            <FontAwesomeIcon
                                                                icon={faTrash}/>
                                                        </IconButton></span>
                                                        }
                                                    </Tooltip>
                                                </Grid>
                                                }
                                            </Grid>
                                        </td>
                                    )
                                }}
                    />
                    }
                </GridTable>
            </Grid>
        </Grid>
    )
}
export { ObligationsTable }