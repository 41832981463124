import { OfficerDto } from "wizards/company/components/CreationForm/models";
import * as Yup from "yup";

export interface OfficerFormState {
    officers: OfficerDto[]
}

const officerFormValidationSchema = Yup.object().shape({
    officers: Yup.array()
        .of(Yup.object().shape({
            name: Yup.string()
                .typeError("Required")
                .required("Required"),
            email: Yup.string()
                .email("Must be a valid email")
                .typeError("Required")
                .required("Required"),
            occupation: Yup.string()
                .typeError("Required")
                .required("Required"),
            appointmentDate: Yup.date()
        }))
})
export { officerFormValidationSchema }