import React from 'react';
import { Mark, Slider } from "@mui/material";

import "./styles.scss";

export interface QuestionProgressProps {
    marks: Mark[]
    progress: number;
}

const QuestionProgress: React.FC<QuestionProgressProps> = ({marks, progress}) => {
    
    return (
        <Slider 
            className={"questionProgression"}
            marks={marks}
            track={false} 
            disabled={true}
            value={progress}
        />
    )
}
export { QuestionProgress }