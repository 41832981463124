import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    Avatar,
    Breadcrumbs,
    Button,
    Chip,
    Divider,
    Grid,
    Skeleton,
    Tooltip,
    Typography
} from "@mui/material";
import moment from "moment";

import { CollapsibleSubsection } from "common/components/sections/CollapsibleSubsection/CollapsibleSubsection";
import { getUserInitials } from "common/helpers/Regex/regex";
import { ObligationCategoryDto } from "common/dtos/Obligations/models";
import { AssetLinkedObligationsDto } from "common/dtos/Assets/AssetLinkedObligationsDto";
import { obligationsService } from "common/services/ObligationsService/obligationsService";
import { ImpactRating } from "assetManagement/components/ImpactRating/ImpactRating";
import { selectedFunctionSelector } from "user/store/selectors";
import { AssetLinkedObligations } from "assetManagement/components/AssetLinkedObligations/AssetLinkedObligations";
import { AssetComplianceSummaryDto } from "common/dtos/Assets/AssetComplianceSummaryDto";
import { assetService } from "common/services/AssetService/assetService";

import "./styles.scss";
import { Permission } from "auth/store/Model";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";

export interface AssetReadOnlyProps {
    asset: AssetLinkedObligationsDto;
    setAsset: (asset: AssetLinkedObligationsDto) => void;
    onEditClicked: () => void;
    loading: boolean;
}

const AssetReadOnly: React.FC<AssetReadOnlyProps> = ({asset, setAsset, onEditClicked, loading}) => {
    const selectedFunction = useSelector(selectedFunctionSelector);
    
    const [obligationCategories, setObligationCategories] = useState<ObligationCategoryDto[]>([]);
    const [assetComplianceSummary, setAssetComplianceSummary] = useState<AssetComplianceSummaryDto>();
    const [averageConfidentiality, setAverageConfidentiality] = useState<number>(3);
    const [averageIntegrity, setAverageIntegrity] = useState<number>(3);
    const [averageAvailability, setAverageAvailability] = useState<number>(3);

    useEffect(() => {
        obligationsService.getObligationCategoriesWithAdditionalDetails(true).then(response => {
            setObligationCategories(response.obligationCategory);
        });
    }, [asset.linkedObligations, selectedFunction])
    
    useEffect(() => {
        if(!loading){
            assetService.getAssetComplianceSummary(asset.assetDetails.id).then(response => {
                setAssetComplianceSummary(response);
            })
        }
    }, [loading, asset.assetDetails.id, selectedFunction])

    const calculateObligationAverages = useCallback(() => {
        
        let divider = obligationCategories.length;
        
        if(divider === 0)
            divider = 1;
        
        let confidentiality: number = 0;
        let integrity: number = 0;
        let availability: number = 0;
        
        obligationCategories.filter(o => asset.linkedObligations.some(lo => lo.id === o.details.id)).forEach((o) => {
            confidentiality += o.additionalDetails.confidentialityImpactId;
            integrity += o.additionalDetails.integrityImpactId;
            availability += o.additionalDetails.availabilityImpactId;
        })
        
        setAverageConfidentiality(Math.floor(confidentiality / divider));
        setAverageIntegrity(Math.floor(integrity / divider));
        setAverageAvailability(Math.floor(availability / divider));
        
    }, [obligationCategories, asset])

    useEffect(() => {
        calculateObligationAverages();
    }, [obligationCategories, asset, calculateObligationAverages, selectedFunction])

    const assetDetails = asset.assetDetails;
    
    return (
        <Grid container spacing={2}>
            <Grid container item justifyContent={"space-between"}>
                <Grid item>
                    <Breadcrumbs separator={"›"}>
                        <Link color={"inherit"} to={"/assets/all"}>
                            Assets
                        </Link>
                        <Typography color={"inherit"}>{assetDetails.id}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <Typography>{assetDetails.assetSample.assetCategory.value}</Typography>
                </Grid>
                <Grid item>
                    <Typography>{`Last updated ${moment(assetDetails.lastUpdatedDate).fromNow()} by ${assetDetails.lastUpdatedBy}`}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider light={false}/>
            </Grid>
            <Grid container item justifyContent={"space-between"}>
                <Grid container item xs={9} spacing={1}>
                    <Grid item>
                        <Chip avatar={
                            <Tooltip title={assetDetails.responsibleOwner.identityProviderUserName}>
                                <Avatar variant={"rounded"}>
                                    {assetDetails.responsibleOwner && getUserInitials(assetDetails.responsibleOwner)}
                                </Avatar>
                            </Tooltip>
                        } label={"Responsible"}/>
                    </Grid>
                    <Grid item>
                        <Chip avatar={
                            <Tooltip title={assetDetails.accountableOwner.identityProviderUserName}>
                                <Avatar variant={"rounded"}>
                                    {assetDetails.accountableOwner && getUserInitials(assetDetails.accountableOwner)}
                                </Avatar>
                            </Tooltip>
                        } label={"Accountable"}/>
                    </Grid>
                </Grid>
                <ValidateUserPermission permissions={[Permission.AssetsEdit]}>
                    <Grid container item xs={3} justifyContent={"flex-end"} spacing={1}>
                        <Grid item>
                            <Button className={"warning-button-outlined"} onClick={onEditClicked}
                                    variant={"outlined"}>Edit</Button>
                        </Grid>
                        <Grid item>
                            <Button color={"secondary"} variant={"outlined"}>Disable</Button>
                        </Grid>
                    </Grid>
                </ValidateUserPermission>
            </Grid>
            <Grid item xs={12}>
                <Divider light={false}/>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item>
                    <Chip label={"Active"} className={"success-item-filled"}/>
                </Grid>
                <Grid item><h2>{assetDetails.alias}</h2></Grid>
            </Grid>
            <Grid container item spacing={2}>
                {assetComplianceSummary ?
                    <Grid item>
                        <Chip variant={"outlined"} color={"primary"} label={`Overall Compliance: ${assetComplianceSummary.compliance}%`} />
                    </Grid> :
                    <Grid item xs={2}>
                        <Skeleton/>
                    </Grid>
                }
                
            </Grid>
            <Grid container item>
                <CollapsibleSubsection
                    title={"Impact Ratings"}
                    collapsible
                    collapsed={true}
                   >
                    <Grid container spacing={2}>
                        <Grid item container spacing={2}>
                            <Grid container item alignItems={"center"} spacing={2}>
                                <Grid item xs={4}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={"h6"} align={"center"} fontWeight={"bold"}>Company Risk Rating</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Divider/>
                                </Grid>
                            </Grid>
                            <Grid container item xs={4} alignItems={"flex-start"}>
                                <ImpactRating
                                    rating={selectedFunction?.confidentialityValue ?? 3}
                                    title={"Confidentiality"}
                                    description={selectedFunction?.confidentialityJustification}
                                />
                            </Grid>
                            <Grid container item xs={4} alignItems={"flex-start"}>
                                <ImpactRating
                                    rating={selectedFunction?.integrityValue ?? 3}
                                    title={"Integrity"}
                                    description={selectedFunction?.integrityJustification}
                                />
                            </Grid>
                            <Grid container item xs={4} alignItems={"flex-start"}>
                                <ImpactRating
                                    rating={selectedFunction?.availabilityValue ?? 3}
                                    title={"Availability"}
                                    description={selectedFunction?.availabilityJustification}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid container item alignItems={"center"} spacing={2}>
                                <Grid item xs={4}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={"h6"} align={"center"} fontWeight={"bold"}>Asset Risk Rating</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Divider/>
                                </Grid>
                            </Grid>
                            <Grid container item xs={4} alignItems={"flex-start"}>
                                <ImpactRating
                                    rating={assetDetails.confidentiality}
                                    title={"Confidentiality"}
                                    description={assetDetails.confidentialityJustification}
                                />
                            </Grid>
                            <Grid container item xs={4} alignItems={"flex-start"}>
                                <ImpactRating
                                    rating={assetDetails.integrity}
                                    title={"Integrity"}
                                    description={assetDetails.integrityJustification}
                                />
                            </Grid>
                            <Grid container item xs={4} alignItems={"flex-start"}>
                                <ImpactRating
                                    rating={assetDetails.availability}
                                    title={"Availability"}
                                    description={assetDetails.availabilityJustification}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid container item alignItems={"center"} spacing={2}>
                                <Grid item xs={4}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={"h6"} align={"center"} fontWeight={"bold"}>Linked Obligation Ratings</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Divider/>
                                </Grid>
                            </Grid>
                            <Grid container item xs={4} alignItems={"flex-start"}>
                                <ImpactRating
                                    rating={averageConfidentiality}
                                    title={"Confidentiality"}
                                    description={"Average Rating"}
                                />
                            </Grid>
                            <Grid container item xs={4} alignItems={"flex-start"}>
                                <ImpactRating
                                    rating={averageIntegrity}
                                    title={"Integrity"}
                                    description={"Average Rating"}
                                />
                            </Grid>
                            <Grid container item xs={4} alignItems={"flex-start"}>
                                <ImpactRating
                                    rating={averageAvailability}
                                    title={"Availability"}
                                    description={"Average Rating"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </CollapsibleSubsection>
            </Grid>
            <Grid container item>
                <AssetLinkedObligations
                    setAsset={setAsset}
                    obligationCategories={obligationCategories}
                    asset={asset}
                    loading={loading}
                />
            </Grid>
        </Grid>
    );
}
export { AssetReadOnly }