import { CardProps, InputLabelProps } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import * as colours from "../../styles/colours.scss";
import * as layout from "../../styles/layout.scss";

const variant = "outlined";
const font = layout.font;

export const muiTheme = createTheme({
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: false
            }
        },
        MuiCard: {
            defaultProps: {
                variant: "outlined"
            },
            styleOverrides: {
                root: {
                    padding: layout.thickPadding,
                    flexGrow: 1
                }
            }
        },
        MuiTextField: {
          defaultProps: {
              size: "small"
          }  
        },
        MuiSelect: {
            defaultProps: {
                size: "small"
            }
        },
        MuiPaper: {
            defaultProps: {
                variant: "outlined"
            }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    background: 'none',
                    border: 'none',
                },
                vertical: {
                    margin: "0 auto"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: layout.borderRadius
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderTop: '1 solid rgba(0, 0, 0, 0.12)', //this color should be theme.palette.divider if that is set
                    borderLeft: '1 solid rgba(0, 0, 0, 0.12)', //this color should be theme.palette.divider if that is set
                    backgroundColor: undefined,
                    height: undefined,
                    width: undefined,
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    color: "white !important"
                },
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    width: "100%"
                }
            }
        },
        MuiSkeleton: {
            defaultProps: {
                animation: "wave"
            }
        }
    },
    typography: {
        fontFamily: font,
        button: {
            textTransform: "none"
        }
    },
    palette: {
        primary: {
            main: colours.primary
        },
        secondary: {
            main: colours.secondary
        },
        success: {
            main: colours.success
        },
        error: {
            main: colours.error
        },
        warning: {
            main: colours.warning
        }
    },
});

export interface TextFieldProps {
    variant: "outlined" | "standard" | "filled" | undefined;
    fullWidth: boolean;
    size: "small" | "medium";
    InputLabelProps: InputLabelProps;
}


export interface FormProps {
    variant: "outlined" | "standard" | "filled" | undefined;
    fullWidth: boolean;
    size: "small" | "medium"
}

const fieldProps: TextFieldProps = {
    variant: variant,
    fullWidth: true,
    InputLabelProps: {shrink: true},
    size: "small"
}

const formProps: FormProps = {
    variant: variant,
    fullWidth: true,
    size: "small"
}

const cardProps: CardProps = {
    variant: variant,
}

export { fieldProps, formProps, cardProps }