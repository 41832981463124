import React from 'react'
import { Button, Grid, Tooltip, Typography } from "@mui/material";

import CompletionImage from "assets/illustrations/completion.svg";
import { WizardStepProps } from "../../../models";
import { InfoRounded } from "@mui/icons-material";

const Completion: React.FC<WizardStepProps> = ({object, onContinueClicked}) => {

    const message: string = `You've successfully created a new organisation.`

    const onClick = (startAccessment: boolean) => {
        onContinueClicked({ 
            ...object,
            startAccessment: startAccessment
        })
    }

    return (
        <Grid xs={10} item container justifyContent={"center"} alignContent={"center"}>
            <Grid direction={"column"} item container xs={6} spacing={1}>
                <Grid item>
                    <Typography variant={"h5"}><b>Congratulations!</b></Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"inherit"} color={"secondary"}>{message}</Typography>
                </Grid>
                <Grid container item spacing={1}>
                    <Grid item>
                        <Button variant={"outlined"} color={"primary"} onClick={() => onClick(false)}>
                            Go to Dashboard
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            disableElevation
                            color={"primary"}
                            onClick={() => onClick(true)}
                            endIcon={
                                <Tooltip title={"Lets start your rapid assessment to begin your compliance journey"}>
                                    <InfoRounded/>
                                </Tooltip>
                            }>
                            Take Assessment
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <img src={CompletionImage} alt={"Wizard completed"} width={400}/>
            </Grid>

        </Grid>
    );
}
export { Completion }