import { createSelector } from "reselect";
import { FunctionState } from "./Model";

import { ApplicationState } from "../../store";
import { FunctionDetailsDto } from "common/dtos/Businesses/FunctionDetailsDto";

const selectorState = (state: ApplicationState): FunctionState =>
  state.businessFunctions;

export const companyFunctionsSelector = createSelector(
    selectorState,
    (functionState: FunctionState): FunctionDetailsDto[] => {
        return functionState.companyFunctions;
    }
);