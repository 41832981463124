import { ReactElement } from "react";

export enum ColumnType{
    Default,
    DateTime,
    Decimal,
    Number,
    Percentage,
    ExchangeRates,
    Boolean,
    Button,
    User,
    Lookup,
    Switch,
    Actions
}

export interface ColumnDefinition{
    key: number;
    name: string;
    field: string;
    hidden: boolean;
    width: number | string;
    type: ColumnType;
    displayLabel: boolean;
    icon?: ReactElement
}