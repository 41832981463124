import { UserDetailsDto } from "common/dtos/Users/models";

export function currencyFormat(number: number, fixedDecimal: number, currencySymbol: string = "") : string {
   
    const decimalCheck = fixedDecimal === 0 ? 2: fixedDecimal

    let formatedCurrency =  number.toFixed(decimalCheck)
        .toString()
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');  

    let formatedNumber = formatedCurrency.substring(0, formatedCurrency.length-3)

    return fixedDecimal === 0 ? (currencySymbol + formatedNumber) : (currencySymbol + formatedCurrency) ;
}

export function isEmptyOrSpaces(str: string | any): boolean{
    return str?.toString() === null || str?.toString().match(/^ *$/) !== null;
}

export function nicifyString(value: string): string{
    var rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;

    return value.replace( rex, '$1$4 $2$3$5').replace(/([a-z])([0-9])/g, '$1 $2').trim();
}

export function getInitials(...names: string[]): string {
    let initials: string = "";
    names.forEach(n => {
        if(!isEmptyOrSpaces(n))
            initials += n[0].toUpperCase()
    })
    
    return initials;
}

export function getUserInitials(user: UserDetailsDto): string {
    
    if(!isEmptyOrSpaces(user.firstName) && !isEmptyOrSpaces(user.surname)){
        return getInitials(user.firstName, user.surname);
    }
    
    return getInitials(user.identityProviderUserName);
}