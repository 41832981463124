import React, { ReactElement, useCallback, useState } from 'react';

import { Container } from "@mui/material";

import { AssessmentWelcome } from "questions/Assessment/components/AssessmentWelcome/AssessmentWelcome";

import "./styles.scss";
import { AssessmentState } from "questions/Assessment/actions";
import { AssessmentQuestions } from "questions/Assessment/components/Questions/AssessmentQuestions/AssessmentQuestions";
import { AssessmentSplash } from "questions/Assessment/components/AssessmentSplash/AssessmentSplash";

import { history } from "../../history";

import WellDoneImage from "assets/illustrations/well_done.svg";
import NatureImage from "assets/illustrations/nature.svg";
import ComeBackLaterImage from "assets/illustrations/come_back_later.svg";

const Assessment: React.FC = () => {
    
    
    const [assessmentState, setAssessmentState] = useState<AssessmentState>(AssessmentState.WELCOME);
    
    const onNextPressed = useCallback(() => {
        setAssessmentState(AssessmentState.ANSWER);
    }, [])
    
    const renderState = (): ReactElement => {
        switch(assessmentState){
            case AssessmentState.WELCOME:
                return <AssessmentWelcome onNextPressed={onNextPressed}/>
            case AssessmentState.ANSWER:
                return <AssessmentQuestions setAssessmentState={setAssessmentState}/>
            case AssessmentState.COME_BACK_LATER:
                return <AssessmentSplash
                    title={"Need to take a break?"}
                    description={"Don't worry, we've saved your progress so far.\n Come back later to finish off the Assessment, we'll be sure to remind you of this \n as it's pretty important."}
                    image={ComeBackLaterImage}
                />
            case AssessmentState.COMPLETE:
                return <AssessmentSplash
                    title={"Awesome Job!"}
                    description={" You've answered all the our questions!\n You should now head over to the Dashboard to see a breakdown of how compliant your company is."}
                    image={WellDoneImage}
                    onButtonClicked={() => history.push("/")}
                    buttonText={"Take me there"}
                />
            default:
                return <AssessmentSplash
                    title={"You're all set!"}
                    description={" There is nothing left to do here, you've done everything we need.\n Nicely done!"}
                    image={NatureImage}
                />
        }
    }
    
    return (
        <Container className={"assessment"} maxWidth={'xl'} disableGutters>
            {renderState()}
        </Container>
    )
}
export { Assessment }