import React, { ReactNode } from 'react';
import { TableRow, TableHead, TableCell } from "@mui/material";
import { ColumnDefinition } from "./ColumnDefinition";

export interface TableDefinitionProps {
    columns: ColumnDefinition[];
}

const TableDefinition: React.FC<TableDefinitionProps> = ({columns}) => {

    const renderColumns = (): ReactNode[] => {
        return columns.filter(c => !c.hidden).map((c, i) => {
            return <TableCell key={i}>{c.displayLabel && c.name}</TableCell>
        });
    }

    return (
        <TableHead>
            <TableRow>
                {renderColumns()}
            </TableRow>
        </TableHead>
    )
}
export { TableDefinition }