import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid } from "@mui/material";
import { SectorSelector } from "common/components/input/selectors/SectorSelector/SectorSelector";
import { TypeSelector } from "common/components/input/selectors/TypeSelector/TypeSelector";
import { SectorTypesDto } from "common/dtos/Questions/Sectors/models";
import { functionService } from "common/services/FunctionService/functionService";
import { LookupDto } from "common/services/LookupService/models";
import { selectedFunctionSelector } from "user/store/selectors";
import { useSelector } from "react-redux";
import { FunctionType } from "common/components/input/forms/BusinessFunctionCreationForm/models";
import { Permission } from "auth/store/Model";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";

/* TODO: Convert Company & Function Wizards to allow schema to be used with this Component 
*  https://gitlab.com/bi-data-solutions/ASIRTA/-/issues/198  
* */


const SectorEditing: React.FC = () => {

    const [selectedSectors, setSelectedSectors] = useState<SectorTypesDto[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<SectorTypesDto[]>([]);
    const [changeDetected, setChangeDetected] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    
    const selectedFunction = useSelector(selectedFunctionSelector);

    useEffect(() => {
        functionService.getFunctionSectors().then(response => {
            setSelectedSectors(response.sectors);
            setSelectedTypes(response.types);
        })
    }, [selectedFunction])

    const onTypeChange = useCallback((types: LookupDto<string>[]) => {
        setChangeDetected(true)
        setSelectedTypes(types)
    }, [])

    const onSectorChange = useCallback((sectors: LookupDto<string>[]) => {
        setChangeDetected(true)
        setSelectedSectors(sectors)
    }, [])
    
    const saveChanges = useCallback(() => {
        
        if(selectedFunction?.functionTypeId === FunctionType.Company && selectedTypes.length === 0) {
            setErrorMessage("Required")
            return;
        }
        
        functionService.setFunctionSectors([...selectedSectors, ...selectedTypes]).finally(() => {
            setChangeDetected(false)
        })
        
    }, [selectedFunction, selectedSectors, selectedTypes])

    return (
        <Grid container spacing={2} justifyContent={"flex-end"}>
            <Grid item xs={12}>
                <TypeSelector
                    label={"Types"}
                    selectedTypes={selectedTypes}
                    errorText={errorMessage}
                    onChange={onTypeChange}
                />
            </Grid>
            <Grid item xs={12}>
                <SectorSelector
                    label={"Sectors"}
                    selectedSectors={selectedSectors}
                    onChange={onSectorChange}
                />
            </Grid>
            <ValidateUserPermission permissions={[Permission.BusinessFunctionsEdit]}>
                <Grid item>
                    <Button 
                        variant={"outlined"}
                        disabled={!changeDetected}
                        onClick={saveChanges} 
                        className={changeDetected ? "success-item-outlined" : ""}
                    >Save Changes</Button>
                </Grid>
            </ValidateUserPermission>
        </Grid>
    );
}
export { SectorEditing }