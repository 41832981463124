import { UserDetailsDto, userDetailsInitialState } from "../Users/models";
import { AddressDto, addressInitialState } from "../../components/input/forms/AddressForm/models";
import { Jurisdiction, OfficerDto } from "../../../wizards/company/components/CreationForm/models";
import { RiskSettingsDto } from "../../../riskLabels/models";
import { LookupDto, lookupDtoInitialState } from "../../services/LookupService/models";
import moment from "moment";

export interface CompanyUserDto {
    user: UserDetailsDto;
    roleId: number;
    joinDate: Date;
}

export const companyUserInitialState: CompanyUserDto = {
    user: userDetailsInitialState,
    roleId: 0,
    joinDate: new Date()
}

export interface CompanyDetailsDto {
    id: number;
    name: string;
    friendlyName: string;
    active: boolean;
    additionalDetails: AdditionalDetailsDto;
    owner: UserDetailsDto;
    notes: string;
    state: boolean;
    settings: CompanySettingsDto;
    officers: OfficerDto[];
    creationDate: Date;
}

export interface AdditionalDetailsDto {
    id: number;
    address: AddressDto;
    companiesHouseId?: string;
    accountingId: string;
    jurisdiction: Jurisdiction; 
    vat?: string;
    state: boolean
    currentChallenges: number;
    employeeNumber: number;
    annualTurnover: number;
}

export interface CompanySettingsDto {
    id: number;
    companyId: number;
    accountManager: UserDetailsDto;
    billingTiersId: number;
    billingTiers: LookupDto<string>;
    userLimit: number;
    freedomOfInformation: boolean;
    state: boolean;
    riskSettings: RiskSettingsDto[];
    defaultFunctionId: number;
}

const companySettingsInitialState: CompanySettingsDto = {
    id: 0,
    companyId: 0,
    accountManager: userDetailsInitialState,
    billingTiersId: 0,
    billingTiers: lookupDtoInitialState,
    freedomOfInformation: true,
    riskSettings: [],
    state: true,
    userLimit: 0,
    defaultFunctionId: 0
}
export { companySettingsInitialState }

const additionalDetailsInitialState: AdditionalDetailsDto = {
    id: 0,
    accountingId: "",
    address: addressInitialState,
    companiesHouseId: undefined,
    employeeNumber: 0,
    annualTurnover: 0,
    currentChallenges: 0,
    vat: undefined,
    jurisdiction: Jurisdiction.None, 
    state: true,
}

const companyDetailsInitialState: CompanyDetailsDto = {
    id: 0,
    name: "",
    friendlyName: "",
    owner: userDetailsInitialState,
    additionalDetails: additionalDetailsInitialState,
    notes: "",
    settings: companySettingsInitialState,
    state: true,
    active: true,
    officers: [],
    creationDate:moment().toDate()
}
export {companyDetailsInitialState}