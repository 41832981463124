import { CompanyDetailsDto, companyDetailsInitialState } from "../Companies/models";
import { LookupDto, lookupDtoInitialState } from "common/services/LookupService/models";
import { UserDetailsDto, userDetailsInitialState } from "common/dtos/Users/models";
import { FunctionType } from "common/components/input/forms/BusinessFunctionCreationForm/models";

export interface FunctionDetailsDto {
    id: number;
    companyId: number;
    company: CompanyDetailsDto;
    name: string;
    confidentialityValue: number;
    confidentialityJustification: string;
    integrityValue: number;
    integrityJustification: string;
    availabilityValue: number;
    availabilityJustification: string;
    statusTypeId: number;
    statusType: LookupDto<string>;
    managementTypeId: number;
    managementType: LookupDto<string>;
    functionType: LookupDto<string>;
    functionTypeId: number;
    ownerId: number;
    owner: UserDetailsDto;
    maintainerId: number;
    maintainer: UserDetailsDto;
}

const functionDetailsInitialState: FunctionDetailsDto = {
    id: 0,
    company: companyDetailsInitialState,
    companyId: 0,
    name: "",
    confidentialityValue: 3,
    confidentialityJustification: "",
    integrityValue: 3,
    integrityJustification: "",
    availabilityValue: 3,
    availabilityJustification: "",
    statusTypeId: 1,
    statusType: lookupDtoInitialState,
    managementTypeId: 1,
    managementType: lookupDtoInitialState,
    functionTypeId: FunctionType.Project,
    functionType: lookupDtoInitialState,
    ownerId: 0,
    owner: userDetailsInitialState,
    maintainerId: 0,
    maintainer: userDetailsInitialState
}
export { functionDetailsInitialState }