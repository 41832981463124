import React, { ReactElement } from 'react';

import { FormControl, Grid, MenuItem, Slider, TextField, Typography } from "@mui/material";
import { nameof } from "ts-simple-nameof";
import { FormikErrors, FormikTouched } from "formik";

import { NotchedSelect } from "common/components/input/fields/NotchedSelect/NotchedSelect";
import { nicifyString } from "common/helpers/Regex/regex";
import { StatusType, FunctionType, ManagementType } from "./models";
import { UserDetailsDto } from "common/dtos/Users/models";
import { fieldProps, formProps } from "common/theming/models";
import { RiskLabelTypeSelector } from '../../selectors/RiskLabelTypeSelector/RiskLabelTypeSelector';
import { renderEnumAsMenuItem } from "../../../../helpers/Object/object";
import { raciMarks } from "common/models/Marks/model";
import { FunctionDetailsDto } from "common/dtos/Businesses/FunctionDetailsDto";

export interface BusinessFunctionCreationFormProps {
    handleChange: (e: any) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void;
    candidates: UserDetailsDto[];
    values: FunctionDetailsDto;
    errors: FormikErrors<FunctionDetailsDto>;
    touched: FormikTouched<FunctionDetailsDto>;
}

const BusinessFunctionCreationForm: React.FC<BusinessFunctionCreationFormProps> = ({
                                                                                       handleChange,
                                                                                       setFieldValue,
                                                                                       candidates,
                                                                                       values,
                                                                                       errors,
                                                                                       touched
                                                                                   }) => {
    const renderCandidates = (): ReactElement[] => {
        return [
            <MenuItem value={"None"} key={""}>None</MenuItem>,
            ...candidates.map((t) => {
                return <MenuItem value={t.id} key={t.id}>{t.identityProviderUserName}</MenuItem>
            })
        ]
    }
     
    const name = `${nicifyString(FunctionType[values.functionTypeId])} Name*`

    return (
        <FormControl {...formProps}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        {...fieldProps}
                        name={nameof<FunctionDetailsDto>(n => n.name)}
                        label={name}
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NotchedSelect
                        name={nameof<FunctionDetailsDto>(n => n.functionTypeId)}
                        label={"Function*"}
                        value={values.functionTypeId}
                        onChange={handleChange}
                        disabled={values.functionTypeId === 1}
                        error={touched.functionTypeId && Boolean(errors.functionTypeId)}
                        helperText={touched.functionTypeId && errors.functionTypeId}
                    >
                        {renderEnumAsMenuItem(FunctionType, [FunctionType.Company])}
                    </NotchedSelect>
                </Grid>
                <Grid item xs={3}>
                    <NotchedSelect
                        name={nameof<FunctionDetailsDto>(n => n.ownerId)}
                        label={"Owner*"}
                        value={values.ownerId > 0 ? values.ownerId : "None"}
                        onChange={handleChange}
                        error={touched.ownerId && Boolean(errors.ownerId)}
                        helperText={touched.ownerId && errors.ownerId}
                    >
                        {renderCandidates()}
                    </NotchedSelect>
                </Grid>
                <Grid item xs={3}>
                    <NotchedSelect
                        name={nameof<FunctionDetailsDto>(n => n.maintainerId)}
                        label={"Maintainer*"}
                        value={values.maintainerId > 0 ? values.maintainerId : "None"}
                        onChange={handleChange}
                        error={touched.maintainerId && Boolean(errors.maintainerId)}
                        helperText={touched.maintainerId && errors.maintainerId}
                    >
                        {renderCandidates()}
                    </NotchedSelect>
                </Grid>
                <Grid item xs={3}>
                    <NotchedSelect
                        name={nameof<FunctionDetailsDto>(n => n.managementTypeId)}
                        label={"Management*"}
                        value={values.managementTypeId}
                        onChange={handleChange}
                        error={touched.managementTypeId && Boolean(errors.managementTypeId)}
                        helperText={touched.managementTypeId && errors.managementTypeId}
                    >
                        {renderEnumAsMenuItem(ManagementType)}
                    </NotchedSelect>
                </Grid>
                <Grid item xs={3}>
                    <NotchedSelect
                        name={nameof<FunctionDetailsDto>(n => n.statusTypeId)}
                        label={"Status*"}
                        value={values.statusTypeId}
                        onChange={handleChange}
                        error={touched.statusTypeId && Boolean(errors.statusTypeId)}
                        helperText={touched.statusTypeId && errors.statusTypeId}
                    >
                        {renderEnumAsMenuItem(StatusType)}
                    </NotchedSelect>
                </Grid>
                <Grid item xs={6}>
                    <Typography gutterBottom>
                        Confidentiality Score
                        <RiskLabelTypeSelector risk={values.confidentialityValue}/>
                    </Typography>
                    <Slider
                        name={nameof<FunctionDetailsDto>(n => n.confidentialityValue)}
                        value={values.confidentialityValue}
                        step={1}
                        marks={raciMarks}
                        min={1}
                        max={5}
                        onChange={(e, v) => setFieldValue(nameof<FunctionDetailsDto>(n => n.confidentialityValue), v)}
                        valueLabelDisplay="auto"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...fieldProps}
                        name={nameof<FunctionDetailsDto>(n => n.confidentialityJustification)}
                        label={"Confidentiality Justification*"}
                        value={values.confidentialityJustification}
                        onChange={handleChange}
                        error={touched.confidentialityJustification && Boolean(errors.confidentialityJustification)}
                        helperText={touched.confidentialityJustification && errors.confidentialityJustification}
                        multiline
                        rows={3}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography gutterBottom>
                        Integrity Score 
                        <RiskLabelTypeSelector risk={values.integrityValue}/>
                    </Typography>
                    <Slider
                        name={nameof<FunctionDetailsDto>(n => n.integrityValue)}
                        value={values.integrityValue}
                        step={1}
                        marks={raciMarks}
                        min={1}
                        max={5}
                        onChange={(e, v) => setFieldValue(nameof<FunctionDetailsDto>(n => n.integrityValue), v)}
                        valueLabelDisplay="auto"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...fieldProps}
                        name={nameof<FunctionDetailsDto>(n => n.integrityJustification)}
                        label={"Integrity Justification*"}
                        value={values.integrityJustification}
                        onChange={handleChange}
                        error={touched.integrityJustification && Boolean(errors.integrityJustification)}
                        helperText={touched.integrityJustification && errors.integrityJustification}
                        multiline
                        rows={3}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography gutterBottom>
                        Availability Score 
                        <RiskLabelTypeSelector risk={values.availabilityValue}/>
                    </Typography>
                    <Slider
                        name={nameof<FunctionDetailsDto>(n => n.availabilityValue)}
                        value={values.availabilityValue}
                        step={1}
                        marks={raciMarks}
                        min={1}
                        max={5}
                        onChange={(e, v) => setFieldValue(nameof<FunctionDetailsDto>(n => n.availabilityValue), v)}
                        valueLabelDisplay="auto"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...fieldProps}
                        name={nameof<FunctionDetailsDto>(n => n.availabilityJustification)}
                        label={"Availability Justification*"}
                        value={values.availabilityJustification}
                        onChange={handleChange}
                        error={touched.availabilityJustification && Boolean(errors.availabilityJustification)}
                        helperText={touched.availabilityJustification && errors.availabilityJustification}
                        multiline
                        rows={3}
                    />
                </Grid>
            </Grid>
        </FormControl>

    )
}
export { BusinessFunctionCreationForm }