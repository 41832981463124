import { FileRoutesResponseDto } from "../../../dtos/Questions/FileRoutesResponseDto/models";
import { HistoryItem, HistoryType } from "../../../dtos/History/models";
import { DelegatedQuestionDto, UserDetailsDto } from "../../../dtos/Users/models";
import { SubquestionAnswersDto } from "../../../dtos/Questions/QuestionAnswers/models";
import { AnswerValue } from "../../../dtos/Questions/models";
import moment from "moment";
import { DelegationType } from "common/dtos/Common/delegation";

class HistoryHelper {
    
    public fileRoutesToHistoryItems(routes: FileRoutesResponseDto[], user: UserDetailsDto, type: HistoryType): HistoryItem[] {

        return routes.map(route => {
            return {
                id: route.fileRoute,
                content: route.fileName,
                additionalContent: "",
                userDetails: user,
                type: type,
                createdBy: route.createdBy,
                creationDate: route.creationDate,
                lastUpdatedBy: route.createdBy,
                lastUpdatedDate: route.creationDate,
            }
        });
    }
    
    public subquestionAnswersToHistoryItems(subquestionAnswers: SubquestionAnswersDto[], user: UserDetailsDto): HistoryItem[] {
        
        return subquestionAnswers.map((subquestion, i) => {
            const questionNumber: number = i + 1;
            return {
                id: questionNumber.toString(),
                content: AnswerValue[subquestion.answer].toString(),
                additionalContent: "",
                userDetails: user,
                type: HistoryType.Answer,
                createdBy: user.identityProviderUserName,
                creationDate: moment().utc(false).toDate(),
                lastUpdatedBy: user.identityProviderUserName,
                lastUpdatedDate: moment().utc(false).toDate(),
            }
        })
    }
    
    public delegatedQuestionsToHistoryItems(delegatedQuestion: DelegatedQuestionDto[], user: UserDetailsDto): HistoryItem[] {
        
        return delegatedQuestion.map(delegation => {
        
            return {
                id: delegation.id.toString(),
                userDetails: delegation.user,
                createdBy: user.identityProviderUserName,
                lastUpdatedBy: user.identityProviderUserName,
                // @ts-ignore
                content: DelegationType[delegation.delegationId],
                additionalContent: "",
                creationDate: moment().utc(false).toDate(),
                lastUpdatedDate: moment().utc(false).toDate(),
                type: HistoryType.QuestionUserDelegation,
            }
        })
        
        
    }
}
export const historyHelper = new HistoryHelper();