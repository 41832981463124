export interface AnswerDto {
    id: number;
    answerValue: number;
    weight: number;
    likelihood: number;
    maturity: number;
}

export enum AnswerValue {
    NotAnswered = 0,
    Unknown = 1,
    NotImplemented = 2,
    Planned = 3,
    Underway = 4,
    AuditedWithIssues = 5,
    Implemented = 6,
    AuditedNoIssues = 7,
    Certified = 8
}

export enum InitialAnswerValue {
    NotAnswered = 0
}