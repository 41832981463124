import React, { ReactFragment } from "react";
import moment from "moment";

import './styles.scss';

const Copyright: ReactFragment = ( 
    <React.Fragment>
        <div className={'copyright'}>
            <p>
                Copyright © The Common Framework™ {moment().year()}.
            </p>
        </div>
    </React.Fragment>)

export { Copyright }