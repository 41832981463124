export interface QuestionStatisticsDto {
    assessmentQuestionsRemaining: number
    unansweredDelegatedQuestions: number;
    questionsRemaining: number;
    totalAccountableQuestions: number;
    totalConsultedQuestions: number;
}

export const questionStatisticsInitialState: QuestionStatisticsDto = {
    assessmentQuestionsRemaining: 0,
    questionsRemaining: 0,
    totalAccountableQuestions: 0,
    totalConsultedQuestions: 0,
    unansweredDelegatedQuestions: 0
}