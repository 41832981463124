const getComplianceScoreClassName = (score: number) => {
    switch(true) {
        case (score <= 1):
            return 'complianceScoreStyle level0';
        case (score <= 10):
            return 'complianceScoreStyle level5';
        case (score <= 25):
            return 'complianceScoreStyle level4';
        case (score <= 70):
            return 'complianceScoreStyle level3';
        case (score <= 95):
            return 'complianceScoreStyle level2';
        default:
            return 'complianceScoreStyle level1';
    }
}

export { getComplianceScoreClassName }