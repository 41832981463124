import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import {
    AppBar, Container,
    Drawer,
    Grid,
    IconButton, Paper,
    TextField,
    Toolbar,
    Tooltip, 
    Typography
} from "@mui/material";
import { Pagination } from '@mui/material';

import { questionsService } from "common/services/QuestionService/questionService";
import { QuestionDetailsDto } from "common/dtos/Questions/QuestionDetails/models";

import { QuestionItem } from "./components/QuestionItem/QuestionItem";
import { PaginationResponseDto } from "common/dtos/Common/pagination";
import {selectedCompanySelector, selectedFunctionSelector} from "user/store/selectors";

import "./styles.scss";
import { QuestionFiltersPanel } from "questions/Questions/components/QuestionFilters/QuestionFiltersPanel";
import { QuestionFilters, QuestionState } from "questions/Questions/components/QuestionFilters/models";

import { questionStyles } from "./models";
import clsx from "clsx";
import { fieldProps } from "common/theming/models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { questionFiltersSelector } from "../Question/selectors";
import { updateQuestionFilters } from "../Question/actions";
import moment from "moment";

const Questions: React.FC = () => {
    const dispatch = useDispatch();

    let selectedCompany = useSelector(selectedCompanySelector);
    let accountId = selectedCompany && selectedCompany.additionalDetails.accountingId;

    const now = moment()
    const daysLeft = 7 - now.diff(selectedCompany && selectedCompany.creationDate, "days")

    const initialPageNumber: number = accountId === "" ? 2 : 0;
    const initialPageSize: number = 10;
    const questionFilters = useSelector(questionFiltersSelector)

    const [questions, setQuestions] = useState<PaginationResponseDto<QuestionDetailsDto[]>>({data: [], total: 0});
    const [pageNumber, setPageNumber] = useState<number>(initialPageNumber);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [open, setOpen] = useState<boolean>(true);

    const debouncedFilterSearch = useRef(_.debounce((q,questionFilters) => onSearchSubmit(q,questionFilters), 500)).current;

    const selectedFunction = useSelector(selectedFunctionSelector);

    const classes = questionStyles();

    const getQuestions = useCallback((number: number, size: number) => {
        questionsService.getQuestions(size, number, questionFilters).then(response => {
            setQuestions(response);
        })
    },[questionFilters])
    
    useEffect(() => {
        setPageNumber(initialPageNumber)
        setPageSize(initialPageSize) 
        getQuestions(initialPageNumber, initialPageSize);
    }, [selectedFunction, getQuestions, initialPageSize, initialPageNumber])

    const onChange = (e: any, page: number) => {
        const pageNumber = page - 1;
    setPageNumber(pageNumber);
    getQuestions(pageNumber, pageSize);
}

const onApplyFilters = useCallback((filters: QuestionFilters) => {

    dispatch(updateQuestionFilters({...filters, search: questionFilters.search }))

    questionsService.getQuestions(pageSize, pageNumber, {...filters, search: questionFilters.search }).then(response => {
        setQuestions(response);
    })
    
}, [pageNumber, pageSize, dispatch, questionFilters.search])


const resetQuestionFilters = useCallback(() => {
    const filters: QuestionFilters = {
        search: "",
        answerValue: null,
        questionState: QuestionState.AllQuestions,
        delegationTypes: [],
        sectors: [],
        obligationCategories: [],
        activityCategories: [],
        trackedQuestionGroups: false,
        trackedObligations: false,
        trackedActivities: false
    }
    dispatch(updateQuestionFilters(filters))
    questionsService.getQuestions(pageSize, pageNumber, {...filters }).then(response => {
        setQuestions(response);
    })
}, [pageNumber, pageSize, dispatch])

const onSearchChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value

    dispatch(updateQuestionFilters({...questionFilters, search: value}))

    debouncedFilterSearch(value, questionFilters);
}

    const onSearchSubmit = (search: string, questionFilters: any) => {
        questionsService.getQuestions(pageSize, pageNumber, {...questionFilters, search}).then(response => {
            setQuestions(response);
        })
    }
    
    const pageCount = Math.ceil(questions.total / pageSize);

    return (
        <div className={classes.root}>
            <AppBar className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
            color={"inherit"}
            variant={"outlined"}
            >
                <Toolbar className={"filtersToolbar"}>
                    <Grid container item xs={8} spacing={1} alignItems={"center"}>
                        <Grid item>
                            <Tooltip title={`${open ? "Hide" : "Show"} Filters`}>
                                <IconButton
                                    size={"small"}
                                    onClick={() => setOpen(!open)}
                                    color={open ? "primary" : "secondary"}
                                >
                                    <FontAwesomeIcon icon={faTags}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item className={"searchField"}>
                            <TextField {...fieldProps} placeholder={"Search"} value={questionFilters.search} onChange={onSearchChange} />
                        </Grid>
                    </Grid>
                    
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant={"persistent"}
                open={open}
                anchor={"left"}
            >
                <QuestionFiltersPanel onSubmit={onApplyFilters} filters={questionFilters} clearFilter={resetQuestionFilters}/>
            </Drawer>
            <main className={clsx(classes.content, {
                [classes.contentShift]: open,
            })}>
                {accountId !== "" ?
                    (
                        <Container maxWidth={'lg'}>
                            <Grid container item spacing={2}>
                                {questions.data.map((q, i) => {
                                    return (
                                        <Grid item xs={12}>
                                            <QuestionItem
                                                key={i}
                                                question={q}
                                                questionNumber={i + ((pageNumber - 1) * pageSize) + 11}
                                                answerDetails={q.answer}
                                            />
                                        </Grid>
                                    )
                                })}
                                <Grid item xs={12}>
                                    <Pagination
                                        onChange={onChange}
                                        count={pageCount}
                                        page={pageNumber + 1}
                                        variant={"outlined"}
                                        shape={"rounded"}
                                        color={"primary"}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                        
                    ):
                    <Container maxWidth={'lg'}>
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>

                                <Paper className={'questionItem'}>
                                    <Grid container justifyContent={"space-between"} spacing={1}>
                                        <Grid container item xs={12} spacing={1} justifyContent={"space-between"}>
                                            <Grid item xs={12}>
                                                <Typography color={"primary"} variant={"body1"} fontWeight={600}>
                                                    Welcome to ASIRTA, the platform for informing your governance.
                                                </Typography>                                                
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography color={"textPrimary"} variant={"body1"}>
                                                    This time limited trial will provide samples of Cyber Security and Data Protection questions with\in ASIRTA to allow you understand what you will get if you pay for a licence. <br/>
                                                    Please <a href="mailto:customerservice@common-framework.com">contact us</a> if you would like to buy a licence.
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography color={'textPrimary'} variant={"body2"}>You have { daysLeft <= 0 ? 0 : daysLeft } days remaining on your trial.</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {questions.data.slice(0,9).map((q, i) => {
                                return (
                                    <Grid item xs={12}>
                                        <QuestionItem
                                            key={i}
                                            question={q}
                                            questionNumber={i + ((pageNumber - 1) * pageSize) - 9}
                                            answerDetails={q.answer}
                                        />
                                    </Grid>
                                )
                            })}
                            
                        </Grid>
                    </Container>
                }
                
            </main>
        </div>
    );

}
export { Questions }