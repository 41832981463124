import { AxiosApi } from "../../axios/axiosApi";
import {
    ObligationRequest,
    obligationCategoryDetailsInitialState,
    ObligationCategoryDto,
    ObligationCategoryResponse,
    TrackedObligationDto,
    trackedObligationInitialState, obligationCategoryInitialState
} from "common/dtos/Obligations/models";
import SnackbarUtils from "../../helpers/SnackBar/snackbar";

class ObligationsService {

    private controllerName: string = "api/Obligations"

    public async getObligationCategories(): Promise<ObligationCategoryResponse> {
        
        return await AxiosApi.get(this.controllerName).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }

    public async getObligationCategoriesWithAdditionalDetails(excludeEmpty: boolean = false): Promise<ObligationCategoryResponse> {

        const url: string = `${this.controllerName}/GetObligationsWithAdditionalDetails?excludeEmpty=${excludeEmpty}`
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async createObligation(request: ObligationRequest): Promise<ObligationCategoryDto> {
        
        const url: string = `${this.controllerName}/Create`;
        
        return await AxiosApi.post(url, JSON.stringify(request)).then(response => {
            SnackbarUtils.success("New Obligation created")
            return response.data;
        }).catch(e => {
            return obligationCategoryDetailsInitialState;
        });
    }
    
    public async updateObligation(request: ObligationRequest): Promise<ObligationCategoryDto> {
        
        const url: string = `${this.controllerName}/Update`
        
        return await AxiosApi.post(url, request).then((response) => {
            SnackbarUtils.success("Obligation updated successfully")
            return response.data;  
        }).catch(e => {
            return obligationCategoryInitialState;
        })
    }
    
    public async deleteObligation(obligationId: number): Promise<void> {

        const url: string = `${this.controllerName}/Delete/${obligationId}`;

        return await AxiosApi.delete(url).then((response) => {
            SnackbarUtils.success("Obligation Deleted")
            return response.data;
        }).catch(e => {
            return obligationCategoryInitialState;
        })
        
    }
    
    public async getAutoAppliedObligations(): Promise<ObligationCategoryDto[]> {
        const url:string = `${this.controllerName}/GetAutoAppliedObligations`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }
    
    public async getTrackedObligations(): Promise<TrackedObligationDto[]> {
        const url:string = `${this.controllerName}/GetTrackedObligations`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }
    
    public async trackObligation(obligationId: number, track: boolean): Promise<TrackedObligationDto> {
        const url:string = `${this.controllerName}/TrackObligation/${obligationId}/${track}`;

        return await AxiosApi.post(url).then(response => {
            return response.data;
        }).catch(e => {
            return trackedObligationInitialState;
        })
    }
}

const obligationsService = new ObligationsService();
export { obligationsService }