import { ActionTypes, ADD_COMPANY_FUNCTION, SET_COMPANY_FUNCTIONS } from "./actionTypes";

import { FunctionState } from "./Model"

const initialState: FunctionState = {
  companyFunctions: []
}

export function businessFunctions(
  state = initialState,
  action: ActionTypes
): any {
  switch (action.type) {
    case SET_COMPANY_FUNCTIONS:
      return {
        ...state,
        companyFunctions: action.payload
      }
    case ADD_COMPANY_FUNCTION:
      return {
        ...state,
        companyFunctions: [
            ...state.companyFunctions,
            action.payload
        ] 
      }
    default:
      return state
  }
}
