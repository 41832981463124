import React, { ReactElement, useState } from "react";

import Tooltip from "@mui/material/Tooltip";
import { Box, Grid, Typography } from "@mui/material";
import { SubMenu } from "../SubMenu/SubMenu";

import { BillingTier, Permission } from "auth/store/Model";

import "./styles.scss";

export interface MenuOptionProps {
    title: string;
    description?: string;
    icon?: ReactElement;
    onClick: (e: React.MouseEvent) => void;
    showText: boolean;
    options?: MenuOptionProps[] | undefined;
    selected: boolean;
    component?: React.ComponentType<any>;
    hidden: boolean;
    hidePanelOnSelected?: boolean;
    restrictedBillingTiers?: BillingTier[];
    permission?: Permission;
    hideOnNoFunction: boolean;
}

const MenuOption: React.FC<MenuOptionProps> = ({
                                                   title,
                                                   description,
                                                   icon,
                                                   onClick,
                                                   showText,
                                                   options,
                                                   selected, hidden
                                               }) => {
    const [hovering, setHover] = useState<boolean>(false);

    const handleMouseEnter = () => {
        setHover(true);
    };
    const handleMouseLeave = () => {
        setHover(false);
    };

    const tooltipTitle = !showText && !options ? title : "";
    const subMenuTitle = showText === false ? title : "";
    const classList = selected === true ? "optionContainer selected" : "optionContainer";
    const displayText = showText === true;
    const displayDescription = showText && description !== null;

    return (
        <Tooltip title={tooltipTitle} arrow placement="right" hidden={hidden}>
            <Grid container
                  className={classList}
                  onClick={onClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
            >
                {icon && <Grid container item xs={showText ? 2 : 12} justifyContent={"center"}>
                    <Grid item><Box display={"flex"}>{icon}</Box></Grid>
                </Grid>}
                { showText &&
                <Grid container item xs={icon ? 10 : 12}>
                    {displayText && <Grid item><Typography>{title}</Typography></Grid>}
                    {displayDescription && <Grid item><p><span>{description}</span></p></Grid>}
                </Grid>
                }

                {options &&
                <div className={"optionSubMenu"}>
                    <SubMenu title={subMenuTitle} menuOptions={options} parentSelected={selected} visible={hovering}
                             shadow={true}/>
                </div>
                }

            </Grid>
        </Tooltip>
    );
};
export { MenuOption };
