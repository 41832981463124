import React, { ReactElement } from 'react';

import {Grid, Card, Divider, Button, Typography} from "@mui/material";

import {
    FunctionType,
    ManagementType, StatusType
} from "common/components/input/forms/BusinessFunctionCreationForm/models";
import { RiskLabelTypeSelector } from "common/components/input/selectors/RiskLabelTypeSelector/RiskLabelTypeSelector";
import { nicifyString } from "common/helpers/Regex/regex";
import { FunctionCreation } from "../../models";
import { WizardStepProps } from "wizards/models";

const Confirmation: React.FC<WizardStepProps> = ({object, onBackClicked, onContinueClicked}) => {

    const businessFunction: FunctionCreation = object as FunctionCreation;

    const renderOptions = (elements: any[]): ReactElement[] => {
        return (
            elements.map((t, i) => {
                return <p key={i}>{nicifyString(t.value)}</p>
            })
        )
    }
    
    const onClick = () => {
        onContinueClicked(object);
    }
    
    const nicifiedFunctionType: string = nicifyString(FunctionType[businessFunction.functionDetails.functionTypeId]);
    
    const buttonLabel: string = `Create ${nicifiedFunctionType}`;
   
    return (
        <Grid className={"confirmation topBottomGutters"} container item xs={10} spacing={2}>
            <Grid item container>
                <Grid item xs={12}>
                    <Typography variant={"h4"}><b>Confirmation</b></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color={"secondary"} variant={"h6"}>Please confirm all the details below are correct</Typography>
                </Grid>
            </Grid>
            

            <Card className={"confirmation"} variant={"outlined"}>
                <Grid container spacing={5}>
                    <Grid container item xs={6} spacing={1}>
                        <Grid item xs={12}><h3>Details</h3></Grid>
                        <Grid item container xs={12} justifyContent={"flex-start"}>
                            <Grid item xs={6}><h4>Name:</h4></Grid>
                            <Grid item xs={6}><p>{businessFunction.functionDetails.name}</p></Grid>
                        </Grid>
                        <Divider light={false}/>
                        <Grid item container xs={12}>
                            <Grid item xs={6}><h4>Function Type:</h4></Grid>
                            <Grid item xs={6}><p>{nicifiedFunctionType}</p></Grid>
                        </Grid>
                        <Divider light={false}/>
                        <Grid item container xs={12}>
                            <Grid item xs={6}><h4>Management Type:</h4></Grid>
                            <Grid item xs={6}><p>{ManagementType[businessFunction.functionDetails.managementTypeId]}</p></Grid>
                        </Grid>
                        <Divider light={false}/>
                        <Grid item container xs={12}>
                            <Grid item xs={6}><h4>Status:</h4></Grid>
                            <Grid item xs={6}><p>{StatusType[businessFunction.functionDetails.statusTypeId]}</p></Grid>
                        </Grid>
                        <Divider light={false}/>
                        <Grid item container xs={12}>
                            <Grid item xs={6}><h4>Owner:</h4></Grid>
                            <Grid item xs={6}><p>{businessFunction.functionDetails.owner?.identityProviderUserName}</p></Grid>
                        </Grid>
                        <Divider light={false}/>
                        <Grid item container xs={12}>
                            <Grid item xs={6}><h4>Maintainer:</h4></Grid>
                            <Grid item xs={6}><p>{businessFunction.functionDetails.maintainer?.identityProviderUserName}</p></Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={1}>
                        <Grid item xs={12}><h3>Compliance Impact</h3></Grid>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item>
                                <h4><b>Confidentiality:</b></h4>
                            </Grid>
                            <Grid item container justifyContent={"space-between"} xs={12}>
                                <Grid item><h4>Score</h4></Grid>
                                <Grid item><p>{businessFunction.functionDetails.confidentialityValue}</p></Grid>
                            </Grid>
                            <Grid item container justifyContent={"space-between"} xs={12}>
                                <Grid item><h4>Effect</h4></Grid> 
                                <Grid item><RiskLabelTypeSelector risk={businessFunction.functionDetails.confidentialityValue}/></Grid>
                            </Grid>
                            <Grid item container justifyContent={"space-between"} xs={12}>
                                <Grid item><h4>Justification</h4></Grid>
                                <Grid item><p>{businessFunction.functionDetails.confidentialityJustification}</p></Grid>
                            </Grid>
                        </Grid>
                        <Divider light={false}/>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item>
                                <h4><b>Integrity:</b></h4>
                            </Grid>
                            <Grid item container justifyContent={"space-between"} xs={12}>
                                <Grid item><h4>Score</h4></Grid>
                                <Grid item><p>{businessFunction.functionDetails.integrityValue}</p></Grid>
                            </Grid>
                            <Grid item container justifyContent={"space-between"} xs={12}>
                                <Grid item><h4>Effect</h4></Grid>
                                <Grid item><RiskLabelTypeSelector risk={businessFunction.functionDetails.integrityValue}/></Grid>
                            </Grid>
                            <Grid item container justifyContent={"space-between"} xs={12}>
                                <Grid item><h4>Justification</h4></Grid>
                                <Grid item><p>{businessFunction.functionDetails.integrityJustification}</p></Grid>
                            </Grid>
                        </Grid>
                        <Divider light={false}/>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item>
                                <h4><b>Availability:</b></h4>
                            </Grid>
                            <Grid item container justifyContent={"space-between"} xs={12}>
                                <Grid item><h4>Score</h4></Grid>
                                <Grid item><p>{businessFunction.functionDetails.availabilityValue}</p></Grid>
                            </Grid>
                            <Grid item container justifyContent={"space-between"} xs={12}>
                                <Grid item><h4>Effect</h4></Grid>
                                <Grid item><RiskLabelTypeSelector risk={businessFunction.functionDetails.availabilityValue}/></Grid> 
                            </Grid>
                            <Grid item container justifyContent={"space-between"} xs={12}>
                                <Grid item><h4>Justification</h4></Grid>
                                <Grid item><p>{businessFunction.functionDetails.availabilityJustification}</p></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={1}>
                        <Grid item xs={12}><h3>Sector & Type Assignment</h3></Grid>
                        <Grid item container xs={12} justifyContent={"flex-start"}>
                            <Grid item xs={6}><h4>Types:</h4></Grid>
                            <Grid item xs={6}>
                                {renderOptions(businessFunction.types)}
                            </Grid>
                        </Grid>
                        <Divider light={false}/>
                        <Grid item container xs={12} justifyContent={"flex-start"}>
                            <Grid item xs={6}><h4>Sectors:</h4></Grid>
                            <Grid item xs={6}>
                                {renderOptions(businessFunction.sectors)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            <Grid container item xs={12}>
                <Grid container item xs={2} justifyContent={"flex-start"}>
                    <Grid item>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            onClick={onBackClicked}
                        >Previous</Button>
                    </Grid>
                </Grid>
                <Grid container xs={10} item justifyContent={"flex-end"}>
                    <Grid item>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            onClick={onClick}
                        >{buttonLabel}</Button>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}
export { Confirmation }