import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CircularProgress, Fade, Grid, Modal, } from "@mui/material";
import { CompanyUserDto } from "common/dtos/Companies/models";
import { userService } from "common/services/UserService/userService";
import { PermissionsRequest, UserCompanyPermission } from "auth/store/Model";
import { getNameFromUser } from "common/helpers/Naming/naming";
import { LookupDto } from "common/services/LookupService/models";
import { lookupService } from "common/services/LookupService/lookupService";
import { ChipSelector } from "common/components/input/fields/ChipSelector/ChipSelector";
import { Formik, FormikProps } from 'formik';
import { nameof } from "ts-simple-nameof";

export interface ModifyPermissionsModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    companyUser: CompanyUserDto;
}

const ModifyPermissionsModal: React.FC<ModifyPermissionsModalProps> = ({open, setOpen, companyUser}) => {

    const [userPermissions, setUserPermissions] = useState<UserCompanyPermission[]>([]);
    const [permissions, setPermissions] = useState<LookupDto<string>[]>([]);
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            userService.getUserPermissions(companyUser.user.id).then((response) => {
                setUserPermissions(response);
            })
        }
    }, [companyUser, open])

    useEffect(() => {
        lookupService.getUserPermissions().then((response) => {
            setPermissions(response);
        })
    }, [companyUser])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const getOptionSelected = (option: LookupDto<string>, selected: UserCompanyPermission[]) => selected.some(opt => opt.permissionId === option.id && opt.active);

    const onSubmit = useCallback((request: PermissionsRequest) => {
        setSaving(true);
        userService.updateUserPermissions(request).finally(() => {
            setSaving(false);
        })
    }, [])

    return (
        <Modal
            className={"modal"}
            open={open}
            onClose={handleClose}
        >
            <Fade in={open}>
                <Grid container item xs={10} sm={8} md={4}>
                    <Card>
                        <Formik
                            initialValues={{ userId: companyUser.user.id, permissions: userPermissions }}
                            onSubmit={(request) => {
                                onSubmit(request)
                            }}
                            validateOnChange={false}
                            enableReinitialize
                        >
                            {(props: FormikProps<PermissionsRequest>) => {

                                const {
                                    handleSubmit,
                                    setFieldValue,
                                    values,
                                } = props;

                                const onChange = (option: any) => {

                                    const index = values.permissions.findIndex(p => p.permissionId === option.id)
                                    
                                    setFieldValue(nameof<PermissionsRequest>(p => p.permissions),
                                            index !== -1 ? [...values.permissions.filter(p => p.permissionId !== option.id), {permissionId: option.id, active: !values.permissions[index].active}]
                                                : [...values.permissions, {permissionId: option.id, active: !userPermissions.some(up => up.permissionId === option.id)}]
                                    )
                                }
                                
                                return (
                                    <Grid container spacing={2} justifyContent={"flex-end"}>
                                        <Grid item xs={12}>
                                            <ChipSelector
                                                label={`Modify Permissions of ${getNameFromUser(companyUser.user)}`}
                                                options={permissions} selected={values.permissions}
                                                getOptionSelected={getOptionSelected} onChange={onChange}/>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => handleSubmit()} color={"success"} variant={"outlined"} endIcon={saving && <CircularProgress color={"success"} size={25}/>}>
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                            }}
                        </Formik>
                    </Card>
                </Grid>
            </Fade>
        </Modal>
    )
}
export { ModifyPermissionsModal }