import { AxiosApi } from "../../axios/axiosApi";
import { CompanyCreation, CompanyCreationResponse } from "../../../wizards/company/models";
import { UserDetailsDto } from "../../dtos/Users/models";
import {
    CompanyDetailsDto,
    CompanySettingsDto,
    companySettingsInitialState,
    companyDetailsInitialState,
    CompanyUserDto, companyUserInitialState
} from "../../dtos/Companies/models";
import SnackbarUtils from "../../helpers/SnackBar/snackbar";
import { RiskLabelsUpdateRequest } from "common/requests/Companies/RiskLabelsUpdateRequest";
import { OfficerUpdateRequest } from "common/requests/Companies/OfficerUpdateRequest";
import { OfficerDto } from "wizards/company/components/CreationForm/models";
import { LookupDto, lookupDtoInitialState } from "common/services/LookupService/models";

class CompanyService {
    private controllerName = "api/Company";

    public async createCompany(companyCreation: CompanyCreation): Promise<CompanyCreationResponse> {

        const url: string = `${this.controllerName}`;
        
        return await AxiosApi.post(url, JSON.stringify(companyCreation)).then(response => {
            SnackbarUtils.success('Company has been created!');
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async updateCompanyDetails(companyDetails: CompanyDetailsDto): Promise<CompanyDetailsDto> {
        const url: string = `${this.controllerName}/UpdateCompanyDetails`;

        return await AxiosApi.post(url, JSON.stringify(companyDetails)).then(response => {
            SnackbarUtils.success('Company Details Updated!');
            return response.data;
        }).catch(e => {
            return companyDetailsInitialState;
        });
    }
    
    public async updateBillingTier(billingTierId: number): Promise<LookupDto<string>> {
        const url: string = `${this.controllerName}/UpdateBillingTier/${billingTierId}`;

        return await AxiosApi.post(url).then(response => {
            SnackbarUtils.success('Billing Tier Updated!');
            return response.data;
        }).catch(e => {
            return lookupDtoInitialState;
        });
    }
    
    public async getUserCompanies(): Promise<CompanyDetailsDto[]> {
        
        const url: string = `${this.controllerName}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async getCompanyUser(companyId: number, userId: number): Promise<CompanyUserDto> {
        const url: string = `${this.controllerName}/GetCompanyUser/${companyId}/${userId}`
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return companyUserInitialState;
        })
    }

    public async getUsersAndGroups(): Promise<CompanyUserDto[]> {
        const url: string = `${this.controllerName}/GetUsersAndGroups`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getUnclaimedUsers(): Promise<UserDetailsDto[]> {
        const url: string = `${this.controllerName}/GetUnclaimedUsers`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }
    
    public async getCompanyById(companyId: number): Promise<CompanyDetailsDto> {
        
        const url: string = `${this.controllerName}/GetCompany/${companyId}`

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async isNameUnique(name: string): Promise<boolean> {

        const url: string = `${this.controllerName}/IsNameUnique?name=${name}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return false;
        })

    }

    public async isCompaniesHouseNumberUnique(companiesHouseNumber: string): Promise<boolean> {

        const url: string = `${this.controllerName}/IsCompaniesHouseNumberUnique/${companiesHouseNumber}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return false;
        })

    }

    public async isAccountingIdUnique(accountId: string): Promise<boolean> {

        const url: string = `${this.controllerName}/IsAccountingIdUnique?accountingId=${accountId}`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return false;
        })

    }
    
    public async getCompanySettings(): Promise<CompanySettingsDto> {
     
        const url: string = `${this.controllerName}/GetCompanySettings`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return companySettingsInitialState;
        })
        
    }
    
    public async updateRiskLabels(request: RiskLabelsUpdateRequest): Promise<void> {
        
        const url: string = `${this.controllerName}/UpdateRiskLabels`;

        return await AxiosApi.post(url, JSON.stringify(request)).then(response => {
            SnackbarUtils.success("Updated successfully")
            return response.data;
        }).catch(e => {
            return companySettingsInitialState;
        })
    }
    
    public async setCompanyUserRole(userId: number, roleId: number): Promise<void> {
        const url: string = `${this.controllerName}/SetCompanyUserRole/${userId}/${roleId}`;
        
        return await AxiosApi.post(url).then(_ => {
            SnackbarUtils.success("User Role Updated")
            return;
        }).catch(e => {
            SnackbarUtils.error("Error Updating User Role")
            return;
        })
    }
    
    public async updateOfficers(request: OfficerUpdateRequest): Promise<OfficerDto[]> {
        const url: string = `${this.controllerName}/UpdateOfficers`;
        
        return await AxiosApi.post(url, JSON.stringify(request)).then(response => {
            SnackbarUtils.success("Updated successfully")
            return response.data;
        }).catch(e => {
            SnackbarUtils.error("Error Updating Company Officers")
            return companyDetailsInitialState;
        })
    }
}

const companyService = new CompanyService();
export { companyService }