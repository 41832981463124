import React, { useCallback } from 'react';

import { UserRaciToActivityCategoryRequest } from "common/dtos/ActivityCategories/models";
import { getUsersByName } from "user/store/actions";
import { RaciManager } from "raci/RaciManager/RaciManager";
import { activityCategoryRaciDelegation } from "raci/actions";

const Raci: React.FC = () => {

    const onSaveChanges = useCallback((request: UserRaciToActivityCategoryRequest): Promise<void> => {
        return activityCategoryRaciDelegation(request);
    }, [])
    
    return (
        <RaciManager
            ignorePermissions={false}
            onSaveChanges={onSaveChanges}
            populateUsersFromApi={true}
            getUsersByName={getUsersByName}
         />
    )
}
export { Raci }