import React, { useEffect, useState } from 'react';
import {
    Button,
    Grid,
} from "@mui/material";

import { Formik, FormikProps } from 'formik';
import { BusinessFunctionCreationForm } from "common/components/input/forms/BusinessFunctionCreationForm/BusinessFunctionCreationForm";
import { creationFormValidationSchema } from "./models";
import { UserDetailsDto } from "common/dtos/Users/models";
import { WizardStepProps } from "wizards/models";
import { getUsersAndGroups } from "company/actions";
import { FunctionDetailsDto } from "common/dtos/Businesses/FunctionDetailsDto";

const CreationForm: React.FC<WizardStepProps> = ({onContinueClicked, object}) => {

    const [candidates, setCandidates] = useState<UserDetailsDto[]>([]);
    
    useEffect(() => {

        getUsersAndGroups().then(data => {
            setCandidates(data.map(u => u.user));
        })

    }, [])

    const onClick = (functionDetails: FunctionDetailsDto) => {
        onContinueClicked({
            ...object,
            functionDetails: functionDetails
        })
    }

    return (
        <Formik
            initialValues={object.functionDetails}
            onSubmit={(functionFormState: FunctionDetailsDto) => {
                onClick(functionFormState)
            }}
            validationSchema={creationFormValidationSchema}
        >
            {(props: FormikProps<FunctionDetailsDto>) => {

                const {errors, touched, handleSubmit, handleChange, setFieldValue, values} = props;

                return (
                    <Grid container item xs={10} spacing={2}>
                        <Grid item xs={12}>
                            <Grid item><h1>Create a Business Function!</h1></Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <BusinessFunctionCreationForm
                                values={values}
                                errors={errors}
                                touched={touched}
                                candidates={candidates}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Grid container item>
                            <Grid container xs={12} item justifyContent={"flex-end"}>
                                <Grid item>
                                    <p className={"required-notice"}>Required fields marked with *</p>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color={"primary"}
                                        variant={"outlined"}
                                        size={"large"}
                                        onClick={() => handleSubmit()}
                                    >Continue</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export
{
    CreationForm
}