import React, { useCallback, useEffect, useRef } from 'react';
import { InviteUsersForm } from "users/InviteUsersForm/InviteUsersForm";
import { WizardStepProps } from "wizards/models";
import { UserInvitationDto } from "users/InviteUsersForm/models";
import { Button, Grid } from "@mui/material";
import { CompanyCreation } from "wizards/company/models";
import { UserRoles } from "common/dtos/Common/userRoles";
import { userSelector } from "user/store/selectors";
import { useSelector } from "react-redux";

const InviteUsers: React.FC<WizardStepProps> = ({onContinueClicked, onBackClicked, object}) => {

    const formRef = useRef<any>();

    const user = useSelector(userSelector);

    const onSubmit = useCallback((invites: UserInvitationDto[]): Promise<void> => {

        onContinueClicked({
            ...object,
            invites: invites
        });

        return Promise.resolve()

    }, [object, onContinueClicked])

    const onNext = useCallback(() => {
        formRef.current?.handleSubmit();
    }, [])

    const populateInvitesWithOfficers = useCallback(() => {

        const companyCreation: CompanyCreation = object;

        object.invites = companyCreation.company.officers
            .filter(o => o.email !== user.email)
            .map((officer): UserInvitationDto => {
                return {
                    email: officer.email,
                    role: UserRoles.Admin,
                    note: ""
                }
            })

    }, [object, user])

    useEffect(() => {
        if (object.invites.length === 0) {
            populateInvitesWithOfficers();
        }
    }, [object.invites, populateInvitesWithOfficers])

    return (
        <React.Fragment>
            <Grid xs={10} container item>
                <InviteUsersForm
                    infoText={`Invite new members by email to ${object.company.name}`}
                    initialState={{invites: object.invites}}
                    onSubmit={onSubmit}
                    formRef={formRef}
                    entryRequired={false}
                />
            </Grid>
            <Grid item xs={10} container justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
                <Grid item>
                    <Button
                        color={"primary"}
                        variant={"outlined"}
                        onClick={onBackClicked}
                    >Previous</Button>
                </Grid>
                <Grid item>
                    <Button
                        color={"primary"}
                        variant={"outlined"}
                        onClick={onNext}
                    >Next</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export { InviteUsers }