import { AxiosApi } from "../../axios/axiosApi";
import { ActivityCategoryDetailsDto, activityCategoryInitialState } from "../../dtos/Questions/QuestionDetails/models";
import { DelegatedActivityCategoryDto } from "../../dtos/Users/models";
import { UserRaciToActivityCategoryRequest } from "../../dtos/ActivityCategories/models";
import SnackbarUtils from "../../helpers/SnackBar/snackbar";
import { TrackedActivityCategoryDto, trackedActivityCategoryInitialState } from "common/dtos/ActivityCategories/models";

class ActivityCategoryService {

    private controllerName: string = "api/ActivityCategory"

    public async getActivityCategories(): Promise<ActivityCategoryDetailsDto[]> {
        
        return await AxiosApi.get(this.controllerName).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        });
    }
    
    public async getUsersByActivityCategory(activityCategoryId: number): Promise<DelegatedActivityCategoryDto[]> {
        const url: string = `${this.controllerName}/GetUsersByActivityCategory/${activityCategoryId}`
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return []
        });
    }
    
    public async activityCategoryRaciDelegation(userRaciToActivityCategoryRequest: UserRaciToActivityCategoryRequest): Promise<void> {
        const url: string = `${this.controllerName}/ActivityCategoryRaciDelegation`

        return await AxiosApi.post(url, JSON.stringify(userRaciToActivityCategoryRequest)).then(response => {
            SnackbarUtils.success("RACI Delegation Updated Successfully") 
            return;
        }).catch(e => {
            return;
        });
    }
    
    public async updateActivityCategory(activityCategory: ActivityCategoryDetailsDto): Promise<ActivityCategoryDetailsDto> {
        const url: string = `${this.controllerName}/UpdateActivityCategory`

        return await AxiosApi.post(url, JSON.stringify(activityCategory)).then(response => {
            return response.data;
        }).catch(e => {
            return activityCategoryInitialState;
        });
    }

    public async getTrackedActivityCategories(): Promise<TrackedActivityCategoryDto[]> {
        const url:string = `${this.controllerName}/GetTrackedActivityCategories`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async trackActivityCategory(activityCategoryDetailsId: number, track: boolean): Promise<TrackedActivityCategoryDto> {
        const url:string = `${this.controllerName}/TrackActivityCategory/${activityCategoryDetailsId}/${track}`;

        return await AxiosApi.post(url).then(response => {
            return response.data;
        }).catch(e => {
            return trackedActivityCategoryInitialState;
        })
    }
    
    public async createActivityCategory(activityCategory: ActivityCategoryDetailsDto): Promise<ActivityCategoryDetailsDto> {
        const url:string = `${this.controllerName}/Create`;

        return await AxiosApi.post(url, JSON.stringify(activityCategory)).then(response => {
            SnackbarUtils.success("Created Activity Category")
            return response.data;
        }).catch(e => {
            return trackedActivityCategoryInitialState;
        })
    }
    
    public async editActivityCategory(activityCategory: ActivityCategoryDetailsDto) : Promise<ActivityCategoryDetailsDto> {
        const url: string = `${this.controllerName}/Edit`;
        
        return await AxiosApi.post(url, JSON.stringify(activityCategory)).then(response => {
            SnackbarUtils.success("Edited Activity Category")
            return response.data;
        }).catch(e => {
            return activityCategoryInitialState;
        })
    }
    
    public async deleteActivityCategory(activityCategoryId: number): Promise<void> {
        const url: string = `${this.controllerName}/Delete/${activityCategoryId}`;
        
        return await AxiosApi.delete(url).then(_ => {
            SnackbarUtils.success("Deleted Activity Category")
        })
    }
}

const activityCategoryService = new ActivityCategoryService();
export { activityCategoryService }