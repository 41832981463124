import React, { useEffect, useState } from 'react';

import { getQuestionsReviewList } from "../../store/actions";
import { QuestionReviewDto } from "common/services/ReportingService/models";

import moment from "moment";
import { orderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";
import { Grid } from "@mui/material";
import {
    GridColumn,
    GridDataStateChangeEvent,
    GridPageChangeEvent, 
    GridSortChangeEvent
} from "@progress/kendo-react-grid";
import { nameof } from "ts-simple-nameof";
import { Grid as GridTable } from "@progress/kendo-react-grid/dist/npm/Grid"

import "./styles.scss";
import ShowMoreText from "react-show-more-text";

const QuestionReviewTable: React.FC = () => {

    const [questionsReviewList, setQuestionsReviewList] = useState<QuestionReviewDto[]>([]);
    const [pagingState, setPagingState] = useState<State>({skip: 0, take: 20});
    const [sort, setSort] = useState<SortDescriptor[]>([]);

    const skip: number = pagingState.skip!;
    const take: number = pagingState.take!;

    
    useEffect(() => {
        getQuestionsReviewList().then((response) => {
            setQuestionsReviewList(response);
        })
    }, [setQuestionsReviewList])

    const onPageChange = (event: GridPageChangeEvent) => {
        setPagingState({
            ...pagingState,
            skip: event.page.skip,
            take: event.page.take
        })
    }

    const onDataStateChange = (event: GridDataStateChangeEvent) => {
        setPagingState(event.dataState);
        setQuestionsReviewList((event.dataState.filter !== null)
            ? process([...questionsReviewList], event.dataState).data
            : questionsReviewList);
    }

    const onSortChange = (event: GridSortChangeEvent) => {
        setSort(event.sort);
    }    
    
    return (
        <Grid container spacing={2} className={'riskTableContainer'}>
            <Grid item xs={12}>
                        <GridTable
                            data={orderBy(questionsReviewList, sort).slice(skip, take + skip)}
                            {...pagingState}
                            onPageChange={onPageChange}
                            onDataStateChange={onDataStateChange}
                            onSortChange={onSortChange}
                            total={questionsReviewList.length}
                            pageable={true}
                            sortable={true}
                            sort={sort}
                            className={'questionReviewTable'}
                        >
                            <GridColumn field={nameof<QuestionReviewDto>(a => a.question)} 
                                        title={"Question"} 
                                        filter={"text"} 
                                        width={'380px'}
                                        cell={props => <td><ShowMoreText
                                            className={"questionReviewTable"}
                                            lines={3}
                                            more='Show more'
                                            less='Show less'
                                            expanded={false}
                                        >
                                            {props.dataItem.question}
                                        </ShowMoreText></td>}/>                           
                            
                            <GridColumn field={nameof<QuestionReviewDto>(a => a.answerStatus)} filter={"text"} title={"Status"}/>
                            
                            <GridColumn field={nameof<QuestionReviewDto>(a => a.lastUpdate)} title={"Last Response"} filter={"date"} 
                            cell={props => <td>{moment.utc(props.dataItem.lastUpdate).format('DD-MM-YYYY')}</td>}/>

                            <GridColumn field={nameof<QuestionReviewDto>(a => a.activityCategory)} filter={"text"} title={"Activity Category"} width={200}/>

                            <GridColumn field={nameof<QuestionReviewDto>(a => a.responsible)} filter={"text"} title={"Responsible"}/>

                            <GridColumn field={nameof<QuestionReviewDto>(a => a.accountable)} filter={"text"} title={"Accountable"}/>

                            <GridColumn field={nameof<QuestionReviewDto>(a => a.consulted)} filter={"text"} title={"Consulted"}/>

                            <GridColumn field={nameof<QuestionReviewDto>(a => a.informed)} filter={"text"} title={"Informed"}/>

                            <GridColumn field={nameof<QuestionReviewDto>(a => a.nextReview)} title={"Next Review"} filter={"date"}
                                        cell={props => <td>{props.dataItem.nextReview ? moment.utc(props.dataItem.nextReview).calendar() : '-'}</td>}/>

                        </GridTable>
                        
            </Grid>
        </Grid>
    );
}

export { QuestionReviewTable }