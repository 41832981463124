export interface AddressDto {
    addressLine1: string;
    addressLine2: string;
    locality: string;
    postcode: string;
    country: string;
}

const addressInitialState: AddressDto = {
    addressLine1: "",
    addressLine2: "",
    country: "",
    locality: "",
    postcode: ""
}

export { addressInitialState };