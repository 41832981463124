import React, { useEffect, useState } from 'react';

import { TitleCard } from "common/components/panels/TitleCard/TitleCard";
import { OverallStatusDistributionDto } from "common/services/ReportingService/models";
import { getOverallStatusDistribution } from "./store/actions";
import { PieChart } from "./components/charts/pieChart/PieChart";
import { DashboardFunctionProps } from "../dashboard/Dashboard";

const OverallStatusDistribution: React.FC<DashboardFunctionProps> = ({ selectedFunction }) => {
    const [data, setData] = useState<OverallStatusDistributionDto[]>([]);

    useEffect(() => {
        getOverallStatusDistribution().then((response) => {
            setData(response);
        })
    }, [setData, selectedFunction])

    return (
        data &&
        <TitleCard
            title={'Question Status'}
            height={250}
            linkIcon={true}
            link={'questions'}
            hoverOver={'Track your progress against obligation questions remaining, to give you a better overview, of what your estate risks are.'}
        >
            <PieChart data={data}/>
        </TitleCard>
    )
}

export { OverallStatusDistribution }