import React, {ReactElement, useState} from 'react';

import {Box, Collapse, Grid, IconButton} from "@mui/material";

import {ExpandMoreRounded, ExpandLessRounded} from "@mui/icons-material";

import "./styles.scss";

export interface CollapsibleSubsectionProps {
    title: string;
    collapsible?: boolean;
    collapsed?: boolean;
    subsectionItems?: ReactElement[];
}

const CollapsibleSubsection: React.FC<CollapsibleSubsectionProps> = ({
                                                                         title,
                                                                         collapsible,
                                                                         collapsed,
                                                                         subsectionItems,
                                                                         children
                                                                     }) => {

    const [expand, setExpand] = useState<boolean>(!collapsed ?? false);

    const toggleExpand = () => {

        if (!collapsible)
            return;

        setExpand(!expand);
    }

    const displayItems = (): ReactElement => {
        const icon = (expand) ? <ExpandLessRounded/> : <ExpandMoreRounded/>
        return (
            <Grid className={"collapsible-icons"} item>
                {subsectionItems && subsectionItems.map((item, index) => <Grid item key={index}>{item}</Grid>)}
                {collapsible &&
                <Grid item>
                    <IconButton
                        size={"small"}
                        onClick={toggleExpand}
                    >
                        {icon}
                    </IconButton>
                </Grid>
                }

            </Grid>
        )
    }

    return (
        <Grid className={"collapsible-subsection"} container item>
            <div className={"heading"}>
                <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                    <Grid className={"collapse-title"} item>
                        <h4 onClick={toggleExpand}>{title}</h4>
                    </Grid>
                    {displayItems()}
                </Grid>
            </div>
            <Grid container item className={"collapsible-content"}>
                <Grid item xs={12}>
                    <Collapse in={!collapsible || (expand && collapsible)}>
                        {children &&
                        <Grid container>
                            <Box width={"100%"} padding={"0.5em"}>
                                {children}
                            </Box>
                        </Grid>
                        }
                    </Collapse>
                </Grid>
            </Grid>
        </Grid>
    );
}
export {CollapsibleSubsection}