import * as Yup from "yup";

import { DelegationType } from "common/dtos/Common/delegation";
import { UserDetailsDto } from "common/dtos/Users/models";

export interface QuestionDelegationForm {
    assignees: DelegationAssignee[];
    note: string;
    emailAssignee: boolean;
}

export interface DelegationAssignee {
    user: UserDetailsDto;
    delegationType: DelegationType;
    emailAssignee: boolean;
}

const questionDelegationFormInitialState: QuestionDelegationForm = {
    assignees: [],
    note: "",
    emailAssignee: false
}
export { questionDelegationFormInitialState }

const questionDelegationInitialState: QuestionDelegationForm = {
    assignees: [],
    note: "",
    emailAssignee: false
}
export { questionDelegationInitialState }

const questionDelegateValidationSchema = Yup.object().shape({
    assignees: Yup.array().of(
        Yup.object().shape({
            user: Yup.object().required(),
            delegationType: Yup.number().min(1, "Required").typeError("Required"),
            emailAssignee: Yup.boolean()
        })
    ),
});
export { questionDelegateValidationSchema }