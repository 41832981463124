import { IAccountInfo } from "react-aad-msal";
import { UserDetailsDto, UserSettingsDto } from "common/dtos/Users/models";

export type AuthenticationState = {
    userClaims: IAccountInfo;
    validated?: boolean;
    companyUserPermission: UserCompanyPermission[]
};

export type Validation = {
    validated: boolean;
    user: UserDetailsDto;
    userSettings: UserSettingsDto;
}

export type Claims = {
    tid: string | undefined,
    oid: string | undefined,
    email: string | undefined
}

export interface IAuthConfig {
    authority: string,
    clientId: string,
    scopes: string[],
    version: string
}

export interface UserCompanyPermission {
    permissionId: number;
    active: boolean;
}

export enum Permission {
    None = 0,
    BusinessFunctionsCreate,
    BusinessFunctionsEdit,
    BusinessFunctionsDelete,
    BusinessFunctionsView,
    CompanyContextsCreate,
    CompanyContextsEdit,
    CompanyContextsDelete,
    CompanyContextsView,
    AssetsCreate,
    AssetsEdit,
    AssetsDelete,
    AssetsView,
    ObligationsCreate,
    ObligationsEdit,
    ObligationsDelete,
    ObligationsView,
    CustomActivitiesCreate,
    CustomActivitiesEdit,
    CustomActivitiesDelete,
    CustomActivitiesView,
    QuestionsView,
    QuestionsEdit,
    QuestionsExport,
    QuestionsEvidenceUpload,
    QuestionsEvidenceUploadsDelete,
    QuestionsDelegate,
    QuestionsAddNotes,
    ReportsRemediate,
    ReportsView,
    ReportsExport,
    RACIResponsibleAssign,
    RACIResponsibleEdit,
    RACIResponsibleDelete,
    RACIAccountableAssign,
    RACIAccountableEdit,
    RACIAccountableDelete,
    RACIConsultedAssign,
    RACIConsultedEdit,
    RACIConsultedDelete,
    RACIInformedAssign,
    RACIInformedEdit,
    RACIInformedDelete,
    UserCreate,
    UserEdit,
    UserDelete,
    UserView,
    UserInvite,
    RisksView,
    GuideLinesView,
    RACIView,
    SysAdmin,
}

export enum BillingTier {
    Bronze = 1,
    Silver = 2,
    Gold = 3,
    Platinum = 4,
}

export interface PermissionsRequest {
    userId: number;
    permissions: UserCompanyPermission[];
}
