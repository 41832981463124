import React from "react";
import { Route } from "react-router";
import { PageNotFound } from "status/PageNotFound/PageNotFound";
import { ComingSoon } from "status/ComingSoon/ComingSoon";
import { Loading } from "status/Loading/Loading";
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "user/store/selectors";

interface ValidationRouteProps {
    exact?: boolean;
    path: string;
    authenticated: boolean;
    comingSoon?: boolean;
    loading: boolean;
    component: React.ComponentType<any>;
    requiresFunctionContext?: boolean;
}

const ValidationRoute = ({
                                component: Component,
                                path,
                                exact,
                                authenticated,
                                comingSoon,
                                loading,
                                requiresFunctionContext,
                                ...otherProps
                            }: ValidationRouteProps) => {

    const renderRoute = (props: any) => {

        if (loading) {
            return <Loading/>
        }
        
        if (authenticated) {
            return comingSoon ? <ComingSoon/> : <Component {...otherProps} />
        }

        return <PageNotFound/>
    }

    const selectedFunction = useSelector(selectedFunctionSelector);

    return ((requiresFunctionContext && selectedFunction) || !requiresFunctionContext ?
        <Route
            path={path}
            exact
            render={otherProps => (
                renderRoute(otherProps)
            )}
        /> : <></>)
}
export { ValidationRoute };