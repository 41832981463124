import React from 'react';
import { Card, Grid, IconButton, Typography } from "@mui/material";
import { Check, EditOutlined } from "@mui/icons-material";
import { userSelector } from "user/store/selectors";
import { useSelector } from "react-redux";
import { isEmptyOrSpaces } from "common/helpers/Regex/regex";

import "../../styles.scss";
import { Link } from "react-router-dom";

const UpdateProfileMessage: React.FC = () => {

    const user = useSelector(userSelector);
    const isComplete = !isEmptyOrSpaces(user.firstName);

    return (
        <Card>
            <Link to={"/settings/user"}>
                <Grid container item alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item>
                            <Grid item xs={12}>
                                <Typography variant={'h6'}>
                                Complete Profile Information
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'body1'} color={"textSecondary"}>
                                    We need just a few more details about you for your profile so we can better cater the ASIRTA
                                    towards you.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <IconButton
                                className={"statusIconButton"} 
                                size={"large"} 
                                color={isComplete ? "success" : "warning"}>
                                {isComplete ? <Check/> : <EditOutlined/>}
                            </IconButton>
                        </Grid> 
                </Grid>
            </Link>
        </Card>
    )
}
export { UpdateProfileMessage }