import React, { ReactElement, useState } from 'react';

import { Container, Grid, Paper, Tab, Tabs } from "@mui/material";

import { NamedComponent } from "../../../generics/NamedComponent/NamedComponent";
import './styles.scss';

export interface ComponentSwitcherProps {
    tables: NamedComponent[];
}

const ComponentSwitcher: React.FC<ComponentSwitcherProps> = ({tables}) => {

    const [activeComponentType, setActiveComponentType] = useState<number>(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveComponentType(newValue);
    }

    const getActiveComponent = () => {
        if (activeComponentType < tables.length) 
            return React.cloneElement(tables[activeComponentType].component);
    }

    const listComponentOptions = (): ReactElement[] => {
        return tables.map((t: NamedComponent, i: number) => {
            return <Tab key={i} label={t.name}/>
        })
    }

    return (
        <Container disableGutters maxWidth={'xl'}>
            <Grid container justifyContent={"center"} spacing={1}>
                <Grid item>
                    <Paper className={"toolbar"} elevation={0}>
                        <Tabs
                            centered
                            onChange={handleChange}
                            value={activeComponentType}
                        >
                            {listComponentOptions()}
                        </Tabs>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {getActiveComponent()}
                </Grid>
            </Grid>
        </Container>
    );
}
export { ComponentSwitcher }