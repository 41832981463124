import React, { ReactElement } from 'react';

import { FormControl, Grid, InputAdornment, MenuItem, TextField } from "@mui/material";
import { nameof } from "ts-simple-nameof";
import { FormikErrors, FormikTouched, getIn } from "formik";

import { NotchedSelect } from "../../fields/NotchedSelect/NotchedSelect";
import { nicifyString } from "common/helpers/Regex/regex";
import { fieldProps, formProps } from "common/theming/models";
import { CompanyDetailsDto } from "common/dtos/Companies/models";
import {
    CurrentChallenges, 
    EmployeeNumber
} from "../../../../../wizards/company/components/AdditionalInfo/models";

export interface CompanyCreationFormProps {
    registered: boolean;
    isRegistered: (registered: boolean) => void;
    handleChange: (e: any) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void;
    values: CompanyDetailsDto;
    errors: FormikErrors<CompanyDetailsDto>;
    touched: FormikTouched<CompanyDetailsDto>;
    setValues: (values: React.SetStateAction<CompanyDetailsDto>, shouldValidate?: (boolean | undefined)) => void;
}

const AdditionalInfoForm: React.FC<CompanyCreationFormProps> = ({
                                                                    registered,
                                                                    isRegistered,
                                                                    handleChange,
                                                                    values,
                                                                    errors,
                                                                    touched,
                                                                    setValues,
                                                                 }) => {
    
    const renderEmployeeNumberOptions = (): ReactElement[] => {
        const regions = Object.keys(EmployeeNumber).filter((key: any) => !isNaN(Number(EmployeeNumber[key]))).map((key: any) => {
            return {
                key: key,
                value: EmployeeNumber[key]
            }
        });
        
        return (
            regions.map((e) => {
               return <MenuItem value={e.value} key={e.key}>{nicifyString(e.key)}</MenuItem>
            })
         )

    }

    const renderChallengeOptions = (): ReactElement[] => {
        const regions = Object.keys(CurrentChallenges).filter((key: any) => !isNaN(Number(CurrentChallenges[key]))).map((key: any) => {
            return {
                key: key,
                value: CurrentChallenges[key]
            }
        });

        return (
            regions.map((e) => {
                return <MenuItem value={e.value} key={e.key}>{nicifyString(e.key)}</MenuItem>
            })
        )
    }

    const employeeNumberTouch = getIn(touched, nameof<CompanyDetailsDto>(c => c.additionalDetails.employeeNumber));
    const employeeNumberError = getIn(errors, nameof<CompanyDetailsDto>(c => c.additionalDetails.employeeNumber));

    const annualTurnoverTouch = getIn(touched, nameof<CompanyDetailsDto>(c => c.additionalDetails.annualTurnover));
    const annualTurnoverError = getIn(errors, nameof<CompanyDetailsDto>(c => c.additionalDetails.annualTurnover));

    const currentChallengesTouch = getIn(touched, nameof<CompanyDetailsDto>(c => c.additionalDetails.currentChallenges));
    const currentChallengesError = getIn(errors, nameof<CompanyDetailsDto>(c => c.additionalDetails.currentChallenges));
        
    return (
        <FormControl{...formProps}>
            <Grid container spacing={2}>
                <Grid item xs={12}/>
                <Grid item xs={8}>
                    <NotchedSelect
                        {...formProps}
                        name={nameof<CompanyDetailsDto>(n => n.additionalDetails.employeeNumber)}
                        label={"How many employees work at your company?*"}
                        value={values?.additionalDetails.employeeNumber || EmployeeNumber.None}
                        onChange={handleChange}
                        error={employeeNumberTouch && Boolean(employeeNumberError)}
                        helperText={employeeNumberTouch && employeeNumberError}
                    >
                        {renderEmployeeNumberOptions()}
                    </NotchedSelect>
                </Grid>
 
                <Grid item xs={4}>
                    <TextField
                        {...fieldProps}
                        name={nameof<CompanyDetailsDto>(c => c.additionalDetails.annualTurnover)}
                        label={"What is your annual turnover?"}
                        value={values.additionalDetails.annualTurnover}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        error={annualTurnoverTouch && Boolean(annualTurnoverError)}
                        helperText={annualTurnoverTouch && annualTurnoverError}
                    />
                </Grid> 
                <Grid item xs={12}>
                    <NotchedSelect
                        {...formProps}
                        name={nameof<CompanyDetailsDto>(n => n.additionalDetails.currentChallenges)}
                        label={"What are your current challenges?*"}
                        value={values?.additionalDetails.currentChallenges || CurrentChallenges.None}
                        onChange={handleChange}
                        error={currentChallengesTouch && Boolean(currentChallengesError)}
                        helperText={currentChallengesTouch && currentChallengesError}
                    >
                        {renderChallengeOptions()}
                    </NotchedSelect>
                </Grid> 
            </Grid>
        </FormControl>
    )
}
export { AdditionalInfoForm }