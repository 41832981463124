import React from 'react'
import { Grid } from "@mui/material";
import { Skeleton } from '@mui/material';

const UserSkeletonLoader: React.FC = () => {
    return (
        <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={1}>
                <Skeleton animation={"wave"} variant="circular" width={40} height={40} />
            </Grid>
            <Grid item xs={11}>
                <Skeleton animation={"wave"} variant="rectangular" width={"100%"} height={10} />
            </Grid>
        </Grid>
    );
}
export { UserSkeletonLoader }