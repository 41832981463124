import React, { ReactElement, useEffect, useState } from 'react';

import { FormControl, FormHelperText, Grid, MenuItem, TextField } from "@mui/material";
import { nameof } from "ts-simple-nameof";
import { FormikErrors, FormikTouched, getIn } from "formik";

import {
    Jurisdiction
} from "wizards/company/components/CreationForm/models";
import { NotchedSelect } from "../../fields/NotchedSelect/NotchedSelect";
import { isEmptyOrSpaces, nicifyString } from "common/helpers/Regex/regex";
import { DebounceAutocomplete } from "../../fields/DebounceAutocomplete/DebounceAutocomplete";
import { CompanyProfileResponseDto, CompanySearchResponseDto } from "common/services/CompaniesHouseService/models";
import { AddressForm } from "../AddressForm/AddressForm";

import { companiesHouseService } from "common/services/CompaniesHouseService/companyHouseService";
import { fieldProps, formProps } from "common/theming/models";
import { CompanyDetailsDto } from "common/dtos/Companies/models";

export interface CompanyCreationFormProps {
    registered: boolean;
    isRegistered: (registered: boolean) => void;
    handleChange: (e: any) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void;
    values: CompanyDetailsDto;
    errors: FormikErrors<CompanyDetailsDto>;
    touched: FormikTouched<CompanyDetailsDto>;
    setValues: (values: React.SetStateAction<CompanyDetailsDto>, shouldValidate?: (boolean | undefined)) => void;
}

const CompanyCreationForm: React.FC<CompanyCreationFormProps> = ({
                                                                     registered,
                                                                     isRegistered,
                                                                     handleChange,
                                                                     values,
                                                                     errors,
                                                                     touched,
                                                                     setValues,
                                                                 }) => {

    const [companiesLoading, setCompaniesLoading] = useState<boolean>(false);
    const [companyOptions, setCompanyOptions] = useState<CompanySearchResponseDto[]>([]);
    const [selectedCompanyName, setSelectedCompanyName] = useState<string>(values.name);

    useEffect(() => {
        if (!isEmptyOrSpaces(values.name)) {
            onCompanyHouseInputChanged(values.name);
        }
    }, [values.name])

    const onToggleCompanyHouseForm = (toggle: boolean) => {
        isRegistered(toggle);
    }

    const onCompanyHouseInputChanged = (value: string): void => {
        if (!isEmptyOrSpaces(value)) {
            setSelectedCompanyName(value);
            setCompaniesLoading(true);
            companiesHouseService.getCompaniesByName(value).then((response: CompanySearchResponseDto[]) => {
                setCompaniesLoading(false);
                setCompanyOptions(response);
            })
            return;
        }

        setCompaniesLoading(false);
        setCompanyOptions([])
    }

    const onCompanyHouseChange = (value: CompanySearchResponseDto | null): void => {
        if (value === null)
            return;

        getCompanyData(value.companiesHouseId).then((response: CompanyProfileResponseDto) => {
            setValues({
                ...values,
                name: response.companyName,
                additionalDetails: {
                    ...values.additionalDetails,
                    companiesHouseId: response.companiesHouseId,
                    jurisdiction: response.jurisdiction,
                    address: response.registeredOfficeAddress,
                },
                officers: response.officers,
            })
        });
    }

    const getCompanyData = (companiesHouseId: string): Promise<CompanyProfileResponseDto> => {
        return companiesHouseService.getCompanyByCompaniesHouseId(companiesHouseId);
    }

    const onSelectedCompanyNameChanged = (value: string) => {
        setSelectedCompanyName(value);
    }

    const renderCompanyAutocomplete = (): ReactElement => {
        return (
            <DebounceAutocomplete
                name={nameof<CompanyDetailsDto>(c => c.name)}
                label={"Organisation Name*"}
                value={selectedCompanyName}
                inputValue={selectedCompanyName}
                variant={"outlined"}
                debounceTime={800}
                isLoading={companiesLoading}
                options={companyOptions}
                placeholder={"Start typing Company name to search..."}
                onInputChanged={onSelectedCompanyNameChanged}
                onValueChanged={onCompanyHouseInputChanged}
                onChange={onCompanyHouseChange}
                getOptionSelected={(option, value) => option.title === value.title}
                helperText={
                    <FormHelperText className={"help-text"} onClick={() => onToggleCompanyHouseForm(false)}>My
                        company is not registered with Companies House.</FormHelperText>
                }
                error={touched.name && Boolean(errors.name)}
                errorHelperText={touched.name && errors.name}
            />
        )
    }

    const renderCompanyTextField = (): ReactElement => {
        return (
            <Grid container item xs={12}>
                <TextField
                    {...fieldProps}
                    name={nameof<CompanyDetailsDto>(c => c.name)}
                    label={"Organisation Name*"}
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                />
                <Grid container>
                    <Grid item xs={6}>
                        <FormHelperText error={touched.name && Boolean(errors.name)}>
                            {touched.name && errors.name}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <FormHelperText className={"help-text"} onClick={() => onToggleCompanyHouseForm(true)}>
                            My company is registered with Companies House.
                        </FormHelperText>
                    </Grid>
                </Grid>

            </Grid>
        )
    }

    const renderCompanyNumber = (): ReactElement | false => {
        
        const name = nameof<CompanyDetailsDto>(c => c.additionalDetails.companiesHouseId);
        const touch = getIn(touched, name);
        const error = getIn(errors, name);
        
        return <TextField {...fieldProps}
                          name={name}
                          value={values?.additionalDetails.companiesHouseId || ""}
                          onChange={handleChange}
                          label={"Company Number*"}
                          error={(touch) && Boolean(error)}
                          helperText={touch && error}
        />
    }

    const renderAccountingId = (): ReactElement | false => {
        
        const name = nameof<CompanyDetailsDto>(c => c.additionalDetails.accountingId);
        const touch = getIn(touched, nameof<CompanyDetailsDto>(c => c.additionalDetails.accountingId));
        const error = getIn(errors, nameof<CompanyDetailsDto>(c => c.additionalDetails.accountingId));
        
        return <TextField {...fieldProps}
                          name={name}
                          value={values?.additionalDetails.accountingId || ""}
                          onChange={handleChange}
                          label={"Accounting ID*"}
                          error={(touch) && Boolean(error)}
                          helperText={touch && error}
        />
    }

    const renderJurisdictionOptions = (): ReactElement[] => {
        const regions = Object.keys(Jurisdiction).filter((key: any) => !isNaN(Number(Jurisdiction[key]))).map((key: any) => {
            return {
                key: key,
                value: Jurisdiction[key]
            }
        });

        return (
            regions.map((e) => {
                return <MenuItem value={e.value} key={e.key}>{nicifyString(e.key)}</MenuItem>
            })
        )
    }
    
    const juristictionTouch = getIn(touched, nameof<CompanyDetailsDto>(c => c.additionalDetails.jurisdiction));
    const juristictionError = getIn(errors, nameof<CompanyDetailsDto>(c => c.additionalDetails.jurisdiction));
    const vatNumberTouch = getIn(touched, nameof<CompanyDetailsDto>(c => c.additionalDetails.vat));
    const vatNumberError = getIn(errors, nameof<CompanyDetailsDto>(c => c.additionalDetails.vat));

    return (
        <FormControl{...formProps}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    {registered ? renderCompanyAutocomplete() : renderCompanyTextField()}
                </Grid>

                <Grid item md={12}>
                    <AddressForm {...fieldProps}
                                 variant={"outlined"}
                                 values={values.additionalDetails.address}
                                 touched={getIn(touched, nameof<CompanyDetailsDto>(c => c.additionalDetails.address))}
                                 errors={getIn(errors, nameof<CompanyDetailsDto>(c => c.additionalDetails.address))}
                                 handleChange={handleChange}
                                 namePrefix={nameof<CompanyDetailsDto>(c => c.additionalDetails.address)}
                    />
                </Grid>

                <Grid item md={4}>
                    {registered ? renderCompanyNumber() : renderAccountingId()}
                </Grid>
                <Grid item xs={4}>
                    <NotchedSelect
                        {...formProps}
                        name={nameof<CompanyDetailsDto>(n => n.additionalDetails.jurisdiction)}
                        label={"Main Country of Operation*"}
                        value={values?.additionalDetails.jurisdiction || Jurisdiction.None}
                        onChange={handleChange}
                        error={juristictionTouch && Boolean(juristictionError)}
                        helperText={juristictionTouch && juristictionError}
                    >
                        {renderJurisdictionOptions()}
                    </NotchedSelect>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        {...fieldProps}
                        name={nameof<CompanyDetailsDto>(c => c.additionalDetails.vat)}
                        label={"VAT Number"}
                        value={values.additionalDetails.vat}
                        onChange={handleChange}
                        error={vatNumberTouch && Boolean(vatNumberError)}
                        helperText={vatNumberTouch && vatNumberError}
                    />
                </Grid>
            </Grid>
        </FormControl>
    )
}
export
{
    CompanyCreationForm
}