import { AxiosApi } from "../../axios/axiosApi";
import { ObligationRiskDto } from "../../../obligationRisks/store/models";
import { ObligationComplianceDto } from "../../../complianceBreakdown/store/models";
import {
    ActivityComplianceRatingDto,
    ActivityComplianceRatingSummaryDto,
    ObligationComplianceRatingSummaryDto,
    ObligationComplianceRatingDto,
    ComplianceOverTimeDto,
    QuestionReviewDto,
    TrackingYourComplianceScoreDto,
    OverallStatusDistributionDto,
    ObligationStatusDto
} from "./models";
import { ObligationCompletion } from "common/views/Obligations";
import { KpiMetrics, kpiMetricsInitialState } from "common/views/KpiMetrics";
import { StatusDistribution } from "common/views/StatusDistribution";
import { ActivityComplianceRating } from "common/views/ActivityComplianceRating";

class ReportingService {
    private controllerName = "api/Reporting";

    public async getObligationRisk(): Promise<ObligationRiskDto[]> {
        const url: string = `${this.controllerName}/ObligationRisks`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getObligationCompliance(): Promise<ObligationComplianceDto[]> {
        const url: string = `${this.controllerName}/ObligationCompliance`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getActivityComplianceRating(): Promise<ActivityComplianceRatingDto[]> {
        const url: string = `${this.controllerName}/ActivityComplianceRating`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getActivityComplianceSummary(): Promise<ActivityComplianceRatingSummaryDto[]> {
        const url: string = `${this.controllerName}/ActivityComplianceRatingSummary`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getCompanyComplianceOverTime(): Promise<ComplianceOverTimeDto[]> {
        const url: string = `${this.controllerName}/CompanyComplianceOverTime`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getComplianceOverTimeByObligationTop5(): Promise<ComplianceOverTimeDto[]> {
        const url: string = `${this.controllerName}/ComplianceOverTimeByObligationTop5`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }    

    public async getComplianceOverTimeByObligation(): Promise<ComplianceOverTimeDto[]> {
        const url: string = `${this.controllerName}/ComplianceOverTimeByObligation`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    

    public async getHighLevelObligationComplianceRating(): Promise<ObligationComplianceRatingDto[]> {
        const url: string = `${this.controllerName}/HighLevelObligationComplianceRating`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getHighLevelObligationComplianceRatingSummary(): Promise<ObligationComplianceRatingSummaryDto[]> {
        const url: string = `${this.controllerName}/HighLevelObligationComplianceRatingSummary`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }
    
    public async getObligationComplianceRating(): Promise<ObligationComplianceRatingDto[]> {
        const url: string = `${this.controllerName}/ObligationComplianceRating`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getObligationComplianceRatingSummary(): Promise<ObligationComplianceRatingSummaryDto[]> {
        const url: string = `${this.controllerName}/ObligationComplianceRatingSummary`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }
    
    public async getObligationCompletionStatistics(): Promise<ObligationCompletion[]> {
        const url: string = `${this.controllerName}/Obligations`;
        
        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }
    
    public async getKpiMetrics(): Promise<KpiMetrics[]> {
        const url: string = `${this.controllerName}/KpiMetrics`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return kpiMetricsInitialState;
        })
    }

    public async getStatusOverview(): Promise<StatusDistribution[]> {
        const url: string = `${this.controllerName}/StatusOverview`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return kpiMetricsInitialState;
        })
    }

    public async getActivityComplianceRatingSummary(): Promise<ActivityComplianceRating[]> {
        const url: string = `${this.controllerName}/ActivityComplianceRatingSummary`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return kpiMetricsInitialState;
        })
    }

    public async getQuestionsReviewList(): Promise<QuestionReviewDto[]> {
        const url: string = `${this.controllerName}/QuestionsReviewList`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getTrackingYourComplianceScore(): Promise<TrackingYourComplianceScoreDto[]> {
        const url: string = `${this.controllerName}/TrackingYourComplianceScore`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getObligationStatusDistribution(): Promise<ObligationStatusDto> {
        const url: string = `${this.controllerName}/ObligationStatusDistribution`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }

    public async getOverallStatusDistribution(): Promise<OverallStatusDistributionDto[]> {
        const url: string = `${this.controllerName}/OverallStatusDistribution`;

        return await AxiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            return [];
        })
    }



}

const reportingService = new ReportingService();
export { reportingService }