import React from 'react';
import { Card, Grid } from "@mui/material";
import { CollapsibleSection } from "common/components/sections/CollapsibleSection/CollapsibleSection";
import { ContextManagement } from "./components/ContextManagement/ContextManagement";
import { BillingTierManagement } from "./components/BillingTierManagement/BillingTierManagement";

import "./styles.scss";
import { selectedCompanySelector, selectedFunctionSelector } from "user/store/selectors";
import { useSelector } from "react-redux";
import { CompanyDetailsEditing } from "settings/companies/components/CompanyDetailsEditing/CompanyDetailsEditing";
import { RiskLabelEditing } from "settings/companies/components/RiskLabelEditing/RiskLabelEditing";
import { TermsSettings } from "../../wizards/company/components/Terms/TermsSettings";

const CompaniesSettings: React.FC = () => {

    let selectedCompany = useSelector(selectedCompanySelector);
    let selectedFunction = useSelector(selectedFunctionSelector);

    return (
        <Grid className={"topBottomGutters"} justifyContent={"center"} alignContent={"flex-start"} container>
            <Grid item container xs={12} sm={10} md={8} spacing={2}>
                <Grid item xs={12}>
                    <Card variant={"outlined"}>
                        <CollapsibleSection
                            title={"Context Management"}
                            subtitle={"View/ Edit the default and currently selected context."}
                        >
                            <ContextManagement/>
                        </CollapsibleSection>
                    </Card>
                </Grid>
                {selectedCompany && selectedFunction &&
                <>
                    <Grid item xs={12}>
                        <Card variant={"outlined"}>
                            <CollapsibleSection
                                title={"Company Details"}
                                subtitle={`View/ Edit information relating to the ${selectedCompany?.name}.`}
                            >
                                <CompanyDetailsEditing/>
                            </CollapsibleSection>
                        </Card>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <Card variant={"outlined"}>*/}
                    {/*        <CollapsibleSection*/}
                    {/*            title={"Company Officers"}*/}
                    {/*            subtitle={`View/ Edit information relating to the ${selectedCompany?.name}.`}*/}
                    {/*        >*/}
                    {/*            <OfficerEditing/>*/}
                    {/*        </CollapsibleSection>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <Card variant={"outlined"}>
                            <CollapsibleSection
                                title={"Billing Tier"}
                                subtitle={`View and/or upgrade your company to a different Billing Tier.`}
                            >
                                <BillingTierManagement/>
                            </CollapsibleSection>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant={"outlined"}>
                            <CollapsibleSection
                                title={"Risk Labels"}
                                subtitle={`View/ Edit your company's risk labels and monetary risk values.`}
                            >
                                <RiskLabelEditing
                                    company={selectedCompany}
                                />
                            </CollapsibleSection>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant={"outlined"}>
                            <CollapsibleSection
                                title={"Terms and conditions of use – ASIRTA™ Portal"}
                                subtitle={`Version 1.0 – dated 25th May 2020`}
                            >
                                <TermsSettings/>
                            </CollapsibleSection>
                        </Card>
                    </Grid>
                </>
                }
            </Grid>
        </Grid>
    );

}
export { CompaniesSettings }