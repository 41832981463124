import React from 'react';
import { Grid, Typography } from "@mui/material";

import WelcomeImage from 'assets/illustrations/celebrate.svg';

const WelcomeMessage: React.FC = () => {
    return (
        <Grid container item justifyContent={"center"} alignContent={"center"}>
            <Grid item>
                <img src={WelcomeImage} width={400} alt={"Welcome"}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'h4'} align={"center"} fontWeight={800} color={"textPrimary"}>
                    Getting started with 
                </Typography>
                <Typography variant={'h2'} align={"center"} color={"primary"} fontWeight={800}>
                    ASIRTA
                </Typography>
            </Grid>
        </Grid>
    )
}
export { WelcomeMessage }