import React, { useCallback, useEffect, useState } from 'react';

import { Button, Card, Container, Grid, Typography } from "@mui/material";

import { obligationsService } from "common/services/ObligationsService/obligationsService";
import {
    ObligationRequest,
    obligationCategoryAdditionalDetailsInitialState,
    obligationCategoryDetailsInitialState,
    ObligationCategoryDto
} from "common/dtos/Obligations/models";
import { BillingTier, Permission } from "auth/store/Model";
import { ValidateCompanyBillingTier } from "auth/components/ValidateCompanyBillingTier";
import { ObligationsTable } from "obligations/components/ObligationsTable/ObligationsTable";
import { ObligationModal } from "obligations/components/ObligationModal/ObligationModal";
import { CompanySettingsDto, companySettingsInitialState } from "common/dtos/Companies/models";
import { companyService } from "common/services/CompanyService/companyService";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";
import { useSelector } from "react-redux";
import { selectedFunctionSelector } from "../user/store/selectors";

const Obligations: React.FC = () => {
    const functionDetails = useSelector(selectedFunctionSelector);
    const [obligationCategories, setObligationCategories] = useState<ObligationCategoryDto[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [createMode, setCreateMode] = useState<boolean>(false);
    const [companySettings, setCompanySettings] = useState<CompanySettingsDto>(companySettingsInitialState);

    useEffect(() => {
        setLoading(true)
        obligationsService.getObligationCategoriesWithAdditionalDetails().then(response => {
            setObligationCategories(response.obligationCategory)
            setLoading(false)
        })
    }, [functionDetails])

    useEffect(() => {
        companyService.getCompanySettings().then(response => {
            setCompanySettings({
                ...response,
                riskSettings: response.riskSettings.sort((a, b) => {
                    return a.level - b.level
                })
            });
        })
    }, [functionDetails])

    const updateObligationCategory = useCallback((obligation: ObligationCategoryDto) => {

        setObligationCategories(prevState => {
            const index = prevState.findIndex(o => o.details.id === obligation.details.id);

            const obligations = [...prevState]
            obligations[index] = obligation;
            return obligations
        })
    }, []);

    const createObligationCategory = useCallback((request: ObligationRequest): Promise<void> => {
        return obligationsService.createObligation(request).then(response => {
            setObligationCategories(prevState => {
                let obligations = [...prevState, response];
                return obligations.sort((a, b) => a.details.name.localeCompare(b.details.name));
            });
        })
    }, []);
    
    const deleteObligationCategory = useCallback((obligationCategory: ObligationCategoryDto): Promise<void> => {
        return obligationsService.deleteObligation(obligationCategory.details.id).then(_ => {
            setObligationCategories(prevState => 
                prevState.filter(o => o.details.id !== obligationCategory.details.id)
            )
        })
    }, [])

    return (
        <ValidateCompanyBillingTier billingTiers={[BillingTier.Silver, BillingTier.Platinum]}>
            <Container className={"topBottomGutters"} maxWidth={'xl'}>
                <Card variant={"outlined"}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            <Typography variant={"h6"}><b>Obligations</b></Typography>
                        </Grid>
                        <ValidateUserPermission permissions={[Permission.ObligationsCreate]}>
                            <Grid item>
                                <Button
                                    variant={"outlined"}
                                    className={"success-item-outlined"}
                                    onClick={() => setCreateMode(true)}
                                >Create Obligation</Button>
                            </Grid>
                        </ValidateUserPermission>
                        <Grid item xs={12}>
                            <ObligationsTable
                                obligationCategories={obligationCategories}
                                updateObligationCategory={updateObligationCategory}
                                deleteObligationCategory={deleteObligationCategory}
                                riskSettings={companySettings.riskSettings}
                                loading={loading}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Container>
            <ValidateUserPermission permissions={[Permission.ObligationsCreate]}>
                <ObligationModal
                    title={"Create New Obligation"}
                    open={createMode}
                    setOpen={setCreateMode}
                    obligationCategory={{
                        details: obligationCategoryDetailsInitialState,
                        additionalDetails: obligationCategoryAdditionalDetailsInitialState
                    }}
                    riskSettings={companySettings.riskSettings}
                    onSubmit={createObligationCategory}
                />
            </ValidateUserPermission>
        </ValidateCompanyBillingTier>
    );
}
export { Obligations }