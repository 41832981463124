import React from 'react';

import { Container } from "@mui/material";

import { ReportingHeader } from "./components/reportingHeader/ReportingHeader";
import { CompanyComplianceOverTimeByObligation } from "./components/complianceOverTime/CompanyComplianceOverTimeByObligation";

const ObligationComplianceOverTime: React.FC = () => {

    return (
        <Container className={"topBottomGutters"} maxWidth={"xl"}>
            <ReportingHeader name={'Obligation compliance over time'} pdfReportTitle={'Obligation compliance'} pdfLandscape={true} exportable={false}/>
            <CompanyComplianceOverTimeByObligation/>
        </Container>
    )
}
export { ObligationComplianceOverTime }