import React, { ReactElement } from 'react';

import "./styles.scss";

export interface  AvatarChipProps {
    label: string;
    avatars: ReactElement[]
}

const AvatarChip: React.FC<AvatarChipProps> = ({label, avatars}) => {
    
    return (
        <div className={"avatarChip"}>
            {avatars.map((a, i) => {
                return a;
            })}
        </div>
    )
}
export { AvatarChip }