import { AuthenticationParameters, Configuration } from "msal";

import {
  MsalAuthProvider,
  LoginType,
  IMsalAuthProviderConfig,
} from "react-aad-msal";

import { AuthConfig } from "../AuthConfig"

// Msal Configurations
export const config: Configuration = {
  auth: {
    authority: AuthConfig.authority,
    clientId: AuthConfig.clientId,
    redirectUri: window.origin,
    validateAuthority: false
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

// Authentication Parameters
const authenticationParameters: AuthenticationParameters = {
  scopes: AuthConfig.scopes,
};

// Options
const options: IMsalAuthProviderConfig = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html' 
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);