import React from 'react';
import { Card, Grid, IconButton, Typography } from "@mui/material";
import { Check, EditOutlined } from "@mui/icons-material";
import { selectedCompanySelector } from "user/store/selectors";
import { useSelector } from "react-redux";

import "../../styles.scss";
import { Link } from "react-router-dom";

const CreateCompanyMessage: React.FC = () => {

    const company = useSelector(selectedCompanySelector);
    
    const isComplete = company !== undefined;

    return (
        <Card>
            <Link to={"/wizards/company"}>
                <Grid container item alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item>
                        <Grid item xs={12}>
                            <Typography variant={'h6'}>
                                Create your Company
                            </Typography>     
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'body1'} color={"textSecondary"}>
                                In order to properly get started using ASIRTA, you must create a Company on our platform.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton
                            className={"statusIconButton"} 
                            size={"large"} 
                            color={isComplete ? "success" : "warning"}>
                            {isComplete ? <Check/> : <EditOutlined/>}
                        </IconButton>
                    </Grid>
                </Grid>
            </Link>
        </Card>
    )
}
export { CreateCompanyMessage }