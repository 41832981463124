import React, { useRef } from 'react';

import { Grid, IconButton, Paper, Select, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import { renderEnumAsMenuItem } from "common/helpers/Object/object";
import { DelegationType } from "common/dtos/Common/delegation";
import { DelegationAssignee } from "questions/Question/components/QuestionSidepanel/components/QuestionDelegation/models";

import "./styles.scss";
import { useValidPermission } from "auth/components/ValidateUserPermission";
import { Permission } from "auth/store/Model";

export interface UserRoleSelectorProps {
    name?: string;
    assignee: DelegationAssignee;
    onRoleChanged: (e: SelectChangeEvent<any>) => void;
    onDeleteClicked: () => void;
}

const UserRoleSelector: React.FC<UserRoleSelectorProps> = ({name, assignee, onRoleChanged, onDeleteClicked}) => {
    
    const selectorRef = useRef(null);
    
    const userCanDelegate = !useValidPermission(Permission.QuestionsDelegate);
    
    return (
        <Grid container item>
            <Paper className={"assigneeItem"} variant={"outlined"}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item xs={10} container alignItems={"center"}>
                        <Tooltip title={assignee.user.email} arrow>
                            <Typography noWrap>{assignee.user.email}</Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={onDeleteClicked} size={"small"} className={"error-filled-item"}><CloseRounded/></IconButton>
                    </Grid>
                    <Grid item xs={10}>
                        <Select
                            name={name}
                            readOnly={userCanDelegate}
                            ref={selectorRef}
                            onChange={onRoleChanged}
                            fullWidth
                            value={assignee.delegationType}
                        >
                            {renderEnumAsMenuItem(DelegationType)}
                        </Select>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
export { UserRoleSelector }