import React, { SyntheticEvent, } from "react";
import { Autocomplete, FormControl, InputLabel, TextField } from "@mui/material";
import { formProps } from "common/theming/models";
import { useDispatch, useSelector } from "react-redux";
import { companyFunctionsSelector } from "../store/selectors";
import { selectedFunctionSelector } from "../../user/store/selectors";
import { selectFunction } from "../../user/store/actions";

import "./styles.scss";
import { FunctionDetailsDto, functionDetailsInitialState } from "common/dtos/Businesses/FunctionDetailsDto";
import { nicifyString } from "common/helpers/Regex/regex";

const FunctionSelection: React.FC = () => {
    
    const companyFunctions = useSelector(companyFunctionsSelector);
    const selectedFunction = useSelector(selectedFunctionSelector);
    
    const dispatch = useDispatch();
    
    const functionsExist: boolean = (companyFunctions.length !== 0);
    
    const onChange = (e: SyntheticEvent<any>, value: FunctionDetailsDto | null) => {
        
        if(value === null)
            return;
        
        dispatch(selectFunction(value));
    }
    
    const label: string | undefined = functionsExist ? undefined : "";
    
    return (
        <FormControl {...formProps}>
            <InputLabel>{label}</InputLabel>
            <Autocomplete
                className={"functionDropdown"}
                options={companyFunctions?.sort((a, b) => a.functionTypeId < b.functionTypeId ? -1 : 1)}
                groupBy={(opt: FunctionDetailsDto) => nicifyString(opt.functionType.value)}
                getOptionLabel={(opt: FunctionDetailsDto) => opt.name}
                fullWidth
                title={label}
                disabled={!functionsExist}
                onChange={onChange}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                renderInput={(params) => 
                    <TextField {...params} fullWidth/>
                }
                value={selectedFunction ?? functionDetailsInitialState}
            />
        </FormControl>
        
    )
}
export { FunctionSelection }