import * as React from 'react';

import { NavigationPanel } from "./components/NavigationPanel/NavigationPanel";
import { ContextBar } from "./components/ContextBar/ContextBar";

import { LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { Toolbar } from "@mui/material";

import "styles/layout.scss";

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {
        return (

            <React.Fragment>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <NavigationPanel/>
                    <div className={'main'}>
                        <ContextBar/>
                        <Toolbar/>
                        <div className={"content"}>
                            {this.props.children}
                        </div>
                    </div>
                </LocalizationProvider>
            </React.Fragment>
        );
    }
}