import React from 'react';

import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import { nameof } from "ts-simple-nameof";

import {
    Button, Card,
    CircularProgress,
    Fade,
    Grid,
    Modal,
    Typography
} from "@mui/material";

import {
    QuestionAnswerForm,
    SubquestionAnswersDto
} from "common/dtos/Questions/QuestionAnswers/models";
import { QuestionDetailsDto } from "common/dtos/Questions/QuestionDetails/models";

import { SubquestionJustification } from "questions/Question/components/SubquestionJustification/SubquestionJustification";

import "./styles.scss";

export interface QuestionSaveChangesModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    saving?: boolean;
    question: QuestionDetailsDto;
    handleSubmit: () => void;
    handleChange: (e: any) => void;
    trackedAnswers: number[];
    initialAnswers: SubquestionAnswersDto[];
    answers: SubquestionAnswersDto[];
    errors?: FormikErrors<QuestionAnswerForm>;
    touched?: FormikTouched<QuestionAnswerForm>;
}

const QuestionSaveChangesModal: React.FC<QuestionSaveChangesModalProps> = ({
                                                                               open,
                                                                               setOpen,
                                                                               saving,
                                                                               question,
                                                                               handleSubmit,
                                                                               handleChange,
                                                                               trackedAnswers,
                                                                               initialAnswers,
                                                                               answers,
                                                                               errors,
                                                                               touched
                                                                           }) => {

    return (
        <Modal className={"document-modal"} open={open}>
            <Fade in={open}>
                <Grid container item xs={11} sm={10} md={9} lg={6} justifyContent={"center"} alignItems={"center"}>
                    <Card>
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"}>Save Changes</Typography>
                            </Grid>
                            <Grid container item spacing={5}>
                                <Grid container item spacing={2}>
                                    <FieldArray
                                        name={nameof<QuestionAnswerForm>(f => f.answers)}
                                        render={({name}) => (
                                            trackedAnswers.map((answerIndex, index) =>
                                                <SubquestionJustification
                                                    key={index}
                                                    name={name}
                                                    question={question}
                                                    answers={answers}
                                                    initialAnswers={initialAnswers}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    answerIndex={answerIndex}
                                                />
                                            )
                                        )}
                                    />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <Grid item container justifyContent={"flex-end"} alignItems={"center"} spacing={2}>
                                        <Grid item>
                                            <Typography color={"secondary"} variant={"caption"}>Required fields
                                                marked
                                                with *</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={"error-item-outlined"}
                                                variant={"outlined"}
                                                onClick={() => setOpen(false)}
                                            >Cancel</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={"success-item-outlined"}
                                                variant={"outlined"}
                                                onClick={handleSubmit}
                                                endIcon={saving &&
                                                <CircularProgress className={"success-item-outlined"} size={20}/>}
                                            >Save Changes</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Fade>
        </Modal>
    );
}
export
{
    QuestionSaveChangesModal
}