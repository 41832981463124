import { CompanyStatus, Jurisdiction, OfficerDto } from "../../../wizards/company/components/CreationForm/models";
import { AddressDto } from "../../components/input/forms/AddressForm/models";

export interface CompanySearchResponseDto {
    title: string;
    companiesHouseId: string;
    address: AddressDto;
    creationDate: Date;
}

export interface CompanyProfileResponseDto {
    companyName: string;
    companiesHouseId: string;
    companyStatus: CompanyStatus;
    creationDate?: Date;
    eTag: string;
    isLiquidated: boolean;
    jurisdiction: Jurisdiction;
    registeredOfficeAddress: AddressDto;
    sicCodes: string[];
    companyType: string[];
    officers: OfficerDto[];
}

const companyProfileResponseInitialState: CompanyProfileResponseDto = {
    companyName: "",
    companiesHouseId: "",
    companyStatus: CompanyStatus.None,
    creationDate: new Date(),
    eTag: "string",
    isLiquidated: false,
    jurisdiction: Jurisdiction.None,
    registeredOfficeAddress: {
        addressLine1: "",
        addressLine2: "",
        locality: "",
        country: "",
        postcode: "",
    },
    sicCodes: [],
    companyType: [],
    officers: []
}
export { companyProfileResponseInitialState };