import React, { ReactElement, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Button, FormControl,  Grid, InputLabel, MenuItem, Select } from "@mui/material";

import { nameof } from "ts-simple-nameof";

import { CompanyDetailsDto } from "common/dtos/Companies/models";
import { companyService } from "common/services/CompanyService/companyService";
import { formProps } from "common/theming/models";
import { userService } from "common/services/UserService/userService";

import { selectedCompanySelector, userSelector, userSettingsSelector } from "user/store/selectors";

import { selectCompany, selectDefaultCompany } from "user/store/actions";
import { getCompanyFunctions } from "businessFunctions/store/actions";
import { SetUserPermissionsAction } from "auth/store/actions";

import { Formik,  FormikProps} from 'formik';
import { ContextSelection } from "../models";

const ContextManagement: React.FC = () => {

    const user = useSelector(userSelector);
    const userSettings = useSelector(userSettingsSelector);
    const userSelectedCompany = useSelector(selectedCompanySelector);

    const [companies, setCompanies] = useState<CompanyDetailsDto[]>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        companyService.getUserCompanies().then(response => {
            setCompanies(response);
        })
    }, [userSelectedCompany, setCompanies])

    const renderOptions = (): ReactElement[] => {
        return companies.map((c, i) => (<MenuItem key={i} value={c.id}>{c.name}</MenuItem>))
    }

    const onClick = async (values: ContextSelection) => {
        
        if (values.selectedCompanyId){
            await dispatch(selectDefaultCompany(values.selectedCompanyId));
        }
        
        if (values.selectedCompanyId && values.selectedCompanyId !== userSelectedCompany?.id){
            
            await dispatch(selectCompany(values.selectedCompanyId));
            await dispatch(getCompanyFunctions(true));
            
            await userService.getUserPermissions(user.id).then(async permissions => {
                await dispatch(SetUserPermissionsAction(permissions))
            })
        }
    }
    
    const isSelectionEmpty: boolean = companies.length === 0;
    
    return (
        <Formik
            initialValues={{
                selectedDefaultCompanyId: userSettings.defaultCompanyId,
                selectedCompanyId: userSelectedCompany?.id
            }}
            onSubmit={onClick}
            enableReinitialize
        >
            {(props: FormikProps<ContextSelection>) => {

                const { handleChange, handleSubmit, handleBlur, values, initialValues} = props;
                
                return (
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <FormControl {...formProps}>
                                <InputLabel>Current Company</InputLabel>
                                <Select
                                    fullWidth
                                    disabled={isSelectionEmpty}
                                    name={nameof<ContextSelection>(c => c.selectedCompanyId)}
                                    label={"Selected Company"}
                                    value={values.selectedCompanyId || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    {renderOptions()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container item justifyContent={"flex-end"}>
                            <Grid item>
                                <Button
                                    onClick={() => handleSubmit()}
                                    variant={"outlined"}
                                    color={"primary"}
                                    disabled={shallowEqual(values,initialValues)}
                                >Apply Changes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
}

export { ContextManagement }