import React from 'react';

import { Button, Container, Grid, Typography } from "@mui/material";

import "./styles.scss";

export interface AssessmentSplashProps {
    image: string;
    title: string;
    description: string;
    onButtonClicked?: () => void;
    buttonText?: string;
}

const AssessmentSplash: React.FC<AssessmentSplashProps> = ({image, title, description, onButtonClicked, buttonText}) => {
    return (
        <Container maxWidth={'lg'} disableGutters>
            <Grid container item spacing={2}>
                <Grid item xs={5}>
                    <img width={"100%"} src={image} alt={"Splash"}/>
                </Grid>
                <Grid item container alignContent={"center"} xs={7} spacing={2} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} align={"left"}><b>{title}</b></Typography>
                    </Grid>
                    <Grid item container justifyContent={"center"}>
                        <Typography variant={"inherit"} color={"secondary"} align={"left"} className={"splash-description"}>
                            { description }
                        </Typography>
                    </Grid>
                    { onButtonClicked &&
                    <Grid item>
                        <Button variant={"outlined"} color={"primary"} onClick={onButtonClicked}>{buttonText ?? "Click Here"}</Button>
                    </Grid>
                    }
                    
                </Grid>
            </Grid>
        </Container>
    );
}
export { AssessmentSplash }