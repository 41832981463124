import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { companyUserPermissions } from "../store/selectors"
import { UserCompanyPermission, Permission } from "../store/Model";

interface ValidateUserPermissionProps {
    permissions: Permission[]
}

const ValidateUserPermission: React.FC<ValidateUserPermissionProps> = ({permissions, children}) => {
    
    const [validated, setValidated] = React.useState<boolean>(false);
    
    const userPermissions: UserCompanyPermission[] = useSelector(companyUserPermissions)
    
    const requiredPermissionsExists = (userPermissions: UserCompanyPermission[], requiredPermission: Permission[]): boolean => {
        return userPermissions?.some(p => requiredPermission.includes(p.permissionId));
    }

    useEffect( () => {
        setValidated(requiredPermissionsExists(userPermissions, permissions));
    },[userPermissions, permissions])
    
    return (
        <>
            { validated && children }
        </>
    )
}

function useValidPermission(permission: Permission) {
    const [validPermission, setValidPermission] = useState<boolean>(false)

    const userPermissions: UserCompanyPermission[] = useSelector(companyUserPermissions)

    useEffect(() => {

        function requiredPermissionExists(userPermissions: UserCompanyPermission[], requiredPermission: Permission): boolean {
            return userPermissions.some(p => p.permissionId === permission);
        }

        setValidPermission(requiredPermissionExists(userPermissions, permission))

    },[userPermissions, permission]);

    return validPermission;
}

export { ValidateUserPermission, useValidPermission }