import React, { useCallback } from 'react';

import { Grid } from "@mui/material";

import { AnswerValue } from "common/dtos/Questions/models";
import { defaultAssessmentAnswerLookup, initialAssessmentAnswerLookup } from "common/dtos/Questions/Assessment/models";

import { AssessmentSubquestion } from "questions/Assessment/components/Questions/AssessmentSubquestion/AssessmentSubquestion";
import { AssessmentSubquestionAnswer, Question } from "questions/Assessment/models";

export interface AssessmentQuestionProps {
    question: Question;
    onUpdateQuestion: (question: Question) => void;
}

const AssessmentQuestion: React.FC<AssessmentQuestionProps> = ({question, onUpdateQuestion}) => {
    
    const onAnswer = useCallback((answer: AssessmentSubquestionAnswer, index: number) => {
        let updatedQuestion = {...question}
        updatedQuestion.subquestions[index] = answer;
        
        onUpdateQuestion(updatedQuestion);
    }, [question, onUpdateQuestion]);
    
    const applyDefaultAnswer = (subquestion: AssessmentSubquestionAnswer, index: number) => {
        onAnswer({
            details: subquestion.details,
            answer: defaultAssessmentAnswerLookup
        }, index);
    }
    
    return (
        <Grid container item justifyContent={"center"} xs={8} spacing={2}>
            { question.subquestions.map((subquestion, index) => {
                
                if(subquestion.answer === initialAssessmentAnswerLookup)
                    applyDefaultAnswer(subquestion, index)
                
                if(index === 0 || question.subquestions[index - 1].answer.value > AnswerValue.NotImplemented){
                    return (
                        <AssessmentSubquestion
                            key={index}
                            subquestion={subquestion}
                            onAnswer={(answer) => onAnswer(answer, index)}
                        />
                    )
                }
                
                return <></>
            })}
        </Grid>
    );
}
export { AssessmentQuestion }