import React from 'react';

import "./styles.scss";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

export interface AmountDisplayProps {
    amount: string;
    tooltip: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
    placement?:  "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top";
    color?: "info" | "success" | "error" | "warning" | string;
    typography?: "inherit" | "button" | "overline" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2";
    loading?: boolean;
}

const AmountDisplay: React.FC<AmountDisplayProps> = ({amount, tooltip, placement, color, typography, loading}) => {

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[0],
            border: '1px solid rgba(0,0,0,0.1)',
            fontSize: 11,
        },
        arrow: {
            "&::before": {
                backgroundColor: theme.palette.common.white,
                boxShadow: theme.shadows[0],
                border: '1px solid rgba(0,0,0,0.1)',
            }
        }
    }))(Tooltip);

    const colorClass = `${color}-item-faded`;
    
    return (
        <LightTooltip arrow title={tooltip} disableHoverListener={!tooltip} placement={placement}>
            <Box className={`amountDisplay ${colorClass}`} pl={"5px"} pr={"5px"} pt={loading ? "3px" : undefined} pb={loading ? "3px" : undefined} display={"flex"} alignItems={"center"}>
                {loading ? <CircularProgress size={15}/> : <Typography variant={typography}>{amount}</Typography>}
            </Box>
        </LightTooltip>
    )
}
export { AmountDisplay }