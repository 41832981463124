import { LookupDto } from "common/services/LookupService/models";
import { FunctionDetailsDto, functionDetailsInitialState } from "common/dtos/Businesses/FunctionDetailsDto";

export interface FunctionCreation {
    functionDetails: FunctionDetailsDto;
    types: LookupDto<number>[];
    sectors: LookupDto<number>[];
    startAssessment:boolean;
}

const functionCreationInitialState: FunctionCreation = {
    functionDetails: functionDetailsInitialState,
    types: [],
    sectors: [],
    startAssessment: false
}
export { functionCreationInitialState }