import React, { useState } from 'react';

import { Button, Grid } from "@mui/material";

import { SectorSelector } from "common/components/input/selectors/SectorSelector/SectorSelector";
import { TypeSelector } from "common/components/input/selectors/TypeSelector/TypeSelector";
import { nicifyString } from "common/helpers/Regex/regex";
import { FunctionType } from "common/components/input/forms/BusinessFunctionCreationForm/models";
import { WizardStepProps } from "wizards/models";

const AssignSectors: React.FC<WizardStepProps> = ({onBackClicked, onContinueClicked, object}) => {

    const [selectedTypes, setSelectedTypes] = useState<any[]>(object.types);
    const [selectedSectors, setSelectedSectors] = useState<any[]>(object.sectors);
    const [selectedErrorText, setSelectedErrorText] = useState<string>("");
    
    const onClick = () => {        
        setSelectedErrorText("");
        
        onContinueClicked({
            ...object,
            types: selectedTypes,
            sectors: selectedSectors
        })
    }
    
    const nicifiedFunctionType = nicifyString(FunctionType[object.functionDetails.functionTypeId]);    
    const title: string = `Please the select the sectors that relate to your ${nicifiedFunctionType}`
    const subtitle: string = `The sectors will determine the questions that are presented for assessment, any sectors that relate to the organisation will be automatically assigned, with the organisational answer presented in the first instance.`
    
        
    return (
        <Grid container item xs={10} spacing={2}>
            <Grid item xs={12}>
                <h1>{title}</h1>
                <h3>{subtitle}</h3>
            </Grid>
            
            <Grid item xs={12}>
                <TypeSelector
                    label={`Which of the following types describes your ${nicifiedFunctionType} in general?`}
                    selectedTypes={selectedTypes}
                    onChange={setSelectedTypes}
                    errorText={selectedErrorText}
                />
            </Grid>
            
            <Grid item xs={12}>
                <SectorSelector
                    label={`What sectors does your ${nicifiedFunctionType} operate under?`}
                    selectedSectors={selectedSectors}
                    onChange={setSelectedSectors}
                />
            </Grid>
            
            <Grid container item xs={12}>
                <Grid container item xs={2} justifyContent={"flex-start"}>
                    <Grid item>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            onClick={onBackClicked}
                        >Previous</Button>
                    </Grid>
                </Grid>
                <Grid container xs={10} item justifyContent={"flex-end"}>
                    <Grid item>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            onClick={onClick}
                        >Continue</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export { AssignSectors }