import React from 'react';
import { BarDatum, ResponsiveBar } from '@nivo/bar'

import "./styles.scss"

export interface BarChartProps {
    dataSet: BarDatum[]
}
export interface ToolTipProps {
    status: string | number, 
    percentage: number ,
    obligation: string | number,
    dataSet: any[]
}

const ToolTipDiv: React.FC<ToolTipProps>  = ({status, percentage , obligation, dataSet}) => {
    const obligationFullname = dataSet.find(a => a.obligationShort === obligation) 
    
    return(
        <div className={'barChartToolTip'}>  
            <strong className={'barChartToolTipHeading'}>{obligationFullname.obligation}</strong>
            <br/>
            <span>
                {status}: {percentage}%
            </span>
        </div>
    )
}

const BarChart: React.FC<BarChartProps> = ({dataSet}) => {

    const commonProps = {
        minValue:0,
        maxValue:100
    }
    
    return (
        <div className={'ResponsiveBarChart'}>
            <ResponsiveBar
                {...commonProps}
                data={dataSet}
                keys={['Outstanding','Compliant', 'Non-compliant']}
                indexBy= {'obligationShort'}
                margin={{top: 20, left: 0, right: 150}}
                padding={0.5}
                axisTop={null}
                axisLeft={null}
                axisRight={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: 0 }}
                axisBottom={null}
                enableGridY={true}
                labelSkipWidth={12}
                labelSkipHeight={12}
                layout={"horizontal"}
                valueScale={{type: 'linear'}}
                animate={true}
                indexScale={{ type: 'band', round: true }}
                colors={['#97999B','#97c45b','#dc1839']}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                label={d => `${d.value}%`}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-left',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -20,
                        itemsSpacing: 25,
                        itemWidth: 100,
                        itemHeight: 15,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20
                    }
                ]}
                tooltip={({ id, value, indexValue }) => (
                    <ToolTipDiv 
                    obligation={indexValue}
                    percentage={value}
                    status={id}
                    dataSet={dataSet}
                    />
                )}
            />
        </div>
    )
}

export { BarChart }