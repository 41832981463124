import React, { useEffect, useState } from 'react';

import { getQuestionsReviewList } from "../../store/actions";
import { QuestionReviewDto } from "common/services/ReportingService/models";

import moment from "moment"; 

import { Grid } from "@mui/material";
import { GridColumn } from "@progress/kendo-react-grid";
import { nameof } from "ts-simple-nameof";
import { Grid as GridTable } from "@progress/kendo-react-grid/dist/npm/Grid"

import "./styles.scss"; 

const QuestionReviewTableExport: React.FC = () => {

    const [questionsReviewList, setQuestionsReviewList] = useState<QuestionReviewDto[]>([]); 
 
    useEffect(() => {
        getQuestionsReviewList().then((response) => {
            setQuestionsReviewList(response);
        })
    }, [setQuestionsReviewList])

    return (
        <Grid container spacing={2} className={'riskTableContainer'}>
            <Grid item xs={12}>
                        <GridTable
                            data={questionsReviewList}
                            className={'pdfGridTable'}
                            filterable={false}
                        >
                            <GridColumn width={200} headerClassName={'pdfGridColumnHeader'}  className={'pdfGridColumn'} field={nameof<QuestionReviewDto>(a => a.question)} title={"Question"}/>
                            
                            <GridColumn width={65} headerClassName={'pdfGridColumnHeader'}  className={'pdfGridColumn'} field={nameof<QuestionReviewDto>(a => a.answerStatus)} title={"Status"}/>
                            
                            <GridColumn  width={65}  headerClassName={'pdfGridColumnHeader'}  className={'pdfGridColumn'} field={nameof<QuestionReviewDto>(a => a.lastUpdate)} title={"Last Update"}  
                            cell={props => <td className={'pdfGridColumnMaxWidth'} >{moment.utc(props.dataItem.lastUpdate).format('DD-MM-YYYY')}</td>}/>

                            <GridColumn  width={90} headerClassName={'pdfGridColumnHeader'}  className={'pdfGridColumn'} field={nameof<QuestionReviewDto>(a => a.activityCategory)} title={"Activity Category"}/>

                            <GridColumn  width={75} headerClassName={'pdfGridColumnHeader'}  className={'pdfGridColumn'} field={nameof<QuestionReviewDto>(a => a.responsible)} title={"Responsible"}/>

                            <GridColumn  width={75} headerClassName={'pdfGridColumnHeader'}  className={'pdfGridColumn'} field={nameof<QuestionReviewDto>(a => a.accountable)} title={"Accountable"}/>

                            <GridColumn  width={75} headerClassName={'pdfGridColumnHeader'}  className={'pdfGridColumn'} field={nameof<QuestionReviewDto>(a => a.consulted)} title={"Consulted"}/>

                            <GridColumn  width={75} headerClassName={'pdfGridColumnHeader'}  className={'pdfGridColumn'} field={nameof<QuestionReviewDto>(a => a.informed)} title={"Informed"}/>

                            <GridColumn  width={65}  headerClassName={'pdfGridColumnHeader'}  className={'pdfGridColumn'} field={nameof<QuestionReviewDto>(a => a.nextReview)} title={"Next Review"} 
                                        cell={props => <td  className={'pdfGridColumnMaxWidth'} >{props.dataItem.nextReview ? moment.utc(props.dataItem.nextReview).calendar() : '-'}</td>}/>

                        </GridTable>
                        
            </Grid>
        </Grid>
    );
}

export { QuestionReviewTableExport }