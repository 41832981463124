import React from 'react';

import { Container } from "@mui/material";

import { ReportingHeader } from "./components/reportingHeader/ReportingHeader";
import { CompanyComplianceOverTime } from "./components/complianceOverTime/CompanyComplianceOverTime";

const ComplianceOverTime: React.FC = () => {

    return (
        <Container className={"topBottomGutters"} maxWidth={"xl"}>
            <ReportingHeader name={'Company compliance over time'} pdfReportTitle={'Company compliance'} pdfLandscape={true} exportable={false}/>
            <CompanyComplianceOverTime/>
        </Container>
    )
}
export { ComplianceOverTime }