import {
    ActivityCategoryDetailsDto, activityCategoryInitialState,
    ObligationGroupDto
} from "common/dtos/Questions/QuestionDetails/models";
import { UserDetailsDto, userDetailsInitialState } from "common/dtos/Users/models";
import { LookupDto, lookupDtoInitialState } from "common/services/LookupService/models";
import { LinkedObligationsDto } from "common/dtos/Assets/LinkedObligationsDto";
import { FunctionDetailsDto, functionDetailsInitialState } from "common/dtos/Businesses/FunctionDetailsDto";

export interface ObligationCategoryResponse {
    obligationCategory: ObligationCategoryDto[];
}

const obligationCategoryResponseInitialState: ObligationCategoryResponse = {
    obligationCategory: []
}
export { obligationCategoryResponseInitialState }

export interface ObligationCategoryDto {
    details: ObligationCategoryDetailsDto;
    additionalDetails: ObligationCategoryAdditionalDetailsDto;
}

export interface ObligationCategoryDetailsDto {
    id: number;
    name: string;
    extendedName: string;
    companyId?: number;
    obligationType: LookupDto<string>;
    activityCategory: ActivityCategoryDetailsDto;
    obligationGroups: ObligationGroupDto[];
    linkedObligations: LinkedObligationsDto[];
    questionTypeId: number;
}

export interface ObligationCategoryAdditionalDetailsDto {
    id: number;
    obligationCategoryDetailsId: number;
    obligationOwnerId: number;
    obligationOwner: UserDetailsDto;
    functionDetailsId: number;
    description: string;
    obligationLink: string;
    obligationReference: string;
    confidentialityImpactId: number;
    confidentialityJusitification: string;
    integrityImpactId: number;
    integrityJustification: string;
    availabilityImpactId: number;
    availabilityJustification: string;
    autoApplied: boolean;
}

export interface ObligationRequest {
    id: number;
    name: string;
    extendedName: string;
    obligationValue: string;
    obligationTypeId: number;
    obligationAdditionalDetailsId: number;
    linkedSectors: number[];
    ownerId: number;
    obligationLink: string;
    obligationReference: string;
    confidentialityImpactId: number;
    confidentialityJustification: string;
    integrityImpactId: number;
    integrityJustification: string;
    availabilityImpactId: number;
    availabilityJustification: string;
    description: string;
    autoApplied: boolean;
}

export interface TrackedObligationDto {
    id: number;
    functionDetailsId: number;
    functionDetails: FunctionDetailsDto;
    obligationCategoryDetailsId: number;
    obligationCategoryDetails: ObligationCategoryDetailsDto;
    trackTypeId: number;
    trackType: LookupDto<string>;
}

const obligationCategoryDetailsInitialState: ObligationCategoryDetailsDto = {
    id: 0,
    name: "",
    obligationType: lookupDtoInitialState,
    activityCategory: activityCategoryInitialState,
    obligationGroups: [],
    linkedObligations: [],
    extendedName: "",
    questionTypeId: 0
}
export { obligationCategoryDetailsInitialState }

const obligationCategoryAdditionalDetailsInitialState: ObligationCategoryAdditionalDetailsDto = {
    id: 0,
    availabilityImpactId: 0,
    availabilityJustification: "",
    confidentialityImpactId: 0,
    confidentialityJusitification: "",
    obligationLink: "",
    obligationOwnerId: 0,
    obligationOwner: userDetailsInitialState,
    description: "",
    functionDetailsId: 0,
    obligationCategoryDetailsId: 0,
    integrityImpactId: 0,
    obligationReference: "",
    integrityJustification: "",
    autoApplied: false
}
export { obligationCategoryAdditionalDetailsInitialState }

const obligationCategoryInitialState: ObligationCategoryDto = {
    details: obligationCategoryDetailsInitialState,
    additionalDetails: obligationCategoryAdditionalDetailsInitialState,
}
export { obligationCategoryInitialState }

export const obligationAdditionalDetailsRequestInitialState: ObligationRequest = {
    id: 0,
    ownerId: 0,
    description: "",
    obligationLink: "",
    obligationReference: "",
    obligationAdditionalDetailsId: 0,
    confidentialityImpactId: 0,
    confidentialityJustification: "",
    integrityImpactId: 0,
    integrityJustification: "",
    availabilityImpactId: 0,
    availabilityJustification: "",
    autoApplied: false,
    obligationTypeId: 0,
    obligationValue: "",
    linkedSectors: [],
    extendedName: "",
    name: ""
}

export const obligationRequestInitialState: ObligationRequest = {
    id: 0,
    name: "",
    ownerId: 0,
    obligationValue: "",
    obligationTypeId: 0,
    extendedName: "",
    linkedSectors: [],
    integrityImpactId: 0,
    confidentialityImpactId: 0,
    availabilityImpactId: 0,
    autoApplied: false,
    availabilityJustification: "",
    obligationReference: "",
    obligationLink: "",
    description: "",
    obligationAdditionalDetailsId: 0,
    integrityJustification: "",
    confidentialityJustification: ""
}

export const trackedObligationInitialState: TrackedObligationDto = {
    id: 0,
    functionDetailsId: 0,
    functionDetails: functionDetailsInitialState,
    obligationCategoryDetailsId: 0,
    obligationCategoryDetails: obligationCategoryDetailsInitialState,
    trackTypeId: 0,
    trackType: lookupDtoInitialState,
}
