import { IAccountInfo } from "react-aad-msal";
import { UserCompanyPermission } from "./Model";

export const AAD_LOGIN_SUCCESS = "AAD_LOGIN_SUCCESS";
export const VALIDATE_USER = "VALIDATE_USER";
export const SET_COMPANY_USER_PERMISSIONS = "SET_COMPANY_USER_PERMISSIONS";

interface aadLoginAction {
    type: typeof AAD_LOGIN_SUCCESS
    payload: IAccountInfo
  }
  
interface validateUser {
    type: typeof VALIDATE_USER
    payload: boolean
}

interface setUserPermissions {
    type: typeof SET_COMPANY_USER_PERMISSIONS,
    payload: UserCompanyPermission[]
}

export type ActionTypes = aadLoginAction | validateUser | setUserPermissions;
