import React from 'react';
import ReactMarkdown from "react-markdown";

import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";

import moment from "moment";

import { getUserInitials } from "common/helpers/Regex/regex";
import { HistoryCommentDto } from "common/dtos/Questions/QuestionComments/models";
import { VerticalLine } from "common/components/misc/VerticalLine/VerticalLine";

import "./styles.scss";
import { getNameFromUser } from "common/helpers/Naming/naming";

export interface HistoryCommentProps {
    comment: HistoryCommentDto;
    onDeleteClicked: (commentId: number) => Promise<void>;
    lineAbove: boolean;
    lineBelow: boolean;
}

const HistoryComment: React.FC<HistoryCommentProps> = ({comment, onDeleteClicked, lineAbove, lineBelow}) => {
    
    return (
        <Grid container item>
            {lineAbove && <VerticalLine className={"lineOffset"}/>}
            <Paper variant={"outlined"} className={"historyComment paper"}>
                <Box display={"flex"} flexGrow={0} marginRight={1}>
                    <Avatar>{getUserInitials(comment.userDetails)}</Avatar>
                </Box>
                <Box display={"flex"} flexGrow={1}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid container item xs={11}>
                            <Grid container item spacing={1}>
                                <Grid item>
                                    <Typography color={"textPrimary"} variant={"body2"}><b>{getNameFromUser(comment.userDetails)}</b></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color={"textSecondary"} variant={"body2"}>{moment(comment.lastUpdatedDate).fromNow()}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactMarkdown className={"markdown"} source={comment.commentMarkdown}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            {lineBelow && <VerticalLine className={"lineOffset"}/>}
        </Grid>
    );
}
export { HistoryComment }