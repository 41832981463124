import React, {useEffect, useState} from 'react';

import {
    Divider,
    Drawer,
    Grid, IconButton,
} from "@mui/material";
import {ChevronLeftRounded, ChevronRightRounded} from "@mui/icons-material";

import {nameof} from "ts-simple-nameof";
import {Formik, FormikProps} from "formik";
import clsx from "clsx";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHandshake} from "@fortawesome/free-regular-svg-icons";
import {faHistory, faParachuteBox, faPoundSign, faTasks} from "@fortawesome/free-solid-svg-icons";

import {DelegatedQuestionDto, UserDetailsDto} from "common/dtos/Users/models";
import {HistoryItem} from "common/dtos/History/models";
import {LookupDto} from "common/services/LookupService/models";
import {renderLookupsAsMenuItem} from "common/helpers/Object/object";
import {nicifyString} from "common/helpers/Regex/regex";

import {QuestionReviewDatePicker} from "./components/QuestionReviewDatePicker/QuestionReviewDatePicker";
import {QuestionAssignees} from "./components/QuestionAssignees/QuestionAssignees";
import {SidepanelDropdown} from "./components/SidepanelDropdown/SidepanelDropdown";
import {SidepanelTextField} from "./components/SidepanelTextField/SidepanelTextField";
import {sidepanelStyles} from "./MuiStyles";

import {
    AnswerAdditionalDetailsDto,
    AnswerAdditionalDetailsRequest
} from "common/dtos/Questions/QuestionAnswers/models";
import {costOfComplianceValidationSchema} from "./models";

import {
    getActivityFrequency,
    getComplianceComplexity,
    getConductActivity,
    getImplementationEffectiveness,
    getThirdPartyManaged,
    undelegateUsersFromQuestion,
} from "../../actions";

import "./styles.scss";

export interface QuestionSidepanelProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    assigneeDropdownOpen: boolean;
    setAssigneeDropdownOpen: (open: boolean) => void;
    questionId: number;
    answerAdditionalDetails: AnswerAdditionalDetailsDto;
    onAdditionalDetailsUpdated: (value: any, field: string) => void;
    history: HistoryItem[];
    setHistory: (history: HistoryItem[]) => void;
    user: UserDetailsDto;
}

const QuestionSidepanel: React.FC<QuestionSidepanelProps> = ({
                                                                 open,
                                                                 setOpen,
                                                                 assigneeDropdownOpen,
                                                                 setAssigneeDropdownOpen,
                                                                 questionId,
                                                                 answerAdditionalDetails,
                                                                 onAdditionalDetailsUpdated,
                                                                 history,
                                                                 setHistory,
                                                                 user
                                                             }) => {
    const [delegatedUsers, setDelegatedUsers] = useState<DelegatedQuestionDto[]>([]);
    const [thirdPartyManaged, setThirdPartyManaged] = useState<LookupDto<string>[]>([]);
    const [conductActivity, setConductActivity] = useState<LookupDto<string>[]>([]);
    const [activityFrequency, setActivityFrequency] = useState<LookupDto<string>[]>([]);
    const [complianceComplexity, setComplianceComplexity] = useState<LookupDto<string>[]>([]);
    const [implementationEffectiveness, setImplementationEffectiveness] = useState<LookupDto<string>[]>([]);


    const classes = sidepanelStyles()

    useEffect(() => {
        getThirdPartyManaged().then(response => setThirdPartyManaged(response))
        getConductActivity().then(response => setConductActivity(response))
        getActivityFrequency().then(response => setActivityFrequency(response))
        getComplianceComplexity().then(response => setComplianceComplexity(response))
        getImplementationEffectiveness().then(response => setImplementationEffectiveness(response))
    }, []);

    const onUnassignClicked = async (user: DelegatedQuestionDto): Promise<void> => {
        await undelegateUsersFromQuestion(questionId, [user.userDetailsId]).then(_ => {
            setDelegatedUsers(delegatedUsers.filter(u => u.userDetailsId !== user.userDetailsId));
        })
    }

    return (
        <Drawer
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            PaperProps={{className: "questionSidepanel"}}
            elevation={0}
            anchor={"right"}
            variant={"permanent"}
            open={open}
        >
            <Grid container className={"sidepanelContent"} justifyContent={"center"}>
                <Grid container item justifyContent={open ? "flex-start" : "center"}>
                    <IconButton size={"small"} onClick={() => setOpen(!open)}>{open
                        ? <ChevronRightRounded/>
                        : <ChevronLeftRounded/>
                    }
                    </IconButton>
                </Grid>
                <Divider light={false}/>
                <Grid item xs={12}>
                    <QuestionAssignees
                        onUnassignClicked={onUnassignClicked}
                        sidebarOpen={open}
                        questionId={questionId}
                        setHistory={setHistory}
                        history={history}
                        assigneeDropdownOpen={assigneeDropdownOpen}
                        setAssigneeDropdownOpen={setAssigneeDropdownOpen}
                    />
                </Grid>
                <Divider light={false}/>
                <Grid item xs={12}>
                    <QuestionReviewDatePicker
                        answerAdditionalDetails={answerAdditionalDetails}
                        label={"Next Review Date"}
                        helperText={"The next available date for this activity to be reviewed"}
                        onChange={(d) => onAdditionalDetailsUpdated(d, nameof<AnswerAdditionalDetailsRequest>(a => a.nextReviewDate))}
                        sidebarOpen={open}
                    />
                </Grid>
                <Divider light={false}/>
                <Grid item xs={12}>
                    <SidepanelDropdown
                        label={"Activity Frequency"}
                        value={answerAdditionalDetails.activityFrequencyId}
                        options={renderLookupsAsMenuItem(activityFrequency)}
                        onChange={(e) => onAdditionalDetailsUpdated(e.target.value, nameof<AnswerAdditionalDetailsRequest>(a => a.activityFrequencyId))}
                        optionLabelSelect={(option: LookupDto<string>) => option.value}
                        optionValueSelect={(option: LookupDto<string>) => option.id}
                        icon={<FontAwesomeIcon icon={faHistory}/>}
                        helperText={"How often is this activity conducted"}
                        sidepanelOpen={open}
                        sidepanelText={activityFrequency[answerAdditionalDetails.activityFrequencyId - 1] &&
                            nicifyString(activityFrequency[answerAdditionalDetails.activityFrequencyId - 1].value)
                        }
                    />
                </Grid>
                <Divider light={false}/>
                <Grid item xs={12}>
                    <SidepanelDropdown
                        label={"Third-Party Managed"}
                        value={answerAdditionalDetails.thirdPartyManagedId}
                        options={renderLookupsAsMenuItem(thirdPartyManaged)}
                        onChange={(e) => onAdditionalDetailsUpdated(e.target.value, nameof<AnswerAdditionalDetailsRequest>(a => a.thirdPartyManagedId))}
                        optionLabelSelect={(option: LookupDto<string>) => option.value}
                        optionValueSelect={(option: LookupDto<string>) => option.id}
                        icon={<FontAwesomeIcon icon={faHandshake}/>}
                        helperText={"Is a third party involved in the management of this activity"}
                        sidepanelOpen={open}
                        sidepanelText={thirdPartyManaged[answerAdditionalDetails.thirdPartyManagedId - 1] &&
                            nicifyString(thirdPartyManaged[answerAdditionalDetails.thirdPartyManagedId - 1].value)
                        }
                    />
                </Grid>
                <Divider light={false}/>
                <Grid item xs={12}>
                    <SidepanelDropdown
                        label={"Conduct Activity"}
                        value={answerAdditionalDetails.conductActivityId}
                        options={renderLookupsAsMenuItem(conductActivity)}
                        onChange={(e) => onAdditionalDetailsUpdated(e.target.value, nameof<AnswerAdditionalDetailsRequest>(a => a.conductActivityId))}
                        optionLabelSelect={(option: LookupDto<string>) => option.value}
                        optionValueSelect={(option: LookupDto<string>) => option.id}
                        icon={<FontAwesomeIcon icon={faParachuteBox}/>}
                        helperText={"Who or what will conduct the activity"}
                        sidepanelOpen={open}
                        sidepanelText={conductActivity[answerAdditionalDetails.conductActivityId - 1] &&
                            nicifyString(conductActivity[answerAdditionalDetails.conductActivityId - 1].value)
                        }
                    />
                </Grid>
                <Divider light={false}/>
                <Grid item xs={12}>
                    <SidepanelDropdown
                        label={"Compliance Complexity"}
                        value={answerAdditionalDetails.complianceComplexityId}
                        options={renderLookupsAsMenuItem(complianceComplexity)}
                        onChange={(e) => onAdditionalDetailsUpdated(e.target.value, nameof<AnswerAdditionalDetailsRequest>(a => a.complianceComplexityId))}
                        optionLabelSelect={(option: LookupDto<string>) => option.value}
                        optionValueSelect={(option: LookupDto<string>) => option.id}
                        icon={<FontAwesomeIcon icon={faTasks}/>}
                        helperText={"The complexity of complying with this requirement, this could be a current complexity for implemented activities or projected for those that are not yet implemented"}
                        sidepanelOpen={open}
                        sidepanelText={complianceComplexity[answerAdditionalDetails.complianceComplexityId - 1] &&
                            nicifyString(complianceComplexity[answerAdditionalDetails.complianceComplexityId - 1].value)
                        }
                    />
                </Grid>
                <Divider light={false}/>
                <Grid item xs={12}>
                    <SidepanelDropdown
                        label={"Implementation Effectiveness"}
                        value={answerAdditionalDetails.implementationEffectivenessId}
                        options={renderLookupsAsMenuItem(implementationEffectiveness)}
                        onChange={(e) => onAdditionalDetailsUpdated(e.target.value, nameof<AnswerAdditionalDetailsRequest>(a => a.implementationEffectivenessId))}
                        optionLabelSelect={(option: LookupDto<string>) => option.value}
                        optionValueSelect={(option: LookupDto<string>) => option.id}
                        icon={<FontAwesomeIcon icon={faTasks}/>}
                        helperText={"Confidence in the effectiveness of the control"}
                        sidepanelOpen={open}
                        sidepanelText={implementationEffectiveness[answerAdditionalDetails.implementationEffectivenessId - 1] &&
                            nicifyString(implementationEffectiveness[answerAdditionalDetails.implementationEffectivenessId - 1].value)
                        }
                    />
                </Grid>
                <Divider light={false}/>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{costOfCompliance: answerAdditionalDetails.costOfCompliance}}
                        enableReinitialize
                        onSubmit={(r) => onAdditionalDetailsUpdated(r.costOfCompliance, nameof<AnswerAdditionalDetailsRequest>(a => a.costOfCompliance))}
                        validationSchema={costOfComplianceValidationSchema}
                    >
                        {(props: FormikProps<{ costOfCompliance: number }>) => {
                            const {errors, handleSubmit, handleChange, values} = props;

                            return (
                                <SidepanelTextField
                                    name={nameof<AnswerAdditionalDetailsDto>(a => a.costOfCompliance)}
                                    label={"Cost of Compliance"}
                                    value={values.costOfCompliance}
                                    onChange={handleChange}
                                    onDebouncedChange={handleSubmit}
                                    icon={<FontAwesomeIcon icon={faPoundSign}/>}
                                    helperText={"The cost of complying with this requirement per annum, this could be a current cost for implemented activities or projected for those that are not yet implemented"}
                                    sidepanelText={`£${answerAdditionalDetails.costOfCompliance}`}
                                    sidepanelOpen={open}
                                    errors={Boolean(errors.costOfCompliance)}
                                    errorText={errors.costOfCompliance}
                                />
                            )
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </Drawer>
    );
}
export {QuestionSidepanel}