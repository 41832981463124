import React, { useState } from 'react';

import { Button, Grid, Typography } from "@mui/material";
import { Formik, FormikProps } from 'formik';
import { AdditionalInfoForm } from "common/components/input/forms/AdditionalInfoForm/AdditionalInfoForm";

import { creationFormValidationSchema } from "./models";
import { WizardStepProps } from "wizards/models";
import { CompanyDetailsDto } from "common/dtos/Companies/models";

const AdditionalInfo: React.FC<WizardStepProps> = ({onBackClicked, onContinueClicked, object}) => {

    const [registered, isRegistered] = useState<boolean>(true);

    const onClick = (formState: CompanyDetailsDto) => {
        onContinueClicked({
            ...object,
            company: formState
        })
    }
    
    return (
        <Formik
            initialValues={{
                ...object.company,
                additionalDetails: {
                    ...object.company.additionalDetails,
                    isRegistered: registered
                }
            }}
            onSubmit={(formState: CompanyDetailsDto) => {
                onClick(formState)
            }}
            validateOnChange={false}
            validationSchema={creationFormValidationSchema}
            enableReinitialize
        >
            {(props: FormikProps<CompanyDetailsDto>) => {

                const {errors, touched, handleChange, handleSubmit, setFieldValue, values, setValues} = props;
                
                return (
                    <Grid item xs={10} container spacing={4}>
                        <Grid item container>
                            <Grid item xs={12}>
                                <Typography variant={"h4"}><b>Lets get some additional info!</b></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color={"secondary"} variant={"h6"}>Help us collect some basic company information.</Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid item container>
                            <AdditionalInfoForm
                                registered={registered}
                                isRegistered={isRegistered}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                errors={errors}
                                handleChange={handleChange}
                                values={values}
                                setValues={setValues}
                            />
                        </Grid>
                        <Grid container item>
                            <Grid container item xs={2} justifyContent={"flex-start"}>
                                <Grid item>
                                    <Button
                                        color={"primary"}
                                        variant={"outlined"}
                                        size={"large"}
                                        onClick={onBackClicked}
                                    >Previous</Button>
                                </Grid>
                            </Grid>
                            <Grid container xs={10} item justifyContent={"flex-end"}>
                                <Grid item>
                                    <p className={"required-notice"}>Required fields marked with *</p>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color={"primary"}
                                        variant={"outlined"}
                                        size={"large"}
                                        onClick={() => handleSubmit()}
                                    >Continue</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                );
            }}
        </Formik>
    );
}
export { AdditionalInfo }