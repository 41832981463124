import React from "react";

import { NamedComponent } from "common/generics/NamedComponent/NamedComponent";
import { FunctionAssetsTable } from "./components/FunctionAssetsTable/FunctionAssetsTable";
import { SampleAssetsTable } from "./components/SampleAssetsTable/SampleAssetsTable";

const AssetTables: NamedComponent[] = [
    {
        name: "Function Assets",
        component: <FunctionAssetsTable/>
    },
    {
        name: "Sample Assets",
        component: <SampleAssetsTable/>
    }
]
export { AssetTables }