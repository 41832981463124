import React from 'react';
import { Card, Container, Grid, Link, Typography } from "@mui/material";
import { AttachmentDropper } from "common/components/input/attachments/AttachmentDropper/AttachmentDropper";

import "./styles.scss";
import { customQuestionsService } from "common/services/CustomQuestionService/customQuestionsService";
import { BillingTier } from "auth/store/Model";
import { ValidateCompanyBillingTier } from "auth/components/ValidateCompanyBillingTier";

const QuestionImporting: React.FC = () => {

    const onFilesDropped = async (files: FileList): Promise<void> => {

        if (files.length > 1 || files.length === 0) {
            return;
        }

        const file = files.item(0);

        if (file !== null) {
            await customQuestionsService.import(file)
        }
    }

    return (
        <ValidateCompanyBillingTier billingTiers={[BillingTier.Platinum,BillingTier.Silver]}>
            <Container className={"importPage"} disableGutters maxWidth={'md'}>
                <Card variant={"outlined"}>
                    <Grid container spacing={2} justifyContent={"flex-end"}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant={'h6'} color={"textPrimary"}>Import Questions</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color={"textSecondary"}>
                                    Using our provided template, create your own custom Questions, Obligations and
                                    Activities
                                    to fully encapsulate your businesses legal requirements and company policies.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <AttachmentDropper
                                onFilesDropped={onFilesDropped}
                                uploadDescription={"a modified version of the template below"}
                            />
                        </Grid>
                        <Grid item>
                            <Link href={require("assets/templates/CustomQuestionTemplate.xlsx")}>Download Template</Link>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </ValidateCompanyBillingTier>
    );
}
export { QuestionImporting }
