import React, { useEffect, useState } from 'react';

import { BumpChart } from "./components/charts/bumpChart/BumpChart";

import { TitleCard } from "common/components/panels/TitleCard/TitleCard";
import { ComplianceOverTimeDto } from "common/services/ReportingService/models";
import { getComplianceOverTimeByObligationTop5 } from "./store/actions";
import { DashboardFunctionProps } from "../dashboard/Dashboard"; 

const ComplianceOverTimeByObligation: React.FC<DashboardFunctionProps> = ({ selectedFunction }) => {

    const [obligationCompliance, setObligationCompliance] = useState<ComplianceOverTimeDto[]>([]);

    useEffect(() => {
        getComplianceOverTimeByObligationTop5().then((response) => {
            setObligationCompliance(response);
        })
    }, [setObligationCompliance, selectedFunction])
    
    return (
        <TitleCard
            title={'Tracking Your compliance over time'}
            height={250}
            linkIcon={true}
            link={'reporting/complianceOverTimeByObligation'}
            hoverOver={'You obligation journey, displaying your compliance rating for each day of interaction and change.'}
        >
            <BumpChart data={obligationCompliance}/>
        </TitleCard>
    )
}
export { ComplianceOverTimeByObligation }