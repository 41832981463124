import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { Button } from "@mui/material";

import { DelegatedActivityCategoryDto, UserDetailsDto } from "common/dtos/Users/models";
import { UserRaciToActivityCategoryRequest } from "common/dtos/ActivityCategories/models";
import { WizardStepProps } from "wizards/models";
import { RaciManager } from "raci/RaciManager/RaciManager";
import { userSelector } from "user/store/selectors";

import "./styles.scss";

const RaciAssignments: React.FC<WizardStepProps> = ({onContinueClicked, onBackClicked, object}) => {
    
    const [searchableUsers, setSearchableUsers] = useState<UserDetailsDto[]>([]);

    const user = useSelector(userSelector);
    
    useEffect(() => {
        setSearchableUsers([user, ...object.invites])
    }, [object.invites, user]);
    
    
    const onSaveChanges = useCallback((request: UserRaciToActivityCategoryRequest, raciChanges: DelegatedActivityCategoryDto[]): Promise<void> => {
        onContinueClicked({...object, 
            raciChanges: raciChanges,
            raciRequest: request
        });
        return Promise.resolve();
    }, [object, onContinueClicked])
    
    const getUsersByName = useCallback((query: string): Promise<UserDetailsDto[]> => {
        const results = searchableUsers.filter((u: UserDetailsDto) => u.email.includes(query) || (u.identityProviderUserName && u.identityProviderUserName.includes(query)))
        return Promise.resolve(results);
    }, [searchableUsers])

    return (
        <RaciManager
            onSaveChanges={onSaveChanges}
            initialRaciChanges={object.raciChanges}
            alwaysShowBar={true}
            saveChangesBarText={"Next"}
            populateUsersFromApi={false}
            getUsersByName={getUsersByName}
            saveChangesBarAdditions={
                <Button className={"secondary-item-filled"} size={"large"} onClick={onBackClicked}>
                    Previous
                </Button>
            }
            accountableDefaultUser={user}
            accountableReadonly={true}
            ignorePermissions={true}
        />
    )
}
export { RaciAssignments }