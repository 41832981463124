import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import '@progress/kendo-theme-material'
import { PDFExport } from '@progress/kendo-react-pdf';
import CopyrightIcon from '@mui/icons-material/Copyright';

import { selectedCompanySelector, userSelector } from "../../../../user/store/selectors";
import icon from "../../../../assets/logos/ASIRTA_LowRes.png"

import './styles.scss'

export interface PdfFileOutputProps {
    pdfReportTitle: string,
    pdfExportComponent?: any
    pdfLandscape: boolean
    pdfComponent?: any
}

const PdfFileOutput: React.FC<PdfFileOutputProps> = ({ pdfExportComponent, pdfReportTitle, pdfLandscape, pdfComponent }) => {

    const user = useSelector(userSelector);
    const company = useSelector(selectedCompanySelector) 
    
    const subject = `Prepared for ${user.identityProviderUserName} of ${company?.name}`|| ""
    
    const dateFormat = new Date();
    
    const PDFExportPageTemplate = () => (
        <div className={'pdfTemplate'}>
            <p><CopyrightIcon color={'primary'} fontSize={'inherit'}/></p>
            <p>Copyright The Common Framework {moment().year()}</p>
        </div>
    )
    
    return (
        <div className={'pdfExport'}>
            <PDFExport
                paperSize="A4"
                landscape={pdfLandscape}
                margin="1cm"
                pageTemplate={PDFExportPageTemplate}
                ref={pdfExportComponent}
                author="The Common Framework"
                creator="The Common Framework"
                date={dateFormat}
                fileName={pdfReportTitle}
                subject={subject}
                title={pdfReportTitle}
            >
                <div className={'pdfTemplateDiv'} >
                    <div className={'pdfTemplateHeader'}>
                        <h3 className={'pdfHeading'}>{pdfReportTitle}</h3>
                        <h5>{subject}</h5>
                        <h6>{moment().format('DD/MM/YYYY')}</h6>
                        <img alt={'Asirta'} src={icon}/>
                        <hr/>
                        <div className={'pdfComponent'}>
                            {pdfComponent}    
                        </div>                         
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export { PdfFileOutput }