import { ColumnDefinition, ColumnType } from "common/components/tables/TableDefinition/ColumnDefinition";

export const activityCategoryTableDefinition: ColumnDefinition[] = [
    {
        key: 0,
        name: "Action",
        field: "action",
        displayLabel: false,
        hidden: false,
        type: ColumnType.Actions,
        width: "10%"
    },
    {
        key: 1,
        name: "Name",
        field: "name",
        displayLabel: true,
        hidden: false,
        type: ColumnType.Default,
        width: "65%"
    },
    {
        key: 2,
        name: "Extended Name",
        field: "extendedName",
        displayLabel: true,
        hidden: true,
        type: ColumnType.Default,
        width: "45%"
    },
    {
        key: 3,
        name: "Type",
        field: "questionType",
        displayLabel: true,
        hidden: false,
        type: ColumnType.Lookup,
        width: "20%"
    }
]