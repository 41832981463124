import React from 'react';

import { CircularProgress, Divider, Grid, Typography } from "@mui/material";

import "./styles.scss";

export interface ProgressCounterProps {
    current: number;
    total: number;
}

const ProgressCounter: React.FC<ProgressCounterProps> = ({current, total}) => {
    
    const percentage: number = current / total * 100;
    
    return (
        <Grid className={"progress_container"} container justifyContent={"center"} alignContent={"center"}>
            <Grid item xs={12}>
                <Typography variant={"h4"} align={"center"}>{current}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"h4"} align={"center"}><b>{total}</b></Typography>
            </Grid>
            <CircularProgress value={percentage} thickness={0.5} size={"7.5em"} variant={"determinate"}/>
        </Grid>
    );
}
export { ProgressCounter }