import React, { ReactElement } from "react";
import { MenuItem } from "@mui/material";
import { nicifyString } from "../Regex/regex";
import { LookupDto } from "../../services/LookupService/models";

export function getKeyValuesFromObject(obj: any){
    return Object.keys(obj).reduce((r:any, k: any) => {
        if(Object(obj[k]) !== obj[k])
            r.push({key: k, value:obj[k]});

        if(Object(obj[k]) === obj[k])
            r.push(...getKeyValuesFromObject(obj[k]));

        return r;
    }, [])
}

export function getValuesFromEnum(Enum: any): number[]{
    const keys = Object.keys(Enum).filter(k => typeof Enum[k as any] === "number"); // ["A", "B"]
    return keys.map(k => Enum[k as any]);
}

export function getKeyValueOptions(Enum: any){
    return Object.keys(Enum).filter((key: any) => !isNaN(Number(Enum[key]))).map((key: any) => {
        return {
            key: key,
            value: Enum[key]
        }
    });
}

export function orderByDate(first: Date, second: Date, newest: boolean){
    
    const f: number = new Date(first).getTime();
    const s: number = new Date(second).getTime();
    
    return newest ? s - f : f - s;
}

export function renderAsMenuItem(items: any[], idSelector: (item: any) => number, valueSelector: (item: any) => string, includeNone?: boolean): ReactElement[] {
    const output: ReactElement[] = includeNone ? [<MenuItem value={""} key={0}>None</MenuItem>] : []
    output.push(...items.map((t, i) => {
        return <MenuItem value={idSelector(t)} key={i}>{nicifyString(valueSelector(t))}</MenuItem>
    }))

    return output;
}

export function renderEnumAsMenuItem(type: any, blacklist?: any[], includeNone?: boolean, defaultItemText: string = "None"): ReactElement[] {
    const options = getKeyValueOptions(type);
    const filteredOptions = options.filter(f => !blacklist?.includes(f.value))
    
    const output: ReactElement[] = includeNone ? [<MenuItem value={""} key={defaultItemText}>{defaultItemText}</MenuItem>] : []
    output.push(...filteredOptions.map((t) => {
        return <MenuItem value={t.value} key={t.key}>{nicifyString(t.key)}</MenuItem>
    }))
    
    return output;
}

export function renderLookupsAsMenuItem(lookups: LookupDto<any>[], includeNone?: boolean, disabled?: LookupDto<any>[]): ReactElement[] {
    const output: ReactElement[] = includeNone ? [<MenuItem value={""} key={0}>None</MenuItem>] : []
    output.push(...lookups.map((t) => {
        return <MenuItem disabled={disabled && disabled.includes(t)} value={t.id} key={t.id}>{nicifyString(t.value)}</MenuItem>
    }))

    return output;
}

export function flattenObject(object: any, depth: number = 0): any{
    const flattened: any = {}
    let currentDepth: number = 0;
    
    Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'object' && object[key] !== null && currentDepth < depth) 
        {
            Object.assign(flattened, flattenObject(object[key]))
            currentDepth++;
        } 
        else 
        {
            flattened[key] = object[key]                
        }
    })

    return flattened
}

export function getProperty(object: any, propertyString: string): any {
    const fields: string[] = propertyString.split(".");
    
    let property = object;
    
    fields.forEach(v => {
        if(property !== null)
            property = property[v]
    })
    
    return property;
}