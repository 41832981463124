import React from 'react';

import {DefaultRawDatum, ResponsivePie} from '@nivo/pie'
import {DatumId} from "@nivo/pie/dist/types/types";
import * as colours from 'styles/colours.scss';

import "./styles.scss"

export interface PieChartProps {
    data: DefaultRawDatum[]
}

export interface datum {
    id: DatumId;
    label: DatumId;
    value: number;
}

const ToolTipDiv: React.FC<datum> = ({id, label, value}) => {

    const pluralStatement = value === 1 ? (
        <span>
                {value} question is currently <br/>in the {id} stage
            </span>
    ) : (<span>
                {value} questions are currently <br/>in the {id} stage
            </span>)

    return (
        <div className={'barChartToolTip'}>
            <strong className={'barChartToolTipHeading'}>{label}</strong>
            <br/>
            {pluralStatement}
        </div>
    )
}

const PieChart: React.FC<PieChartProps> = ({data}) => {

    const commonProps = {
        minValue: 0,
    }

    const pieColours = [
        colours.outstanding,
        colours.unknown,
        colours.notImplemented,
        colours.planned,
        colours.underway,
        colours.implemented,
        colours.auditedNoIssues,
        colours.certified,
    ]

    return (
        <div className={'ResponsiveBarChart'}>
            <ResponsivePie
                {...commonProps}
                data={data}
                margin={{top: 20, right: 0, bottom: 10, left: 130}}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={2}
                activeOuterRadiusOffset={4}
                borderWidth={1}
                colors={pieColours}
                borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{from: 'color'}}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{from: 'color', modifiers: [['darker', 2]]}}
                tooltip={a => (
                    <ToolTipDiv
                        id={a.datum.id}
                        value={a.datum.value}
                        label={a.datum.label}
                    />
                )}
                legends={[
                    {
                        anchor: 'top-left',
                        direction: 'column',
                        justify: false,
                        translateX: -130,
                        translateY: -20,
                        itemsSpacing: 5,
                        itemWidth: 120,
                        itemHeight: 18,
                        itemTextColor: '#000',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    )
}

export {PieChart}