import React from 'react';
import { Button, Grid, Typography } from "@mui/material";
import { faSkiing } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface AssessmentWelcomeProps {
    onNextPressed: () => void;
}

const AssessmentWelcome: React.FC<AssessmentWelcomeProps> = ({onNextPressed}) => {
    return (
        <Grid container item xs={6} spacing={2} justifyContent={"center"}>
            <Grid item><FontAwesomeIcon size={"6x"} icon={faSkiing}/></Grid>
            <Grid item xs={12}>
                <Typography variant={"h5"} align={"center"}><b>Rapid Assessment Questions</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align={"left"}>
                    You will now be presented with a set of questions for a rapid assessment, delivering a high-level view of where you are on your compliance journey.
                    <br/><br/>
                    These questions use dependency maps and are designed to determine which questions you cannot answer at this time.
                    <br/><br/>
                    The responses you provide will not capture the evidence at this stage of the process, but will allow a view of where you are within 10 mins.
                    <br/><br/>
                    In the event you do not have time to respond, or are returning to this process as a result of new areas being assigned to you, you will be to continue from where you left off.
                    <br/><br/>
                    You will be able to see which areas have been answered using this process by using the 'Answered by the rapid assessment' filter later on.
                </Typography>
            </Grid>
            <Grid item>
                <Button size={"large"} disableElevation variant={"contained"} color={"primary"} onClick={onNextPressed}>Get Started</Button>
            </Grid>
        </Grid>
    );
}
export { AssessmentWelcome }