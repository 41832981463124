import React, { ReactElement, useRef } from 'react';

import { CircularProgress, FormHelperText, Grid, TextField } from "@mui/material";
import { Autocomplete } from '@mui/material';
import _ from 'lodash';

import './styles.scss'
import {fieldProps} from "../../../../theming/models";

export interface DebounceAutocompleteProps {
    name: string;
    label: string;
    value: string;
    inputValue: string;
    options: any[];
    variant: "standard" | "filled" | "outlined" | undefined;
    isLoading: boolean;
    debounceTime: number;
    placeholder?: string;
    onValueChanged: (value: string) => void;
    onInputChanged: (value: string) => void;
    onChange: (data: any) => void;
    helperText?: ReactElement;
    error?: boolean;
    errorHelperText?: any;
    clearOnBlur?: boolean;
    getOptionSelected?: (option: any, value: any) => boolean;
}

const DebounceAutocomplete: React.FC<DebounceAutocompleteProps> = ({
                                                                       name,
                                                                       label,
                                                                       value,
                                                                       inputValue,
                                                                       variant,
                                                                       options,
                                                                       isLoading,
                                                                       debounceTime,
                                                                       placeholder,
                                                                       onInputChanged,
                                                                       onValueChanged,
                                                                       onChange,
                                                                       helperText,
                                                                       error,
                                                                       errorHelperText,
                                                                       clearOnBlur,
                                                                       getOptionSelected
                                                                   }) => {

    const debouncedQuery = useRef(_.debounce(q => onValueChanged(q), debounceTime)).current;

    const onInputChange = (event: object, val: string, reason: string) => {
        onInputChanged(val);
        debouncedQuery(val);
    }

    const onValueSelected = (event: object, value: any, reason: string) => {
        onChange(value);
    }

    const renderErrorLabel = () => {
        if (error)
            return <FormHelperText error={error}>{errorHelperText}</FormHelperText>
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Autocomplete
                    fullWidth
                    value={value && {title: value}}
                    isOptionEqualToValue={getOptionSelected}
                    getOptionLabel={(option) => option.title || ""}
                    options={options}
                    loading={isLoading}
                    clearOnBlur={clearOnBlur}
                    onInputChange={onInputChange}
                    inputValue={inputValue}
                    onChange={onValueSelected}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...fieldProps}
                            name={name}
                            fullWidth
                            label={label}
                            placeholder={placeholder}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {isLoading && <CircularProgress color="primary" size={20}/>}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            error={error}
                        />
                    )}
                />
            </Grid>
            
            <Grid item container>
                <Grid item xs={6}>
                    {renderErrorLabel()}
                </Grid>
                <Grid item xs={6}>
                    {helperText}
                </Grid>
            </Grid>


        </Grid>
    );
}
export { DebounceAutocomplete }