import { AssetDetailsDto, assetDetailsInitialState } from "common/dtos/Assets/AssetDetailsDto";
import { ObligationCategoryDetailsDto } from "common/dtos/Obligations/models";

export interface AssetLinkedObligationsDto {
    assetDetails: AssetDetailsDto;
    linkedObligations: ObligationCategoryDetailsDto[];
}

export const assetLinkedObligationsInitialState: AssetLinkedObligationsDto = {
    assetDetails: assetDetailsInitialState,
    linkedObligations: []
}