import { FunctionDetailsDto, functionDetailsInitialState } from "common/dtos/Businesses/FunctionDetailsDto";
import { QuestionGroupDto, questionGroupInitialState } from "common/dtos/Questions/QuestionDetails/models";
import { LookupDto, lookupDtoInitialState } from "common/services/LookupService/models";

export interface TrackedQuestionGroupDto {
    id: number;
    functionDetailsId: number;
    functionDetails: FunctionDetailsDto;
    questionGroupId: number;
    questionGroup: QuestionGroupDto;
    trackTypeId: number;
    trackType: LookupDto<string>;
}

export const trackedQuestionGroupInitialState: TrackedQuestionGroupDto = {
    id: 0,
    functionDetails: functionDetailsInitialState,
    functionDetailsId: 0,
    questionGroup: questionGroupInitialState,
    questionGroupId: 0,
    trackType: lookupDtoInitialState,
    trackTypeId: 0
}

export interface TrackQuestionGroupsDto {
    ids: number[];
    track: boolean;
}

/*
 public int Id { get; set; }
        
        public int FunctionDetailsId { get; set; }
        
        public FunctionDetailsDto FunctionDetails { get; set; }
        
        public int QuestionGroupId { get; set; }
        
        public QuestionGroupDto QuestionGroup { get; set; }
        
        public int TrackTypeId { get; set; }
        
        public LookupDto<string> TrackType { get; set; }
 */