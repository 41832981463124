import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import {
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    Grid,
    LinearProgress, List, ListItem, ListItemIcon, ListItemText,
    Paper,
    TextField,
    Typography
} from "@mui/material";

import { Formik, FormikProps } from 'formik';
import { nameof } from "ts-simple-nameof";
import _ from "lodash";

import { DelegationType } from "common/dtos/Common/delegation";
import { DelegatedQuestionDto, UserDetailsDto } from "common/dtos/Users/models";
import { QuestionDelegationDto, QuestionDelegationRequest } from "common/dtos/Questions/Requests/models";
import {
    getArrayDifferenceById,
    getArrayDifferencesByComparator
} from "common/helpers/Array/array";
import { UserRoleSelector } from "common/components/input/selectors/UserRoleSelector/UserRoleSelector";

import {
    DelegationAssignee,
    questionDelegateValidationSchema,
    QuestionDelegationForm,
    questionDelegationFormInitialState,
} from "./models";
import { getUsersByName } from "user/store/actions";

import "./styles.scss";
import { getNameFromUser } from "common/helpers/Naming/naming";
import { useValidPermission, ValidateUserPermission } from "auth/components/ValidateUserPermission";
import { Permission } from "auth/store/Model";

export interface QuestionDelegationProps {
    questionId: number;
    delegatedUsers: DelegatedQuestionDto[]
    open: boolean;
    onUsersDelegated: (request: QuestionDelegationRequest) => Promise<void>;
}

const QuestionDelegation: React.FC<QuestionDelegationProps> = ({
                                                                   questionId,
                                                                   delegatedUsers,
                                                                   open,
                                                                   onUsersDelegated,
                                                               }) => {

    const [assignees, setAssignees] = useState<DelegationAssignee[]>([]);
    const [searchName, setSearchName] = useState<string>("");
    const [users, setUsers] = useState<UserDetailsDto[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [formState, setFormState] = useState<QuestionDelegationForm>(questionDelegationFormInitialState);

    const debounceSearch = useMemo(() =>
        _.debounce((val) => getUsersByName(val).then(data => {
            setUsers(data);
        }).finally(() => setSearching(false)), 500), [setUsers]);

    useEffect(() => {
        const newAssignees = delegatedUsers.map((assignee): DelegationAssignee => {
            return {
                user: assignee.user,
                delegationType: assignee.delegationId,
                emailAssignee: false
            }
        })
       
        setAssignees(newAssignees)
        setFormState(prevState => { return {...prevState, assignees: newAssignees}})
        
        debounceSearch("");
    }, [delegatedUsers, debounceSearch])

    const onSearchChange = useCallback((e: ChangeEvent<any>) => {
        setSearching(true)
        setSearchName(e.target.value);
        debounceSearch(e.target.value);
    }, [debounceSearch])

    const onSubmit = useCallback((values: QuestionDelegationForm) => {

        setSaving(true);

        const unassignedUsers: DelegationAssignee[] = getArrayDifferenceById(formState.assignees, values.assignees, item => item.user.id);
        const assigneeChanges = getArrayDifferencesByComparator(
            values.assignees,
            formState.assignees,
            (source, target) => source.user.id === target.user.id && source.delegationType === target.delegationType
        );

        if (unassignedUsers.length === 0 && assigneeChanges.length === 0){
            setSaving(false);
            return;
        }

        const request: QuestionDelegationRequest = {
            unassigneeIds: unassignedUsers.map(i => i.user.id),
            assignees: assigneeChanges.map((change: DelegationAssignee): QuestionDelegationDto => {
                return {
                    userId: change.user.id,
                    delegationType: change.delegationType,
                    note: values.note,
                    emailAssignee: values.emailAssignee
                }
            })
        }

        onUsersDelegated(request).finally(() => setSaving(false));
    }, [formState.assignees, onUsersDelegated])

    const onClick = useCallback((user: UserDetailsDto) => (e: ChangeEvent<any>) => {
        const checked: boolean = e.target.checked;

        setAssignees(checked
            ? [...assignees, {user: user, delegationType: DelegationType.Responsible, emailAssignee: false}]
            : assignees.filter(a => a.user.id !== user.id)
        )
    }, [assignees])

    const userCanDelegate = !useValidPermission(Permission.QuestionsDelegate);
    
    return (
        <Collapse className={"collapseContainer"} in={open} collapsedSize={0}>
            <Formik
                initialValues={{...questionDelegationFormInitialState, assignees: assignees}}
                onSubmit={onSubmit}
                enableReinitialize
                validationSchema={questionDelegateValidationSchema}
            >
                {(props: FormikProps<QuestionDelegationForm>) => {

                    const {handleChange, handleSubmit, values} = props;

                    return (
                        <Grid container item spacing={1}>
                            <Grid item xs={12}>
                                <Paper className={"paper"}>
                                    <Grid container item>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={userCanDelegate}
                                                variant={"standard"}
                                                fullWidth size={"medium"}
                                                placeholder={"Search for User..."}
                                                value={searchName}
                                                onChange={onSearchChange}
                                            />
                                        </Grid>
                                        <Grid container item>
                                            <List dense={true}>
                                                {searching
                                                    ? <Grid item xs={12}><LinearProgress/></Grid>
                                                    : users.map((user, index) => {
                                                        return (
                                                            <ListItem key={index} disableGutters disablePadding>
                                                                <ListItemIcon>
                                                                    <Checkbox onClick={onClick(user)}
                                                                              disabled={userCanDelegate}
                                                                              readOnly={userCanDelegate}
                                                                              checked={assignees.some(a => a.user.id === user.id)}
                                                                              color={"primary"} size={"small"}/>
                                                                </ListItemIcon>
                                                                <ListItemText>{getNameFromUser(user)}</ListItemText>
                                                            </ListItem>
                                                        )
                                                    })}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Typography><b>Assignees</b></Typography>
                            </Grid>
                            <Grid container item className={"assigneeList"} spacing={1}>
                                {values.assignees.map((assignee, index) =>
                                    <Grid item xs={12} key={index}>
                                        <UserRoleSelector
                                            key={index}
                                            name={`${nameof<QuestionDelegationForm>(f => f.assignees)}[${index}].${nameof<DelegationAssignee>(d => d.delegationType)}`}
                                            assignee={assignee}
                                            onRoleChanged={handleChange}
                                            onDeleteClicked={() => setAssignees(assignees.filter(u => u.user.id !== assignee.user.id))}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name={nameof<QuestionDelegationForm>(f => f.note)}
                                    size={"small"}
                                    onChange={handleChange}
                                    value={values.note}
                                    multiline
                                    rows={2}
                                    disabled={userCanDelegate}
                                    variant={"outlined"}
                                    placeholder={"Notes..."}
                                    fullWidth
                                />
                            </Grid>
                            <Grid container item justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
                                <Grid item>
                                    <Typography variant={"caption"}>Notify by email?</Typography>
                                    <Checkbox
                                        readOnly={userCanDelegate}
                                        name={nameof<QuestionDelegationForm>(f => f.emailAssignee)}
                                        checked={values.emailAssignee}
                                        disabled={userCanDelegate}
                                        size={"small"}
                                        color={"primary"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <ValidateUserPermission permissions={[Permission.QuestionsDelegate]}>
                                    <Grid item>
                                        <Button
                                            className={"success-item-outlined"}
                                            onClick={() => handleSubmit()}
                                            variant={"outlined"}
                                            size={"small"}
                                            disabled={saving}
                                            endIcon={saving &&
                                            <CircularProgress className={"success-item-outlined"} size={20}/>}
                                        >Save Changes</Button>
                                    </Grid>
                                </ValidateUserPermission>
                            </Grid>
                          </Grid>
                    );
                }}
            </Formik>
        </Collapse>
    );
}
export { QuestionDelegation }