import { IAuthConfig } from "./store/Model"

const authConfig: IAuthConfig =
{
    authority: "https://asirtaportallive.b2clogin.com/tfp/asirtaportallive.onmicrosoft.com/B2C_1_signupsignin",
    clientId: "84c2044b-78cc-4c72-bac1-a70dca229424",
    scopes: ["https://asirtaportallive.onmicrosoft.com/84c2044b-78cc-4c72-bac1-a70dca229424/ApiAccess", "openid"],
    version: "1.24.0"
}

export const AuthConfig = authConfig