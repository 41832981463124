import React, { useState } from 'react';
import { history } from '../../../history';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";

import {
    Grid as GridTable,
    GridColumn,
    GridPageChangeEvent,
    GridSortChangeEvent,
    GridDetailRowProps
} from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor, State } from "@progress/kendo-data-query";

import "../detailedActivityRisks/styles.scss";

const ObligationRiskDetailExpanded = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem && props.dataItem.details;

    const compliantStatus = (compliant: number, status: string) => {
        return compliant === 1 ? <CheckCircleIcon className={'checkCircleIcon'} /> : status === 'Outstanding' ? <HelpIcon className={'helpIcon'}/> :  <CancelIcon className={'cancelIcon'}/>
    }
    const [pagingState, setPagingState] = useState<State>({skip: 0, take: 20});
    const [sort, setSort] = useState<SortDescriptor[]>([]);

    const skip: number = pagingState.skip!;
    const take: number = pagingState.take!;


    const onPageChange = (event: GridPageChangeEvent) => {
        setPagingState({
            ...pagingState,
            skip: event.page.skip,
            take: event.page.take
        })
    }

    const onSortChange = (event: GridSortChangeEvent) => {
        setSort(event.sort);
    }

    const getClassName = (rows: number) => {
        switch (true) {
            case (rows <= 4) : return 'return'+rows;
            default : return 'return5';
        }
    }

    const linkToQuestion = (e: number) => {
        let path = `/questions/${e}`;
        history.push(path);
    }
    
    return (
        <div className={'detailedRiskTable'}>
            <GridTable
                data={orderBy(dataItem, sort).slice(skip, take + skip)}
                {...pagingState}
                onPageChange={onPageChange}
                filterable={false}
                onSortChange={onSortChange}
                total={dataItem.length}
                pageable={dataItem.length > 5}
                sortable={true}
                sort={sort}
                className={getClassName(dataItem.length)}
                onRowClick={e => linkToQuestion(e.dataItem.questionDetailsId)}
            >
                <GridColumn field={"question"} title={"Obligation Question"}
                />
                <GridColumn field={"lastResponseBy"} title={"Last Response By"} width={"250px"}
                />
                <GridColumn field={"status"} title={"Status"} width={"200px"}
                />
                <GridColumn field={"compliancePercentage"} title={"Compliant"} width={"150px"}
                            cell={props => <td>{compliantStatus(props.dataItem.compliant, props.dataItem.status)}</td>}
                />
            </GridTable>
        </div>


    );
};

export { ObligationRiskDetailExpanded }