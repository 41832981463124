import React from "react";

import { ComponentStep } from "common/components/panels/ComponentStepper/ComponentStep";
import { Welcome } from "./components/Welcome/Welcome"
import { CreationForm } from "./components/CreationForm/CreationForm";
import { AssignSectors } from "./components/AssignSectors/AssignSectors";
import { Confirmation } from "./components/Confirmation/Confirmation";
import { functionCreationInitialState } from "./models";
import { Completion } from "./components/Completion/Completion";

const BusinessFunctionWizardSteps: ComponentStep[] = [
    {
        label: "Welcome",
        component: <Welcome object={functionCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Create a Business Function",
        component: <CreationForm object={functionCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Assign Sectors",
        component: <AssignSectors object={functionCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Confirmation",
        component: <Confirmation object={functionCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: true
    },
    {
        label: "Completion",
        component: <Completion object={functionCreationInitialState} onBackClicked={() => {}} onContinueClicked={() => {}}/>,
        isVisible: false
    },
]
export { BusinessFunctionWizardSteps }