import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, CircularProgress, Grid } from "@mui/material";
import { RiskLabelTable } from "riskLabels/components/RiskLabelTable/RiskLabelTable";
import { companyService } from "common/services/CompanyService/companyService";
import { riskLabelsValidationSchema } from "settings/companies/components/RiskLabelEditing/models";
import {
    riskLabelRequestInitialState,
    RiskLabelsUpdateRequest
} from "common/requests/Companies/RiskLabelsUpdateRequest";
import { CompanyDetailsDto } from "common/dtos/Companies/models";
import { Permission } from "auth/store/Model";
import { ValidateUserPermission } from "auth/components/ValidateUserPermission";

export interface RiskLabelEditingProps {
    company: CompanyDetailsDto | undefined;
}

const RiskLabelEditing: React.FC<RiskLabelEditingProps> = ({company}) => {

    const [riskLabelRequest, setRiskLabelRequest] = useState<RiskLabelsUpdateRequest>(riskLabelRequestInitialState);
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        companyService.getCompanySettings().then(response => {
            setRiskLabelRequest({
                
                riskLabels: response.riskSettings
                
                
            });
        });
    }, [setRiskLabelRequest, company])

    const saveChanges = useCallback((request: RiskLabelsUpdateRequest) => {

        setSaving(true);
        companyService.updateRiskLabels(request).then(_ => {
            setRiskLabelRequest(request);
        }).finally(() => {
            setSaving(false);
        })
    }, [])

    return (
        <Formik
            initialValues={riskLabelRequest}
            validationSchema={riskLabelsValidationSchema}
            enableReinitialize
            onSubmit={(values) => {
                saveChanges(values)
            }}
        >
            {({
                  values,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                  touched,
                  errors
              }) => {
                console.log(touched,errors)
                return (
                    <Grid container spacing={2} justifyContent={"flex-end"}>
                        <Grid item xs={12}>
                            <RiskLabelTable
                                riskSettings={values.riskLabels}
                                touched={touched}
                                errors={errors}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                turnover={company?.additionalDetails.annualTurnover ?? 10000}
                            />
                        </Grid>
                        <ValidateUserPermission permissions={[Permission.CompanyContextsEdit]}>
                            <Grid item>
                                <Button
                                    onClick={() => handleSubmit()}
                                    variant={"outlined"}
                                    className={"success-item-outlined"}
                                    endIcon={saving && <CircularProgress size={20} className={"success-item-outlined"}/>}
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                        </ValidateUserPermission>
                    </Grid>
                );
            }}
        </Formik>
    );
}
export { RiskLabelEditing }