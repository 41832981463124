import React from 'react';
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";
import { Chip, Grid, Paper, Typography } from "@mui/material";

import { QuestionDetailedAnswer, QuestionDetailsDto } from "common/dtos/Questions/QuestionDetails/models";
import { AnswerValue } from "common/dtos/Questions/models";
import { nicifyString } from "common/helpers/Regex/regex";

import "./styles.scss";

export interface QuestionItemProps {
    question: QuestionDetailsDto;
    questionNumber: number;
    answerDetails: QuestionDetailedAnswer;
}

const QuestionItem: React.FC<QuestionItemProps> = ({question, questionNumber, answerDetails}) => 
{
    const hasBeenUpdated = answerDetails.lastUpdatedBy;
    const lastUpdatedBy = hasBeenUpdated ? `| Last updated by ${answerDetails.lastUpdatedBy}` : "";
    
    return (
        <Paper className={'questionItem'}>
            <Link to={`/questions/${question.id}`}>
                <Grid container justifyContent={"space-between"} spacing={1}>
                    <Grid container item xs={12} spacing={1} justifyContent={"space-between"}>
                        <Grid item xs={10}>
                            <Typography color={"primary"} variant={"body1"} fontWeight={600}>{question.activityHeader}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Chip className={`answerStatusChip ${_.camelCase(AnswerValue[answerDetails.answer])}`} label={nicifyString(AnswerValue[answerDetails.answer])}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color={'textPrimary'} variant={"body2"}>{question.activity}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent={"space-between"}>
                        <Grid item>
                            <Typography color={'textSecondary'} variant={"body2"}>{`#${questionNumber} ${lastUpdatedBy}`}</Typography>
                        </Grid>
                        { hasBeenUpdated &&
                        <Grid item>
                            <Typography color={'textSecondary'} variant={"body2"}>{`Updated ${moment.utc(answerDetails.lastUpdatedDate).fromNow()}`}</Typography>
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </Link>
        </Paper>
    );
    
}
export { QuestionItem }