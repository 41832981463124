import { LookupDto } from "common/services/LookupService/models";
import { RiskSettingsDto } from "riskLabels/models";
import {UserInvitationDto} from "users/InviteUsersForm/models";
import { UserRaciToActivityCategoryRequest } from "common/dtos/ActivityCategories/models";
import { CompanyDetailsDto, companyDetailsInitialState } from "common/dtos/Companies/models";
import { FunctionDetailsDto } from "common/dtos/Businesses/FunctionDetailsDto";

export interface CompanyCreation {
    company: CompanyDetailsDto;
    types: LookupDto<string>[];
    sectors: LookupDto<string>[];
    riskSettings: RiskSettingsDto[];
    invites: UserInvitationDto[];
    raciRequest: UserRaciToActivityCategoryRequest;
    defaultCompany: boolean;
    startAccessment: boolean;
    isRegistered: boolean;
}

export interface CompanyCreationResponse {
    company: CompanyDetailsDto;
    function: FunctionDetailsDto;
}

export const companyCreationInitialState: CompanyCreation = {
    company: companyDetailsInitialState,
    types: [],
    sectors: [],
    riskSettings: [],
    invites: [],
    raciRequest: {
        raciUnassignments: [],
        raciAssignments: []
    },
    defaultCompany: false,
    startAccessment: false,
    isRegistered: true
}